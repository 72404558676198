import midUseful from "../assets/animation/mid-useful.json";
import lowUseful from "../assets/animation/low-useful.json";
import highUseful from "../assets/animation/high-useful.json";
import lowFriendly from "../assets/animation/low-friendly.json";
import midFriendly from "../assets/animation/mid-friendly.json";
import highFriendly from "../assets/animation/high-friendly.json";
import lowBad from "../assets/animation/low-bad.json";
import midBad from "../assets/animation/mid-bad.json";
import highBad from "../assets/animation/high-bad.json";

import { SPOT_COLORS } from "./calcVotesPercantage";

export const chooseAnimation = (color: string) => {
	if (color === SPOT_COLORS.Dangerous.LOW) {
		return lowBad;
	} else if (color === SPOT_COLORS.Dangerous.MIDDLE) {
		return midBad;
	} else if (color === SPOT_COLORS.Dangerous.TOP) {
		return highBad;
	} else if (color === SPOT_COLORS["Bike Friendly"].LOW) {
		return lowFriendly;
	} else if (color === SPOT_COLORS["Bike Friendly"].MIDDLE) {
		return midFriendly;
	} else if (color === SPOT_COLORS["Bike Friendly"].TOP) {
		return highFriendly;
	} else if (color === SPOT_COLORS.Useful.LOW) {
		return lowUseful;
	} else if (color === SPOT_COLORS.Useful.MIDDLE) {
		return midUseful;
	} else if (color === SPOT_COLORS.Useful.TOP) {
		return highUseful;
	}
};
