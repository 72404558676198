import { db } from "../firebase";
import { collection, getDocs } from "firebase/firestore";

export const getUserCount = async () => {
	try {
		const usersCollection = collection(db, "users");
		const spotsCollection = collection(db, "spots");
		const userSnapshot = await getDocs(usersCollection);
		const spotsSnapshot = await getDocs(spotsCollection);
		const userCount = userSnapshot.size;
		const spotsCount = spotsSnapshot.size;
		return {
			userCount,
			spotsCount,
		};
	} catch (error) {
		console.error("Error fetching user count:", error);
	}
};
