import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

export const uploadImageToStorage = async (
	imageFile: File,
	userId: string,
	path: string = "/profilesPics"
) => {
	const storage = getStorage();
	const storageRef = ref(storage, userId + path);

	await uploadBytes(storageRef, imageFile);

	const url = await getDownloadURL(storageRef);

	return url;
};
