import AboutSection from "./AboutSection";
import AboutParagraph from "./AboutParagraph";
import {useTranslation} from "react-i18next";

const ColumnRight = () => {
  const {t} = useTranslation();

  return (
    <AboutSection title={t("about.column-right.title")}>
      <AboutParagraph title={t("about.column-right.section_1.title")}>
        <p>{t("about.column-right.section_1.text_1")}</p>
        <p>{t("about.column-right.section_1.text_2")}</p>
      </AboutParagraph>
      <AboutParagraph title={t("about.column-right.section_2.title")}>
        <p>{t("about.column-right.section_2.text_1")}</p>
        <p>{t("about.column-right.section_2.text_2")}</p>
      </AboutParagraph>
      <AboutParagraph title={t("about.column-right.section_3.title")}>
        <p>{t("about.column-right.section_3.text_1")}</p>
        <p>{t("about.column-right.section_3.text_2")}</p>
      </AboutParagraph>
    </AboutSection>
  );
};

export default ColumnRight;
