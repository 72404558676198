import React from "react";

interface IButtonsProps {
  onCancel: () => void;
  onSubmit: () => void;
  cancelLabel: string;
  submitLabel: string;
  cancelButtonStyle: string;
  submitButtonStyle: string;
  position: string;
}

const ProfileEditButtons: React.FC<IButtonsProps> = ({
  onCancel,
  onSubmit,
  cancelLabel,
  submitLabel,
  position,
  cancelButtonStyle,
  submitButtonStyle,
}) => {
  return (
    <div className={`flex ${position} items-end mt-2 md:mt-0 fixed gap-3`}>
        <button
          onClick={onCancel}
          className={`flex items-center justify-center ${cancelButtonStyle}`}
        >
          {cancelLabel}
        </button>
        <button
          onClick={onSubmit}
          className={`flex items-center justify-center w-[10rem] h-[3.5rem] ${submitButtonStyle}`}
        >
          {submitLabel}
        </button>
    </div>
  );
};

export default ProfileEditButtons;
