import { useState } from "react";
import { Modal } from "../../../../../shared/Modal";
import {
	REPORT_OPTIONS,
	REPORT_COMMENT_OPTIONS,
} from "../../../../../constants";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { formatCurrentTime } from "../../../../../utils/getTime";
import { collection, addDoc } from "@firebase/firestore";
import { db } from "../../../../../firebase/firebase";
import { reportCommentThunk } from "../../../../../firebase/comments/handleComments";

interface IReportModal {
	isOpen: boolean;
	handleClose: () => void;
	spotId?: string;
	commentId?: string;
	comment?: string | undefined;
	currentUser?: string | undefined;
}

const ReportModal = ({
	isOpen,
	handleClose,
	spotId,
	commentId,
	comment,
	currentUser,
}: IReportModal) => {
	const { t } = useTranslation();
	const lang = i18next.language;

	const [selectedTags, setSelectedTags] = useState<string[]>([]);
	const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

	const handleSelect = (id: string) => {
		const arr = [...selectedTags];
		arr.includes(id)
			? setSelectedTags(arr.filter((item) => item !== id))
			: arr.push(id) && setSelectedTags(arr);
	};

	const handleSubmit = async () => {
		const submitObj: { status: string; [key: string]: any } = {
			status: "New",
		};

		for (let tag of selectedTags) {
			if (spotId) {
				submitObj[tag] = {
					[spotId]: formatCurrentTime(),
				};
			}
		}

		const spotsCollectionRef = collection(db, "reportedSpots");
		const docRef = await addDoc(spotsCollectionRef, submitObj);

		setIsSubmitted(true);
	};

	const handleCommentSubmit = () => {
		if (comment && currentUser && commentId && spotId) {
			reportCommentThunk({
				reportType: selectedTags,
				comment: comment,
				commentId: commentId,
				currentUser: currentUser,
				spotId: spotId,
			});
			setIsSubmitted(true);
		}
	};

	const newHandleClose = () => {
		handleClose();
		setIsSubmitted(false);
	};

	const options = commentId ? REPORT_COMMENT_OPTIONS : REPORT_OPTIONS;

	return (
		<Modal
			isOpen={isOpen}
			handleClose={newHandleClose}
			modalContainerClassName={"max-w-[710px] relative p-[40px] rounded-xl"}
		>
			<div className="max-w-[710px] w-[100%] my-0 mx-auto overflow-auto">
				<div className="mb-[24px]">
					<div className="mb-[8px] flex items-center gap-[8px]">
						<svg
							width="25"
							height="25"
							viewBox="0 0 25 25"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M6.60084 3.5C4.33601 3.5 2.5 5.29086 2.5 7.5V20.5C2.5 21.0523 2.959 21.5 3.52521 21.5C4.09142 21.5 4.55042 21.0523 4.55042 20.5V14.5H10.3183C10.7735 16.2252 12.3791 17.5 14.2899 17.5H18.9034C21.1682 17.5 23.0042 15.7091 23.0042 13.5V9.5C23.0042 7.29086 21.1682 5.5 18.9034 5.5H15.1401C14.7179 4.33481 13.5786 3.5 12.2395 3.5H6.60084ZM11.2143 12.5H4.55042V7.5C4.55042 6.39543 5.46842 5.5 6.60084 5.5H12.2395C12.8057 5.5 13.2647 5.94772 13.2647 6.5V10.5C13.2647 11.6046 12.3467 12.5 11.2143 12.5ZM14.2899 15.5C13.4587 15.5 12.743 15.0175 12.4211 14.324C14.0969 13.8214 15.3151 12.2995 15.3151 10.5V7.5H18.9034C20.0358 7.5 20.9538 8.39543 20.9538 9.5V13.5C20.9538 14.6046 20.0358 15.5 18.9034 15.5H14.2899Z"
								fill="#16181C"
							/>
						</svg>

						<h4 className="font-poppins text-[22px] font-semibold">
							{!isSubmitted
								? commentId
									? t("activity.report_comment")
									: t("activity.report_spot")
								: t("activity.report_spot_success")}
						</h4>
					</div>
					<h5 className="font-poppins text-[16px]">
						{!isSubmitted
							? commentId
								? t("activity.report_comment_dscrpt")
								: t("activity.report_spot_dscrpt")
							: t("activity.report_spot_dscrpt_success")}
					</h5>
				</div>
				{!isSubmitted ? (
					<div className="grid grid-cols-2 gap-[12px] mb-[16px] p-[5px] h-[400px] overflow-y-auto">
						{options.map(
							({ id, enTitle, enDescription, deDescription, deTitle }) => {
								const title = lang === "de" ? deTitle : enTitle;
								const description =
									lang === "de" ? deDescription : enDescription;
								return (
									<button
										key={id}
										onClick={() => {
											handleSelect(id);
										}}
										className={`border-2 border-[#dfe1e5] rounded-[15px] p-[16px] cursor-pointer text-left ${
											selectedTags.includes(id)
												? "outline outline-2 outline-[#3A85F4]"
												: "outline outline-1 outline-lightGray"
										}`}
									>
										<h4 className="font-poppins text-[#16181c] font-bold">
											{title}
										</h4>
										<p>{description}</p>
									</button>
								);
							}
						)}
					</div>
				) : (
					<div className="h-[400px] flex justify-center items-center">
						<svg
							width="48"
							height="48"
							viewBox="0 0 48 48"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M42.3574 13.4142C43.1582 12.6332 43.1582 11.3668 42.3574 10.5858C41.5567 9.80474 40.2584 9.80474 39.4577 10.5858L17.7524 31.7574C16.9516 32.5384 15.6534 32.5384 14.8527 31.7574L7.50029 24.5858C6.69955 23.8047 5.40129 23.8047 4.60055 24.5858C3.79982 25.3668 3.79982 26.6332 4.60055 27.4142L11.9529 34.5858C14.3551 36.9289 18.2499 36.9289 20.6521 34.5858L42.3574 13.4142Z"
								fill="#16181C"
							/>
						</svg>
					</div>
				)}

				<div>
					{!isSubmitted && (
						<button
							className="group flex w-full gap-[8px] items-center transition-all justify-center bg-[#0A0B0E] rounded-[30px] py-[14px] text-center text-white font-poppins disabled:bg-[#DFE1E5] disabled:text-[#94979C]"
							type="button"
							onClick={() => {
								commentId ? handleCommentSubmit() : handleSubmit();
							}}
							disabled={selectedTags.length === 0}
						>
							{t("addSpot.submit")}
						</button>
					)}

					<button
						className={`flex w-full items-center border-2 border-[#F0F0F0] mt-[16px] transition-all justify-center rounded-[30px] py-[14px] text-center text-[#16181C] text-[16px] font-poppins hover:border-[#BFC2C7] hover:dg-[#FAFAFA] transition-all ${
							isSubmitted ? "bg-[#242629] text-white" : "bg-white"
						}`}
						type="button"
						onClick={newHandleClose}
					>
						{!isSubmitted
							? t("addSpot.cancel")
							: t("activity.report_spot_close")}
					</button>
				</div>
			</div>
		</Modal>
	);
};

export default ReportModal;
