import { useTranslation } from "react-i18next";

import VoteSystem from "../../../shared/VoteSystem";

export interface IVotes {
	date: number;
	submitted_by: string;
	tokens: number;
}

interface IAddVotes {
	votes: number;
	color: string;
	percantage: number;
	voteInfo: IVotes;
	category: string;
	handleChange: (str: string) => void;
}

const AddVotes = ({
	votes,
	color,
	percantage,
	handleChange,
	voteInfo,
	category,
}: IAddVotes) => {
	const { t } = useTranslation();

	return (
		<div className="grid grid-cols-[140px,_auto] gap-[40px] bg-[#ffffff] p-[16px] rounded-[10px] mb-[16px]">
			<div>
				<h4 className="text-xl text-[#1a1a1a]">{t("addSpot.vote")}</h4>
				<p className="text-midGray poppins text-sm">{t("addSpot.vote_text")}</p>
			</div>
			<VoteSystem
				votes={votes}
				color={color}
				percantage={percantage}
				handleChange={handleChange}
				voteInfo={voteInfo}
				category={category}
			/>
		</div>
	);
};

export default AddVotes;
