import { useTranslation } from "react-i18next";
import { setTokens } from "../../../redux/api/userSlice";
import { useAppDispatch } from "../../../redux/hooks";
import { updateTokensForSelectedProject } from "../../../redux/thunks";

import { closeIcon } from "../../../assets/shared";

interface IAddPlaceHeader {
	editPlace: boolean;
	handleClose: () => void;
	tokens: number;
}

const AddPlaceHeader = ({
	editPlace,
	handleClose,
	tokens,
}: IAddPlaceHeader) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	return (
		<div className="flex justify-between items-center pb-[16px] sticky top-0 bg-[#F0F0F0] pt-4 z-[15] relative">
			<h3 className="font-semibold text-xl tracking-tighter text-gray-900">
				{editPlace ? t("addSpot.update_spot") : t("addSpot.create_spot")}
			</h3>
			<button
				onClick={() => {
					handleClose();
					dispatch(updateTokensForSelectedProject(tokens));
				}}
				className="bg-[#ffffff] rounded-full h-12 w-12 flex justify-center items-center p-1"
			>
				<img src={closeIcon} alt="close" />
			</button>
		</div>
	);
};

export default AddPlaceHeader;
