import { collection, DocumentData, getDocs } from "firebase/firestore";
import { db } from "./firebase";
import { calcVotesPercantage } from "../utils/calcVotesPercantage";

export const getSpots = async () => {
	try {
		const spotsData: DocumentData[] = [];
		const spotsDocs = await getDocs(collection(db, "spots"));

		spotsDocs.forEach((doc) => {
			const spotDoc = doc.data();
			spotsData.push({ ...spotDoc, id: doc.id });
		});

		return calcVotesPercantage(spotsData);
	} catch (err) {
		console.error(err);
	}
};
