import Alarm from "./Alarm";

interface Props {
  placeholder: string;
  register: any;
  name: string;
  type: string;
  error: string | undefined;
}

const Textarea = ({placeholder, type, register, name, error}: Props) => {
  return (
    <>
      <textarea
        className="formField h-24 resize-none"
        cols={30}
        rows={5}
        placeholder={placeholder}
        type={type}
        {...register(name)}
      />
      {error && <Alarm error={error} />}
    </>
  );
};

export default Textarea;
