import { useTranslation } from "react-i18next";

interface Props {
	category: {
		img: string;
		text: string;
		name: string;
	};
	selectCategory: (category: string) => void;
	selectedCategory: string;
}

const SingleAddCategory = ({
	category,
	selectCategory,
	selectedCategory,
}: Props) => {
	const { t } = useTranslation();

	return (
		<button
			className={`flex justify-center h-10 p-[8px] border-2 rounded-[10px] items-center text-[14px] leading-[19px] gap-2 ${
				selectedCategory === category.name ? "border-[#3E85F0]" : ""
			}`}
			onClick={(e) => {
				e.preventDefault();
				selectCategory(category.name);
			}}
		>
			<img className="w-6 h-6" src={category.img} alt="category-icon" />
			<span>{t(`ranking.categories.${category.name}`)}</span>
		</button>
	);
};

export default SingleAddCategory;
