import AppButton from "../../shared/AppButton";
import { radicalChange } from "../../assets/start-page";
import Header from "../header/Header";
import mainParrotScene from "../../assets/animation/main-parrot-scene.json";
import { paths } from "../../constants";
import Footer from "../Footer";
import Phone from "./Phone";
import Newsletter from "./Newsletter";
import { useTranslation } from "react-i18next";
import HeaderLink from "../../shared/HeaderLink";
import useGetAnimation from "../../hooks/useGetAnimation";

import i18n from "i18next";


const StartPage = () => {
 

  const Animation = useGetAnimation(mainParrotScene);
  const { t } = useTranslation();

  const currentLanguage = i18n.language;

  const headerClassNames: Record<string, string> = {
    en: "max-w-[300px] lg:max-w-[900px]",
    de: "max-w-[900px] lg:max-w-[900px]",
  };

  return (
    <>
      <Header>
        <HeaderLink />
      </Header>
      
      <div className="pt-[200px] lg:pt-[215px] mx-auto w-full mb-[82px] flex flex-col items-center font-poppins">
        <div
          className={`text-[40px] lg:text-[60px] flex items-center leading-[56px] lg:leading-[76px] justify-center font-poppinsBold w-full  text-center ${
            headerClassNames[currentLanguage as any]
          } px-4`}
        >
          {t("start-page.title")}
        </div>
        <div className="text-[20px] lg:text-[24px] flex items-center justify-center w-full  text-center max-w-[900px] px-4">
          {t("start-page.description")}
        </div>
        <section>
          <div className="w-full relative flex flex-col items-center gap-y-[72px] mb-16 lg:mb-20">
            {Animation}
            <div className="px-4 flex flex-col lg:flex-row  items-center justify-center gap-8 lg:gap-[22px]  static lg:absolute top-[52px] z-30">
              <AppButton app={"appstore"} />
              <AppButton app={"google"} />
            </div>
          </div>
          <Phone />
          <Newsletter />
          <div className="px-4 flex items-center flex-col justify-center">
            <p className="text-lg mb-5">{t("start-page.partnership")}</p>
            <a target="_blank" rel="noopener noreferrer" href={paths.radical}>
              <img src={radicalChange} alt="radical-change" />
            </a>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default StartPage;
