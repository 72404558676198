import {useTranslation} from "react-i18next";
import Section from "../../Section";

const Push = () => {
  const {t} = useTranslation();
  return (
    <Section title={t("policy.push.title")}>
      <p>{t("policy.push.text_1")}</p>
    </Section>
  );
};

export default Push;
