import { collection, DocumentData, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import { handleCommentsUser, sortComments } from "./handleComments";
import { getReplies } from "./getReplies";

export const getComments = async (spotId: string) => {
	const querySnapshot = await getDocs(
		collection(db, "spots", spotId, "comments")
	);
	//   "YaeMNwlNfwkAWgtMzmae"
	let replies: DocumentData[] = [];
	let parentComments: DocumentData[] = [];
	if (!querySnapshot.empty) {
		querySnapshot.forEach((doc) => {
			const comment = doc.data();
			comment.parent_comment_id
				? replies.push(comment)
				: parentComments.push(comment);
		});
	}
	parentComments = await Promise.all(
		parentComments.map(
			async (comment: DocumentData) => await handleCommentsUser(comment)
		)
	);
	parentComments = await Promise.all(
		parentComments.map(async (comment: DocumentData) => {
			const commentReplies = replies.filter(
				(reply: DocumentData) => reply.parent_comment_id === comment.id
			);
			const handledReplies = await getReplies(commentReplies, comment);

			return {
				...comment,
				replies: handledReplies,
				repliesAmount: handledReplies.length,
			};
		})
	);
	const sortedComments = sortComments(parentComments);
	return sortedComments.filter((comment: DocumentData) => comment);
};
