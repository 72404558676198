import Section from "./Section";
import {useLottie} from "lottie-react";
import whiteParrotAnimation from "../../assets/animation/white-parrot.json";
import {useTranslation} from "react-i18next";

const Newsletter = () => {
  const {t} = useTranslation();

  const options = {
    animationData: whiteParrotAnimation,
    loop: true,
  };
  const {View} = useLottie(options);

  return (
    <div className="px-4 flex flex-col  items-center justify-center mb-[85px] lg:mb-32">
      <div className="w-[86px] lg:w-[104px] mb-3">{View}</div>
      <Section
        title={t("start-page.news-letter.title")}
        textAlign="text-center"
      >
        <p className="lg:mb-5 text-center">
          {t("start-page.news-letter.text_1")}
        </p>
        <form
          className="w-full"
          action="https://parry.us11.list-manage.com/subscribe/post?u=c9a1b7bd7dd4363fba9c33555&amp;id=a4932a5ee0&amp;f_id=00559ae0f0"
          method="post"
          target="_blank"
        >
          <div className="lg:bg-[#F2F2F2] rounded-full w-full relative flex flex-col lg:flex-row items-center">
            <input
              placeholder={t("start-page.news-letter.placeholder")}
              type="email"
              id="mce-EMAIL"
              name="EMAIL"
              className="text-center lg:text-left w-[282px] lg:w-full text-[19px] lg:text-lg bg-[#F2F2F2] px-5 lg:px-8 py-3 lg:py-4 rounded-full outline-none placeholder:text-[#C8C8C8] mb-6 lg:mb-0 "
              required
            />
            <button
              type="submit"
              className="lg:absolute w-max top-0 right-0 text-[16px] lg:text-lg border-[3px] lg:border-4 border-black hover:bg-black transition-all duration-300 hover:text-white rounded-full px-6 py-3 bg-white"
            >
              {t("start-page.news-letter.sign-up")}
            </button>
          </div>
        </form>
      </Section>
    </div>
  );
};

export default Newsletter;
