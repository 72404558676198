// import { useEffect } from "react";
// import { getProjects } from "../../firebase/getProjects";
import ProjectCard from "./ProjectCard";
// import useGetCardsInfo from "../../hooks/useGetCardsInfo";
// import { IProjectCard } from "../../interfaces";
// import useGetProjects from "../../hooks/useGetProjects";
import { Project } from "../../interfaces";
import { useAppSelector } from "../../redux/hooks";
import withExtraComponent from "../../utils/HOCs/withExtraComponent";

export const ProjectList = () => {
	// useGetProjects();
	const cards = useAppSelector((state) => state.projectCards.projects);

	return (
		<>
			{cards &&
				cards.map((card: Project) => (
					<ProjectCard
						id={card.id!}
						key={card.id}
						linkTo={card.projectSlug}
						images={card.images}
						tagName={card.tag_name}
						title={card.name}
						description={card.description}
						location={card.locationString}
						reactions={true}
					/>
				))}
		</>
	);
};

const EnhancedProjectList = withExtraComponent(
	ProjectList,
	"flex md:gap-10 xl:gap-[40px] relative w-full max-w-[708px] rounded-xl flex-wrap"
);

export default EnhancedProjectList;
