import { Modal } from "../../../../shared/Modal";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { paths } from "../../../../constants";
import { useTranslation } from "react-i18next";

import voteGradationsEn from "../../../../assets/detailedView/votegradationsEN.png";
import voteGradationsDe from "../../../../assets/detailedView/votegradationsDE.png";
import votingBarEN from "../../../../assets/detailedView/votingbarinfoEN.png";
import votingBarDE from "../../../../assets/detailedView/votingbarInfoDE.png";

interface IVotingInfoModal {
	isOpen: boolean;
	handleClose: () => void;
}

const VotingInfoModal = ({ isOpen, handleClose }: IVotingInfoModal) => {
	const { t } = useTranslation();

	const [currLang, setCurrLang] = useState<"de" | "en">("de");

	useEffect(() => {
		const lang = localStorage.getItem("lang");
		if (lang) {
			setCurrLang(lang as "de" | "en");
		} else {
			setCurrLang("de");
		}
	}, [currLang]);

	return (
		<Modal
			isOpen={isOpen}
			handleClose={handleClose}
			modalContainerClassName={
				"max-w-[940px] w-full max-h-[600px] relative py-[60px] overflow-y-auto overflow-x-hidden"
			}
		>
			<div className="max-w-[840px] w-full mx-auto">
				<h2 className="mb-[32px] font-poppinsBold text-[22px]">
					{t("ranking.qvInfo.title")}
				</h2>
				<div className="flex flex-col gap-[16px] text-[14px]">
					<p>{t("ranking.qvInfo.subtitle")}</p>
					<div>
						<h4 className="font-poppinsBold mb-[8px]">{t("ranking.rank")}</h4>
						<p>{t("ranking.qvInfo.rankText")}</p>
					</div>
					<div>
						<h4 className="font-poppinsBold mb-[8px]">
							{t("ranking.qvInfo.priority")}
						</h4>
						<div className="flex items-center gap-[48px]">
							<p className="basis-[44.84%]">
								{t("ranking.qvInfo.priorityText")}{" "}
								<p>{t("ranking.qvInfo.priorityBottom")}</p>{" "}
								<p>{t("ranking.qvInfo.priorityMiddle")}</p>{" "}
								<p>{t("ranking.qvInfo.priorityTop")}</p>
							</p>
							<img
								src={currLang === "en" ? voteGradationsEn : voteGradationsDe}
								alt="vote gradations"
								className="basis-auto"
							/>
						</div>
					</div>
					<div>
						<h4 className="font-poppinsBold mb-[8px]">
							{t("ranking.qvInfo.voters")}
						</h4>
						<p>{t("rankingqvInfo..votersText")}</p>
					</div>
					<div>
						<h4 className="font-poppinsBold mb-[8px]">
							{t("ranking.qvInfo.votes")}
						</h4>
						<div className="flex items-center gap-[48px]">
							<div className="basis-[44.84%] flex flex-col gap-[30px]">
								<p>{t("ranking.qvInfo.votesTextFirst")}</p>
								<p>
									{t("ranking.qvInfo.votesTextSecond")}{" "}
									<Link className="underline" to={paths.qv}>
										{t("ranking.qvInfo.votesLink")}
									</Link>
									.
								</p>
								<p>{t("ranking.qvInfo.votesTextThird")}</p>
							</div>
							<img
								src={currLang === "en" ? votingBarEN : votingBarDE}
								alt="vote gradations"
								className="basis-auto"
							/>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default VotingInfoModal;
