import { useAppSelector } from "../../../redux/hooks";

import { useTranslation } from "react-i18next";
import successBg from "../../../assets/addplace/successBg.jpg";
import successPary from "../../../assets/addplace/parrySucess.svg";

const PlaceCreated = () => {
	const { t } = useTranslation();

	const creationStatus = useAppSelector(
		(state) => state.location.placeCreationStatus
	);

	return (
		<div
			className={`w-[750px] h-full relative transition-all overflow-hidden rounded-[20px] p-[16px] ${
				creationStatus === "idle"
					? "translate-y-[100%] opacity-0"
					: "translate-y-[0%] opacity-1"
			}`}
		>
			<div className="relative z-10">
				<h3 className="font-poppins text-[24px]">{t("addSpot.success")}</h3>
			</div>
			<div className="absolute top-[50%] z-10 left-[50%] translate-x-[-50%] translate-y-[-50%] flex justify-center items-center flex-col gap-[12px]">
				<img className="w-[172px]" src={successPary} alt="parry" />
				<p className="font-poppins text-[20px] text-center">
					{t("addSpot.success_thanks")}
				</p>
			</div>
			<img
				className="absolute w-full h-full left-0 top-0 object-cover"
				src={successBg}
				alt="sucess"
			/>
		</div>
	);
};

export default PlaceCreated;
