export const roundTokens = (tokens: number) => {
	return Math.floor(Math.sqrt(tokens));
};

export const updateVoteInfo = (
	currData: any,
	userID: string | undefined,
	tokens: number,
	isAddingVote: boolean,
	currTokens?: number
) => {
	if (userID && currData[userID]) {
		const currVotes = roundTokens(currData[userID].tokens);
		const maxVotes = roundTokens(tokens);

		if (isAddingVote) {
			const userVotes = currVotes + 1;

			if (
				currTokens &&
				currTokens - userVotes ** 2 + currData[userID].tokens < 0
			)
				return null;
			if (userVotes > maxVotes + userVotes) return null; // CHANGED ADDED userVotes

			return {
				date: Date.now(),
				submitted_by: userID,
				tokens: userVotes ** 2,
			};
		} else {
			if (currVotes === 0 || isNaN(currVotes)) return null; // TODO: Handle no votes to remove

			const userVotes = currVotes - 1;

			if (userVotes === 0) return null;
			return {
				date: Date.now(),
				submitted_by: userID,
				tokens: userVotes ** 2,
			};
		}
	} else if (userID) {
		return null;
	}
};

export const createVoteInfo = (
	userID: string | undefined,
	testFn: () => void = () => {}
) => {
	if (userID) {
		testFn();
		return {
			date: Date.now(),
			submitted_by: userID,
			tokens: 1,
		};
	}
};
