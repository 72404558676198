import { LngLatLike } from "mapbox-gl";
import { useful, danger, goodSpot } from "../assets/map";
import { IDistrict, ISortType } from "../interfaces";
import type { CountryCode } from "libphonenumber-js/types";

export const allowedCountries: CountryCode[] = [
	"AT",
	"BE",
	"BG",
	"HR",
	"CY",
	"CZ",
	"DK",
	"EE",
	"FI",
	"FR",
	"DE",
	"GR",
	"HU",
	"IE",
	"IT",
	"LV",
	"LT",
	"LU",
	"MT",
	"NL",
	"PL",
	"PT",
	"RO",
	"SK",
	"SI",
	"ES",
	"SE",
	"CH",
	"IS",
	"LI",
	"NO",
	"US",
];

export interface Region {
	name: string;
	coords?: [number, number];
	gen?: boolean;
	polygon?: {
		type: "Feature";
		geometry: {
			type: "Polygon";
			coordinates: number[][][];
		};
		properties: {};
	};
}

export const paths = {
	login: "/login",
	startPage: "/",
	newProject: "/new-project",
	projects: "/projects",
	project: (projectName: string) => `/project/${projectName}`,
	ranking: "/ranking",
	about: "/about-us",
	aboutForApp: "/about-us-for-app",
	aboutForAppDe: "/about-us-for-app-de",
	qv: "/qv-info",
	contact: "/contact",
	imprint: "/imprint",
	policy: "/privacy-policy",
	terms: "/terms",
	editProfile: "/edit-profile",
	accountDeletion: "/delete-account",
	support: "https://opencollective.com/citizentech",
	twitter: "https://twitter.com/parry_community",
	radical: "https://www.radicalxchange.org/#message",
};

export const sortTypes: ISortType[] = [
	{ name: "Most votes", sortType: "votes" },
	{ name: "Most recent", sortType: "recent" },
	{ name: "First created", sortType: "first" },
];

export const categories = [
	{ name: "Dangerous", img: danger, text: "Dangerous" },
	{
		name: "Bike Friendly",
		img: goodSpot,
		text: "Bike-friendly",
	},
	{ name: "Useful", img: useful, text: "Useful" },
];

export const BERLIN_COORDINATES: LngLatLike = [13.4090828, 52.520815];

export const regions = [
	"Charlottenburg-Wilmersdorf",
	"Friedrichshain-Kreuzberg",
	"Lichtenberg",
	"Marzahn-Hellersdorf",
	"Mitte",
	"Neukölln",
	"Pankow",
	"Reinickendorf",
	"Spandau",
	"Steglitz-Zehlendorf",
	"Tempelhof-Schöneberg",
	"Treptow-Köpenick",
];

export const districts: IDistrict[] = [
	{ name: "All", coords: [13.2833322, 52.499998], gen: true },
	{ name: "Berlin", coords: BERLIN_COORDINATES, gen: true },
	{
		name: "Charlottenburg-Wilmersdorf",
		coords: [13.2833322, 52.499998],
		gen: false,
		region: "Berlin",
	},
	{
		name: "Friedrichshain-Kreuzberg",
		coords: [13.4499982, 52.499998],
		gen: false,
		region: "Berlin",
	},
	{
		name: "Lichtenberg",
		coords: [13.499998, 52.5333312],
		gen: false,
		region: "Berlin",
	},
	{
		name: "Marzahn-Hellersdorf",
		coords: [13.583830998, 52.537164518],
		gen: false,
		region: "Berlin",
	},
	{
		name: "Mitte",
		coords: [13.3666652, 52.5166646],
		gen: false,
		region: "Berlin",
	},
	{
		name: "Neukölln",
		coords: [13.4499982, 52.4833314],
		gen: false,
		region: "Berlin",
	},
	{
		name: "Pankow",
		coords: [13.3999984, 52.5666644],
		gen: false,
		region: "Berlin",
	},
	{
		name: "Reinickendorf",
		coords: [13.333332, 52.5666644],
		gen: false,
		region: "Berlin",
	},
	{
		name: "Spandau",
		coords: [13.201999192, 52.53499786],
		gen: false,
		region: "Berlin",
	},
	{
		name: "Steglitz-Zehlendorf",
		coords: [13.249999, 52.4333316],
		gen: false,
		region: "Berlin",
	},
	{
		name: "Tempelhof-Schöneberg",
		coords: [13.3833318, 52.4666648],
		gen: false,
		region: "Berlin",
	},
	{
		name: "Treptow-Köpenick",
		coords: [13.5666644, 52.4499982],
		gen: false,
		region: "Berlin",
	},
];

export const mapStyle = "mapbox://styles/mapbox/streets-v11";

export const mapboxAccessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

export const rankingStyles = {
	navbarHeight: {
		value: 36,
		style: "h-6 mb-3",
	},
	dropTitleHeight: { value: 44, style: "h-11" },
	listTopPosition: { value: 52, style: "top-[52px]" },
	borderFix: 3,
};

export const interestsList = [
	{
		id: "biodiversity",
		icon: "🐝",
		en: "Biodiversity",
		de: "Artenvielfalt",
	},

	{
		id: "accessibility",
		icon: "🧑‍🦽",
		en: "Accessibility",
		de: "Barrierefreiheit",
	},

	{
		id: "treesParks",
		icon: "🌳",
		en: "Trees & Parks",
		de: "Bäume & Grünflächen",
	},

	{
		id: "housing",
		icon: "🏘️",
		en: "Affordable Housing",
		de: "Bezahlbares Wohnen",
	},

	{
		id: "digitalisation",
		icon: "💻",
		en: "Digitalisation",
		de: "Digitalisierung",
	},

	{
		id: "volunteering",
		icon: "💚",
		en: "Volunteering",
		de: "Ehrenamt",
	},

	{
		id: "climateProtection",
		icon: "🌍",
		en: "Climate Protection",
		de: "Klimaschutz",
	},

	{
		id: "airQuality",
		icon: "😶‍🌫️",
		en: "Air Quality",
		de: "Luftqualität",
	},

	{
		id: "publicTransport",
		icon: "🚃",
		en: "Public Transport",
		de: "Öffentlicher Nahverkehr",
	},

	{
		id: "protestActivism",
		icon: "🪧",
		en: "Protest & Activism",
		de: "Protest & Aktivismus",
	},

	{
		id: "cycling",
		icon: "🚲",
		en: "Cycling",
		de: "Radinfrastruktur",
	},

	{
		id: "cleanStreets",
		icon: "🗑️",
		en: "Clean Streets",
		de: "Saubere Straßen",
	},

	{
		id: "safety",
		icon: "🦺",
		en: "Safety",
		de: "Sicherheit",
	},

	{
		id: "sportsLeisure",
		icon: "👟",
		en: "Sports & Leisure",
		de: "Sport & Freizeit",
	},

	{
		id: "participation",
		icon: "🗳️",
		en: "Participation",
		de: "Teilhabe",
	},

	{
		id: "trafficPlanning",
		icon: "🚦",
		en: "Traffic Planning",
		de: "Verkehrsplanung",
	},
];

export const tags = {
	dangerous: [
		{
			id: "dangerous",
			icon: "⚠️",
			en: "Dangerous",
			de: "Gefährlich",
		},
		{
			id: "badParking",
			icon: "🅿",
			en: "Bad Parking",
			de: "Parkverhalten",
		},
		{
			id: "badRoad",
			icon: "🕳",
			en: "Bad road",
			de: "Schlechte Straße",
		},
		{
			id: "construction",
			icon: "🚧",
			en: "Construction",
			de: "Bauarbeiten",
		},
		{
			id: "traffic",
			icon: "🚙",
			en: "Traffic",
			de: "Verkehr",
		},
		{
			id: "junction",
			icon: "🚦",
			en: "Junction",
			de: "Kreuzung",
		},
		{
			id: "feelsUnsafe",
			icon: "👀",
			en: "Feels unsafe",
			de: "Fühle mich unsicher",
		},
		{
			id: "badPlanning",
			icon: "📐",
			en: "Bad Planning",
			de: "Fehlplanung",
		},
		{
			id: "other",
			icon: "📢",
			en: "Other",
			de: "Sonstiges",
		},
	],
	bikeFriendly: [
		{
			id: "lessTraffic",
			icon: "🍃",
			en: "Less traffic",
			de: "Wenig Verkehr",
		},
		{
			id: "carfree",
			icon: "🌳",
			en: "Car free",
			de: "Autofrei",
		},
		{
			id: "roadCondition",
			icon: "🛣",
			en: "Road Condition",
			de: "Gute Fahrbahn",
		},
		{
			id: "safeJunction",
			icon: "🚦",
			en: "Safe junction",
			de: "Sichere Kreuzung",
		},
		{
			id: "other",
			icon: "📢",
			en: "Other",
			de: "Sonstiges",
		},
	],
	useful: [
		{
			id: "bikeRepairs",
			icon: "🔧",
			en: "Bike repairs",
			de: "Fahrradreparatur",
		},
		{
			id: "freeAir",
			icon: "💨",
			en: "Free air",
			de: "Kostenlose Luftpumpe",
		},
		{
			id: "chargingStation",
			icon: "⚡",
			en: "Charging station",
			de: "Ladestation",
		},
		{
			id: "bikesForSale",
			icon: "🛒",
			en: "Bikes for sale",
			de: "Fahrradladen",
		},
		{
			id: "rentalBikes",
			icon: "🚲",
			en: "Rental bikes",
			de: "Leihfahrräder",
		},
		{
			id: "idealForLocking",
			icon: "🔓",
			en: "Ideal for locking",
			de: "Ideal zum Anschließen",
		},
		{
			id: "innerTubeVendingMachine",
			icon: "🛞",
			en: "Inner tube vending machine",
			de: "Schlauchautomat",
		},
		{
			id: "other",
			icon: "📢",
			en: "Other",
			de: "Sonstiges",
		},
	],
};

export const REPORT_OPTIONS = [
	{
		id: "inaccurate_location",
		enTitle: "Inaccurate location",
		enDescription: "This issue has been reported in the wrong location.",
		deTitle: "Falscher Ort",
		deDescription: "Dieser Spot wurde an der falschen Stelle gemeldet.",
	},
	{
		id: "incorrect_information",
		enTitle: "Incorrect information",
		enDescription:
			"The information on this spot is not accurate and up to date.",
		deTitle: "Falsche Information",
		deDescription:
			"Die Informationen in diesem Spot sind nicht korrekt oder auf dem neuesten Stand.",
	},
	{
		id: "duplicate",
		enTitle: "Duplicate",
		enDescription: "This spot already exists and we should keep the other one.",
		deTitle: "Duplikat",
		deDescription:
			"Diesen Spot gibt es bereits und wir sollten den anderen behalten.",
	},
	{
		id: "upsetting_content",
		enTitle: "Upsetting content",
		enDescription:
			"The Image or description feels like it should not be on Parry.",
		deTitle: "Beunruhigender Inhalt",
		deDescription:
			"Das Bild oder die Beschreibung haben nichts auf Parry zu suchen.",
	},
	{
		id: "abuse_or_bullying",
		enTitle: "Abuse or bullying",
		enDescription:
			"This spot seems mean spirited, and singles out an individual, or specific group of people.",
		deTitle: "Missbrauch oder Mobbing",
		deDescription:
			"Dieser Spot scheint böswillig zu sein und zielt auf eine einzelne Person oder eine spezifische Gruppe von Menschen ab.",
	},
	{
		id: "privacy_violation",
		enTitle: "Privacy violation",
		enDescription:
			"This spot seems to disclose personal information without the subject’s knowledge.",
		deTitle: "Verletzung der Privatsphäre",
		deDescription:
			"Dieser Ort scheint persönliche Informationen ohne das Wissen der betroffenen Person preiszugeben.",
	},
];

export const REPORT_COMMENT_OPTIONS = [
	{
		id: "incorrect_information",
		enTitle: "Incorrect information",
		enDescription:
			"The information in this comment is not accurate and up to date.",
		deTitle: "Falsche Information",
		deDescription:
			"Die Informationen in diesem Kommentar sind nicht korrekt oder auf dem neuesten Stand.",
	},
	{
		id: "upsetting_content",
		enTitle: "Upsetting content",
		enDescription: "The comment feels like it should not be on Parry.",
		deTitle: "Beunruhigender Inhalt",
		deDescription: "Der Kommentar hat nichts auf Parry zu suchen.",
	},
	{
		id: "abuse_or_bullying",
		enTitle: "Abuse or bullying",
		enDescription:
			"This comment seems mean spirited, and singles out an individual, or specific group of people.",
		deTitle: "Missbrauch oder Mobbing",
		deDescription:
			"Dieser Kommentar scheint böswillig zu sein und zielt auf eine einzelne Person oder eine spezifische Gruppe von Menschen ab.",
	},
	{
		id: "privacy_violation",
		enTitle: "Privacy violation",
		enDescription:
			"This comment seems to disclose personal information without the subject’s knowledge.",
		deTitle: "Verletzung der Privatsphäre",
		deDescription:
			"Dieser Kommentar scheint persönliche Informationen ohne das Wissen der betroffenen Person preiszugeben.",
	},
];

export const regions2: Region[] = [
	{ name: "All", coords: [13.2833322, 52.499998], gen: true },
	{ name: "Berlin", coords: BERLIN_COORDINATES, gen: true },
	{
		name: "Charlottenburg-Wilmersdorf",
		coords: [13.2833322, 52.499998],
		gen: false,
		polygon: {
			type: "Feature",
			geometry: {
				type: "Polygon",
				coordinates: [
					[
						[13.1865954, 52.489298],
						[13.1875725, 52.491232],
						[13.1895918, 52.4929407],
						[13.1894319, 52.4949858],
						[13.1898203, 52.4975432],
						[13.1917093, 52.4986912],
						[13.1952155, 52.4997414],
						[13.1981897, 52.499792],
						[13.1990817, 52.499888],
						[13.2051704, 52.5013633],
						[13.2097274, 52.5029098],
						[13.2093457, 52.5043024],
						[13.2087621, 52.5050455],
						[13.2093301, 52.5058751],
						[13.2104999, 52.5072015],
						[13.2110126, 52.5085223],
						[13.2118605, 52.509347],
						[13.2120085, 52.5093064],
						[13.2123221, 52.5092443],
						[13.2124329, 52.5092366],
						[13.2127884, 52.5091866],
						[13.2128017, 52.5092091],
						[13.2130117, 52.5091619],
						[13.2132311, 52.5090972],
						[13.2139232, 52.5090727],
						[13.2139789, 52.5090954],
						[13.2140851, 52.509101],
						[13.2141573, 52.5091212],
						[13.2142409, 52.5091502],
						[13.2144632, 52.5092745],
						[13.2145174, 52.509327],
						[13.2145456, 52.5093678],
						[13.2155819, 52.5091595],
						[13.2156956, 52.5093652],
						[13.2149809, 52.5095096],
						[13.2151045, 52.5096504],
						[13.2155245, 52.5102731],
						[13.2158675, 52.510681],
						[13.2158528, 52.5106853],
						[13.2162014, 52.511341],
						[13.2164365, 52.511651],
						[13.2164771, 52.5117009],
						[13.2167603, 52.5119417],
						[13.2171427, 52.5121568],
						[13.2174467, 52.5124072],
						[13.2184779, 52.5133972],
						[13.2188372, 52.5133743],
						[13.2194564, 52.513994],
						[13.2196801, 52.5140084],
						[13.2197518, 52.5135763],
						[13.220947, 52.5136221],
						[13.2203775, 52.5142853],
						[13.22021, 52.5147527],
						[13.2199191, 52.5160888],
						[13.2195275, 52.5204934],
						[13.2195068, 52.5212261],
						[13.2196624, 52.5218245],
						[13.2198803, 52.5222866],
						[13.2202585, 52.5231777],
						[13.221047, 52.5244154],
						[13.2217557, 52.5256258],
						[13.2216148, 52.5256392],
						[13.2217209, 52.5257927],
						[13.2218156, 52.5257633],
						[13.222042, 52.5261289],
						[13.2226908, 52.5260971],
						[13.2238392, 52.5258803],
						[13.2247643, 52.5257976],
						[13.2259167, 52.5257688],
						[13.2272825, 52.5255923],
						[13.2280721, 52.5256882],
						[13.2281311, 52.5258308],
						[13.2282234, 52.5259945],
						[13.2288341, 52.5259598],
						[13.2309086, 52.5258557],
						[13.2313242, 52.52573],
						[13.233853, 52.5259199],
						[13.2348287, 52.5260034],
						[13.2362293, 52.5260942],
						[13.2365612, 52.526096],
						[13.2376284, 52.5261206],
						[13.2380872, 52.526125],
						[13.2387259, 52.5260791],
						[13.2391256, 52.5260076],
						[13.2394544, 52.5259326],
						[13.2398199, 52.5258583],
						[13.2399824, 52.5261291],
						[13.2402018, 52.525978],
						[13.2404788, 52.5259448],
						[13.2420535, 52.5255674],
						[13.242192, 52.5255513],
						[13.2422487, 52.5255429],
						[13.243999, 52.5250297],
						[13.2450405, 52.524789],
						[13.2461679, 52.524491],
						[13.2462314, 52.5245558],
						[13.2465028, 52.5244816],
						[13.2475713, 52.525027],
						[13.2474458, 52.5252804],
						[13.2473487, 52.5254979],
						[13.2472536, 52.5258173],
						[13.2472061, 52.5260474],
						[13.2471275, 52.527228],
						[13.2470236, 52.5274269],
						[13.2468987, 52.5275322],
						[13.246662, 52.5276358],
						[13.2463412, 52.5276887],
						[13.2466811, 52.5283134],
						[13.2472391, 52.527999],
						[13.2475853, 52.5278268],
						[13.2483066, 52.5275445],
						[13.2485721, 52.5274578],
						[13.2492707, 52.5272798],
						[13.2498062, 52.5271793],
						[13.2568799, 52.5272019],
						[13.2578775, 52.5272665],
						[13.259121, 52.5273668],
						[13.2598505, 52.5274869],
						[13.2604165, 52.5276639],
						[13.2620049, 52.52815],
						[13.2624594, 52.5282627],
						[13.2629233, 52.5283537],
						[13.2635047, 52.5284768],
						[13.2640648, 52.5285782],
						[13.265318, 52.5287192],
						[13.2660113, 52.5287787],
						[13.2664489, 52.528812],
						[13.2678895, 52.5289152],
						[13.2685072, 52.5289509],
						[13.2689355, 52.5289688],
						[13.2697362, 52.528959],
						[13.2708982, 52.5289419],
						[13.2721893, 52.5288914],
						[13.2731084, 52.5288465],
						[13.2738552, 52.5288256],
						[13.2746826, 52.5288671],
						[13.2765886, 52.5295766],
						[13.2769868, 52.5297758],
						[13.2773411, 52.5299544],
						[13.2776103, 52.5300484],
						[13.2779087, 52.530133],
						[13.2782311, 52.530199],
						[13.2787837, 52.5302679],
						[13.2790816, 52.5302929],
						[13.2792457, 52.5302922],
						[13.2793254, 52.5303239],
						[13.2793687, 52.5302917],
						[13.2799403, 52.5302604],
						[13.2802138, 52.5302269],
						[13.2805603, 52.5301489],
						[13.2808473, 52.5300598],
						[13.2811858, 52.5304889],
						[13.2812572, 52.5305794],
						[13.2823242, 52.5336333],
						[13.2824665, 52.5339814],
						[13.2817215, 52.5340744],
						[13.2802897, 52.5343628],
						[13.2762749, 52.5356389],
						[13.2763252, 52.5356987],
						[13.2762459, 52.5357219],
						[13.2762143, 52.5358508],
						[13.2762552, 52.5358553],
						[13.2762071, 52.5360307],
						[13.2761636, 52.5361465],
						[13.27615, 52.5361828],
						[13.2760299, 52.5364444],
						[13.2758147, 52.5367868],
						[13.2758104, 52.5368391],
						[13.2757683, 52.5368641],
						[13.2756407, 52.5370328],
						[13.2754724, 52.5372405],
						[13.2755498, 52.5372505],
						[13.2755505, 52.5372641],
						[13.2755928, 52.5372655],
						[13.2755308, 52.5373671],
						[13.2753216, 52.5376059],
						[13.2752276, 52.5376921],
						[13.2749722, 52.5378763],
						[13.2748073, 52.5379847],
						[13.2746728, 52.5380678],
						[13.2743966, 52.5382315],
						[13.2739289, 52.5384289],
						[13.2735908, 52.5385612],
						[13.2734349, 52.538606],
						[13.2732473, 52.5386595],
						[13.2730772, 52.5387045],
						[13.2725901, 52.5387995],
						[13.2727775, 52.5392662],
						[13.2727922, 52.539313],
						[13.2725402, 52.5393624],
						[13.2729549, 52.5404355],
						[13.2736135, 52.542285],
						[13.2735973, 52.5424316],
						[13.270662, 52.5478863],
						[13.2701364, 52.5484412],
						[13.2699758, 52.5485577],
						[13.2699792, 52.5486537],
						[13.270307, 52.5486505],
						[13.2703409, 52.5493405],
						[13.270606, 52.549344],
						[13.2705931, 52.5494336],
						[13.2719974, 52.5493829],
						[13.2721064, 52.549305],
						[13.282606, 52.5491305],
						[13.2911608, 52.5489628],
						[13.2952683, 52.5488919],
						[13.2959723, 52.5488957],
						[13.2962128, 52.5488957],
						[13.3015376, 52.5488072],
						[13.3149708, 52.5483208],
						[13.317956, 52.548241],
						[13.3185077, 52.5478304],
						[13.3186261, 52.547738],
						[13.318895, 52.5475594],
						[13.319329, 52.5472928],
						[13.3194795, 52.5471764],
						[13.3199588, 52.5467232],
						[13.3202847, 52.546445],
						[13.3206237, 52.5462131],
						[13.3228869, 52.5445189],
						[13.3231681, 52.5442868],
						[13.323504, 52.5439963],
						[13.324326, 52.543359],
						[13.3250526, 52.5427934],
						[13.3255614, 52.5423868],
						[13.3258414, 52.5421617],
						[13.3262655, 52.5419096],
						[13.3262338, 52.5418741],
						[13.326802, 52.5416194],
						[13.3273247, 52.5414304],
						[13.327309, 52.541308],
						[13.3271621, 52.5399526],
						[13.3271417, 52.5397326],
						[13.3271899, 52.5396468],
						[13.3283524, 52.5387989],
						[13.3291366, 52.5382348],
						[13.3287315, 52.5380969],
						[13.3283865, 52.5380239],
						[13.3281689, 52.5379828],
						[13.3268818, 52.5378035],
						[13.3268787, 52.5377684],
						[13.3268464, 52.5377513],
						[13.3268046, 52.5377419],
						[13.3266237, 52.5377211],
						[13.3259433, 52.5376236],
						[13.3259177, 52.537621],
						[13.3259016, 52.5376236],
						[13.3258868, 52.5376276],
						[13.3258746, 52.5376327],
						[13.3258645, 52.5376408],
						[13.3258534, 52.5376583],
						[13.3258496, 52.5376687],
						[13.3114285, 52.5356243],
						[13.3115043, 52.5353996],
						[13.3125516, 52.5326418],
						[13.3127883, 52.5323093],
						[13.3129198, 52.5322026],
						[13.3130779, 52.5318816],
						[13.313078, 52.5318813],
						[13.3169491, 52.5323233],
						[13.3172964, 52.5323723],
						[13.3170679, 52.5320484],
						[13.3173209, 52.5315279],
						[13.3168971, 52.531244],
						[13.3167268, 52.531179],
						[13.316619, 52.5311582],
						[13.3166697, 52.5310511],
						[13.3167287, 52.5309262],
						[13.316741, 52.5309003],
						[13.3147368, 52.5305107],
						[13.313691, 52.530304],
						[13.3133556, 52.5302609],
						[13.3164185, 52.5220304],
						[13.3168021, 52.5209231],
						[13.317309, 52.5206209],
						[13.3177979, 52.5203665],
						[13.3182794, 52.5207436],
						[13.3187769, 52.5211203],
						[13.3187454, 52.5218744],
						[13.3187153, 52.5219252],
						[13.3186875, 52.5220758],
						[13.318713, 52.5221594],
						[13.3188175, 52.5226128],
						[13.3188665, 52.5228442],
						[13.3189005, 52.5229426],
						[13.3190732, 52.5231743],
						[13.319223, 52.5233808],
						[13.3193699, 52.523589],
						[13.3194651, 52.523706],
						[13.3195567, 52.5237793],
						[13.3197897, 52.5239461],
						[13.320012, 52.5240407],
						[13.3202776, 52.5241476],
						[13.3209395, 52.52441],
						[13.3211029, 52.5244663],
						[13.321366, 52.5245221],
						[13.3216764, 52.5245738],
						[13.3220093, 52.5246162],
						[13.3224029, 52.5246494],
						[13.3232515, 52.5247049],
						[13.3235232, 52.5247142],
						[13.3236685, 52.5247082],
						[13.3238047, 52.5246964],
						[13.3241097, 52.5246624],
						[13.3244224, 52.5246152],
						[13.3247362, 52.5245547],
						[13.3250529, 52.5244793],
						[13.3252111, 52.5244322],
						[13.3255136, 52.5243361],
						[13.3258189, 52.5242337],
						[13.3262845, 52.5240464],
						[13.3264936, 52.5239589],
						[13.3266744, 52.5238725],
						[13.3268602, 52.5237712],
						[13.3269849, 52.5236954],
						[13.3272275, 52.523544],
						[13.3281929, 52.5228786],
						[13.3284241, 52.522713],
						[13.3285019, 52.5226508],
						[13.3286204, 52.5225512],
						[13.3286317, 52.5225547],
						[13.3286419, 52.5225558],
						[13.3286517, 52.5225539],
						[13.3286588, 52.5225513],
						[13.3287799, 52.5224402],
						[13.3288974, 52.5223309],
						[13.3288992, 52.5223201],
						[13.3288902, 52.5223117],
						[13.3288815, 52.5223083],
						[13.3288844, 52.5222993],
						[13.3290664, 52.5220746],
						[13.3292903, 52.5218024],
						[13.3293989, 52.5215924],
						[13.3294539, 52.5214544],
						[13.3294947, 52.5213339],
						[13.3295281, 52.5211961],
						[13.3295539, 52.5209237],
						[13.3295384, 52.5207043],
						[13.3294961, 52.5204744],
						[13.3294343, 52.5202761],
						[13.3293014, 52.5199439],
						[13.3292558, 52.5198188],
						[13.3291706, 52.5195229],
						[13.3290951, 52.5191688],
						[13.3291059, 52.5189676],
						[13.3291398, 52.5187707],
						[13.329178, 52.5186457],
						[13.3292808, 52.5184352],
						[13.3293703, 52.5182858],
						[13.3294724, 52.5181429],
						[13.3296383, 52.5179535],
						[13.329795, 52.5178037],
						[13.3299903, 52.5176589],
						[13.3301421, 52.5175537],
						[13.3303265, 52.517441],
						[13.3307283, 52.5172069],
						[13.3308998, 52.5171264],
						[13.3311858, 52.5170202],
						[13.331672, 52.5168684],
						[13.3318731, 52.5168094],
						[13.3322138, 52.5167331],
						[13.3322818, 52.5167042],
						[13.3325726, 52.5166846],
						[13.3325801, 52.5166902],
						[13.3325936, 52.516692],
						[13.3329896, 52.5166757],
						[13.333225, 52.5166699],
						[13.3334345, 52.5166761],
						[13.3336073, 52.5166892],
						[13.3337817, 52.5167102],
						[13.3339796, 52.5167375],
						[13.3342149, 52.5167715],
						[13.3343775, 52.5164144],
						[13.3347954, 52.5155021],
						[13.335333, 52.5150385],
						[13.3353781, 52.5148588],
						[13.3361484, 52.5143777],
						[13.3359279, 52.5139614],
						[13.3361112, 52.5139681],
						[13.3360169, 52.5137871],
						[13.3358553, 52.5133009],
						[13.3317636, 52.5130162],
						[13.3316517, 52.5129344],
						[13.3316703, 52.5128689],
						[13.3316518, 52.5128124],
						[13.3315537, 52.5127923],
						[13.3314591, 52.5128072],
						[13.3314189, 52.5128372],
						[13.3313966, 52.5128808],
						[13.3307602, 52.5128413],
						[13.3308416, 52.5126605],
						[13.331017, 52.5125289],
						[13.3314144, 52.5123733],
						[13.331658, 52.5122879],
						[13.3320446, 52.512156],
						[13.3331081, 52.5119271],
						[13.3337099, 52.5117932],
						[13.3338159, 52.5117772],
						[13.333908, 52.5117783],
						[13.3344765, 52.5116846],
						[13.3343987, 52.5115524],
						[13.3343306, 52.5114457],
						[13.3341862, 52.5112355],
						[13.3331702, 52.51075],
						[13.3327899, 52.5109505],
						[13.3324359, 52.5108305],
						[13.3316487, 52.5105993],
						[13.3315444, 52.510734],
						[13.3308425, 52.5098036],
						[13.3326448, 52.5092446],
						[13.3326507, 52.5091935],
						[13.3329644, 52.5090952],
						[13.3332735, 52.5089901],
						[13.3332969, 52.5090158],
						[13.3339029, 52.5088268],
						[13.3343111, 52.5087383],
						[13.3343794, 52.5087214],
						[13.3346893, 52.50861],
						[13.3348035, 52.5084868],
						[13.3347645, 52.5084748],
						[13.3347953, 52.5084195],
						[13.3348131, 52.5083692],
						[13.3348091, 52.5082701],
						[13.3348072, 52.5082364],
						[13.3347953, 52.5081718],
						[13.3347329, 52.5080721],
						[13.3348071, 52.5080531],
						[13.3341621, 52.5073161],
						[13.3338643, 52.5068775],
						[13.3337324, 52.5066962],
						[13.3337867, 52.5066703],
						[13.333815, 52.5066932],
						[13.3339919, 52.5066121],
						[13.3340673, 52.5065787],
						[13.3340022, 52.5065183],
						[13.3347405, 52.5061821],
						[13.335261, 52.5059377],
						[13.3354027, 52.5058792],
						[13.3355834, 52.5058267],
						[13.3357443, 52.5057986],
						[13.3358381, 52.5057867],
						[13.3362178, 52.5057519],
						[13.3365935, 52.5057203],
						[13.3367126, 52.5057159],
						[13.3368565, 52.5057198],
						[13.3369049, 52.5057211],
						[13.3370014, 52.505728],
						[13.3371235, 52.5057436],
						[13.3375282, 52.5057955],
						[13.3381883, 52.5058694],
						[13.3382358, 52.5058748],
						[13.3382599, 52.5058776],
						[13.3383515, 52.5058882],
						[13.3385796, 52.5059151],
						[13.3386125, 52.5059212],
						[13.3386261, 52.5059237],
						[13.3386407, 52.5059263],
						[13.3387914, 52.5056224],
						[13.3388505, 52.5055031],
						[13.3388883, 52.5054269],
						[13.3389373, 52.5053527],
						[13.3391048, 52.5051171],
						[13.339196, 52.5051353],
						[13.3394556, 52.5051728],
						[13.3397341, 52.5051919],
						[13.3400212, 52.5051852],
						[13.3402909, 52.5051628],
						[13.3409772, 52.50507],
						[13.3414287, 52.5049347],
						[13.3395347, 52.5031907],
						[13.336906, 52.5008673],
						[13.3391852, 52.4999828],
						[13.3389388, 52.4994827],
						[13.3379785, 52.4978387],
						[13.3376048, 52.496894],
						[13.3373044, 52.4959781],
						[13.3373855, 52.4959866],
						[13.3373703, 52.4958482],
						[13.3372728, 52.4958626],
						[13.3373334, 52.4947789],
						[13.3374469, 52.4947871],
						[13.337462, 52.4944841],
						[13.3374467, 52.494072],
						[13.3373487, 52.4940659],
						[13.3371253, 52.4923016],
						[13.3372174, 52.4923137],
						[13.337175, 52.4921321],
						[13.3370663, 52.4910006],
						[13.3375528, 52.4907224],
						[13.3373154, 52.4904859],
						[13.3369911, 52.4904053],
						[13.3369497, 52.4903618],
						[13.3368996, 52.4896794],
						[13.3369048, 52.4894054],
						[13.3370568, 52.4884745],
						[13.3371396, 52.4883984],
						[13.3372152, 52.4880485],
						[13.3371474, 52.4880427],
						[13.3370906, 52.4869342],
						[13.3370569, 52.4859231],
						[13.3371359, 52.4859183],
						[13.337181, 52.4856736],
						[13.3370854, 52.485677],
						[13.3370974, 52.4846883],
						[13.3371093, 52.4830686],
						[13.3371242, 52.4825349],
						[13.3372053, 52.4825283],
						[13.3370938, 52.482202],
						[13.3370492, 52.482088],
						[13.3370519, 52.4807077],
						[13.3371689, 52.4803542],
						[13.3371642, 52.4801151],
						[13.337059, 52.4798891],
						[13.3370654, 52.4789126],
						[13.3370669, 52.4785242],
						[13.3370632, 52.4781335],
						[13.3329854, 52.4778486],
						[13.3328676, 52.4774162],
						[13.3288294, 52.4774374],
						[13.3282711, 52.4774326],
						[13.3271553, 52.4774608],
						[13.3243538, 52.4774807],
						[13.3224784, 52.4774956],
						[13.3204861, 52.4774738],
						[13.3204297, 52.4774712],
						[13.3204223, 52.4773497],
						[13.3204008, 52.4768214],
						[13.3204001, 52.4767523],
						[13.3203897, 52.4763951],
						[13.3203248, 52.4753373],
						[13.3203253, 52.4750189],
						[13.3203092, 52.4744136],
						[13.3202643, 52.4735926],
						[13.3202586, 52.4734821],
						[13.3202465, 52.4727887],
						[13.3202211, 52.4725697],
						[13.3201785, 52.4714899],
						[13.3201505, 52.470749],
						[13.3201337, 52.4705235],
						[13.3202438, 52.470458],
						[13.3202551, 52.4704396],
						[13.3205261, 52.4702659],
						[13.3205224, 52.4701306],
						[13.3202551, 52.4699743],
						[13.3202501, 52.4699678],
						[13.3202503, 52.4699549],
						[13.3202616, 52.469946],
						[13.3202264, 52.4699264],
						[13.3202529, 52.4699087],
						[13.3202457, 52.4699049],
						[13.3202544, 52.4698998],
						[13.3201844, 52.4698583],
						[13.3201117, 52.4698101],
						[13.3201052, 52.4696547],
						[13.3200615, 52.4688638],
						[13.3200575, 52.4688098],
						[13.3199923, 52.4671559],
						[13.3200013, 52.4669971],
						[13.3115218, 52.467121],
						[13.3111402, 52.4671131],
						[13.3109332, 52.4670619],
						[13.3106939, 52.4669318],
						[13.30999, 52.4672428],
						[13.3095944, 52.4674334],
						[13.3090597, 52.4677169],
						[13.3085345, 52.4674566],
						[13.3082946, 52.4675906],
						[13.3075544, 52.4678631],
						[13.3065561, 52.4674199],
						[13.3035466, 52.4679101],
						[13.2979829, 52.4688399],
						[13.2901747, 52.4703259],
						[13.2901497, 52.4701262],
						[13.2898873, 52.4698914],
						[13.2896566, 52.4700671],
						[13.2891192, 52.4705166],
						[13.2884941, 52.47037],
						[13.2881531, 52.4703272],
						[13.2812577, 52.4689034],
						[13.2786258, 52.4684945],
						[13.2785923, 52.4685918],
						[13.2782073, 52.4686215],
						[13.272446, 52.4679402],
						[13.2723081, 52.4678517],
						[13.2693555, 52.4675484],
						[13.2679049, 52.4673863],
						[13.2665765, 52.4672645],
						[13.2649893, 52.4670438],
						[13.264858, 52.4670255],
						[13.2629525, 52.4669523],
						[13.2624412, 52.4671847],
						[13.2625189, 52.4675279],
						[13.2616643, 52.4677623],
						[13.2608054, 52.4672466],
						[13.2598593, 52.4668523],
						[13.2592381, 52.4666774],
						[13.2589884, 52.4664729],
						[13.2578194, 52.4665292],
						[13.25753, 52.4665726],
						[13.2555519, 52.4667361],
						[13.2546735, 52.4667628],
						[13.2530888, 52.4665983],
						[13.2527783, 52.4666968],
						[13.2523879, 52.4667302],
						[13.2479671, 52.4675165],
						[13.2479138, 52.4675327],
						[13.2478355, 52.4675372],
						[13.2473674, 52.4677124],
						[13.247201, 52.4677019],
						[13.2467534, 52.467738],
						[13.2438098, 52.4682472],
						[13.2430243, 52.4683578],
						[13.2427177, 52.468436],
						[13.2420062, 52.4685665],
						[13.2414113, 52.4686317],
						[13.2390372, 52.4690412],
						[13.2390449, 52.469057],
						[13.2383476, 52.4691935],
						[13.2383352, 52.4691693],
						[13.2380598, 52.4692053],
						[13.2374965, 52.4693115],
						[13.2373494, 52.4693306],
						[13.2372056, 52.4693644],
						[13.2370957, 52.469375],
						[13.2366911, 52.469444],
						[13.2363478, 52.4694899],
						[13.2360675, 52.469533],
						[13.2350778, 52.4696698],
						[13.2338938, 52.4701174],
						[13.232684, 52.4704217],
						[13.2314741, 52.4707564],
						[13.2302229, 52.4707472],
						[13.2286895, 52.4704859],
						[13.2267982, 52.4703596],
						[13.22552, 52.4701266],
						[13.2237758, 52.4699855],
						[13.2236175, 52.470017],
						[13.2215884, 52.469978],
						[13.2201438, 52.4700571],
						[13.2188217, 52.4700416],
						[13.2165747, 52.4701224],
						[13.2161603, 52.4700955],
						[13.2150712, 52.4699585],
						[13.2139879, 52.4697966],
						[13.2137798, 52.4697266],
						[13.2133475, 52.4696188],
						[13.2121844, 52.4695564],
						[13.2115778, 52.469439],
						[13.2103587, 52.4697272],
						[13.210195, 52.4696636],
						[13.2082611, 52.4699401],
						[13.2066362, 52.4703242],
						[13.2058106, 52.4704202],
						[13.2050129, 52.4705227],
						[13.2045674, 52.4705268],
						[13.2041481, 52.4704906],
						[13.2037478, 52.4704988],
						[13.2035557, 52.4704724],
						[13.2027524, 52.47025],
						[13.202206, 52.4701594],
						[13.2017272, 52.4699814],
						[13.201573, 52.4698776],
						[13.2009803, 52.4692788],
						[13.2007561, 52.4691031],
						[13.2006155, 52.4690372],
						[13.2002936, 52.4689779],
						[13.1997147, 52.4688164],
						[13.1992711, 52.4687456],
						[13.1989763, 52.4687686],
						[13.1980733, 52.4690206],
						[13.1979336, 52.4691331],
						[13.197732, 52.4693586],
						[13.1976177, 52.4697371],
						[13.1976671, 52.4701086],
						[13.1977293, 52.4703489],
						[13.1975927, 52.4706292],
						[13.1969963, 52.4711694],
						[13.1966212, 52.4713471],
						[13.1963228, 52.4713408],
						[13.1952413, 52.4712527],
						[13.1945732, 52.4709827],
						[13.1881678, 52.4718369],
						[13.1882878, 52.4741827],
						[13.1872447, 52.4786565],
						[13.190158, 52.4826124],
						[13.1898295, 52.4843352],
						[13.1871454, 52.4871319],
						[13.1865954, 52.489298],
					],
				],
			},
			properties: {},
		},
	},
	{
		name: "Friedrichshain-Kreuzberg",
		coords: [13.4499982, 52.499998],
		gen: false,
		polygon: {
			type: "Feature",
			geometry: {
				type: "Polygon",
				coordinates: [
					[
						[13.3682291, 52.4933357],
						[13.3685807, 52.4939555],
						[13.3684148, 52.4944781],
						[13.3684522, 52.4949243],
						[13.3684812, 52.4952231],
						[13.3685269, 52.4957389],
						[13.368539, 52.4958761],
						[13.36856, 52.4960771],
						[13.3685913, 52.4964111],
						[13.3685916, 52.4964588],
						[13.3685962, 52.4965108],
						[13.3686038, 52.4965258],
						[13.3686362, 52.496877],
						[13.3686779, 52.4973847],
						[13.369601, 52.4981061],
						[13.3697289, 52.4987765],
						[13.3695331, 52.4988698],
						[13.3703731, 52.4995143],
						[13.370362, 52.4995305],
						[13.3705522, 52.4996846],
						[13.3705545, 52.4996818],
						[13.370587, 52.4996836],
						[13.3708251, 52.4998602],
						[13.3708884, 52.4999544],
						[13.3713442, 52.501634],
						[13.3714531, 52.501758],
						[13.3717361, 52.5020391],
						[13.3721632, 52.5024872],
						[13.373043, 52.5033823],
						[13.3736093, 52.504164],
						[13.3742442, 52.5034855],
						[13.3746784, 52.5032356],
						[13.3747522, 52.5032345],
						[13.3749797, 52.5033759],
						[13.377639, 52.5079091],
						[13.3776503, 52.5079658],
						[13.3789222, 52.5069995],
						[13.3857617, 52.5073286],
						[13.3868833, 52.5073951],
						[13.3898682, 52.5075711],
						[13.390484, 52.5075985],
						[13.3922257, 52.5077033],
						[13.3942839, 52.5078332],
						[13.3981888, 52.5080789],
						[13.3987719, 52.5081134],
						[13.3987944, 52.5080917],
						[13.3992301, 52.5080775],
						[13.3997671, 52.5088],
						[13.3999387, 52.5090282],
						[13.4000254, 52.509151],
						[13.4001886, 52.5093755],
						[13.4002283, 52.5093816],
						[13.4009175, 52.5091378],
						[13.4012638, 52.5090381],
						[13.4021013, 52.5087285],
						[13.4027619, 52.5085334],
						[13.4036335, 52.5081778],
						[13.404037, 52.5079941],
						[13.4043012, 52.5078617],
						[13.4044429, 52.5077718],
						[13.4045782, 52.5078856],
						[13.4046343, 52.5079228],
						[13.4052837, 52.5082102],
						[13.4056214, 52.5079615],
						[13.4057965, 52.5078345],
						[13.4059383, 52.5077299],
						[13.4064044, 52.5073853],
						[13.4073589, 52.5066478],
						[13.4074002, 52.5066723],
						[13.4078501, 52.5063267],
						[13.4080301, 52.5061832],
						[13.4099694, 52.5069278],
						[13.4108939, 52.5057255],
						[13.4115252, 52.5048911],
						[13.4140723, 52.5040371],
						[13.4146097, 52.5046798],
						[13.4145925, 52.5047921],
						[13.4149072, 52.5049185],
						[13.4166273, 52.5044415],
						[13.4166967, 52.5044216],
						[13.4168472, 52.5043798],
						[13.4176016, 52.5041685],
						[13.4182136, 52.5050177],
						[13.4184001, 52.5049933],
						[13.4185186, 52.5049867],
						[13.4185318, 52.504969],
						[13.4189443, 52.5050603],
						[13.4189245, 52.50508],
						[13.4189981, 52.5051182],
						[13.4191101, 52.5052037],
						[13.4194305, 52.5056491],
						[13.4206174, 52.5053214],
						[13.4211305, 52.5051754],
						[13.4215419, 52.5050785],
						[13.4218109, 52.505042],
						[13.4220787, 52.5050273],
						[13.4223011, 52.5050078],
						[13.4226642, 52.5049872],
						[13.423038, 52.50498],
						[13.4233526, 52.5049868],
						[13.4237378, 52.505009],
						[13.4239799, 52.5050293],
						[13.4243499, 52.5050748],
						[13.4244748, 52.5050933],
						[13.4247139, 52.505133],
						[13.4249569, 52.5051819],
						[13.4252194, 52.5052477],
						[13.4255041, 52.5053239],
						[13.4258215, 52.5054247],
						[13.4261207, 52.5055508],
						[13.4267083, 52.5057986],
						[13.4269722, 52.5057312],
						[13.4271997, 52.5056674],
						[13.427499, 52.5060935],
						[13.4276139, 52.5062578],
						[13.427689, 52.5063666],
						[13.4278537, 52.5066013],
						[13.4279958, 52.5068044],
						[13.4281137, 52.5069735],
						[13.4283665, 52.5073345],
						[13.4288144, 52.5079776],
						[13.4290283, 52.5080363],
						[13.4294017, 52.5085714],
						[13.4292376, 52.5086726],
						[13.4292032, 52.5087282],
						[13.4291869, 52.5088211],
						[13.42917, 52.5088441],
						[13.4288928, 52.5089875],
						[13.4286965, 52.509001],
						[13.4283935, 52.5090733],
						[13.4277159, 52.5095111],
						[13.4271416, 52.5098435],
						[13.4260889, 52.5104434],
						[13.4255866, 52.5107436],
						[13.4249204, 52.5111143],
						[13.4242054, 52.5115003],
						[13.4235907, 52.5118554],
						[13.4232627, 52.5120616],
						[13.4233569, 52.5126682],
						[13.42399, 52.5133045],
						[13.4240861, 52.5133932],
						[13.4242813, 52.5135692],
						[13.4246173, 52.5138765],
						[13.4251965, 52.5144161],
						[13.4255792, 52.5148191],
						[13.4256938, 52.5149428],
						[13.4265029, 52.5158327],
						[13.4257778, 52.5159913],
						[13.4259571, 52.5162893],
						[13.4266276, 52.5173261],
						[13.4270187, 52.5179385],
						[13.4265929, 52.5181171],
						[13.4264656, 52.5181717],
						[13.4262864, 52.5182252],
						[13.4258857, 52.5183374],
						[13.4264469, 52.5191356],
						[13.4269188, 52.5197438],
						[13.4272839, 52.5196462],
						[13.427473, 52.519607],
						[13.4283998, 52.5195865],
						[13.4287208, 52.520244],
						[13.4291879, 52.5212028],
						[13.4291793, 52.5212255],
						[13.4286516, 52.5214498],
						[13.427648, 52.5218833],
						[13.4255523, 52.5227924],
						[13.4259104, 52.5228316],
						[13.4260028, 52.5229219],
						[13.4265191, 52.5229415],
						[13.426501, 52.5232938],
						[13.4262749, 52.5233038],
						[13.425881, 52.5233521],
						[13.4254847, 52.5234462],
						[13.4254333, 52.5234391],
						[13.424745, 52.5236397],
						[13.4240706, 52.5238552],
						[13.4237733, 52.5239632],
						[13.422336, 52.524527],
						[13.4217536, 52.5247551],
						[13.4197996, 52.5255288],
						[13.4197534, 52.5255461],
						[13.4211262, 52.5263827],
						[13.4214272, 52.5265644],
						[13.4231452, 52.5276105],
						[13.4236419, 52.5279139],
						[13.4238095, 52.5279009],
						[13.4240058, 52.5278814],
						[13.4246655, 52.5279085],
						[13.4248525, 52.5278771],
						[13.4253239, 52.5281363],
						[13.4257116, 52.5281744],
						[13.4258277, 52.5281884],
						[13.4286471, 52.5285172],
						[13.4304785, 52.5287302],
						[13.4310942, 52.5288027],
						[13.4329347, 52.5290145],
						[13.4341523, 52.5291544],
						[13.435773, 52.5293486],
						[13.4366795, 52.5294538],
						[13.437232, 52.5295187],
						[13.4374809, 52.5295472],
						[13.4382923, 52.5287936],
						[13.4387777, 52.5287923],
						[13.4422716, 52.5310293],
						[13.4438714, 52.5297357],
						[13.4441439, 52.5294451],
						[13.4442814, 52.5293284],
						[13.4454153, 52.52827],
						[13.4459817, 52.5277359],
						[13.4461507, 52.5275769],
						[13.446842, 52.5269579],
						[13.4472042, 52.5266336],
						[13.4473144, 52.5265314],
						[13.447357, 52.526492],
						[13.4473853, 52.5264737],
						[13.4477359, 52.5265775],
						[13.4493913, 52.5270705],
						[13.4505688, 52.5274219],
						[13.4507781, 52.5274853],
						[13.4521808, 52.5277942],
						[13.4522881, 52.5276665],
						[13.4524264, 52.5275228],
						[13.4523301, 52.5274427],
						[13.4523878, 52.5273584],
						[13.4526439, 52.5269846],
						[13.4531472, 52.52625],
						[13.4536811, 52.5254706],
						[13.4544606, 52.5243289],
						[13.4545287, 52.524223],
						[13.4550135, 52.5234902],
						[13.4557182, 52.5224493],
						[13.4558301, 52.5222712],
						[13.4556734, 52.5220484],
						[13.455054, 52.5212186],
						[13.4556863, 52.5210971],
						[13.4568867, 52.5208797],
						[13.4570865, 52.5208434],
						[13.4586005, 52.520567],
						[13.4600519, 52.5203021],
						[13.4604408, 52.5202327],
						[13.4605709, 52.520208],
						[13.4610613, 52.5201185],
						[13.4612384, 52.5200842],
						[13.4617642, 52.5199904],
						[13.4623151, 52.5198918],
						[13.4624349, 52.5198705],
						[13.4624949, 52.519861],
						[13.4627309, 52.5198386],
						[13.4636778, 52.5199086],
						[13.46384, 52.5199217],
						[13.4655333, 52.5200577],
						[13.4669131, 52.5201686],
						[13.4670292, 52.5201796],
						[13.4680286, 52.5202628],
						[13.4695427, 52.520389],
						[13.4708106, 52.5204914],
						[13.471182, 52.5205195],
						[13.4720305, 52.5205838],
						[13.4721166, 52.5205903],
						[13.4723656, 52.5206084],
						[13.4729572, 52.5198985],
						[13.4735151, 52.5191256],
						[13.4739614, 52.5192196],
						[13.4739892, 52.5191771],
						[13.475472, 52.5169059],
						[13.4755062, 52.5168234],
						[13.4760548, 52.5161244],
						[13.4764586, 52.5153008],
						[13.4765677, 52.5151541],
						[13.4766028, 52.5151233],
						[13.4767101, 52.5150482],
						[13.4777288, 52.5147204],
						[13.477492, 52.514388],
						[13.4758875, 52.5148631],
						[13.4754514, 52.5138226],
						[13.4754364, 52.51343],
						[13.4755311, 52.5130692],
						[13.4756314, 52.5130593],
						[13.475777, 52.5124218],
						[13.4758391, 52.5121224],
						[13.4759275, 52.5121135],
						[13.4759414, 52.5120589],
						[13.475888, 52.5120534],
						[13.4761107, 52.5111151],
						[13.4761484, 52.5109576],
						[13.4762658, 52.5104393],
						[13.4761639, 52.5103481],
						[13.4760482, 52.5103012],
						[13.4758272, 52.5102558],
						[13.4757244, 52.5100974],
						[13.4754191, 52.5096326],
						[13.4752503, 52.5094582],
						[13.4748083, 52.5089966],
						[13.4744845, 52.5086598],
						[13.4742096, 52.5083707],
						[13.4739616, 52.5081087],
						[13.4739021, 52.5081014],
						[13.474028, 52.5080676],
						[13.4739355, 52.5079698],
						[13.4738053, 52.5080071],
						[13.4738207, 52.5079312],
						[13.4730669, 52.5071259],
						[13.4734064, 52.5069189],
						[13.472832, 52.5067101],
						[13.4727896, 52.5066437],
						[13.472769, 52.5065774],
						[13.4728041, 52.5064933],
						[13.472729, 52.5064557],
						[13.4727145, 52.5063598],
						[13.4722603, 52.5061089],
						[13.472166, 52.5060408],
						[13.4717481, 52.5057351],
						[13.4715166, 52.5055151],
						[13.4714793, 52.5054665],
						[13.4711643, 52.5051377],
						[13.4712936, 52.5050021],
						[13.4706177, 52.5042759],
						[13.4704278, 52.5040641],
						[13.4702062, 52.5037443],
						[13.4694918, 52.5022678],
						[13.4685496, 52.4997086],
						[13.4690009, 52.4996149],
						[13.4699714, 52.49948],
						[13.4702758, 52.4994222],
						[13.4703009, 52.4993714],
						[13.4730756, 52.4990026],
						[13.4756862, 52.4973025],
						[13.4775987, 52.4959532],
						[13.4786274, 52.4952712],
						[13.4787715, 52.4952876],
						[13.4798223, 52.4945622],
						[13.4833635, 52.4921012],
						[13.4837311, 52.4918588],
						[13.4841318, 52.4916924],
						[13.4849871, 52.4916339],
						[13.4856938, 52.4912963],
						[13.4860524, 52.4908601],
						[13.4861097, 52.4907442],
						[13.4876023, 52.490202],
						[13.4885271, 52.4898498],
						[13.4887873, 52.4892704],
						[13.4914434, 52.4882828],
						[13.4910096, 52.487359],
						[13.4902657, 52.4876087],
						[13.4892758, 52.4878137],
						[13.4887351, 52.487866],
						[13.4876828, 52.4878844],
						[13.4868897, 52.4877771],
						[13.4860314, 52.4875315],
						[13.4851047, 52.4870836],
						[13.4827868, 52.4860888],
						[13.4824179, 52.4871764],
						[13.4821589, 52.4875193],
						[13.4815253, 52.4877771],
						[13.4800125, 52.48804],
						[13.4792868, 52.4879871],
						[13.4784037, 52.4874117],
						[13.4779461, 52.4879705],
						[13.4778346, 52.4886813],
						[13.4763154, 52.4899304],
						[13.4754399, 52.4904008],
						[13.4735688, 52.4912004],
						[13.472968, 52.4912631],
						[13.4702214, 52.4917752],
						[13.4659382, 52.4930696],
						[13.4632699, 52.49417],
						[13.4632285, 52.4943345],
						[13.4641615, 52.4951129],
						[13.4633549, 52.4954855],
						[13.4630717, 52.4954437],
						[13.4621962, 52.4955378],
						[13.4595955, 52.4965568],
						[13.4583073, 52.4968071],
						[13.4550436, 52.4973665],
						[13.4547627, 52.4974248],
						[13.4544446, 52.497486],
						[13.454066, 52.497565],
						[13.4535828, 52.4975426],
						[13.4533719, 52.4975354],
						[13.4531167, 52.4975536],
						[13.4529296, 52.4977068],
						[13.452013, 52.4975232],
						[13.4513876, 52.4973922],
						[13.4510076, 52.4972687],
						[13.4506551, 52.4970891],
						[13.4500838, 52.4966885],
						[13.4474502, 52.4948733],
						[13.446909, 52.4948401],
						[13.4455486, 52.494856],
						[13.4451616, 52.494545],
						[13.4446428, 52.4941678],
						[13.4423861, 52.4923765],
						[13.440686, 52.4910889],
						[13.4404055, 52.4909351],
						[13.4401045, 52.4907393],
						[13.4398843, 52.4906049],
						[13.4398107, 52.4905074],
						[13.4397655, 52.4903859],
						[13.4397152, 52.4902456],
						[13.4396815, 52.4901289],
						[13.4396798, 52.4900322],
						[13.4396363, 52.4899002],
						[13.4396419, 52.4898352],
						[13.4396337, 52.4896945],
						[13.439616, 52.4896918],
						[13.4396278, 52.4896586],
						[13.4394355, 52.4896336],
						[13.4392625, 52.4896095],
						[13.4392535, 52.4896441],
						[13.4392338, 52.4896408],
						[13.439228, 52.4896601],
						[13.438566, 52.4901512],
						[13.4383633, 52.4903265],
						[13.4382887, 52.4903853],
						[13.4372844, 52.4906862],
						[13.4371352, 52.4907396],
						[13.4365475, 52.4909389],
						[13.4352982, 52.4913201],
						[13.4329811, 52.4920569],
						[13.4306646, 52.4927926],
						[13.4298525, 52.4930559],
						[13.4290746, 52.4933099],
						[13.4282946, 52.4935638],
						[13.4277767, 52.4937325],
						[13.4275708, 52.4937992],
						[13.4270498, 52.4939686],
						[13.4266613, 52.4940951],
						[13.4262867, 52.4942171],
						[13.4259279, 52.4943341],
						[13.4252666, 52.4945485],
						[13.4249348, 52.4946564],
						[13.4241331, 52.494917],
						[13.423316, 52.4951829],
						[13.4230669, 52.4952639],
						[13.4227462, 52.4953664],
						[13.4224899, 52.4954452],
						[13.4224259, 52.495463],
						[13.4222249, 52.4955198],
						[13.4219508, 52.4955905],
						[13.421668, 52.4956565],
						[13.4213921, 52.4957134],
						[13.4209691, 52.4957889],
						[13.4207201, 52.4958348],
						[13.4204842, 52.495865],
						[13.4203999, 52.4958638],
						[13.4204624, 52.4957903],
						[13.420639, 52.4956194],
						[13.4207907, 52.495474],
						[13.4215921, 52.4941974],
						[13.4217059, 52.4940225],
						[13.4222972, 52.4930834],
						[13.4223996, 52.4929191],
						[13.4227769, 52.4923143],
						[13.4229477, 52.4920412],
						[13.4234829, 52.4911806],
						[13.4235851, 52.491017],
						[13.4241967, 52.4900393],
						[13.424297, 52.4898778],
						[13.4249189, 52.4888765],
						[13.4252864, 52.4882845],
						[13.42535, 52.488182],
						[13.4253551, 52.4879987],
						[13.4249381, 52.4877802],
						[13.4241585, 52.4868285],
						[13.4238569, 52.4865329],
						[13.4237047, 52.4863837],
						[13.4231919, 52.4865698],
						[13.421256, 52.4871637],
						[13.4176929, 52.4876359],
						[13.4085595, 52.4887699],
						[13.4078874, 52.4888602],
						[13.4077228, 52.4887471],
						[13.4077568, 52.4886623],
						[13.4078376, 52.4884843],
						[13.4079124, 52.4884777],
						[13.4083375, 52.4874688],
						[13.4084232, 52.4871133],
						[13.4082859, 52.4868939],
						[13.4073191, 52.4860657],
						[13.4067496, 52.4854769],
						[13.4064589, 52.4835903],
						[13.4063457, 52.4827923],
						[13.404886, 52.4830993],
						[13.4034421, 52.4833999],
						[13.4023153, 52.4835847],
						[13.4012255, 52.4837388],
						[13.4001472, 52.4838407],
						[13.399383, 52.483893],
						[13.3987169, 52.4839328],
						[13.3979566, 52.4839487],
						[13.3946131, 52.4840216],
						[13.394234, 52.4841256],
						[13.3942337, 52.4843358],
						[13.3942367, 52.4845401],
						[13.3942422, 52.4849395],
						[13.3942454, 52.4850678],
						[13.3942502, 52.4853329],
						[13.3942541, 52.4854938],
						[13.3942586, 52.4857242],
						[13.3942612, 52.4857753],
						[13.3910787, 52.4858401],
						[13.3862812, 52.4858229],
						[13.3862732, 52.4852299],
						[13.3862684, 52.4848587],
						[13.3848479, 52.4848716],
						[13.3847829, 52.4849107],
						[13.3768548, 52.4849652],
						[13.3716065, 52.484979],
						[13.371719, 52.4851739],
						[13.3740185, 52.4851671],
						[13.3740954, 52.4861755],
						[13.3741755, 52.4877318],
						[13.3734818, 52.4877505],
						[13.373518, 52.4879703],
						[13.374606, 52.4891099],
						[13.3748892, 52.4893189],
						[13.375413, 52.4893656],
						[13.3754648, 52.4904497],
						[13.3756924, 52.4906809],
						[13.3760501, 52.4910507],
						[13.3760886, 52.4911468],
						[13.3764656, 52.4915151],
						[13.3765623, 52.4916704],
						[13.3723924, 52.4925302],
						[13.3720009, 52.4926097],
						[13.3710649, 52.4927373],
						[13.3710816, 52.4929042],
						[13.3692527, 52.4932859],
						[13.3691729, 52.4931506],
						[13.3682291, 52.4933357],
					],
				],
			},
			properties: {},
		},
	},
	{
		name: "Lichtenberg",
		coords: [13.499998, 52.5333312],
		gen: false,
		polygon: {
			type: "Feature",
			geometry: {
				type: "Polygon",
				coordinates: [
					[
						[13.4561898, 52.5282609],
						[13.456338, 52.5283984],
						[13.4574441, 52.5286351],
						[13.4580032, 52.5287609],
						[13.4592035, 52.5290289],
						[13.4606738, 52.5293566],
						[13.4610053, 52.5296773],
						[13.4611417, 52.5298424],
						[13.4619275, 52.5307969],
						[13.4625476, 52.5315493],
						[13.4630569, 52.5321665],
						[13.4632475, 52.5323974],
						[13.4633585, 52.5325924],
						[13.4633883, 52.5326331],
						[13.4644439, 52.5329887],
						[13.4644329, 52.5330078],
						[13.4644313, 52.5330338],
						[13.4644317, 52.5330519],
						[13.4644572, 52.5330903],
						[13.4644929, 52.533123],
						[13.4645407, 52.5331495],
						[13.4645248, 52.5332005],
						[13.4671723, 52.5341196],
						[13.4673192, 52.5340117],
						[13.4688009, 52.5346738],
						[13.4678405, 52.5361648],
						[13.4669076, 52.5364486],
						[13.4664503, 52.5366655],
						[13.4676484, 52.5384068],
						[13.4673832, 52.5385292],
						[13.4699168, 52.5404095],
						[13.4670814, 52.5480522],
						[13.4674381, 52.5482858],
						[13.4684259, 52.5482024],
						[13.4695601, 52.5477185],
						[13.4747835, 52.5476057],
						[13.4751683, 52.5475984],
						[13.4767591, 52.547568],
						[13.4796577, 52.5475127],
						[13.4804809, 52.5481134],
						[13.4797858, 52.5491312],
						[13.4801242, 52.5494483],
						[13.4817248, 52.5494928],
						[13.4821821, 52.5495929],
						[13.4852897, 52.55231],
						[13.485859, 52.5521623],
						[13.4862249, 52.5524014],
						[13.4903042, 52.5517063],
						[13.4943469, 52.5507942],
						[13.4956823, 52.553586],
						[13.4957006, 52.5541699],
						[13.4955725, 52.5550486],
						[13.4950392, 52.5561224],
						[13.4935359, 52.5575009],
						[13.4924909, 52.5585708],
						[13.4916121, 52.559505],
						[13.491504, 52.5596033],
						[13.4913264, 52.5597618],
						[13.4910885, 52.5599389],
						[13.4908677, 52.5600773],
						[13.4905637, 52.56024],
						[13.4902917, 52.5603604],
						[13.4899991, 52.5604732],
						[13.4886764, 52.5608773],
						[13.4873081, 52.5612835],
						[13.4869826, 52.5613583],
						[13.486328, 52.5614565],
						[13.4853992, 52.5615513],
						[13.4851886, 52.5617991],
						[13.4853229, 52.5622738],
						[13.4855984, 52.5624191],
						[13.4860696, 52.5641525],
						[13.48369, 52.564432],
						[13.4839605, 52.5653026],
						[13.4839908, 52.5653218],
						[13.4840093, 52.5653397],
						[13.4840272, 52.5653639],
						[13.4840437, 52.5654002],
						[13.4840837, 52.565511],
						[13.4840912, 52.5655429],
						[13.4840994, 52.5656119],
						[13.4840966, 52.5657147],
						[13.4840998, 52.5657398],
						[13.4841009, 52.5658936],
						[13.484108, 52.5659716],
						[13.4841538, 52.5660255],
						[13.4839654, 52.5660738],
						[13.4835964, 52.5661175],
						[13.4875878, 52.5692187],
						[13.482925, 52.5714758],
						[13.4830354, 52.5715588],
						[13.4828305, 52.5723651],
						[13.4827956, 52.5726311],
						[13.4827397, 52.5726212],
						[13.4825711, 52.5725609],
						[13.4823636, 52.5725166],
						[13.4821881, 52.572494],
						[13.4820276, 52.5724936],
						[13.4817652, 52.5725395],
						[13.4815434, 52.572583],
						[13.4813323, 52.5726023],
						[13.4810542, 52.5726171],
						[13.4809206, 52.572616],
						[13.4802781, 52.5726066],
						[13.4799173, 52.5726294],
						[13.479702, 52.5726604],
						[13.4794593, 52.5726933],
						[13.479575, 52.5733407],
						[13.4798553, 52.5733239],
						[13.4798883, 52.57351],
						[13.4796199, 52.5735275],
						[13.4796679, 52.5737783],
						[13.4796253, 52.5737775],
						[13.479747, 52.5743465],
						[13.4797603, 52.5743964],
						[13.4797907, 52.5745119],
						[13.4798728, 52.5746808],
						[13.480077, 52.575021],
						[13.4800812, 52.5751378],
						[13.4797464, 52.5751491],
						[13.4797867, 52.5752818],
						[13.479818, 52.575425],
						[13.4799151, 52.5754144],
						[13.4799478, 52.5765082],
						[13.4799895, 52.5776948],
						[13.4800573, 52.577719],
						[13.4801461, 52.5777422],
						[13.4804531, 52.5777252],
						[13.4805163, 52.5777019],
						[13.4805154, 52.5777509],
						[13.4805132, 52.5777789],
						[13.4804158, 52.5777982],
						[13.4804027, 52.5778553],
						[13.4802701, 52.5778356],
						[13.480254, 52.5779186],
						[13.4801988, 52.5779239],
						[13.4800385, 52.5786013],
						[13.480084, 52.5786048],
						[13.4799446, 52.5791969],
						[13.4798717, 52.5793391],
						[13.4798182, 52.5794418],
						[13.4797363, 52.5796012],
						[13.4796981, 52.5800125],
						[13.479689, 52.580092],
						[13.4797613, 52.5803427],
						[13.4797715, 52.5805544],
						[13.479722, 52.5807813],
						[13.4797165, 52.5809259],
						[13.4797203, 52.5810569],
						[13.4797294, 52.5811627],
						[13.4797744, 52.5813564],
						[13.4798205, 52.5815854],
						[13.4800089, 52.5822473],
						[13.4801665, 52.5826263],
						[13.4802622, 52.582875],
						[13.4804483, 52.5834134],
						[13.4804764, 52.5835099],
						[13.4816147, 52.5833164],
						[13.4819913, 52.5840053],
						[13.4835889, 52.5840782],
						[13.4843443, 52.5856414],
						[13.4846596, 52.5856249],
						[13.4855653, 52.587362],
						[13.4857277, 52.5876131],
						[13.4864446, 52.5883837],
						[13.4883915, 52.5897282],
						[13.4889814, 52.5900195],
						[13.5050484, 52.5963705],
						[13.5051814, 52.5964434],
						[13.5053598, 52.5961871],
						[13.5058152, 52.5954048],
						[13.5081262, 52.5921593],
						[13.5107985, 52.5922272],
						[13.5161427, 52.5924362],
						[13.5171535, 52.5925092],
						[13.5176365, 52.5925432],
						[13.520641, 52.5926732],
						[13.5228167, 52.5927302],
						[13.527236, 52.5922273],
						[13.527424, 52.5922043],
						[13.5297906, 52.5917614],
						[13.5347149, 52.5906816],
						[13.5358248, 52.5904456],
						[13.5371226, 52.5901707],
						[13.5384744, 52.5898807],
						[13.5416769, 52.5891638],
						[13.5419359, 52.5891028],
						[13.5463032, 52.588057],
						[13.5471451, 52.587855],
						[13.5469831, 52.587577],
						[13.5470487, 52.5875584],
						[13.547818, 52.5873401],
						[13.548022, 52.5872681],
						[13.5493398, 52.5867602],
						[13.5495088, 52.5866952],
						[13.5498579, 52.586713],
						[13.5530173, 52.5852654],
						[13.554673, 52.5845095],
						[13.555083, 52.5843225],
						[13.5560029, 52.5838076],
						[13.5579716, 52.5827087],
						[13.5604232, 52.5813399],
						[13.5610457, 52.580915],
						[13.5617816, 52.5804127],
						[13.5623438, 52.5799503],
						[13.5627164, 52.5796422],
						[13.5635328, 52.5789672],
						[13.5637804, 52.5787902],
						[13.5644507, 52.5783112],
						[13.5665034, 52.5761677],
						[13.5669013, 52.5754634],
						[13.5674164, 52.5748609],
						[13.5676755, 52.5745361],
						[13.567546, 52.5744011],
						[13.5664304, 52.5729756],
						[13.5652689, 52.5725382],
						[13.563402, 52.5719665],
						[13.5614211, 52.5715291],
						[13.556255, 52.5703078],
						[13.5508182, 52.5690086],
						[13.5461509, 52.5678738],
						[13.5456949, 52.5678045],
						[13.5455425, 52.5677187],
						[13.5443821, 52.5667535],
						[13.5423957, 52.566085],
						[13.5412414, 52.5659681],
						[13.5399089, 52.5648983],
						[13.5370433, 52.5627211],
						[13.5341396, 52.560474],
						[13.5321088, 52.5592015],
						[13.5295396, 52.5576396],
						[13.5283155, 52.5569564],
						[13.5254938, 52.5554291],
						[13.5255382, 52.5553791],
						[13.5253561, 52.555318],
						[13.5262378, 52.5540305],
						[13.5263086, 52.5539835],
						[13.5271876, 52.5522872],
						[13.5272456, 52.5522735],
						[13.5272246, 52.5521737],
						[13.527706, 52.5508963],
						[13.527751, 52.5508258],
						[13.527743, 52.5507896],
						[13.5279571, 52.550214],
						[13.5278879, 52.5501562],
						[13.5282001, 52.5494387],
						[13.5282496, 52.5494446],
						[13.5282752, 52.5493895],
						[13.5282217, 52.5493814],
						[13.5288242, 52.5477584],
						[13.5288032, 52.5477244],
						[13.5288407, 52.5476248],
						[13.5288761, 52.5476018],
						[13.5296936, 52.5454409],
						[13.529697, 52.5454042],
						[13.5300078, 52.5444703],
						[13.5304506, 52.5435475],
						[13.5305644, 52.5435462],
						[13.5305735, 52.5435337],
						[13.5306316, 52.5429453],
						[13.5305974, 52.5429162],
						[13.5307591, 52.5424357],
						[13.5307852, 52.5423866],
						[13.5309947, 52.5417303],
						[13.5313589, 52.5409853],
						[13.5318962, 52.5395273],
						[13.5322639, 52.539314],
						[13.5315768, 52.5391734],
						[13.5316051, 52.5391144],
						[13.5316878, 52.5389721],
						[13.5314164, 52.5389196],
						[13.5312952, 52.5388931],
						[13.5314317, 52.5389098],
						[13.5314964, 52.538856],
						[13.53159, 52.5386002],
						[13.5310923, 52.5384866],
						[13.5307651, 52.5384063],
						[13.5305957, 52.5384565],
						[13.5287859, 52.5379919],
						[13.5278441, 52.5378027],
						[13.5265727, 52.5373976],
						[13.5254199, 52.5370114],
						[13.5254037, 52.5370303],
						[13.5253535, 52.5370141],
						[13.5253609, 52.5370059],
						[13.5249532, 52.5368646],
						[13.5245701, 52.5367315],
						[13.5245775, 52.5367215],
						[13.5241339, 52.5365759],
						[13.5237119, 52.5364431],
						[13.5235135, 52.5363896],
						[13.523067, 52.5362735],
						[13.5227951, 52.5362147],
						[13.5223845, 52.5361329],
						[13.521834, 52.5360448],
						[13.5215531, 52.5360081],
						[13.5207876, 52.5359351],
						[13.5205059, 52.5359165],
						[13.5198056, 52.5358896],
						[13.5192132, 52.5358352],
						[13.5192156, 52.535822],
						[13.5191721, 52.53582],
						[13.5192043, 52.5357877],
						[13.5192591, 52.5357084],
						[13.5181536, 52.5356966],
						[13.5178543, 52.5356766],
						[13.5175532, 52.5356566],
						[13.5172418, 52.5356244],
						[13.5169658, 52.5355915],
						[13.5184415, 52.5336533],
						[13.5187089, 52.5327772],
						[13.5188811, 52.5313689],
						[13.5191583, 52.5287074],
						[13.5193609, 52.526089],
						[13.5194396, 52.5250717],
						[13.5192836, 52.5209244],
						[13.5189832, 52.5164899],
						[13.5188251, 52.5144964],
						[13.5187823, 52.5139564],
						[13.5249774, 52.5137899],
						[13.5255782, 52.5137729],
						[13.528737, 52.5136619],
						[13.5300829, 52.5137528],
						[13.5307967, 52.5138056],
						[13.5313117, 52.5138056],
						[13.5320155, 52.5137534],
						[13.5328738, 52.5136802],
						[13.5337664, 52.5136019],
						[13.5344702, 52.513534],
						[13.5349595, 52.5134452],
						[13.53538, 52.513325],
						[13.5356169, 52.5132325],
						[13.5358349, 52.5131474],
						[13.5362984, 52.5129228],
						[13.5365923, 52.5127387],
						[13.5370966, 52.5123639],
						[13.53744, 52.5119826],
						[13.537603, 52.5116953],
						[13.5377747, 52.5113035],
						[13.5378605, 52.5109849],
						[13.5378949, 52.5106558],
						[13.5378643, 52.5099309],
						[13.5362109, 52.5099898],
						[13.5363065, 52.5079405],
						[13.5361174, 52.5079007],
						[13.5363048, 52.5064026],
						[13.5360176, 52.506001],
						[13.5351965, 52.505546],
						[13.5394999, 52.4985446],
						[13.5417345, 52.4982942],
						[13.5421115, 52.4982212],
						[13.5421454, 52.4982004],
						[13.542659, 52.4976301],
						[13.542763, 52.4976619],
						[13.5437461, 52.4966696],
						[13.543645, 52.4966185],
						[13.5440171, 52.4962126],
						[13.5437459, 52.4959357],
						[13.5438782, 52.4956827],
						[13.5439345, 52.4956562],
						[13.5442627, 52.4955171],
						[13.5443253, 52.49558],
						[13.5445023, 52.4955104],
						[13.5447042, 52.4944035],
						[13.5445811, 52.4943931],
						[13.5447714, 52.4933306],
						[13.5446582, 52.4931906],
						[13.544791, 52.4931671],
						[13.5448708, 52.4931082],
						[13.5450036, 52.4931127],
						[13.5460317, 52.4920773],
						[13.5459135, 52.4920404],
						[13.5462013, 52.4917399],
						[13.5467415, 52.4908059],
						[13.5468887, 52.4908458],
						[13.5476298, 52.4895774],
						[13.5475067, 52.4895301],
						[13.5482331, 52.4883457],
						[13.5483852, 52.4883811],
						[13.5491236, 52.4872159],
						[13.5489885, 52.4871598],
						[13.549797, 52.4859194],
						[13.549953, 52.485954],
						[13.5502142, 52.4855003],
						[13.5502871, 52.4852086],
						[13.550114, 52.4847958],
						[13.549955, 52.4848091],
						[13.5498205, 52.4844605],
						[13.5490636, 52.4837891],
						[13.5491711, 52.4837386],
						[13.5484407, 52.4826125],
						[13.5483058, 52.4826145],
						[13.5482504, 52.4825156],
						[13.5478625, 52.4825514],
						[13.5478534, 52.4819378],
						[13.5478703, 52.4819275],
						[13.5478509, 52.4809544],
						[13.5479313, 52.4808654],
						[13.5479326, 52.4798141],
						[13.5484727, 52.4798084],
						[13.5489805, 52.4792211],
						[13.5489588, 52.4791454],
						[13.5508136, 52.4770151],
						[13.5509107, 52.4769774],
						[13.5525134, 52.4739873],
						[13.5532396, 52.4736172],
						[13.5508832, 52.4736182],
						[13.5506125, 52.4736479],
						[13.5503596, 52.4736757],
						[13.5494842, 52.4738221],
						[13.5489949, 52.4738848],
						[13.5481195, 52.4738221],
						[13.5484666, 52.4732732],
						[13.5429199, 52.4738569],
						[13.5420083, 52.4739528],
						[13.5400771, 52.4748311],
						[13.5380258, 52.4731424],
						[13.5323867, 52.4690538],
						[13.5306615, 52.4678355],
						[13.5242372, 52.4733847],
						[13.5217732, 52.4751713],
						[13.5205678, 52.4740103],
						[13.5163106, 52.475448],
						[13.5158643, 52.4755996],
						[13.5154866, 52.475678],
						[13.5149888, 52.4757356],
						[13.5144996, 52.4757669],
						[13.5140361, 52.4757774],
						[13.513461, 52.475746],
						[13.5116586, 52.4754898],
						[13.5077876, 52.4749148],
						[13.5043372, 52.4780671],
						[13.5041913, 52.4782815],
						[13.5031442, 52.4801895],
						[13.5019663, 52.4824494],
						[13.5018784, 52.4824586],
						[13.5015412, 52.4825664],
						[13.5014175, 52.482589],
						[13.5013119, 52.4825956],
						[13.5005855, 52.4826829],
						[13.4995903, 52.482874],
						[13.4985909, 52.4831226],
						[13.4982784, 52.4832121],
						[13.4979782, 52.4833066],
						[13.4971152, 52.4833333],
						[13.4968842, 52.4833047],
						[13.4967425, 52.4833152],
						[13.4966361, 52.4833156],
						[13.4941951, 52.4831241],
						[13.4938577, 52.4838766],
						[13.4930015, 52.485637],
						[13.4914136, 52.4871841],
						[13.4910096, 52.487359],
						[13.4914434, 52.4882828],
						[13.4887873, 52.4892704],
						[13.4885271, 52.4898498],
						[13.4876023, 52.490202],
						[13.4861097, 52.4907442],
						[13.4860524, 52.4908601],
						[13.4856938, 52.4912963],
						[13.4849871, 52.4916339],
						[13.4841318, 52.4916924],
						[13.4837311, 52.4918588],
						[13.4833635, 52.4921012],
						[13.4798223, 52.4945622],
						[13.4787715, 52.4952876],
						[13.4786274, 52.4952712],
						[13.4775987, 52.4959532],
						[13.4756862, 52.4973025],
						[13.4730756, 52.4990026],
						[13.4703009, 52.4993714],
						[13.4702758, 52.4994222],
						[13.4699714, 52.49948],
						[13.4690009, 52.4996149],
						[13.4685496, 52.4997086],
						[13.4694918, 52.5022678],
						[13.4702062, 52.5037443],
						[13.4704278, 52.5040641],
						[13.4706177, 52.5042759],
						[13.4712936, 52.5050021],
						[13.4711643, 52.5051377],
						[13.4714793, 52.5054665],
						[13.4715166, 52.5055151],
						[13.4717481, 52.5057351],
						[13.472166, 52.5060408],
						[13.4722603, 52.5061089],
						[13.4727145, 52.5063598],
						[13.472729, 52.5064557],
						[13.4728041, 52.5064933],
						[13.472769, 52.5065774],
						[13.4727896, 52.5066437],
						[13.472832, 52.5067101],
						[13.4734064, 52.5069189],
						[13.4730669, 52.5071259],
						[13.4738207, 52.5079312],
						[13.4738053, 52.5080071],
						[13.4739355, 52.5079698],
						[13.474028, 52.5080676],
						[13.4739021, 52.5081014],
						[13.4739616, 52.5081087],
						[13.4742096, 52.5083707],
						[13.4744845, 52.5086598],
						[13.4748083, 52.5089966],
						[13.4752503, 52.5094582],
						[13.4754191, 52.5096326],
						[13.4757244, 52.5100974],
						[13.4758272, 52.5102558],
						[13.4760482, 52.5103012],
						[13.4761639, 52.5103481],
						[13.4762658, 52.5104393],
						[13.4761484, 52.5109576],
						[13.4761107, 52.5111151],
						[13.475888, 52.5120534],
						[13.4759414, 52.5120589],
						[13.4759275, 52.5121135],
						[13.4758391, 52.5121224],
						[13.475777, 52.5124218],
						[13.4756314, 52.5130593],
						[13.4755311, 52.5130692],
						[13.4754364, 52.51343],
						[13.4754514, 52.5138226],
						[13.4758875, 52.5148631],
						[13.477492, 52.514388],
						[13.4777288, 52.5147204],
						[13.4767101, 52.5150482],
						[13.4766028, 52.5151233],
						[13.4765677, 52.5151541],
						[13.4764586, 52.5153008],
						[13.4760548, 52.5161244],
						[13.4755062, 52.5168234],
						[13.475472, 52.5169059],
						[13.4739892, 52.5191771],
						[13.4739614, 52.5192196],
						[13.4735151, 52.5191256],
						[13.4729572, 52.5198985],
						[13.4723656, 52.5206084],
						[13.4721166, 52.5205903],
						[13.4714962, 52.5213402],
						[13.4707951, 52.5220413],
						[13.4704533, 52.5223044],
						[13.4698619, 52.522716],
						[13.4696124, 52.5228511],
						[13.4689462, 52.5231848],
						[13.4683253, 52.5234339],
						[13.4674599, 52.523738],
						[13.4668811, 52.5239048],
						[13.4663588, 52.5240349],
						[13.4656706, 52.5241482],
						[13.464924, 52.524256],
						[13.4627094, 52.5245135],
						[13.4611837, 52.52504],
						[13.460756, 52.5251735],
						[13.4601158, 52.5253615],
						[13.4600782, 52.5253943],
						[13.458773, 52.5259261],
						[13.4579016, 52.5265932],
						[13.4570679, 52.5274154],
						[13.4561898, 52.5282609],
					],
				],
			},
			properties: {},
		},
	},
	{
		name: "Marzahn-Hellersdorf",
		coords: [13.583830998, 52.537164518],
		gen: false,
		polygon: {
			type: "Feature",
			geometry: {
				type: "Polygon",
				coordinates: [
					[
						[13.5169658, 52.5355915],
						[13.5172418, 52.5356244],
						[13.5175532, 52.5356566],
						[13.5178543, 52.5356766],
						[13.5181536, 52.5356966],
						[13.5192591, 52.5357084],
						[13.5192043, 52.5357877],
						[13.5191721, 52.53582],
						[13.5192156, 52.535822],
						[13.5192132, 52.5358352],
						[13.5198056, 52.5358896],
						[13.5205059, 52.5359165],
						[13.5207876, 52.5359351],
						[13.5215531, 52.5360081],
						[13.521834, 52.5360448],
						[13.5223845, 52.5361329],
						[13.5227951, 52.5362147],
						[13.523067, 52.5362735],
						[13.5235135, 52.5363896],
						[13.5237119, 52.5364431],
						[13.5241339, 52.5365759],
						[13.5245775, 52.5367215],
						[13.5245701, 52.5367315],
						[13.5249532, 52.5368646],
						[13.5253609, 52.5370059],
						[13.5253535, 52.5370141],
						[13.5254037, 52.5370303],
						[13.5254199, 52.5370114],
						[13.5265727, 52.5373976],
						[13.5278441, 52.5378027],
						[13.5287859, 52.5379919],
						[13.5305957, 52.5384565],
						[13.5307651, 52.5384063],
						[13.5310923, 52.5384866],
						[13.53159, 52.5386002],
						[13.5314964, 52.538856],
						[13.5314317, 52.5389098],
						[13.5312952, 52.5388931],
						[13.5314164, 52.5389196],
						[13.5316878, 52.5389721],
						[13.5316051, 52.5391144],
						[13.5315768, 52.5391734],
						[13.5322639, 52.539314],
						[13.5318962, 52.5395273],
						[13.5313589, 52.5409853],
						[13.5309947, 52.5417303],
						[13.5307852, 52.5423866],
						[13.5307591, 52.5424357],
						[13.5305974, 52.5429162],
						[13.5306316, 52.5429453],
						[13.5305735, 52.5435337],
						[13.5305644, 52.5435462],
						[13.5304506, 52.5435475],
						[13.5300078, 52.5444703],
						[13.529697, 52.5454042],
						[13.5296936, 52.5454409],
						[13.5288761, 52.5476018],
						[13.5288407, 52.5476248],
						[13.5288032, 52.5477244],
						[13.5288242, 52.5477584],
						[13.5282217, 52.5493814],
						[13.5282752, 52.5493895],
						[13.5282496, 52.5494446],
						[13.5282001, 52.5494387],
						[13.5278879, 52.5501562],
						[13.5279571, 52.550214],
						[13.527743, 52.5507896],
						[13.527751, 52.5508258],
						[13.527706, 52.5508963],
						[13.5272246, 52.5521737],
						[13.5272456, 52.5522735],
						[13.5271876, 52.5522872],
						[13.5263086, 52.5539835],
						[13.5262378, 52.5540305],
						[13.5253561, 52.555318],
						[13.5255382, 52.5553791],
						[13.5254938, 52.5554291],
						[13.5283155, 52.5569564],
						[13.5295396, 52.5576396],
						[13.5321088, 52.5592015],
						[13.5341396, 52.560474],
						[13.5370433, 52.5627211],
						[13.5399089, 52.5648983],
						[13.5412414, 52.5659681],
						[13.5423957, 52.566085],
						[13.5443821, 52.5667535],
						[13.5455425, 52.5677187],
						[13.5456949, 52.5678045],
						[13.5461509, 52.5678738],
						[13.5508182, 52.5690086],
						[13.556255, 52.5703078],
						[13.5614211, 52.5715291],
						[13.563402, 52.5719665],
						[13.5652689, 52.5725382],
						[13.5664304, 52.5729756],
						[13.567546, 52.5744011],
						[13.5676755, 52.5745361],
						[13.5677785, 52.5744137],
						[13.5682686, 52.5737892],
						[13.5684893, 52.5733878],
						[13.5685743, 52.5731038],
						[13.5691717, 52.5730839],
						[13.5690621, 52.5733818],
						[13.569627, 52.5733709],
						[13.569962, 52.5733629],
						[13.5708189, 52.5733359],
						[13.5712038, 52.5733139],
						[13.5715687, 52.5732949],
						[13.5716577, 52.5732909],
						[13.5733445, 52.5736109],
						[13.5745903, 52.5738548],
						[13.5747612, 52.5738738],
						[13.5749602, 52.5738778],
						[13.5753162, 52.5737139],
						[13.5758141, 52.5734739],
						[13.5760421, 52.5733559],
						[13.57665, 52.5730809],
						[13.576804, 52.572993],
						[13.5769319, 52.572886],
						[13.5770389, 52.572749],
						[13.5771079, 52.572612],
						[13.5773449, 52.5720971],
						[13.5774599, 52.5718681],
						[13.5777969, 52.5711582],
						[13.5778328, 52.5710892],
						[13.5778958, 52.5710362],
						[13.5790017, 52.5710442],
						[13.5802205, 52.5710472],
						[13.5812173, 52.5710662],
						[13.5812963, 52.5711202],
						[13.5814033, 52.5711392],
						[13.5815043, 52.5710172],
						[13.5815273, 52.5708682],
						[13.5818683, 52.5703223],
						[13.5825722, 52.5690065],
						[13.5828931, 52.5684615],
						[13.5831161, 52.5679426],
						[13.5831785, 52.567746],
						[13.5832081, 52.5676526],
						[13.5832921, 52.5670917],
						[13.5832621, 52.5667447],
						[13.5831761, 52.5665387],
						[13.5830322, 52.5661958],
						[13.5829992, 52.5659928],
						[13.5831002, 52.5655199],
						[13.5830522, 52.5651079],
						[13.5831072, 52.5649519],
						[13.5831302, 52.5648829],
						[13.5838151, 52.5637421],
						[13.5839161, 52.5632391],
						[13.5839661, 52.5625522],
						[13.5840461, 52.5622162],
						[13.5841711, 52.5618503],
						[13.5844161, 52.5611334],
						[13.5844941, 52.5599545],
						[13.5846701, 52.5593976],
						[13.5850521, 52.5587986],
						[13.5857407, 52.5582114],
						[13.5863429, 52.5576848],
						[13.5865349, 52.5573028],
						[13.5877247, 52.555747],
						[13.5878437, 52.5552741],
						[13.5877878, 52.5541142],
						[13.5877238, 52.5527673],
						[13.5875648, 52.5523934],
						[13.5873329, 52.5519774],
						[13.5870609, 52.5517294],
						[13.5870089, 52.5515735],
						[13.5870349, 52.5512145],
						[13.586911, 52.5508565],
						[13.5864991, 52.5502266],
						[13.5864401, 52.5501236],
						[13.5863412, 52.5499941],
						[13.5862721, 52.5498336],
						[13.5862606, 52.5496508],
						[13.5863541, 52.549561],
						[13.5864811, 52.5494827],
						[13.5865831, 52.5494597],
						[13.586757, 52.5494407],
						[13.586853, 52.5494297],
						[13.586987, 52.5494107],
						[13.587119, 52.5493987],
						[13.587289, 52.5493797],
						[13.5873999, 52.5493607],
						[13.5875079, 52.5493497],
						[13.5876419, 52.5493307],
						[13.5901165, 52.5489068],
						[13.5926342, 52.5484799],
						[13.5928081, 52.5484529],
						[13.5928881, 52.5484419],
						[13.5930601, 52.5484109],
						[13.5949288, 52.5480909],
						[13.5980834, 52.547553],
						[13.5983183, 52.547511],
						[13.5989573, 52.5474089],
						[13.5990254, 52.5473969],
						[13.5997611, 52.5472671],
						[13.5999991, 52.5472251],
						[13.6033126, 52.5466602],
						[13.6034666, 52.5466332],
						[13.6036105, 52.5466102],
						[13.607418, 52.5459963],
						[13.6096406, 52.5456413],
						[13.6104265, 52.5455154],
						[13.6107035, 52.5454514],
						[13.6136691, 52.5449854],
						[13.6171795, 52.5444015],
						[13.6173705, 52.5443785],
						[13.6174495, 52.5444325],
						[13.6182594, 52.5443026],
						[13.6187573, 52.5442226],
						[13.6191558, 52.5441719],
						[13.6193283, 52.5441516],
						[13.6196483, 52.5441337],
						[13.6202012, 52.544077],
						[13.6233228, 52.543812],
						[13.6235005, 52.5438062],
						[13.6244682, 52.5437326],
						[13.6267297, 52.5435333],
						[13.628606, 52.5433671],
						[13.6307077, 52.5431665],
						[13.6326163, 52.5429965],
						[13.6335093, 52.5429075],
						[13.6343922, 52.5428307],
						[13.6349833, 52.5425276],
						[13.6355082, 52.5422878],
						[13.6363951, 52.5422407],
						[13.6373901, 52.5422501],
						[13.6376451, 52.5409372],
						[13.6371157, 52.5403917],
						[13.6363723, 52.5396646],
						[13.6357021, 52.538997],
						[13.6355794, 52.5388779],
						[13.6351417, 52.538451],
						[13.6349065, 52.5382241],
						[13.6347377, 52.5382548],
						[13.634558, 52.5379259],
						[13.6345244, 52.5379338],
						[13.6345118, 52.537916],
						[13.6343849, 52.5379439],
						[13.6344003, 52.53798],
						[13.6341648, 52.5380271],
						[13.6339467, 52.5376518],
						[13.6337374, 52.5376984],
						[13.6333901, 52.5378154],
						[13.6331218, 52.5378679],
						[13.6330874, 52.5378019],
						[13.6327843, 52.5377929],
						[13.6318095, 52.5380844],
						[13.6316955, 52.5380844],
						[13.6315745, 52.5380584],
						[13.6309926, 52.5379134],
						[13.6307416, 52.5377304],
						[13.6306417, 52.5376884],
						[13.6305287, 52.5376994],
						[13.6294348, 52.5379364],
						[13.6289499, 52.5380424],
						[13.6286379, 52.5378589],
						[13.628348, 52.5376884],
						[13.628103, 52.5376044],
						[13.6279711, 52.5375814],
						[13.6277781, 52.5375774],
						[13.6275131, 52.5375964],
						[13.6249645, 52.5380233],
						[13.6249985, 52.5377944],
						[13.6251305, 52.5371954],
						[13.6251625, 52.5370624],
						[13.6253305, 52.5362955],
						[13.6255065, 52.5355286],
						[13.6255215, 52.5354566],
						[13.6256555, 52.5348387],
						[13.6257945, 52.5342018],
						[13.6257575, 52.5340908],
						[13.6248169, 52.5335886],
						[13.6251391, 52.5323394],
						[13.6254315, 52.5312269],
						[13.6256906, 52.5301817],
						[13.6260146, 52.5304035],
						[13.6267356, 52.5301252],
						[13.6267775, 52.5301661],
						[13.626993, 52.5301839],
						[13.6289801, 52.5304062],
						[13.6307268, 52.5305542],
						[13.6315337, 52.5305962],
						[13.6329305, 52.5305892],
						[13.6338763, 52.5305543],
						[13.6366069, 52.5305353],
						[13.6397765, 52.5304517],
						[13.6403977, 52.530433],
						[13.640774, 52.5304186],
						[13.6417103, 52.5303216],
						[13.6422092, 52.5302148],
						[13.6430008, 52.5300431],
						[13.6437346, 52.5298853],
						[13.6449507, 52.5298144],
						[13.6469544, 52.5297075],
						[13.6483062, 52.5296977],
						[13.6516514, 52.5297653],
						[13.6569499, 52.5298835],
						[13.6572591, 52.5290326],
						[13.6578667, 52.5272174],
						[13.6580698, 52.5264539],
						[13.6582348, 52.526171],
						[13.658593, 52.5258555],
						[13.6571209, 52.5251071],
						[13.656944, 52.5250001],
						[13.6554882, 52.5241342],
						[13.6518158, 52.5225813],
						[13.6487263, 52.5213424],
						[13.6470646, 52.5206745],
						[13.6466167, 52.5204645],
						[13.644307, 52.5193776],
						[13.6431942, 52.5188507],
						[13.6424573, 52.5184807],
						[13.6419034, 52.5181677],
						[13.6410366, 52.5176188],
						[13.6393039, 52.5163869],
						[13.6373637, 52.5152188],
						[13.6372942, 52.515177],
						[13.6372222, 52.515135],
						[13.6354185, 52.5138421],
						[13.6353155, 52.5137662],
						[13.6349546, 52.5134992],
						[13.6348686, 52.5134222],
						[13.6347946, 52.5133542],
						[13.6342707, 52.5128852],
						[13.6338308, 52.5124343],
						[13.6333429, 52.5118743],
						[13.6331289, 52.5116374],
						[13.632829, 52.5112364],
						[13.632564, 52.5108785],
						[13.6318092, 52.5098946],
						[13.6303434, 52.5078108],
						[13.6297576, 52.5069909],
						[13.6295582, 52.506775],
						[13.6294736, 52.5066312],
						[13.6293387, 52.5063626],
						[13.6291956, 52.5061284],
						[13.6289081, 52.5055466],
						[13.6284718, 52.504421],
						[13.6280633, 52.5032673],
						[13.6267818, 52.4991001],
						[13.6263459, 52.4981309],
						[13.6258698, 52.4972576],
						[13.6254245, 52.496546],
						[13.625019, 52.4958889],
						[13.6240237, 52.4942882],
						[13.6247326, 52.4940363],
						[13.6257875, 52.4936583],
						[13.6297089, 52.4930714],
						[13.629655, 52.4929929],
						[13.6296169, 52.4929374],
						[13.6279552, 52.4916216],
						[13.6267484, 52.4906337],
						[13.6252467, 52.4894058],
						[13.6251287, 52.4893098],
						[13.623331, 52.487838],
						[13.6229881, 52.487551],
						[13.6203305, 52.4853432],
						[13.6196039, 52.4847399],
						[13.6182579, 52.4836224],
						[13.6152164, 52.4810316],
						[13.6150014, 52.4807877],
						[13.6148575, 52.4806047],
						[13.6145575, 52.4794568],
						[13.6142216, 52.47779],
						[13.6141201, 52.4776969],
						[13.6137178, 52.4762672],
						[13.6135848, 52.4757032],
						[13.6136598, 52.4756612],
						[13.6148336, 52.4754323],
						[13.6158875, 52.4751613],
						[13.6163994, 52.4749663],
						[13.6162134, 52.4744869],
						[13.6145159, 52.4731865],
						[13.6132644, 52.472169],
						[13.6113751, 52.470657],
						[13.6115655, 52.470523],
						[13.608364, 52.4711086],
						[13.6084161, 52.4720698],
						[13.6084204, 52.4721488],
						[13.6084584, 52.4728496],
						[13.6042012, 52.472813],
						[13.6031509, 52.4728513],
						[13.601798, 52.4734352],
						[13.6002959, 52.4741671],
						[13.5995835, 52.4744861],
						[13.5983991, 52.4751343],
						[13.5979184, 52.4755473],
						[13.5975579, 52.4757042],
						[13.5965108, 52.4761486],
						[13.5957984, 52.4765825],
						[13.5939951, 52.4774736],
						[13.593172, 52.4778685],
						[13.5911121, 52.4789192],
						[13.5863986, 52.4811282],
						[13.5838233, 52.4804593],
						[13.5825705, 52.4801481],
						[13.5818628, 52.4799688],
						[13.5800141, 52.4810102],
						[13.5751561, 52.4794786],
						[13.5746596, 52.4778699],
						[13.5739631, 52.4768648],
						[13.574006, 52.4765563],
						[13.5739202, 52.4764727],
						[13.5737914, 52.4764727],
						[13.5736884, 52.4765511],
						[13.5736713, 52.4767968],
						[13.5716457, 52.4760126],
						[13.5693711, 52.4751082],
						[13.5682472, 52.4746775],
						[13.5675429, 52.4744076],
						[13.5673713, 52.4742299],
						[13.5671138, 52.473958],
						[13.566985, 52.4737489],
						[13.5666932, 52.4737384],
						[13.5654487, 52.4735764],
						[13.5652255, 52.4738273],
						[13.5628738, 52.4733045],
						[13.5591916, 52.4732052],
						[13.5583978, 52.4732208],
						[13.5578612, 52.4732313],
						[13.5567454, 52.4733568],
						[13.5549945, 52.4735711],
						[13.5536126, 52.4736234],
						[13.5532396, 52.4736172],
						[13.5525134, 52.4739873],
						[13.5509107, 52.4769774],
						[13.5508136, 52.4770151],
						[13.5489588, 52.4791454],
						[13.5489805, 52.4792211],
						[13.5484727, 52.4798084],
						[13.5479326, 52.4798141],
						[13.5479313, 52.4808654],
						[13.5478509, 52.4809544],
						[13.5478703, 52.4819275],
						[13.5478534, 52.4819378],
						[13.5478625, 52.4825514],
						[13.5482504, 52.4825156],
						[13.5483058, 52.4826145],
						[13.5484407, 52.4826125],
						[13.5491711, 52.4837386],
						[13.5490636, 52.4837891],
						[13.5498205, 52.4844605],
						[13.549955, 52.4848091],
						[13.550114, 52.4847958],
						[13.5502871, 52.4852086],
						[13.5502142, 52.4855003],
						[13.549953, 52.485954],
						[13.549797, 52.4859194],
						[13.5489885, 52.4871598],
						[13.5491236, 52.4872159],
						[13.5483852, 52.4883811],
						[13.5482331, 52.4883457],
						[13.5475067, 52.4895301],
						[13.5476298, 52.4895774],
						[13.5468887, 52.4908458],
						[13.5467415, 52.4908059],
						[13.5462013, 52.4917399],
						[13.5459135, 52.4920404],
						[13.5460317, 52.4920773],
						[13.5450036, 52.4931127],
						[13.5448708, 52.4931082],
						[13.544791, 52.4931671],
						[13.5446582, 52.4931906],
						[13.5447714, 52.4933306],
						[13.5445811, 52.4943931],
						[13.5447042, 52.4944035],
						[13.5445023, 52.4955104],
						[13.5443253, 52.49558],
						[13.5442627, 52.4955171],
						[13.5439345, 52.4956562],
						[13.5438782, 52.4956827],
						[13.5437459, 52.4959357],
						[13.5440171, 52.4962126],
						[13.543645, 52.4966185],
						[13.5437461, 52.4966696],
						[13.542763, 52.4976619],
						[13.542659, 52.4976301],
						[13.5421454, 52.4982004],
						[13.5421115, 52.4982212],
						[13.5417345, 52.4982942],
						[13.5394999, 52.4985446],
						[13.5351965, 52.505546],
						[13.5360176, 52.506001],
						[13.5363048, 52.5064026],
						[13.5361174, 52.5079007],
						[13.5363065, 52.5079405],
						[13.5362109, 52.5099898],
						[13.5378643, 52.5099309],
						[13.5378949, 52.5106558],
						[13.5378605, 52.5109849],
						[13.5377747, 52.5113035],
						[13.537603, 52.5116953],
						[13.53744, 52.5119826],
						[13.5370966, 52.5123639],
						[13.5365923, 52.5127387],
						[13.5362984, 52.5129228],
						[13.5358349, 52.5131474],
						[13.5356169, 52.5132325],
						[13.53538, 52.513325],
						[13.5349595, 52.5134452],
						[13.5344702, 52.513534],
						[13.5337664, 52.5136019],
						[13.5328738, 52.5136802],
						[13.5320155, 52.5137534],
						[13.5313117, 52.5138056],
						[13.5307967, 52.5138056],
						[13.5300829, 52.5137528],
						[13.528737, 52.5136619],
						[13.5255782, 52.5137729],
						[13.5249774, 52.5137899],
						[13.5187823, 52.5139564],
						[13.5188251, 52.5144964],
						[13.5189832, 52.5164899],
						[13.5192836, 52.5209244],
						[13.5194396, 52.5250717],
						[13.5193609, 52.526089],
						[13.5191583, 52.5287074],
						[13.5188811, 52.5313689],
						[13.5187089, 52.5327772],
						[13.5184415, 52.5336533],
						[13.5169658, 52.5355915],
					],
				],
			},
			properties: {},
		},
	},
	{
		name: "Mitte",
		coords: [13.3666652, 52.5166646],
		gen: false,
		polygon: {
			type: "Feature",
			geometry: {
				type: "Polygon",
				coordinates: [
					[
						[13.3015376, 52.5488072],
						[13.3028842, 52.5504488],
						[13.3036245, 52.551257],
						[13.3042867, 52.5519975],
						[13.3097219, 52.5577259],
						[13.3121467, 52.5583901],
						[13.3148406, 52.5590527],
						[13.3175644, 52.559693],
						[13.3187867, 52.5599216],
						[13.3213191, 52.5602541],
						[13.3236469, 52.5605517],
						[13.3239149, 52.5605703],
						[13.3241139, 52.5606287],
						[13.3246204, 52.5607095],
						[13.3251049, 52.5608409],
						[13.3253012, 52.5609133],
						[13.3254554, 52.560974],
						[13.3258453, 52.5611796],
						[13.3261287, 52.5613846],
						[13.3263487, 52.5615832],
						[13.3265657, 52.5618666],
						[13.3267595, 52.5622461],
						[13.3268009, 52.562382],
						[13.330928, 52.562151],
						[13.3317982, 52.5616579],
						[13.3323272, 52.5619021],
						[13.3363297, 52.5640219],
						[13.3366609, 52.5642092],
						[13.3371139, 52.5644452],
						[13.3468718, 52.5624485],
						[13.348711, 52.5620707],
						[13.3487438, 52.5619934],
						[13.3488264, 52.5619806],
						[13.3491235, 52.5619286],
						[13.3568789, 52.5620214],
						[13.3597194, 52.5618572],
						[13.3598146, 52.5617984],
						[13.3601163, 52.560471],
						[13.3645389, 52.5611792],
						[13.3650504, 52.5584304],
						[13.3651054, 52.5581157],
						[13.3666588, 52.5582214],
						[13.3671403, 52.5584785],
						[13.3711585, 52.5606041],
						[13.3771519, 52.560662],
						[13.3785175, 52.566113],
						[13.3814864, 52.5664447],
						[13.3826882, 52.5669338],
						[13.3839481, 52.5674465],
						[13.3841422, 52.5674962],
						[13.3844451, 52.5675428],
						[13.3849228, 52.5675621],
						[13.3854088, 52.5675288],
						[13.3859371, 52.5674612],
						[13.3871373, 52.5673076],
						[13.387359, 52.5672792],
						[13.3875832, 52.5672505],
						[13.3876691, 52.5672394],
						[13.3877633, 52.5672226],
						[13.3879037, 52.5673213],
						[13.3880026, 52.5673912],
						[13.388399, 52.5676684],
						[13.3885147, 52.5676044],
						[13.3886553, 52.5676591],
						[13.3890222, 52.5674165],
						[13.3890625, 52.5673788],
						[13.3890522, 52.5673244],
						[13.388793, 52.5671568],
						[13.3889572, 52.5670678],
						[13.3889262, 52.5670012],
						[13.3906385, 52.5660227],
						[13.3907522, 52.5658226],
						[13.3907257, 52.5657953],
						[13.3910366, 52.565625],
						[13.3910741, 52.5656462],
						[13.3913431, 52.5656227],
						[13.392865, 52.5647592],
						[13.393182, 52.5645538],
						[13.3933788, 52.5644019],
						[13.3933271, 52.5643782],
						[13.3933802, 52.5643426],
						[13.3935294, 52.5642328],
						[13.3936636, 52.564134],
						[13.3937289, 52.5640856],
						[13.394238, 52.563636],
						[13.3944908, 52.563394],
						[13.3952131, 52.5626122],
						[13.395228, 52.5625774],
						[13.3959136, 52.5618209],
						[13.3962198, 52.561294],
						[13.3963284, 52.5610733],
						[13.3964081, 52.5608611],
						[13.3965399, 52.5604382],
						[13.396628, 52.5601187],
						[13.3966467, 52.5600229],
						[13.3967565, 52.5597583],
						[13.3968464, 52.5595026],
						[13.3969751, 52.5591524],
						[13.3969616, 52.5588124],
						[13.3970113, 52.5584433],
						[13.3970341, 52.5583962],
						[13.3971127, 52.558269],
						[13.3971833, 52.5578919],
						[13.3971512, 52.5578869],
						[13.3971566, 52.5573954],
						[13.3971615, 52.5572462],
						[13.3971822, 52.5563229],
						[13.3973465, 52.5556524],
						[13.3976194, 52.5546096],
						[13.3980919, 52.5529598],
						[13.3981075, 52.5527765],
						[13.3980782, 52.5523533],
						[13.397982, 52.551992],
						[13.3978531, 52.5516546],
						[13.3977476, 52.5514806],
						[13.3976262, 52.5513245],
						[13.3973473, 52.5511137],
						[13.3971892, 52.55097],
						[13.3968515, 52.5507042],
						[13.3988952, 52.5508871],
						[13.399236, 52.5490548],
						[13.3992861, 52.5489438],
						[13.400276, 52.546727],
						[13.400306, 52.5466498],
						[13.4003573, 52.5464168],
						[13.4017794, 52.5437064],
						[13.4024741, 52.5423351],
						[13.4027319, 52.5418258],
						[13.4035266, 52.5402138],
						[13.4041547, 52.5403892],
						[13.4042237, 52.5404067],
						[13.4045527, 52.5402595],
						[13.4047002, 52.540185],
						[13.4053577, 52.5388355],
						[13.4055366, 52.5384811],
						[13.4058863, 52.5377837],
						[13.4065804, 52.536422],
						[13.4069181, 52.5357102],
						[13.4069768, 52.5356028],
						[13.4074838, 52.5346907],
						[13.4078028, 52.5345646],
						[13.4083548, 52.5343478],
						[13.4064339, 52.5323628],
						[13.4060436, 52.5310551],
						[13.4059576, 52.530765],
						[13.4060629, 52.5305863],
						[13.4058745, 52.5303766],
						[13.4055381, 52.5296496],
						[13.4073923, 52.5293459],
						[13.4082053, 52.5292086],
						[13.4094567, 52.5289251],
						[13.4100859, 52.5287966],
						[13.4114239, 52.5285597],
						[13.4118819, 52.5284263],
						[13.4120826, 52.5283652],
						[13.4123258, 52.5283026],
						[13.4155634, 52.5273999],
						[13.4159556, 52.5271846],
						[13.4161457, 52.5270836],
						[13.4166868, 52.5268757],
						[13.4167082, 52.5268863],
						[13.4169053, 52.526796],
						[13.4168897, 52.5267836],
						[13.4175618, 52.5264858],
						[13.41821, 52.5261978],
						[13.4197534, 52.5255461],
						[13.4197996, 52.5255288],
						[13.4217536, 52.5247551],
						[13.422336, 52.524527],
						[13.4237733, 52.5239632],
						[13.4240706, 52.5238552],
						[13.424745, 52.5236397],
						[13.4254333, 52.5234391],
						[13.4254847, 52.5234462],
						[13.425881, 52.5233521],
						[13.4262749, 52.5233038],
						[13.426501, 52.5232938],
						[13.4265191, 52.5229415],
						[13.4260028, 52.5229219],
						[13.4259104, 52.5228316],
						[13.4255523, 52.5227924],
						[13.427648, 52.5218833],
						[13.4286516, 52.5214498],
						[13.4291793, 52.5212255],
						[13.4291879, 52.5212028],
						[13.4287208, 52.520244],
						[13.4283998, 52.5195865],
						[13.427473, 52.519607],
						[13.4272839, 52.5196462],
						[13.4269188, 52.5197438],
						[13.4264469, 52.5191356],
						[13.4258857, 52.5183374],
						[13.4262864, 52.5182252],
						[13.4264656, 52.5181717],
						[13.4265929, 52.5181171],
						[13.4270187, 52.5179385],
						[13.4266276, 52.5173261],
						[13.4259571, 52.5162893],
						[13.4257778, 52.5159913],
						[13.4265029, 52.5158327],
						[13.4256938, 52.5149428],
						[13.4255792, 52.5148191],
						[13.4251965, 52.5144161],
						[13.4246173, 52.5138765],
						[13.4242813, 52.5135692],
						[13.4240861, 52.5133932],
						[13.42399, 52.5133045],
						[13.4233569, 52.5126682],
						[13.4232627, 52.5120616],
						[13.4235907, 52.5118554],
						[13.4242054, 52.5115003],
						[13.4249204, 52.5111143],
						[13.4255866, 52.5107436],
						[13.4260889, 52.5104434],
						[13.4271416, 52.5098435],
						[13.4277159, 52.5095111],
						[13.4283935, 52.5090733],
						[13.4286965, 52.509001],
						[13.4288928, 52.5089875],
						[13.42917, 52.5088441],
						[13.4291869, 52.5088211],
						[13.4292032, 52.5087282],
						[13.4292376, 52.5086726],
						[13.4294017, 52.5085714],
						[13.4290283, 52.5080363],
						[13.4288144, 52.5079776],
						[13.4283665, 52.5073345],
						[13.4281137, 52.5069735],
						[13.4279958, 52.5068044],
						[13.4278537, 52.5066013],
						[13.427689, 52.5063666],
						[13.4276139, 52.5062578],
						[13.427499, 52.5060935],
						[13.4271997, 52.5056674],
						[13.4269722, 52.5057312],
						[13.4267083, 52.5057986],
						[13.4261207, 52.5055508],
						[13.4258215, 52.5054247],
						[13.4255041, 52.5053239],
						[13.4252194, 52.5052477],
						[13.4249569, 52.5051819],
						[13.4247139, 52.505133],
						[13.4244748, 52.5050933],
						[13.4243499, 52.5050748],
						[13.4239799, 52.5050293],
						[13.4237378, 52.505009],
						[13.4233526, 52.5049868],
						[13.423038, 52.50498],
						[13.4226642, 52.5049872],
						[13.4223011, 52.5050078],
						[13.4220787, 52.5050273],
						[13.4218109, 52.505042],
						[13.4215419, 52.5050785],
						[13.4211305, 52.5051754],
						[13.4206174, 52.5053214],
						[13.4194305, 52.5056491],
						[13.4191101, 52.5052037],
						[13.4189981, 52.5051182],
						[13.4189245, 52.50508],
						[13.4189443, 52.5050603],
						[13.4185318, 52.504969],
						[13.4185186, 52.5049867],
						[13.4184001, 52.5049933],
						[13.4182136, 52.5050177],
						[13.4176016, 52.5041685],
						[13.4168472, 52.5043798],
						[13.4166967, 52.5044216],
						[13.4166273, 52.5044415],
						[13.4149072, 52.5049185],
						[13.4145925, 52.5047921],
						[13.4146097, 52.5046798],
						[13.4140723, 52.5040371],
						[13.4115252, 52.5048911],
						[13.4108939, 52.5057255],
						[13.4099694, 52.5069278],
						[13.4080301, 52.5061832],
						[13.4078501, 52.5063267],
						[13.4074002, 52.5066723],
						[13.4073589, 52.5066478],
						[13.4064044, 52.5073853],
						[13.4059383, 52.5077299],
						[13.4057965, 52.5078345],
						[13.4056214, 52.5079615],
						[13.4052837, 52.5082102],
						[13.4046343, 52.5079228],
						[13.4045782, 52.5078856],
						[13.4044429, 52.5077718],
						[13.4043012, 52.5078617],
						[13.404037, 52.5079941],
						[13.4036335, 52.5081778],
						[13.4027619, 52.5085334],
						[13.4021013, 52.5087285],
						[13.4012638, 52.5090381],
						[13.4009175, 52.5091378],
						[13.4002283, 52.5093816],
						[13.4001886, 52.5093755],
						[13.4000254, 52.509151],
						[13.3999387, 52.5090282],
						[13.3997671, 52.5088],
						[13.3992301, 52.5080775],
						[13.3987944, 52.5080917],
						[13.3987719, 52.5081134],
						[13.3981888, 52.5080789],
						[13.3942839, 52.5078332],
						[13.3922257, 52.5077033],
						[13.390484, 52.5075985],
						[13.3898682, 52.5075711],
						[13.3868833, 52.5073951],
						[13.3857617, 52.5073286],
						[13.3789222, 52.5069995],
						[13.3776503, 52.5079658],
						[13.377639, 52.5079091],
						[13.3749797, 52.5033759],
						[13.3747522, 52.5032345],
						[13.3746784, 52.5032356],
						[13.3742442, 52.5034855],
						[13.3736093, 52.504164],
						[13.373043, 52.5033823],
						[13.3721632, 52.5024872],
						[13.3717361, 52.5020391],
						[13.3714531, 52.501758],
						[13.3713442, 52.501634],
						[13.3708884, 52.4999544],
						[13.3708251, 52.4998602],
						[13.370587, 52.4996836],
						[13.3705545, 52.4996818],
						[13.3705522, 52.4996846],
						[13.370362, 52.4995305],
						[13.3703731, 52.4995143],
						[13.3695331, 52.4988698],
						[13.3693571, 52.4987357],
						[13.3690868, 52.4987573],
						[13.3688402, 52.4987771],
						[13.3630442, 52.4995624],
						[13.3625368, 52.499662],
						[13.3603634, 52.5002845],
						[13.3600544, 52.5003768],
						[13.3586633, 52.5007739],
						[13.3576374, 52.5010619],
						[13.3513967, 52.5025842],
						[13.3514081, 52.5025413],
						[13.3415264, 52.5049424],
						[13.3414287, 52.5049347],
						[13.3409772, 52.50507],
						[13.3402909, 52.5051628],
						[13.3400212, 52.5051852],
						[13.3397341, 52.5051919],
						[13.3394556, 52.5051728],
						[13.339196, 52.5051353],
						[13.3391048, 52.5051171],
						[13.3389373, 52.5053527],
						[13.3388883, 52.5054269],
						[13.3388505, 52.5055031],
						[13.3387914, 52.5056224],
						[13.3386407, 52.5059263],
						[13.3386261, 52.5059237],
						[13.3386125, 52.5059212],
						[13.3385796, 52.5059151],
						[13.3383515, 52.5058882],
						[13.3382599, 52.5058776],
						[13.3382358, 52.5058748],
						[13.3381883, 52.5058694],
						[13.3375282, 52.5057955],
						[13.3371235, 52.5057436],
						[13.3370014, 52.505728],
						[13.3369049, 52.5057211],
						[13.3368565, 52.5057198],
						[13.3367126, 52.5057159],
						[13.3365935, 52.5057203],
						[13.3362178, 52.5057519],
						[13.3358381, 52.5057867],
						[13.3357443, 52.5057986],
						[13.3355834, 52.5058267],
						[13.3354027, 52.5058792],
						[13.335261, 52.5059377],
						[13.3347405, 52.5061821],
						[13.3340022, 52.5065183],
						[13.3340673, 52.5065787],
						[13.3339919, 52.5066121],
						[13.333815, 52.5066932],
						[13.3337867, 52.5066703],
						[13.3337324, 52.5066962],
						[13.3338643, 52.5068775],
						[13.3341621, 52.5073161],
						[13.3348071, 52.5080531],
						[13.3347329, 52.5080721],
						[13.3347953, 52.5081718],
						[13.3348072, 52.5082364],
						[13.3348091, 52.5082701],
						[13.3348131, 52.5083692],
						[13.3347953, 52.5084195],
						[13.3347645, 52.5084748],
						[13.3348035, 52.5084868],
						[13.3346893, 52.50861],
						[13.3343794, 52.5087214],
						[13.3343111, 52.5087383],
						[13.3339029, 52.5088268],
						[13.3332969, 52.5090158],
						[13.3332735, 52.5089901],
						[13.3329644, 52.5090952],
						[13.3326507, 52.5091935],
						[13.3326448, 52.5092446],
						[13.3308425, 52.5098036],
						[13.3315444, 52.510734],
						[13.3316487, 52.5105993],
						[13.3324359, 52.5108305],
						[13.3327899, 52.5109505],
						[13.3331702, 52.51075],
						[13.3341862, 52.5112355],
						[13.3343306, 52.5114457],
						[13.3343987, 52.5115524],
						[13.3344765, 52.5116846],
						[13.333908, 52.5117783],
						[13.3338159, 52.5117772],
						[13.3337099, 52.5117932],
						[13.3331081, 52.5119271],
						[13.3320446, 52.512156],
						[13.331658, 52.5122879],
						[13.3314144, 52.5123733],
						[13.331017, 52.5125289],
						[13.3308416, 52.5126605],
						[13.3307602, 52.5128413],
						[13.3313966, 52.5128808],
						[13.3314189, 52.5128372],
						[13.3314591, 52.5128072],
						[13.3315537, 52.5127923],
						[13.3316518, 52.5128124],
						[13.3316703, 52.5128689],
						[13.3316517, 52.5129344],
						[13.3317636, 52.5130162],
						[13.3358553, 52.5133009],
						[13.3360169, 52.5137871],
						[13.3361112, 52.5139681],
						[13.3359279, 52.5139614],
						[13.3361484, 52.5143777],
						[13.3353781, 52.5148588],
						[13.335333, 52.5150385],
						[13.3347954, 52.5155021],
						[13.3343775, 52.5164144],
						[13.3342149, 52.5167715],
						[13.3339796, 52.5167375],
						[13.3337817, 52.5167102],
						[13.3336073, 52.5166892],
						[13.3334345, 52.5166761],
						[13.333225, 52.5166699],
						[13.3329896, 52.5166757],
						[13.3325936, 52.516692],
						[13.3325801, 52.5166902],
						[13.3325726, 52.5166846],
						[13.3322818, 52.5167042],
						[13.3322138, 52.5167331],
						[13.3318731, 52.5168094],
						[13.331672, 52.5168684],
						[13.3311858, 52.5170202],
						[13.3308998, 52.5171264],
						[13.3307283, 52.5172069],
						[13.3303265, 52.517441],
						[13.3301421, 52.5175537],
						[13.3299903, 52.5176589],
						[13.329795, 52.5178037],
						[13.3296383, 52.5179535],
						[13.3294724, 52.5181429],
						[13.3293703, 52.5182858],
						[13.3292808, 52.5184352],
						[13.329178, 52.5186457],
						[13.3291398, 52.5187707],
						[13.3291059, 52.5189676],
						[13.3290951, 52.5191688],
						[13.3291706, 52.5195229],
						[13.3292558, 52.5198188],
						[13.3293014, 52.5199439],
						[13.3294343, 52.5202761],
						[13.3294961, 52.5204744],
						[13.3295384, 52.5207043],
						[13.3295539, 52.5209237],
						[13.3295281, 52.5211961],
						[13.3294947, 52.5213339],
						[13.3294539, 52.5214544],
						[13.3293989, 52.5215924],
						[13.3292903, 52.5218024],
						[13.3290664, 52.5220746],
						[13.3288844, 52.5222993],
						[13.3288815, 52.5223083],
						[13.3288902, 52.5223117],
						[13.3288992, 52.5223201],
						[13.3288974, 52.5223309],
						[13.3287799, 52.5224402],
						[13.3286588, 52.5225513],
						[13.3286517, 52.5225539],
						[13.3286419, 52.5225558],
						[13.3286317, 52.5225547],
						[13.3286204, 52.5225512],
						[13.3285019, 52.5226508],
						[13.3284241, 52.522713],
						[13.3281929, 52.5228786],
						[13.3272275, 52.523544],
						[13.3269849, 52.5236954],
						[13.3268602, 52.5237712],
						[13.3266744, 52.5238725],
						[13.3264936, 52.5239589],
						[13.3262845, 52.5240464],
						[13.3258189, 52.5242337],
						[13.3255136, 52.5243361],
						[13.3252111, 52.5244322],
						[13.3250529, 52.5244793],
						[13.3247362, 52.5245547],
						[13.3244224, 52.5246152],
						[13.3241097, 52.5246624],
						[13.3238047, 52.5246964],
						[13.3236685, 52.5247082],
						[13.3235232, 52.5247142],
						[13.3232515, 52.5247049],
						[13.3224029, 52.5246494],
						[13.3220093, 52.5246162],
						[13.3216764, 52.5245738],
						[13.321366, 52.5245221],
						[13.3211029, 52.5244663],
						[13.3209395, 52.52441],
						[13.3202776, 52.5241476],
						[13.320012, 52.5240407],
						[13.3197897, 52.5239461],
						[13.3195567, 52.5237793],
						[13.3194651, 52.523706],
						[13.3193699, 52.523589],
						[13.319223, 52.5233808],
						[13.3190732, 52.5231743],
						[13.3189005, 52.5229426],
						[13.3188665, 52.5228442],
						[13.3188175, 52.5226128],
						[13.318713, 52.5221594],
						[13.3186875, 52.5220758],
						[13.3187153, 52.5219252],
						[13.3187454, 52.5218744],
						[13.3187769, 52.5211203],
						[13.3182794, 52.5207436],
						[13.3177979, 52.5203665],
						[13.317309, 52.5206209],
						[13.3168021, 52.5209231],
						[13.3164185, 52.5220304],
						[13.3133556, 52.5302609],
						[13.313691, 52.530304],
						[13.3147368, 52.5305107],
						[13.316741, 52.5309003],
						[13.3167287, 52.5309262],
						[13.3166697, 52.5310511],
						[13.316619, 52.5311582],
						[13.3167268, 52.531179],
						[13.3168971, 52.531244],
						[13.3173209, 52.5315279],
						[13.3170679, 52.5320484],
						[13.3172964, 52.5323723],
						[13.3169491, 52.5323233],
						[13.313078, 52.5318813],
						[13.3130779, 52.5318816],
						[13.3129198, 52.5322026],
						[13.3127883, 52.5323093],
						[13.3125516, 52.5326418],
						[13.3115043, 52.5353996],
						[13.3114285, 52.5356243],
						[13.3258496, 52.5376687],
						[13.3258534, 52.5376583],
						[13.3258645, 52.5376408],
						[13.3258746, 52.5376327],
						[13.3258868, 52.5376276],
						[13.3259016, 52.5376236],
						[13.3259177, 52.537621],
						[13.3259433, 52.5376236],
						[13.3266237, 52.5377211],
						[13.3268046, 52.5377419],
						[13.3268464, 52.5377513],
						[13.3268787, 52.5377684],
						[13.3268818, 52.5378035],
						[13.3281689, 52.5379828],
						[13.3283865, 52.5380239],
						[13.3287315, 52.5380969],
						[13.3291366, 52.5382348],
						[13.3283524, 52.5387989],
						[13.3271899, 52.5396468],
						[13.3271417, 52.5397326],
						[13.3271621, 52.5399526],
						[13.327309, 52.541308],
						[13.3273247, 52.5414304],
						[13.326802, 52.5416194],
						[13.3262338, 52.5418741],
						[13.3262655, 52.5419096],
						[13.3258414, 52.5421617],
						[13.3255614, 52.5423868],
						[13.3250526, 52.5427934],
						[13.324326, 52.543359],
						[13.323504, 52.5439963],
						[13.3231681, 52.5442868],
						[13.3228869, 52.5445189],
						[13.3206237, 52.5462131],
						[13.3202847, 52.546445],
						[13.3199588, 52.5467232],
						[13.3194795, 52.5471764],
						[13.319329, 52.5472928],
						[13.318895, 52.5475594],
						[13.3186261, 52.547738],
						[13.3185077, 52.5478304],
						[13.317956, 52.548241],
						[13.3149708, 52.5483208],
						[13.3015376, 52.5488072],
					],
				],
			},
			properties: {},
		},
	},
	{
		name: "Neukölln",
		coords: [13.4499982, 52.4833314],
		gen: false,
		polygon: {
			type: "Feature",
			geometry: {
				type: "Polygon",
				coordinates: [
					[
						[13.3994933, 52.4180204],
						[13.3999243, 52.4185371],
						[13.4001226, 52.4187739],
						[13.4048278, 52.4200071],
						[13.4054683, 52.4217627],
						[13.4026075, 52.4221665],
						[13.4036017, 52.4234416],
						[13.4040904, 52.4241432],
						[13.4051514, 52.4255153],
						[13.4054554, 52.4259514],
						[13.4057743, 52.4264693],
						[13.4060972, 52.4271172],
						[13.4062547, 52.4275205],
						[13.4063455, 52.4278149],
						[13.4065401, 52.4286027],
						[13.406575, 52.4287882],
						[13.4067696, 52.4298938],
						[13.4068768, 52.4301432],
						[13.4073482, 52.4308473],
						[13.4077099, 52.4313872],
						[13.4082262, 52.4321977],
						[13.4086042, 52.4327269],
						[13.4088682, 52.4331862],
						[13.4089819, 52.4335244],
						[13.4090233, 52.4337747],
						[13.409159, 52.4342126],
						[13.4097177, 52.4350261],
						[13.4103801, 52.4361454],
						[13.410446, 52.437009],
						[13.4105856, 52.4374894],
						[13.4109372, 52.4383447],
						[13.4109765, 52.4384322],
						[13.4111729, 52.4388826],
						[13.4116433, 52.4401198],
						[13.4118892, 52.4407646],
						[13.4129983, 52.4435475],
						[13.4139856, 52.4459438],
						[13.4145718, 52.4467282],
						[13.41477, 52.4470112],
						[13.4149885, 52.4480277],
						[13.4151055, 52.448476],
						[13.4151914, 52.4485837],
						[13.4157323, 52.4492662],
						[13.4159388, 52.45014],
						[13.4160773, 52.4508232],
						[13.4168347, 52.4522419],
						[13.4176908, 52.4522032],
						[13.4196272, 52.4546549],
						[13.4211298, 52.4567611],
						[13.4227945, 52.4566655],
						[13.4244282, 52.4566285],
						[13.4260456, 52.4566605],
						[13.4265871, 52.4567698],
						[13.4248407, 52.4578148],
						[13.4228622, 52.4585422],
						[13.421251, 52.4591366],
						[13.4206129, 52.459369],
						[13.421449, 52.4606672],
						[13.4224018, 52.4605259],
						[13.4232379, 52.4604049],
						[13.4230248, 52.4613266],
						[13.4219833, 52.4615164],
						[13.4220286, 52.4616097],
						[13.4221473, 52.4624995],
						[13.4221057, 52.46272],
						[13.4219033, 52.4628709],
						[13.4217124, 52.4634942],
						[13.4216953, 52.4638101],
						[13.4216237, 52.4651364],
						[13.4216195, 52.4653002],
						[13.4215591, 52.465764],
						[13.4215498, 52.4658357],
						[13.4215065, 52.4658316],
						[13.4168805, 52.4653962],
						[13.416041, 52.4691537],
						[13.413491, 52.4787059],
						[13.4109501, 52.4779019],
						[13.4108798, 52.4777237],
						[13.4068134, 52.4781877],
						[13.4066327, 52.478229],
						[13.4068123, 52.4788793],
						[13.406246, 52.4801692],
						[13.4061509, 52.4806017],
						[13.4060789, 52.4809987],
						[13.4061265, 52.4810907],
						[13.4063142, 52.4825926],
						[13.4063457, 52.4827923],
						[13.4064589, 52.4835903],
						[13.4067496, 52.4854769],
						[13.4073191, 52.4860657],
						[13.4082859, 52.4868939],
						[13.4084232, 52.4871133],
						[13.4083375, 52.4874688],
						[13.4079124, 52.4884777],
						[13.4078376, 52.4884843],
						[13.4077568, 52.4886623],
						[13.4077228, 52.4887471],
						[13.4078874, 52.4888602],
						[13.4085595, 52.4887699],
						[13.4176929, 52.4876359],
						[13.421256, 52.4871637],
						[13.4231919, 52.4865698],
						[13.4237047, 52.4863837],
						[13.4238569, 52.4865329],
						[13.4241585, 52.4868285],
						[13.4249381, 52.4877802],
						[13.4253551, 52.4879987],
						[13.42535, 52.488182],
						[13.4252864, 52.4882845],
						[13.4249189, 52.4888765],
						[13.424297, 52.4898778],
						[13.4241967, 52.4900393],
						[13.4235851, 52.491017],
						[13.4234829, 52.4911806],
						[13.4229477, 52.4920412],
						[13.4227769, 52.4923143],
						[13.4223996, 52.4929191],
						[13.4222972, 52.4930834],
						[13.4217059, 52.4940225],
						[13.4215921, 52.4941974],
						[13.4207907, 52.495474],
						[13.420639, 52.4956194],
						[13.4204624, 52.4957903],
						[13.4203999, 52.4958638],
						[13.4204842, 52.495865],
						[13.4207201, 52.4958348],
						[13.4209691, 52.4957889],
						[13.4213921, 52.4957134],
						[13.421668, 52.4956565],
						[13.4219508, 52.4955905],
						[13.4222249, 52.4955198],
						[13.4224259, 52.495463],
						[13.4224899, 52.4954452],
						[13.4227462, 52.4953664],
						[13.4230669, 52.4952639],
						[13.423316, 52.4951829],
						[13.4241331, 52.494917],
						[13.4249348, 52.4946564],
						[13.4252666, 52.4945485],
						[13.4259279, 52.4943341],
						[13.4262867, 52.4942171],
						[13.4266613, 52.4940951],
						[13.4270498, 52.4939686],
						[13.4275708, 52.4937992],
						[13.4277767, 52.4937325],
						[13.4282946, 52.4935638],
						[13.4290746, 52.4933099],
						[13.4298525, 52.4930559],
						[13.4306646, 52.4927926],
						[13.4329811, 52.4920569],
						[13.4352982, 52.4913201],
						[13.4365475, 52.4909389],
						[13.4371352, 52.4907396],
						[13.4372844, 52.4906862],
						[13.4382887, 52.4903853],
						[13.4383633, 52.4903265],
						[13.438566, 52.4901512],
						[13.439228, 52.4896601],
						[13.4392338, 52.4896408],
						[13.4392535, 52.4896441],
						[13.4392625, 52.4896095],
						[13.4394355, 52.4896336],
						[13.4396278, 52.4896586],
						[13.439616, 52.4896918],
						[13.4396337, 52.4896945],
						[13.4396419, 52.4898352],
						[13.4396363, 52.4899002],
						[13.442613, 52.4883799],
						[13.4444472, 52.4874576],
						[13.4467642, 52.4889535],
						[13.4486583, 52.4879826],
						[13.4487256, 52.4880276],
						[13.4503267, 52.4870854],
						[13.4504433, 52.4871484],
						[13.4560669, 52.4842532],
						[13.4571227, 52.4850526],
						[13.4581365, 52.4858194],
						[13.4585642, 52.4855156],
						[13.4611798, 52.4837022],
						[13.4611547, 52.4835385],
						[13.4640048, 52.481536],
						[13.466633, 52.4796839],
						[13.4681143, 52.4786409],
						[13.4690888, 52.4779516],
						[13.4699438, 52.4773526],
						[13.4705734, 52.4769091],
						[13.47038, 52.4760111],
						[13.4699678, 52.4748049],
						[13.4697902, 52.4743987],
						[13.4696043, 52.4739922],
						[13.4695889, 52.473804],
						[13.4695973, 52.4737287],
						[13.4697915, 52.4733394],
						[13.4700471, 52.473026],
						[13.4707617, 52.4722546],
						[13.471289, 52.4717381],
						[13.4719926, 52.4710576],
						[13.4726717, 52.4704044],
						[13.4728854, 52.4702003],
						[13.4729597, 52.4701325],
						[13.4740125, 52.4691207],
						[13.4751541, 52.4680203],
						[13.4755979, 52.4676555],
						[13.4761859, 52.4672635],
						[13.4763569, 52.4671266],
						[13.4769546, 52.4665545],
						[13.477629, 52.4659105],
						[13.4783366, 52.4652375],
						[13.4784173, 52.4651331],
						[13.4784771, 52.4649879],
						[13.4785501, 52.4639858],
						[13.4781339, 52.4633695],
						[13.4776765, 52.4626972],
						[13.4771968, 52.4619892],
						[13.4766429, 52.4615619],
						[13.475804, 52.4609152],
						[13.4754454, 52.4603268],
						[13.4751825, 52.4599101],
						[13.4749768, 52.4594705],
						[13.4749512, 52.4591937],
						[13.4749713, 52.4587633],
						[13.4744335, 52.4587783],
						[13.4722406, 52.4588451],
						[13.4715476, 52.4588624],
						[13.4637041, 52.4590656],
						[13.459492, 52.4591786],
						[13.4573529, 52.4592357],
						[13.457264, 52.4592087],
						[13.457234, 52.4592582],
						[13.4571139, 52.4592539],
						[13.4569992, 52.4592033],
						[13.4569705, 52.459118],
						[13.4577131, 52.4581556],
						[13.4592323, 52.4563616],
						[13.4600906, 52.4551064],
						[13.4608012, 52.4541031],
						[13.4622192, 52.4520569],
						[13.4630174, 52.4511311],
						[13.4639358, 52.4502418],
						[13.4649915, 52.4494729],
						[13.4700555, 52.4469305],
						[13.4742022, 52.4449198],
						[13.4751364, 52.444469],
						[13.476163, 52.4439753],
						[13.4762727, 52.4439386],
						[13.4765395, 52.4438817],
						[13.4766797, 52.4438326],
						[13.4776699, 52.4433511],
						[13.4781606, 52.443104],
						[13.4782747, 52.4430063],
						[13.4784027, 52.4428671],
						[13.4784971, 52.4428034],
						[13.478547, 52.4428037],
						[13.4786129, 52.442772],
						[13.478685, 52.4427362],
						[13.480441, 52.4418715],
						[13.4842104, 52.4400502],
						[13.4882342, 52.4381717],
						[13.4931866, 52.435702],
						[13.497976, 52.43341],
						[13.5007655, 52.4321017],
						[13.5072583, 52.4289565],
						[13.5084702, 52.4285007],
						[13.5085549, 52.4285653],
						[13.5097524, 52.4281683],
						[13.5107558, 52.4278708],
						[13.5116809, 52.4276593],
						[13.5136811, 52.4272668],
						[13.5140917, 52.4272022],
						[13.5156786, 52.4270097],
						[13.5197724, 52.4267526],
						[13.5199511, 52.4267279],
						[13.5202823, 52.4266203],
						[13.520432, 52.4265915],
						[13.5204517, 52.4265675],
						[13.5205057, 52.4265574],
						[13.5204662, 52.4262432],
						[13.5203661, 52.4260501],
						[13.5201749, 52.426027],
						[13.5199375, 52.4251446],
						[13.5187184, 52.4222512],
						[13.518875, 52.4221344],
						[13.5192771, 52.4217022],
						[13.5191921, 52.4216506],
						[13.519466, 52.4213921],
						[13.519908, 52.421304],
						[13.5201348, 52.4210147],
						[13.5204508, 52.4208148],
						[13.5206018, 52.4201525],
						[13.5207856, 52.4195381],
						[13.5220515, 52.4184343],
						[13.5221001, 52.4183029],
						[13.5222198, 52.4183107],
						[13.5221928, 52.4177866],
						[13.5222007, 52.4171988],
						[13.5220934, 52.4171945],
						[13.5221994, 52.4168639],
						[13.5223656, 52.4164192],
						[13.5229117, 52.4154488],
						[13.5223041, 52.4145543],
						[13.5223985, 52.4145322],
						[13.5223308, 52.4144405],
						[13.5226438, 52.4143357],
						[13.5226983, 52.4141462],
						[13.5241327, 52.4135961],
						[13.5236575, 52.4131714],
						[13.5235647, 52.4131969],
						[13.5231626, 52.4126393],
						[13.5233979, 52.4124309],
						[13.522957, 52.4121338],
						[13.5234288, 52.4116683],
						[13.5228726, 52.4110416],
						[13.5231821, 52.4107878],
						[13.5232964, 52.4107912],
						[13.5228486, 52.4096301],
						[13.5224541, 52.4086087],
						[13.5221196, 52.4079129],
						[13.5218424, 52.4074056],
						[13.5217104, 52.4072555],
						[13.5213011, 52.4068993],
						[13.5206595, 52.4064015],
						[13.5207283, 52.4063266],
						[13.5205664, 52.406248],
						[13.5189773, 52.4051428],
						[13.5192807, 52.4049456],
						[13.5187597, 52.4046873],
						[13.5170219, 52.4035997],
						[13.5170533, 52.4035398],
						[13.5167171, 52.4033352],
						[13.5170398, 52.4028345],
						[13.516031, 52.4021738],
						[13.5160508, 52.4017829],
						[13.514114, 52.4012863],
						[13.5140785, 52.4012772],
						[13.5136728, 52.401171],
						[13.5129132, 52.4010163],
						[13.5114559, 52.4007739],
						[13.5093484, 52.4004319],
						[13.5057644, 52.399916],
						[13.5009594, 52.3993634],
						[13.4974723, 52.3987988],
						[13.4950582, 52.3985705],
						[13.4929203, 52.3982173],
						[13.4919079, 52.3980213],
						[13.4905494, 52.3977499],
						[13.4888338, 52.3976162],
						[13.4865074, 52.3974179],
						[13.485901, 52.3973066],
						[13.4835752, 52.3969397],
						[13.481786, 52.3964871],
						[13.4797667, 52.3959466],
						[13.479165, 52.3990426],
						[13.479152, 52.3991536],
						[13.479063, 52.3998745],
						[13.479009, 52.3999965],
						[13.4788251, 52.4004194],
						[13.4782861, 52.4013883],
						[13.4782521, 52.4014704],
						[13.4777192, 52.4027582],
						[13.4777352, 52.4030901],
						[13.4775403, 52.4035466],
						[13.4768663, 52.40391],
						[13.4756094, 52.4062828],
						[13.4740766, 52.4086865],
						[13.4722448, 52.4118941],
						[13.4722058, 52.4119631],
						[13.4710229, 52.4137939],
						[13.4693931, 52.4168805],
						[13.4688022, 52.4181584],
						[13.4683472, 52.4190853],
						[13.4682612, 52.4192262],
						[13.4680108, 52.4200398],
						[13.4663765, 52.4202371],
						[13.4643808, 52.420729],
						[13.4640158, 52.420813],
						[13.4637119, 52.420836],
						[13.4635949, 52.420897],
						[13.4633619, 52.420859],
						[13.4609298, 52.4207224],
						[13.4573425, 52.4199183],
						[13.456631, 52.4197761],
						[13.4550352, 52.4193291],
						[13.4533655, 52.4188371],
						[13.4510401, 52.4181442],
						[13.4509244, 52.4181491],
						[13.4495434, 52.4177113],
						[13.4495381, 52.4175403],
						[13.4489802, 52.4173803],
						[13.4485083, 52.4172503],
						[13.4479343, 52.4171283],
						[13.4458891, 52.4164034],
						[13.4457677, 52.4163194],
						[13.4455511, 52.4162517],
						[13.4438766, 52.4157857],
						[13.4425438, 52.4153687],
						[13.4409845, 52.4148545],
						[13.4392587, 52.4144845],
						[13.4388868, 52.4144425],
						[13.4320939, 52.4125467],
						[13.4304461, 52.4122607],
						[13.4302391, 52.4122687],
						[13.4295063, 52.4121467],
						[13.4278135, 52.4118717],
						[13.4258908, 52.4114977],
						[13.4241271, 52.4110938],
						[13.4218405, 52.4105288],
						[13.4200437, 52.4102538],
						[13.4197338, 52.4102118],
						[13.4195922, 52.4101749],
						[13.4197674, 52.4104759],
						[13.4182742, 52.4107583],
						[13.4169525, 52.4113041],
						[13.416027, 52.4116832],
						[13.4150571, 52.412074],
						[13.4106109, 52.4133716],
						[13.410461, 52.4133879],
						[13.409421, 52.4135172],
						[13.4076929, 52.4134691],
						[13.4057925, 52.4132206],
						[13.4045523, 52.4129813],
						[13.4024999, 52.4126958],
						[13.3997774, 52.417521],
						[13.3994933, 52.4180204],
					],
				],
			},
			properties: {},
		},
	},
	{
		name: "Pankow",
		coords: [13.3999984, 52.5666644],
		gen: false,
		polygon: {
			type: "Feature",
			geometry: {
				type: "Polygon",
				coordinates: [
					[
						[13.3475592, 52.5900844],
						[13.3511811, 52.5907109],
						[13.353672, 52.5909099],
						[13.3573416, 52.5909581],
						[13.3589569, 52.5910554],
						[13.3607251, 52.591286],
						[13.36177, 52.5921525],
						[13.368161, 52.5974905],
						[13.3689436, 52.5981315],
						[13.3708598, 52.5996597],
						[13.375409, 52.6065294],
						[13.3757864, 52.6070102],
						[13.3762453, 52.6076539],
						[13.3763311, 52.611146],
						[13.3759964, 52.6129544],
						[13.3750866, 52.6146794],
						[13.3734987, 52.6169567],
						[13.3706234, 52.621188],
						[13.3689497, 52.6231419],
						[13.3668907, 52.6254447],
						[13.3673126, 52.6254722],
						[13.3670456, 52.6256931],
						[13.3670596, 52.6258191],
						[13.3675015, 52.6260141],
						[13.3676865, 52.6259911],
						[13.3677625, 52.6258461],
						[13.3679664, 52.6257851],
						[13.3680374, 52.6257661],
						[13.3681724, 52.6257271],
						[13.3684524, 52.6256851],
						[13.3687283, 52.6257661],
						[13.3695012, 52.6261701],
						[13.3695912, 52.6263381],
						[13.3697082, 52.6264941],
						[13.3702661, 52.627047],
						[13.370738, 52.627414],
						[13.3710789, 52.62725],
						[13.3713889, 52.627238],
						[13.3716858, 52.627322],
						[13.3717588, 52.627341],
						[13.3720348, 52.627322],
						[13.3721288, 52.627353],
						[13.3724277, 52.627501],
						[13.3726447, 52.627631],
						[13.3740335, 52.6279479],
						[13.3764481, 52.6293628],
						[13.377062, 52.6297218],
						[13.3772299, 52.6298778],
						[13.3763471, 52.6306947],
						[13.3756401, 52.6313316],
						[13.3759231, 52.6313616],
						[13.3761881, 52.6314916],
						[13.376375, 52.6316825],
						[13.3776588, 52.6329834],
						[13.3778488, 52.6334444],
						[13.3783667, 52.6338953],
						[13.3788936, 52.6343523],
						[13.3795235, 52.6338953],
						[13.3801034, 52.6343713],
						[13.3803973, 52.6346352],
						[13.38263, 52.6352032],
						[13.382445, 52.6355272],
						[13.382354, 52.6355962],
						[13.382266, 52.6356611],
						[13.3814122, 52.6359781],
						[13.3814371, 52.6360841],
						[13.3818631, 52.6363711],
						[13.382092, 52.6364471],
						[13.382227, 52.6364891],
						[13.382544, 52.6365501],
						[13.3826499, 52.636569],
						[13.3827769, 52.6365771],
						[13.3829619, 52.6365841],
						[13.3834048, 52.6365541],
						[13.3839058, 52.6364701],
						[13.3841617, 52.6364431],
						[13.3843407, 52.6364201],
						[13.3844597, 52.6364091],
						[13.3847136, 52.6364281],
						[13.3849756, 52.6364471],
						[13.3855805, 52.6365541],
						[13.3869413, 52.636931],
						[13.3874622, 52.637084],
						[13.3879191, 52.637206],
						[13.3881951, 52.637317],
						[13.388912, 52.637893],
						[13.3892029, 52.6381289],
						[13.3893299, 52.6383159],
						[13.3893399, 52.6385149],
						[13.3895128, 52.6389608],
						[13.3895738, 52.6390518],
						[13.3897528, 52.6393198],
						[13.3898108, 52.6394068],
						[13.3899038, 52.6396738],
						[13.3898968, 52.6399177],
						[13.3898898, 52.6401737],
						[13.3898838, 52.6404257],
						[13.3899057, 52.6404947],
						[13.3899357, 52.6405937],
						[13.3898687, 52.6410286],
						[13.3898547, 52.6415696],
						[13.3899607, 52.6418945],
						[13.3901487, 52.6421155],
						[13.3902627, 52.6422565],
						[13.3903186, 52.6423025],
						[13.3903756, 52.6423525],
						[13.3909205, 52.6428174],
						[13.3910015, 52.6428904],
						[13.3916874, 52.6434694],
						[13.3920803, 52.6433744],
						[13.3921833, 52.6436033],
						[13.3922293, 52.6441713],
						[13.3922373, 52.6442783],
						[13.3922713, 52.6443583],
						[13.3923773, 52.6446222],
						[13.3923903, 52.6448662],
						[13.3925472, 52.6451672],
						[13.3929032, 52.6455141],
						[13.3931591, 52.6457701],
						[13.3932101, 52.6459801],
						[13.393872, 52.646575],
						[13.393966, 52.646647],
						[13.3938318, 52.6467188],
						[13.393779, 52.646747],
						[13.393996, 52.647025],
						[13.3941389, 52.647201],
						[13.3942039, 52.6474709],
						[13.3955007, 52.647258],
						[13.3961836, 52.647254],
						[13.3964956, 52.647304],
						[13.3970225, 52.6476429],
						[13.3966125, 52.6478299],
						[13.3971175, 52.6482379],
						[13.3974124, 52.6483449],
						[13.3976114, 52.6483679],
						[13.3978304, 52.6482729],
						[13.3979653, 52.6482189],
						[13.3982293, 52.6479709],
						[13.3983418, 52.6478942],
						[13.3988772, 52.647529],
						[13.3994881, 52.647109],
						[13.3999981, 52.6467811],
						[13.4014749, 52.6458392],
						[13.4026737, 52.6451633],
						[13.4034766, 52.6447633],
						[13.4045024, 52.6443204],
						[13.4060092, 52.6437784],
						[13.4063832, 52.6436145],
						[13.4066821, 52.6434165],
						[13.4069551, 52.6432215],
						[13.407356, 52.6428896],
						[13.407759, 52.6425656],
						[13.4079729, 52.6426956],
						[13.4087588, 52.6428246],
						[13.4100426, 52.6430306],
						[13.4122933, 52.6433896],
						[13.4127732, 52.6430536],
						[13.4133451, 52.6426496],
						[13.4135311, 52.6425847],
						[13.413902, 52.6425197],
						[13.4147709, 52.6424167],
						[13.4148879, 52.6422037],
						[13.4149329, 52.6419017],
						[13.4149009, 52.6416348],
						[13.4143985, 52.6410867],
						[13.4149619, 52.6406699],
						[13.4165637, 52.639377],
						[13.4171616, 52.6390791],
						[13.4201092, 52.6377173],
						[13.4216601, 52.6369586],
						[13.4244482, 52.6355448],
						[13.4266132, 52.6367554],
						[13.4270548, 52.6369546],
						[13.4274131, 52.6371374],
						[13.4280563, 52.6373958],
						[13.4285938, 52.6373639],
						[13.4305861, 52.6372962],
						[13.4327212, 52.6372057],
						[13.4333767, 52.6374499],
						[13.4343842, 52.6378965],
						[13.434245, 52.6385603],
						[13.4341728, 52.6390925],
						[13.4338595, 52.6400405],
						[13.4334797, 52.6414532],
						[13.4336428, 52.6430157],
						[13.4337994, 52.6438392],
						[13.433912, 52.6443436],
						[13.4355032, 52.644621],
						[13.4368926, 52.6448124],
						[13.4389375, 52.6451092],
						[13.4398473, 52.6452342],
						[13.440241, 52.6468642],
						[13.4407158, 52.6491082],
						[13.4408338, 52.6492222],
						[13.4413097, 52.6493171],
						[13.4415727, 52.6493171],
						[13.4416417, 52.6492222],
						[13.4416657, 52.6490122],
						[13.4420776, 52.6490352],
						[13.4423795, 52.6490542],
						[13.4425925, 52.6491082],
						[13.443293, 52.6493504],
						[13.4436546, 52.6494994],
						[13.4450643, 52.6494786],
						[13.4459033, 52.6496329],
						[13.4468421, 52.6498594],
						[13.4476789, 52.649985],
						[13.4485619, 52.6499564],
						[13.4506337, 52.6497637],
						[13.451993, 52.6497032],
						[13.4522734, 52.6495994],
						[13.451963, 52.6487717],
						[13.4530049, 52.6487033],
						[13.4565004, 52.6484324],
						[13.4595469, 52.6482194],
						[13.4603858, 52.6486004],
						[13.4608437, 52.6487184],
						[13.4618886, 52.6489934],
						[13.4619776, 52.6490664],
						[13.4625455, 52.6497943],
						[13.4629404, 52.6501412],
						[13.4634853, 52.6504282],
						[13.4641832, 52.6505992],
						[13.4648051, 52.6508052],
						[13.465252, 52.6510682],
						[13.4656219, 52.6512741],
						[13.4664068, 52.6515801],
						[13.4667178, 52.6516671],
						[13.4670147, 52.6517211],
						[13.4676226, 52.6517401],
						[13.4682145, 52.6517091],
						[13.4684895, 52.6517671],
						[13.4687305, 52.6517861],
						[13.4691014, 52.6517441],
						[13.4692844, 52.6517471],
						[13.4699093, 52.6519151],
						[13.4701272, 52.6520341],
						[13.4704142, 52.6523041],
						[13.47152, 52.653037],
						[13.4723549, 52.653491],
						[13.4732157, 52.6537809],
						[13.4735987, 52.6539529],
						[13.4737976, 52.6541129],
						[13.4739206, 52.6542839],
						[13.4739816, 52.6548298],
						[13.4741057, 52.6551597],
						[13.4741999, 52.6555255],
						[13.4742109, 52.6557549],
						[13.4740437, 52.6560107],
						[13.4737189, 52.656272],
						[13.4734728, 52.6565572],
						[13.4624211, 52.6575368],
						[13.4538312, 52.661943],
						[13.450794, 52.6627057],
						[13.4516228, 52.6631957],
						[13.4547117, 52.6655557],
						[13.4552685, 52.6657261],
						[13.4580795, 52.6678551],
						[13.4591855, 52.6687041],
						[13.4594825, 52.6689751],
						[13.4595854, 52.6690661],
						[13.465958, 52.6671452],
						[13.4705037, 52.6710614],
						[13.470734, 52.671039],
						[13.4754659, 52.6750011],
						[13.4770944, 52.6739001],
						[13.479568, 52.6754755],
						[13.4800747, 52.6748807],
						[13.4763065, 52.6711504],
						[13.4749591, 52.6684443],
						[13.47551, 52.6678804],
						[13.475972, 52.6672774],
						[13.475963, 52.6667245],
						[13.4779727, 52.6676774],
						[13.4845532, 52.6692935],
						[13.4850065, 52.6694023],
						[13.4860674, 52.6696843],
						[13.488128, 52.6703752],
						[13.4884, 52.6699093],
						[13.488384, 52.6698293],
						[13.4877731, 52.6690164],
						[13.4874612, 52.6681085],
						[13.4872143, 52.6667086],
						[13.4868738, 52.6653449],
						[13.4865734, 52.6638019],
						[13.4862805, 52.6624821],
						[13.4853506, 52.659472],
						[13.4895092, 52.6559208],
						[13.4897132, 52.6557839],
						[13.4900301, 52.6555739],
						[13.4898831, 52.6555169],
						[13.491257, 52.654727],
						[13.4922158, 52.6541741],
						[13.4922208, 52.6540291],
						[13.4939106, 52.6533272],
						[13.4940196, 52.6534072],
						[13.4963012, 52.6525143],
						[13.4961943, 52.6524343],
						[13.4975231, 52.6519154],
						[13.4983859, 52.6515874],
						[13.4998887, 52.6510115],
						[13.4999987, 52.6510115],
						[13.5002407, 52.6510075],
						[13.5004796, 52.6509505],
						[13.5008496, 52.6508055],
						[13.5015055, 52.6505576],
						[13.5024354, 52.6502216],
						[13.5027339, 52.6500615],
						[13.5029563, 52.6499436],
						[13.5034582, 52.6498017],
						[13.5043101, 52.6496187],
						[13.505315, 52.6492227],
						[13.5060718, 52.6489898],
						[13.5071627, 52.6483109],
						[13.5073697, 52.6482039],
						[13.5082445, 52.6477689],
						[13.5100353, 52.6468001],
						[13.5102749, 52.6466985],
						[13.5104393, 52.6466254],
						[13.5128244, 52.6454115],
						[13.5152545, 52.6460982],
						[13.5153845, 52.6461362],
						[13.5162474, 52.6463802],
						[13.5190219, 52.6469751],
						[13.5191129, 52.6469371],
						[13.5196089, 52.6460292],
						[13.5203428, 52.6446904],
						[13.5216026, 52.6447514],
						[13.5228704, 52.6448124],
						[13.5227454, 52.6441375],
						[13.5223172, 52.6413888],
						[13.5222806, 52.6412228],
						[13.5221798, 52.6407186],
						[13.5221356, 52.6405249],
						[13.5219806, 52.639777],
						[13.5216237, 52.638976],
						[13.5215767, 52.6388231],
						[13.5215307, 52.6386751],
						[13.5213468, 52.6380981],
						[13.520114, 52.6352184],
						[13.5182774, 52.6316558],
						[13.5176266, 52.62971],
						[13.5175376, 52.6295841],
						[13.5157019, 52.6290421],
						[13.5120494, 52.6280352],
						[13.5109466, 52.6276962],
						[13.5103427, 52.6275092],
						[13.5078531, 52.6265703],
						[13.5065303, 52.6260404],
						[13.5061504, 52.6258874],
						[13.5057475, 52.6256704],
						[13.5056595, 52.6255254],
						[13.5056325, 52.6245675],
						[13.5054656, 52.6222638],
						[13.5054406, 52.620543],
						[13.5054337, 52.619964],
						[13.5054445, 52.6199302],
						[13.5053937, 52.6197351],
						[13.5053027, 52.6196121],
						[13.5049917, 52.6195711],
						[13.5043978, 52.6193421],
						[13.503606, 52.6191321],
						[13.503305, 52.6190141],
						[13.5021002, 52.6171483],
						[13.5011241, 52.6151097],
						[13.500502, 52.6138753],
						[13.5002605, 52.613385],
						[13.4993323, 52.6120427],
						[13.4987704, 52.6109234],
						[13.4985662, 52.6106114],
						[13.4984895, 52.6104641],
						[13.4970763, 52.6066844],
						[13.4967323, 52.6051316],
						[13.4966894, 52.6049376],
						[13.4975362, 52.6052316],
						[13.498847, 52.6052846],
						[13.4999989, 52.6036408],
						[13.5017167, 52.6011841],
						[13.5025466, 52.5999972],
						[13.5051814, 52.5964434],
						[13.5050484, 52.5963705],
						[13.4889814, 52.5900195],
						[13.4883915, 52.5897282],
						[13.4864446, 52.5883837],
						[13.4857277, 52.5876131],
						[13.4855653, 52.587362],
						[13.4846596, 52.5856249],
						[13.4843443, 52.5856414],
						[13.4835889, 52.5840782],
						[13.4819913, 52.5840053],
						[13.4816147, 52.5833164],
						[13.4804764, 52.5835099],
						[13.4804483, 52.5834134],
						[13.4802622, 52.582875],
						[13.4801665, 52.5826263],
						[13.4800089, 52.5822473],
						[13.4798205, 52.5815854],
						[13.4797744, 52.5813564],
						[13.4797294, 52.5811627],
						[13.4797203, 52.5810569],
						[13.4797165, 52.5809259],
						[13.479722, 52.5807813],
						[13.4797715, 52.5805544],
						[13.4797613, 52.5803427],
						[13.479689, 52.580092],
						[13.4796981, 52.5800125],
						[13.4797363, 52.5796012],
						[13.4798182, 52.5794418],
						[13.4798717, 52.5793391],
						[13.4799446, 52.5791969],
						[13.480084, 52.5786048],
						[13.4800385, 52.5786013],
						[13.4801988, 52.5779239],
						[13.480254, 52.5779186],
						[13.4802701, 52.5778356],
						[13.4804027, 52.5778553],
						[13.4804158, 52.5777982],
						[13.4805132, 52.5777789],
						[13.4805154, 52.5777509],
						[13.4805163, 52.5777019],
						[13.4804531, 52.5777252],
						[13.4801461, 52.5777422],
						[13.4800573, 52.577719],
						[13.4799895, 52.5776948],
						[13.4799478, 52.5765082],
						[13.4799151, 52.5754144],
						[13.479818, 52.575425],
						[13.4797867, 52.5752818],
						[13.4797464, 52.5751491],
						[13.4800812, 52.5751378],
						[13.480077, 52.575021],
						[13.4798728, 52.5746808],
						[13.4797907, 52.5745119],
						[13.4797603, 52.5743964],
						[13.479747, 52.5743465],
						[13.4796253, 52.5737775],
						[13.4796679, 52.5737783],
						[13.4796199, 52.5735275],
						[13.4798883, 52.57351],
						[13.4798553, 52.5733239],
						[13.479575, 52.5733407],
						[13.4794593, 52.5726933],
						[13.479702, 52.5726604],
						[13.4799173, 52.5726294],
						[13.4802781, 52.5726066],
						[13.4809206, 52.572616],
						[13.4810542, 52.5726171],
						[13.4813323, 52.5726023],
						[13.4815434, 52.572583],
						[13.4817652, 52.5725395],
						[13.4820276, 52.5724936],
						[13.4821881, 52.572494],
						[13.4823636, 52.5725166],
						[13.4825711, 52.5725609],
						[13.4827397, 52.5726212],
						[13.4827956, 52.5726311],
						[13.4828305, 52.5723651],
						[13.4830354, 52.5715588],
						[13.482925, 52.5714758],
						[13.4875878, 52.5692187],
						[13.4835964, 52.5661175],
						[13.4839654, 52.5660738],
						[13.4841538, 52.5660255],
						[13.484108, 52.5659716],
						[13.4841009, 52.5658936],
						[13.4840998, 52.5657398],
						[13.4840966, 52.5657147],
						[13.4840994, 52.5656119],
						[13.4840912, 52.5655429],
						[13.4840837, 52.565511],
						[13.4840437, 52.5654002],
						[13.4840272, 52.5653639],
						[13.4840093, 52.5653397],
						[13.4839908, 52.5653218],
						[13.4839605, 52.5653026],
						[13.48369, 52.564432],
						[13.4860696, 52.5641525],
						[13.4855984, 52.5624191],
						[13.4853229, 52.5622738],
						[13.4851886, 52.5617991],
						[13.4853992, 52.5615513],
						[13.486328, 52.5614565],
						[13.4869826, 52.5613583],
						[13.4873081, 52.5612835],
						[13.4886764, 52.5608773],
						[13.4899991, 52.5604732],
						[13.4902917, 52.5603604],
						[13.4905637, 52.56024],
						[13.4908677, 52.5600773],
						[13.4910885, 52.5599389],
						[13.4913264, 52.5597618],
						[13.491504, 52.5596033],
						[13.4916121, 52.559505],
						[13.4924909, 52.5585708],
						[13.4935359, 52.5575009],
						[13.4950392, 52.5561224],
						[13.4955725, 52.5550486],
						[13.4957006, 52.5541699],
						[13.4956823, 52.553586],
						[13.4943469, 52.5507942],
						[13.4903042, 52.5517063],
						[13.4862249, 52.5524014],
						[13.485859, 52.5521623],
						[13.4852897, 52.55231],
						[13.4821821, 52.5495929],
						[13.4817248, 52.5494928],
						[13.4801242, 52.5494483],
						[13.4797858, 52.5491312],
						[13.4804809, 52.5481134],
						[13.4796577, 52.5475127],
						[13.4767591, 52.547568],
						[13.4751683, 52.5475984],
						[13.4747835, 52.5476057],
						[13.4695601, 52.5477185],
						[13.4684259, 52.5482024],
						[13.4674381, 52.5482858],
						[13.4670814, 52.5480522],
						[13.4699168, 52.5404095],
						[13.4673832, 52.5385292],
						[13.4676484, 52.5384068],
						[13.4664503, 52.5366655],
						[13.4669076, 52.5364486],
						[13.4678405, 52.5361648],
						[13.4688009, 52.5346738],
						[13.4673192, 52.5340117],
						[13.4671723, 52.5341196],
						[13.4645248, 52.5332005],
						[13.4645407, 52.5331495],
						[13.4644929, 52.533123],
						[13.4644572, 52.5330903],
						[13.4644317, 52.5330519],
						[13.4644313, 52.5330338],
						[13.4644329, 52.5330078],
						[13.4644439, 52.5329887],
						[13.4633883, 52.5326331],
						[13.4633585, 52.5325924],
						[13.4632475, 52.5323974],
						[13.4630569, 52.5321665],
						[13.4625476, 52.5315493],
						[13.4619275, 52.5307969],
						[13.4611417, 52.5298424],
						[13.4610053, 52.5296773],
						[13.4606738, 52.5293566],
						[13.4592035, 52.5290289],
						[13.4580032, 52.5287609],
						[13.4574441, 52.5286351],
						[13.456338, 52.5283984],
						[13.4561898, 52.5282609],
						[13.4570679, 52.5274154],
						[13.4579016, 52.5265932],
						[13.458773, 52.5259261],
						[13.4600782, 52.5253943],
						[13.4601158, 52.5253615],
						[13.460756, 52.5251735],
						[13.4611837, 52.52504],
						[13.4627094, 52.5245135],
						[13.464924, 52.524256],
						[13.4656706, 52.5241482],
						[13.4663588, 52.5240349],
						[13.4668811, 52.5239048],
						[13.4674599, 52.523738],
						[13.4683253, 52.5234339],
						[13.4689462, 52.5231848],
						[13.4696124, 52.5228511],
						[13.4698619, 52.522716],
						[13.4704533, 52.5223044],
						[13.4707951, 52.5220413],
						[13.4714962, 52.5213402],
						[13.4721166, 52.5205903],
						[13.4720305, 52.5205838],
						[13.471182, 52.5205195],
						[13.4708106, 52.5204914],
						[13.4695427, 52.520389],
						[13.4680286, 52.5202628],
						[13.4670292, 52.5201796],
						[13.4669131, 52.5201686],
						[13.4655333, 52.5200577],
						[13.46384, 52.5199217],
						[13.4636778, 52.5199086],
						[13.4627309, 52.5198386],
						[13.4624949, 52.519861],
						[13.4624349, 52.5198705],
						[13.4623151, 52.5198918],
						[13.4617642, 52.5199904],
						[13.4612384, 52.5200842],
						[13.4610613, 52.5201185],
						[13.4605709, 52.520208],
						[13.4604408, 52.5202327],
						[13.4600519, 52.5203021],
						[13.4586005, 52.520567],
						[13.4570865, 52.5208434],
						[13.4568867, 52.5208797],
						[13.4556863, 52.5210971],
						[13.455054, 52.5212186],
						[13.4556734, 52.5220484],
						[13.4558301, 52.5222712],
						[13.4557182, 52.5224493],
						[13.4550135, 52.5234902],
						[13.4545287, 52.524223],
						[13.4544606, 52.5243289],
						[13.4536811, 52.5254706],
						[13.4531472, 52.52625],
						[13.4526439, 52.5269846],
						[13.4523878, 52.5273584],
						[13.4523301, 52.5274427],
						[13.4524264, 52.5275228],
						[13.4522881, 52.5276665],
						[13.4521808, 52.5277942],
						[13.4507781, 52.5274853],
						[13.4505688, 52.5274219],
						[13.4493913, 52.5270705],
						[13.4477359, 52.5265775],
						[13.4473853, 52.5264737],
						[13.447357, 52.526492],
						[13.4473144, 52.5265314],
						[13.4472042, 52.5266336],
						[13.446842, 52.5269579],
						[13.4461507, 52.5275769],
						[13.4459817, 52.5277359],
						[13.4454153, 52.52827],
						[13.4442814, 52.5293284],
						[13.4441439, 52.5294451],
						[13.4438714, 52.5297357],
						[13.4422716, 52.5310293],
						[13.4387777, 52.5287923],
						[13.4382923, 52.5287936],
						[13.4374809, 52.5295472],
						[13.437232, 52.5295187],
						[13.4366795, 52.5294538],
						[13.435773, 52.5293486],
						[13.4341523, 52.5291544],
						[13.4329347, 52.5290145],
						[13.4310942, 52.5288027],
						[13.4304785, 52.5287302],
						[13.4286471, 52.5285172],
						[13.4258277, 52.5281884],
						[13.4257116, 52.5281744],
						[13.4253239, 52.5281363],
						[13.4248525, 52.5278771],
						[13.4246655, 52.5279085],
						[13.4240058, 52.5278814],
						[13.4238095, 52.5279009],
						[13.4236419, 52.5279139],
						[13.4231452, 52.5276105],
						[13.4214272, 52.5265644],
						[13.4211262, 52.5263827],
						[13.4197534, 52.5255461],
						[13.41821, 52.5261978],
						[13.4175618, 52.5264858],
						[13.4168897, 52.5267836],
						[13.4169053, 52.526796],
						[13.4167082, 52.5268863],
						[13.4166868, 52.5268757],
						[13.4161457, 52.5270836],
						[13.4159556, 52.5271846],
						[13.4155634, 52.5273999],
						[13.4123258, 52.5283026],
						[13.4120826, 52.5283652],
						[13.4118819, 52.5284263],
						[13.4114239, 52.5285597],
						[13.4100859, 52.5287966],
						[13.4094567, 52.5289251],
						[13.4082053, 52.5292086],
						[13.4073923, 52.5293459],
						[13.4055381, 52.5296496],
						[13.4058745, 52.5303766],
						[13.4060629, 52.5305863],
						[13.4059576, 52.530765],
						[13.4060436, 52.5310551],
						[13.4064339, 52.5323628],
						[13.4083548, 52.5343478],
						[13.4078028, 52.5345646],
						[13.4074838, 52.5346907],
						[13.4069768, 52.5356028],
						[13.4069181, 52.5357102],
						[13.4065804, 52.536422],
						[13.4058863, 52.5377837],
						[13.4055366, 52.5384811],
						[13.4053577, 52.5388355],
						[13.4047002, 52.540185],
						[13.4045527, 52.5402595],
						[13.4042237, 52.5404067],
						[13.4041547, 52.5403892],
						[13.4035266, 52.5402138],
						[13.4027319, 52.5418258],
						[13.4024741, 52.5423351],
						[13.4017794, 52.5437064],
						[13.4003573, 52.5464168],
						[13.400306, 52.5466498],
						[13.400276, 52.546727],
						[13.3992861, 52.5489438],
						[13.399236, 52.5490548],
						[13.3988952, 52.5508871],
						[13.3968515, 52.5507042],
						[13.3971892, 52.55097],
						[13.3973473, 52.5511137],
						[13.3976262, 52.5513245],
						[13.3977476, 52.5514806],
						[13.3978531, 52.5516546],
						[13.397982, 52.551992],
						[13.3980782, 52.5523533],
						[13.3981075, 52.5527765],
						[13.3980919, 52.5529598],
						[13.3976194, 52.5546096],
						[13.3973465, 52.5556524],
						[13.3971822, 52.5563229],
						[13.3971615, 52.5572462],
						[13.3971566, 52.5573954],
						[13.3971512, 52.5578869],
						[13.3971833, 52.5578919],
						[13.3971127, 52.558269],
						[13.3970341, 52.5583962],
						[13.3970113, 52.5584433],
						[13.3969616, 52.5588124],
						[13.3969751, 52.5591524],
						[13.3968464, 52.5595026],
						[13.3967565, 52.5597583],
						[13.3966467, 52.5600229],
						[13.396628, 52.5601187],
						[13.3965399, 52.5604382],
						[13.3964081, 52.5608611],
						[13.3963284, 52.5610733],
						[13.3962198, 52.561294],
						[13.3959136, 52.5618209],
						[13.395228, 52.5625774],
						[13.3952131, 52.5626122],
						[13.3944908, 52.563394],
						[13.394238, 52.563636],
						[13.3937289, 52.5640856],
						[13.3936636, 52.564134],
						[13.3935294, 52.5642328],
						[13.3933802, 52.5643426],
						[13.3933271, 52.5643782],
						[13.3933788, 52.5644019],
						[13.393182, 52.5645538],
						[13.392865, 52.5647592],
						[13.3913431, 52.5656227],
						[13.3910741, 52.5656462],
						[13.3910366, 52.565625],
						[13.3907257, 52.5657953],
						[13.3907522, 52.5658226],
						[13.3906385, 52.5660227],
						[13.3889262, 52.5670012],
						[13.3889572, 52.5670678],
						[13.388793, 52.5671568],
						[13.3890522, 52.5673244],
						[13.3890625, 52.5673788],
						[13.3890222, 52.5674165],
						[13.3886553, 52.5676591],
						[13.3885147, 52.5676044],
						[13.388399, 52.5676684],
						[13.3883789, 52.5676788],
						[13.3884506, 52.5677509],
						[13.3890454, 52.5681287],
						[13.3892788, 52.5682415],
						[13.3877861, 52.5691433],
						[13.3881074, 52.5693447],
						[13.3843106, 52.5714935],
						[13.3830825, 52.5723328],
						[13.3825506, 52.5720761],
						[13.3822376, 52.5720278],
						[13.3796779, 52.5725342],
						[13.3796274, 52.5726065],
						[13.3796177, 52.5727329],
						[13.3795172, 52.5727223],
						[13.3793561, 52.5728234],
						[13.3792632, 52.5730849],
						[13.379038, 52.5730577],
						[13.3789133, 52.5734602],
						[13.3785888, 52.5734173],
						[13.3784882, 52.5737264],
						[13.3782578, 52.5736872],
						[13.3781427, 52.5736142],
						[13.3764547, 52.5747432],
						[13.3765231, 52.5747999],
						[13.3764357, 52.5747908],
						[13.3762047, 52.5749734],
						[13.375841, 52.57527],
						[13.3751867, 52.5756782],
						[13.3747562, 52.5758869],
						[13.3743137, 52.5760834],
						[13.3734207, 52.5764417],
						[13.3723052, 52.576934],
						[13.3722478, 52.5768789],
						[13.3715251, 52.5771901],
						[13.3711342, 52.5773712],
						[13.3681962, 52.5789752],
						[13.366282, 52.5800195],
						[13.3640223, 52.5814686],
						[13.3639115, 52.5812777],
						[13.3637138, 52.5813772],
						[13.3621936, 52.5821425],
						[13.3591994, 52.5837723],
						[13.3592543, 52.5838545],
						[13.3588466, 52.5841711],
						[13.3578126, 52.5843938],
						[13.3521093, 52.5875377],
						[13.3522325, 52.5875892],
						[13.351871, 52.587803],
						[13.3513899, 52.5880214],
						[13.3497575, 52.588926],
						[13.3499269, 52.5892514],
						[13.3491344, 52.5891983],
						[13.3475592, 52.5900844],
					],
				],
			},
			properties: {},
		},
	},
	{
		name: "Reinickendorf",
		coords: [13.333332, 52.5666644],
		gen: false,
		polygon: {
			type: "Feature",
			geometry: {
				type: "Polygon",
				coordinates: [
					[
						[13.2012543, 52.6063298],
						[13.2013183, 52.6067308],
						[13.2014623, 52.6070018],
						[13.2018012, 52.6072147],
						[13.2020822, 52.6073977],
						[13.2025471, 52.6075807],
						[13.20296, 52.6076917],
						[13.203276, 52.6077947],
						[13.2050017, 52.6082447],
						[13.2057276, 52.6084966],
						[13.2062255, 52.6087906],
						[13.2066244, 52.6091526],
						[13.2069384, 52.6094695],
						[13.2076202, 52.6101565],
						[13.2084201, 52.6111934],
						[13.209185, 52.6121853],
						[13.2093159, 52.6123572],
						[13.2120824, 52.6156909],
						[13.2135992, 52.6176137],
						[13.2147639, 52.6190895],
						[13.2152049, 52.6193035],
						[13.2167126, 52.6201624],
						[13.2178754, 52.6218862],
						[13.2181384, 52.6222332],
						[13.219904, 52.624591],
						[13.22015, 52.6252199],
						[13.220241, 52.6257998],
						[13.2203559, 52.6269447],
						[13.2205319, 52.6273716],
						[13.2205938, 52.6278186],
						[13.2205698, 52.6282415],
						[13.2214377, 52.6282036],
						[13.2226715, 52.6281496],
						[13.2229925, 52.6281386],
						[13.2242653, 52.6283596],
						[13.2246362, 52.6282716],
						[13.2251741, 52.6281956],
						[13.2251531, 52.6282986],
						[13.2255641, 52.6282106],
						[13.2256451, 52.6282036],
						[13.225823, 52.6281916],
						[13.226024, 52.6281916],
						[13.226087, 52.6277567],
						[13.22644, 52.6277267],
						[13.2274218, 52.6275627],
						[13.2280957, 52.6275017],
						[13.2287746, 52.6274407],
						[13.2299024, 52.6273907],
						[13.2305683, 52.6273607],
						[13.2322691, 52.6274287],
						[13.232579, 52.6275467],
						[13.2353846, 52.6278027],
						[13.2400289, 52.6279058],
						[13.2409337, 52.6280467],
						[13.2410877, 52.6280817],
						[13.2411977, 52.6281077],
						[13.2413447, 52.6281427],
						[13.2415596, 52.6281957],
						[13.2416826, 52.6282227],
						[13.2419236, 52.6282607],
						[13.2421446, 52.6282607],
						[13.2425785, 52.6283287],
						[13.2431214, 52.6282607],
						[13.2436193, 52.6281498],
						[13.245961, 52.6273339],
						[13.2462729, 52.6273069],
						[13.2469888, 52.6272839],
						[13.2474708, 52.6272689],
						[13.2476377, 52.6274249],
						[13.2479617, 52.6273599],
						[13.2491375, 52.6275469],
						[13.2494025, 52.6275549],
						[13.2499344, 52.6274859],
						[13.2504533, 52.6274179],
						[13.2521031, 52.6273409],
						[13.252667, 52.6273569],
						[13.2539338, 52.6275129],
						[13.2545227, 52.6275239],
						[13.2563794, 52.6275629],
						[13.2567253, 52.6276009],
						[13.2571963, 52.6277419],
						[13.2579492, 52.6277419],
						[13.2596979, 52.6277339],
						[13.2602388, 52.6277989],
						[13.2614526, 52.627635],
						[13.2626884, 52.627318],
						[13.2635683, 52.627231],
						[13.2642342, 52.6268645],
						[13.2644036, 52.6281741],
						[13.2644349, 52.6284221],
						[13.2645581, 52.6293168],
						[13.2645839, 52.6300686],
						[13.264821, 52.6314766],
						[13.264894, 52.6321325],
						[13.264907, 52.6325405],
						[13.264981, 52.6334104],
						[13.2650869, 52.6347152],
						[13.2648849, 52.6352911],
						[13.264438, 52.636344],
						[13.264348, 52.636493],
						[13.263959, 52.6371449],
						[13.2636351, 52.6375079],
						[13.2628732, 52.6383698],
						[13.2625832, 52.6387627],
						[13.262464, 52.6389243],
						[13.2623362, 52.6393457],
						[13.2622732, 52.6397806],
						[13.2622121, 52.6401185],
						[13.2621412, 52.6407195],
						[13.2623222, 52.6406925],
						[13.2652497, 52.6402806],
						[13.2694211, 52.6397384],
						[13.2697818, 52.6397578],
						[13.2716395, 52.6398587],
						[13.2742945, 52.640316],
						[13.2763331, 52.6402307],
						[13.276921, 52.6402617],
						[13.2814863, 52.6409177],
						[13.2827851, 52.6410247],
						[13.283473, 52.6410857],
						[13.2837719, 52.6411127],
						[13.2843173, 52.6462671],
						[13.2845464, 52.6479379],
						[13.2841297, 52.6484938],
						[13.2844251, 52.6483525],
						[13.2845354, 52.6484131],
						[13.2848765, 52.6503786],
						[13.2850634, 52.6521554],
						[13.2851024, 52.6525334],
						[13.2819039, 52.6526673],
						[13.2821348, 52.6547121],
						[13.2821518, 52.6548641],
						[13.282362, 52.6569453],
						[13.2827516, 52.6607614],
						[13.2831109, 52.6606247],
						[13.2838164, 52.6605445],
						[13.2846083, 52.6604685],
						[13.2853512, 52.6603955],
						[13.2859641, 52.6602735],
						[13.286595, 52.6601175],
						[13.286927, 52.6600366],
						[13.2872029, 52.6599876],
						[13.2875489, 52.6599606],
						[13.2877339, 52.6599536],
						[13.2878378, 52.6599496],
						[13.2880458, 52.6599566],
						[13.2881848, 52.6599646],
						[13.2885116, 52.659981],
						[13.2886593, 52.6599705],
						[13.2901591, 52.6598663],
						[13.293071, 52.6594957],
						[13.293517, 52.6593847],
						[13.293608, 52.6593617],
						[13.2940813, 52.6593541],
						[13.2942969, 52.6593507],
						[13.2946508, 52.6593427],
						[13.2947238, 52.6593427],
						[13.2951277, 52.6593507],
						[13.2952327, 52.6593547],
						[13.2961426, 52.6593657],
						[13.2970963, 52.6593635],
						[13.2979023, 52.6593617],
						[13.299793, 52.6593617],
						[13.299999, 52.6593538],
						[13.3006599, 52.6593348],
						[13.3015838, 52.6592318],
						[13.3021837, 52.6592358],
						[13.3028426, 52.6592778],
						[13.3029705, 52.6592928],
						[13.3043393, 52.6594648],
						[13.3070859, 52.6596518],
						[13.3072619, 52.6594418],
						[13.3075998, 52.6590609],
						[13.3078438, 52.6588389],
						[13.3089347, 52.658217],
						[13.3087857, 52.657985],
						[13.3097236, 52.657512],
						[13.3099545, 52.657554],
						[13.3100874, 52.6574615],
						[13.3101774, 52.6573705],
						[13.3051158, 52.6553],
						[13.300561, 52.6534367],
						[13.3059773, 52.6471395],
						[13.3068172, 52.6457576],
						[13.3080396, 52.6443226],
						[13.3084481, 52.6438436],
						[13.309196, 52.6439806],
						[13.309222, 52.6438586],
						[13.3094336, 52.6427837],
						[13.3090318, 52.6426728],
						[13.3091817, 52.6419866],
						[13.3088704, 52.641945],
						[13.3089391, 52.6414548],
						[13.3081578, 52.6407716],
						[13.3082341, 52.6402406],
						[13.3079525, 52.6401031],
						[13.3072682, 52.6401112],
						[13.3073081, 52.6396885],
						[13.306273, 52.6396082],
						[13.3068123, 52.6386927],
						[13.3069289, 52.6381155],
						[13.307069, 52.6377786],
						[13.3070615, 52.6375659],
						[13.3057838, 52.637355],
						[13.3062077, 52.6364647],
						[13.3066318, 52.6359681],
						[13.3076638, 52.6347596],
						[13.3079427, 52.6344337],
						[13.3083772, 52.633871],
						[13.3085789, 52.6335908],
						[13.3086812, 52.6334702],
						[13.3089775, 52.6332056],
						[13.3093514, 52.6327819],
						[13.3097213, 52.6321581],
						[13.3097806, 52.6319979],
						[13.3098198, 52.6315965],
						[13.3100449, 52.631136],
						[13.3101941, 52.6306215],
						[13.309776, 52.6306116],
						[13.3096898, 52.6300977],
						[13.310282, 52.6300081],
						[13.3099991, 52.6296739],
						[13.3096897, 52.6294757],
						[13.3088392, 52.6291503],
						[13.308484, 52.6290787],
						[13.3077806, 52.6289497],
						[13.3064455, 52.6287417],
						[13.3058654, 52.6285722],
						[13.305132, 52.6282183],
						[13.3036742, 52.6279437],
						[13.3032371, 52.6278199],
						[13.3028371, 52.6276738],
						[13.3025414, 52.6275524],
						[13.3026242, 52.6271941],
						[13.3054465, 52.6276048],
						[13.3072694, 52.627766],
						[13.3073907, 52.6277767],
						[13.3085257, 52.6279537],
						[13.3091481, 52.6279738],
						[13.3103715, 52.6280607],
						[13.3111239, 52.6281137],
						[13.3113545, 52.6281355],
						[13.3119962, 52.6281844],
						[13.3123518, 52.6282046],
						[13.3130203, 52.628189],
						[13.3138348, 52.6280066],
						[13.3151658, 52.6276002],
						[13.3163745, 52.6273487],
						[13.3173636, 52.6271921],
						[13.3180486, 52.627023],
						[13.3188858, 52.6267846],
						[13.3195719, 52.6266549],
						[13.3235972, 52.6264342],
						[13.3239994, 52.6263709],
						[13.3250935, 52.6260787],
						[13.325555, 52.6259385],
						[13.3261969, 52.625821],
						[13.3266131, 52.6257619],
						[13.3270345, 52.6257155],
						[13.3273813, 52.6256858],
						[13.3286827, 52.6254464],
						[13.3294264, 52.6252489],
						[13.3316253, 52.6243805],
						[13.332555, 52.6240185],
						[13.3328528, 52.6242063],
						[13.3367296, 52.6226241],
						[13.3367401, 52.6226655],
						[13.3369873, 52.6228164],
						[13.3374621, 52.6229756],
						[13.3376671, 52.6230502],
						[13.3379154, 52.623086],
						[13.338246, 52.6230912],
						[13.338335, 52.6231682],
						[13.338422, 52.6234271],
						[13.338586, 52.6234731],
						[13.3388259, 52.6234651],
						[13.3391799, 52.6232972],
						[13.3400808, 52.6233622],
						[13.3406177, 52.6232632],
						[13.3414795, 52.6234162],
						[13.3417155, 52.6234202],
						[13.3421954, 52.6233662],
						[13.3428203, 52.6235192],
						[13.3431143, 52.6235422],
						[13.3434082, 52.6235222],
						[13.3436017, 52.6234755],
						[13.3438045, 52.6235898],
						[13.3440271, 52.6237479],
						[13.3467587, 52.6236431],
						[13.351226, 52.6239042],
						[13.351505, 52.6229883],
						[13.351797, 52.6231033],
						[13.351944, 52.6231263],
						[13.3521649, 52.6231183],
						[13.3524709, 52.6230233],
						[13.3525039, 52.6229503],
						[13.3525969, 52.6227403],
						[13.3526888, 52.6227063],
						[13.3527808, 52.6227403],
						[13.3527978, 52.6227843],
						[13.3528678, 52.6229653],
						[13.3529218, 52.6230113],
						[13.3531778, 52.6231143],
						[13.3536167, 52.6231943],
						[13.3546105, 52.6232253],
						[13.3547015, 52.6232823],
						[13.3547705, 52.6234193],
						[13.3550295, 52.6234773],
						[13.3551605, 52.6235383],
						[13.3552304, 52.6235683],
						[13.3553564, 52.6236453],
						[13.3554664, 52.6236373],
						[13.3558753, 52.6235033],
						[13.3561003, 52.6233473],
						[13.3563213, 52.6233283],
						[13.3564123, 52.6233623],
						[13.3565602, 52.6233433],
						[13.3568012, 52.6232553],
						[13.3570962, 52.6232443],
						[13.3576881, 52.6231493],
						[13.358034, 52.6233093],
						[13.358254, 52.6233773],
						[13.3590789, 52.6235193],
						[13.3595268, 52.6240033],
						[13.3597457, 52.6241182],
						[13.3599837, 52.6241632],
						[13.3603366, 52.6241332],
						[13.3606486, 52.6241332],
						[13.3609206, 52.6242132],
						[13.3611595, 52.6243852],
						[13.3613575, 52.6244192],
						[13.3616724, 52.6243772],
						[13.3618014, 52.6244232],
						[13.3619974, 52.6246822],
						[13.3622333, 52.6248732],
						[13.3623973, 52.6249302],
						[13.3627713, 52.6249912],
						[13.3633352, 52.6249842],
						[13.3635581, 52.6249112],
						[13.3636091, 52.6248422],
						[13.3637171, 52.6246902],
						[13.3637671, 52.6246172],
						[13.3641161, 52.6245982],
						[13.364723, 52.6247012],
						[13.3649589, 52.6247812],
						[13.3653249, 52.6249612],
						[13.3649729, 52.6251172],
						[13.3648559, 52.6251702],
						[13.36464, 52.6252702],
						[13.364352, 52.6253991],
						[13.3647389, 52.6254261],
						[13.3650689, 52.6255751],
						[13.3654268, 52.6253732],
						[13.3655458, 52.6253042],
						[13.3659288, 52.6253192],
						[13.3660218, 52.6254262],
						[13.3662127, 52.6256511],
						[13.3664947, 52.6255331],
						[13.3668907, 52.6254447],
						[13.3689497, 52.6231419],
						[13.3706234, 52.621188],
						[13.3734987, 52.6169567],
						[13.3750866, 52.6146794],
						[13.3759964, 52.6129544],
						[13.3763311, 52.611146],
						[13.3762453, 52.6076539],
						[13.3757864, 52.6070102],
						[13.375409, 52.6065294],
						[13.3708598, 52.5996597],
						[13.3689436, 52.5981315],
						[13.368161, 52.5974905],
						[13.36177, 52.5921525],
						[13.3607251, 52.591286],
						[13.3589569, 52.5910554],
						[13.3573416, 52.5909581],
						[13.353672, 52.5909099],
						[13.3511811, 52.5907109],
						[13.3475592, 52.5900844],
						[13.3491344, 52.5891983],
						[13.3499269, 52.5892514],
						[13.3497575, 52.588926],
						[13.3513899, 52.5880214],
						[13.351871, 52.587803],
						[13.3522325, 52.5875892],
						[13.3521093, 52.5875377],
						[13.3578126, 52.5843938],
						[13.3588466, 52.5841711],
						[13.3592543, 52.5838545],
						[13.3591994, 52.5837723],
						[13.3621936, 52.5821425],
						[13.3637138, 52.5813772],
						[13.3639115, 52.5812777],
						[13.3640223, 52.5814686],
						[13.366282, 52.5800195],
						[13.3681962, 52.5789752],
						[13.3711342, 52.5773712],
						[13.3715251, 52.5771901],
						[13.3722478, 52.5768789],
						[13.3723052, 52.576934],
						[13.3734207, 52.5764417],
						[13.3743137, 52.5760834],
						[13.3747562, 52.5758869],
						[13.3751867, 52.5756782],
						[13.375841, 52.57527],
						[13.3762047, 52.5749734],
						[13.3764357, 52.5747908],
						[13.3765231, 52.5747999],
						[13.3764547, 52.5747432],
						[13.3781427, 52.5736142],
						[13.3782578, 52.5736872],
						[13.3784882, 52.5737264],
						[13.3785888, 52.5734173],
						[13.3789133, 52.5734602],
						[13.379038, 52.5730577],
						[13.3792632, 52.5730849],
						[13.3793561, 52.5728234],
						[13.3795172, 52.5727223],
						[13.3796177, 52.5727329],
						[13.3796274, 52.5726065],
						[13.3796779, 52.5725342],
						[13.3822376, 52.5720278],
						[13.3825506, 52.5720761],
						[13.3830825, 52.5723328],
						[13.3843106, 52.5714935],
						[13.3881074, 52.5693447],
						[13.3877861, 52.5691433],
						[13.3892788, 52.5682415],
						[13.3890454, 52.5681287],
						[13.3884506, 52.5677509],
						[13.3883789, 52.5676788],
						[13.388399, 52.5676684],
						[13.3880026, 52.5673912],
						[13.3879037, 52.5673213],
						[13.3877633, 52.5672226],
						[13.3876691, 52.5672394],
						[13.3875832, 52.5672505],
						[13.387359, 52.5672792],
						[13.3871373, 52.5673076],
						[13.3859371, 52.5674612],
						[13.3854088, 52.5675288],
						[13.3849228, 52.5675621],
						[13.3844451, 52.5675428],
						[13.3841422, 52.5674962],
						[13.3839481, 52.5674465],
						[13.3826882, 52.5669338],
						[13.3814864, 52.5664447],
						[13.3785175, 52.566113],
						[13.3771519, 52.560662],
						[13.3711585, 52.5606041],
						[13.3671403, 52.5584785],
						[13.3666588, 52.5582214],
						[13.3651054, 52.5581157],
						[13.3650504, 52.5584304],
						[13.3645389, 52.5611792],
						[13.3601163, 52.560471],
						[13.3598146, 52.5617984],
						[13.3597194, 52.5618572],
						[13.3568789, 52.5620214],
						[13.3491235, 52.5619286],
						[13.3488264, 52.5619806],
						[13.3487438, 52.5619934],
						[13.348711, 52.5620707],
						[13.3468718, 52.5624485],
						[13.3371139, 52.5644452],
						[13.3366609, 52.5642092],
						[13.3363297, 52.5640219],
						[13.3323272, 52.5619021],
						[13.3317982, 52.5616579],
						[13.330928, 52.562151],
						[13.3268009, 52.562382],
						[13.3267595, 52.5622461],
						[13.3265657, 52.5618666],
						[13.3263487, 52.5615832],
						[13.3261287, 52.5613846],
						[13.3258453, 52.5611796],
						[13.3254554, 52.560974],
						[13.3253012, 52.5609133],
						[13.3251049, 52.5608409],
						[13.3246204, 52.5607095],
						[13.3241139, 52.5606287],
						[13.3239149, 52.5605703],
						[13.3236469, 52.5605517],
						[13.3213191, 52.5602541],
						[13.3187867, 52.5599216],
						[13.3175644, 52.559693],
						[13.3148406, 52.5590527],
						[13.3121467, 52.5583901],
						[13.3097219, 52.5577259],
						[13.3042867, 52.5519975],
						[13.3036245, 52.551257],
						[13.3028842, 52.5504488],
						[13.3015376, 52.5488072],
						[13.2962128, 52.5488957],
						[13.2959723, 52.5488957],
						[13.2952683, 52.5488919],
						[13.2911608, 52.5489628],
						[13.282606, 52.5491305],
						[13.2721064, 52.549305],
						[13.2719974, 52.5493829],
						[13.2705931, 52.5494336],
						[13.270606, 52.549344],
						[13.2703409, 52.5493405],
						[13.2627741, 52.5494596],
						[13.2583654, 52.5495769],
						[13.2569526, 52.5496763],
						[13.2556183, 52.5498685],
						[13.2542679, 52.5501356],
						[13.2530129, 52.5504431],
						[13.2517857, 52.550872],
						[13.2506018, 52.5513404],
						[13.2493554, 52.5519277],
						[13.2455101, 52.5538427],
						[13.2413352, 52.5559191],
						[13.2411115, 52.5559488],
						[13.2397813, 52.556535],
						[13.238477, 52.5571787],
						[13.232346, 52.5602375],
						[13.2311755, 52.5607955],
						[13.2308834, 52.561067],
						[13.2306973, 52.5613541],
						[13.2307061, 52.5614353],
						[13.2299186, 52.5620671],
						[13.2280953, 52.5633057],
						[13.2278669, 52.5636312],
						[13.2278503, 52.5638594],
						[13.2278753, 52.5639858],
						[13.2279171, 52.5640892],
						[13.2283193, 52.5647904],
						[13.2284962, 52.5650987],
						[13.2286092, 52.5653401],
						[13.2286523, 52.5655451],
						[13.2286641, 52.5657659],
						[13.2286261, 52.5660364],
						[13.2285258, 52.5663111],
						[13.2284192, 52.566483],
						[13.2282544, 52.5666762],
						[13.2281141, 52.5668117],
						[13.2273827, 52.5673549],
						[13.2270846, 52.5679772],
						[13.226916, 52.5688989],
						[13.2261949, 52.5701711],
						[13.2255697, 52.5710554],
						[13.2251772, 52.5724901],
						[13.2245856, 52.5729771],
						[13.2233903, 52.5739381],
						[13.2220336, 52.5747827],
						[13.2206768, 52.575929],
						[13.2194882, 52.5766397],
						[13.2183895, 52.5776609],
						[13.218188, 52.5786157],
						[13.2180663, 52.5791926],
						[13.2176785, 52.5807635],
						[13.21742, 52.5826878],
						[13.2166444, 52.5839837],
						[13.2171889, 52.5872519],
						[13.2173123, 52.5875391],
						[13.2180572, 52.5889919],
						[13.218873, 52.5907127],
						[13.2192115, 52.5914059],
						[13.2188334, 52.5924067],
						[13.2181148, 52.593187],
						[13.2153168, 52.594958],
						[13.2141245, 52.5955098],
						[13.2137426, 52.5956684],
						[13.211336, 52.596785],
						[13.2105881, 52.5971899],
						[13.2092073, 52.5981168],
						[13.2073525, 52.5993607],
						[13.2067276, 52.5999936],
						[13.2053018, 52.6014394],
						[13.2032571, 52.6032742],
						[13.2024292, 52.6038351],
						[13.2019053, 52.6042811],
						[13.2014263, 52.605041],
						[13.2013493, 52.605285],
						[13.2012623, 52.6058799],
						[13.2012543, 52.6063298],
					],
				],
			},
			properties: {},
		},
	},
	{
		name: "Spandau",
		coords: [13.201999192, 52.53499786],
		gen: false,
		polygon: {
			type: "Feature",
			geometry: {
				type: "Polygon",
				coordinates: [
					[
						[13.1092963, 52.4506355],
						[13.1093133, 52.4506984],
						[13.109561, 52.4510791],
						[13.1099117, 52.4515485],
						[13.1103586, 52.4520075],
						[13.1103887, 52.4520384],
						[13.1110808, 52.4527883],
						[13.1113561, 52.4531641],
						[13.1116587, 52.4536401],
						[13.1118245, 52.4539849],
						[13.1118614, 52.4540617],
						[13.1120131, 52.4543773],
						[13.1120297, 52.4544118],
						[13.1120572, 52.4546227],
						[13.1121754, 52.4555289],
						[13.1121989, 52.4556214],
						[13.1122922, 52.4559733],
						[13.1123016, 52.4560299],
						[13.1123421, 52.4562166],
						[13.1124127, 52.456444],
						[13.1124179, 52.4564607],
						[13.1124317, 52.4565052],
						[13.1124456, 52.4565818],
						[13.1124496, 52.4566008],
						[13.1124792, 52.4567413],
						[13.11251, 52.4568792],
						[13.1125179, 52.4569203],
						[13.1125518, 52.4570785],
						[13.1125601, 52.4571204],
						[13.1125619, 52.4571294],
						[13.1125983, 52.4573122],
						[13.1126149, 52.457396],
						[13.1126167, 52.4574051],
						[13.1126411, 52.4575277],
						[13.1126549, 52.4575971],
						[13.1126742, 52.4576943],
						[13.1126508, 52.4578471],
						[13.1126275, 52.4579985],
						[13.112604, 52.4581539],
						[13.1125998, 52.4581806],
						[13.1125569, 52.4585202],
						[13.1125265, 52.4586699],
						[13.1124959, 52.4587718],
						[13.1124669, 52.4588675],
						[13.1124665, 52.4588687],
						[13.1124572, 52.4588997],
						[13.1124095, 52.4589949],
						[13.1119678, 52.459227],
						[13.1105027, 52.459997],
						[13.1113408, 52.4618635],
						[13.1114287, 52.4637277],
						[13.1110075, 52.4646597],
						[13.1105565, 52.4656575],
						[13.1118552, 52.4674466],
						[13.1139542, 52.4686191],
						[13.1137879, 52.4697996],
						[13.1141292, 52.4700651],
						[13.1162061, 52.47168],
						[13.1172345, 52.4732799],
						[13.117357, 52.4740024],
						[13.11736, 52.4740101],
						[13.1174793, 52.4743233],
						[13.1174799, 52.4743249],
						[13.1175166, 52.4743907],
						[13.1175359, 52.4745166],
						[13.1175518, 52.4745785],
						[13.1175442, 52.4747783],
						[13.1175486, 52.4748604],
						[13.1175488, 52.4748651],
						[13.1175571, 52.4750324],
						[13.1175924, 52.4757675],
						[13.117679, 52.4770999],
						[13.1176933, 52.4773203],
						[13.1180596, 52.4773478],
						[13.1183527, 52.4773699],
						[13.1191222, 52.4775049],
						[13.1193354, 52.4775377],
						[13.1197908, 52.4776079],
						[13.1204056, 52.4777],
						[13.120502, 52.4777473],
						[13.120683, 52.4777111],
						[13.1211998, 52.4778163],
						[13.1214396, 52.4778583],
						[13.1219563, 52.4779545],
						[13.1221694, 52.4779945],
						[13.1233578, 52.478169],
						[13.1239691, 52.4782488],
						[13.1262767, 52.4786569],
						[13.1265667, 52.4787931],
						[13.1268671, 52.4789596],
						[13.1279237, 52.4795712],
						[13.1283335, 52.4798205],
						[13.1286057, 52.4800301],
						[13.1295595, 52.4807658],
						[13.1297532, 52.4809155],
						[13.1297956, 52.480948],
						[13.1307165, 52.4816449],
						[13.1316295, 52.4823514],
						[13.1325474, 52.483065],
						[13.1334657, 52.4837785],
						[13.1343875, 52.4844967],
						[13.1346123, 52.4846726],
						[13.1352875, 52.4852013],
						[13.1354566, 52.485332],
						[13.1362016, 52.4859079],
						[13.1367555, 52.4863365],
						[13.1371014, 52.4866042],
						[13.1389363, 52.4880285],
						[13.1398204, 52.4887131],
						[13.1402613, 52.4890582],
						[13.1407108, 52.489407],
						[13.14164, 52.4901282],
						[13.1417055, 52.4901788],
						[13.1418129, 52.4902615],
						[13.1429842, 52.4911655],
						[13.1444314, 52.4922931],
						[13.1453011, 52.4929646],
						[13.1462036, 52.4936611],
						[13.1466026, 52.4939779],
						[13.146664, 52.4940267],
						[13.1470992, 52.4943722],
						[13.1477817, 52.494905],
						[13.1478485, 52.4949572],
						[13.1484142, 52.4953987],
						[13.149243, 52.4960443],
						[13.1493023, 52.4960905],
						[13.1495904, 52.4963148],
						[13.1497949, 52.4964545],
						[13.1500589, 52.4966348],
						[13.1507737, 52.4970487],
						[13.1508512, 52.4970936],
						[13.1508855, 52.4971135],
						[13.151226, 52.49731],
						[13.1516037, 52.4975797],
						[13.15235, 52.4981312],
						[13.1524751, 52.498186],
						[13.1525122, 52.4982129],
						[13.1533527, 52.4988189],
						[13.1542598, 52.499473],
						[13.1547672, 52.4998389],
						[13.1552475, 52.5001852],
						[13.1553043, 52.5002267],
						[13.1556168, 52.5004552],
						[13.1562811, 52.5009408],
						[13.157141, 52.5015695],
						[13.1572153, 52.5016238],
						[13.1611249, 52.5042368],
						[13.1686315, 52.5092535],
						[13.1585643, 52.5129449],
						[13.1576151, 52.5130409],
						[13.1482435, 52.5168824],
						[13.1474226, 52.5171534],
						[13.1464107, 52.5173254],
						[13.1455949, 52.5173714],
						[13.1454319, 52.5175123],
						[13.1450289, 52.5178593],
						[13.144814, 52.5183362],
						[13.144574, 52.5188662],
						[13.144121, 52.5191751],
						[13.1436451, 52.5193321],
						[13.1431382, 52.5196141],
						[13.1427212, 52.5196901],
						[13.1348945, 52.5185501],
						[13.1322129, 52.5181491],
						[13.1299262, 52.5179851],
						[13.1290984, 52.5178632],
						[13.1261768, 52.5174852],
						[13.1173933, 52.5170335],
						[13.1188298, 52.5218036],
						[13.1191449, 52.5273318],
						[13.1192575, 52.5291256],
						[13.1192728, 52.5295766],
						[13.1202144, 52.5320345],
						[13.1234958, 52.5371309],
						[13.1238432, 52.5380806],
						[13.1245455, 52.5401146],
						[13.1254153, 52.5435132],
						[13.1250735, 52.5437533],
						[13.1252787, 52.544499],
						[13.1261382, 52.5465667],
						[13.1265012, 52.5472247],
						[13.1277172, 52.5498083],
						[13.1280109, 52.5504663],
						[13.1289642, 52.5526394],
						[13.1292317, 52.5533653],
						[13.1293082, 52.5534549],
						[13.1298787, 52.5548228],
						[13.1305028, 52.5560229],
						[13.1309832, 52.5558306],
						[13.1311829, 52.5557001],
						[13.1325494, 52.5548734],
						[13.1335548, 52.5539637],
						[13.1338112, 52.5537652],
						[13.134462, 52.5533556],
						[13.136339, 52.5527069],
						[13.1372448, 52.5526109],
						[13.1380165, 52.5525642],
						[13.1392837, 52.5525116],
						[13.1404385, 52.5524875],
						[13.1413564, 52.552465],
						[13.1425607, 52.552252],
						[13.1432002, 52.5520839],
						[13.1442377, 52.552112],
						[13.1445825, 52.5525512],
						[13.1448282, 52.5524793],
						[13.1452377, 52.5525226],
						[13.1453201, 52.5525778],
						[13.145686, 52.5527908],
						[13.1458511, 52.5529243],
						[13.1460367, 52.553404],
						[13.1465814, 52.5540986],
						[13.1469124, 52.5542978],
						[13.1472243, 52.554936],
						[13.1472417, 52.5553465],
						[13.1472914, 52.5558404],
						[13.1469364, 52.5570538],
						[13.1469581, 52.5576769],
						[13.1468626, 52.5586067],
						[13.1465862, 52.5591436],
						[13.1462737, 52.559335],
						[13.1460684, 52.5604032],
						[13.1458155, 52.5606104],
						[13.1460206, 52.5608663],
						[13.1464998, 52.5610878],
						[13.1466878, 52.5611908],
						[13.1470027, 52.5613588],
						[13.1471364, 52.5614366],
						[13.1473817, 52.5624424],
						[13.1473791, 52.5626837],
						[13.1474515, 52.5630987],
						[13.1474739, 52.5633956],
						[13.1474542, 52.5638884],
						[13.1474516, 52.5640295],
						[13.1475198, 52.5644058],
						[13.1477186, 52.5647172],
						[13.1478106, 52.5648034],
						[13.1480061, 52.5649943],
						[13.1481267, 52.5651649],
						[13.148567, 52.5655346],
						[13.1498694, 52.5667651],
						[13.1500655, 52.5670661],
						[13.1501399, 52.5672945],
						[13.1502858, 52.5677179],
						[13.1504729, 52.568196],
						[13.1511906, 52.5691583],
						[13.1512589, 52.5696931],
						[13.1513551, 52.5699847],
						[13.1521721, 52.5703233],
						[13.1523786, 52.5704508],
						[13.1525887, 52.5707197],
						[13.1527001, 52.5710126],
						[13.1528701, 52.5716329],
						[13.1528829, 52.5720286],
						[13.1527697, 52.572488],
						[13.153181, 52.5729097],
						[13.1535448, 52.5732461],
						[13.1535282, 52.5737371],
						[13.1534654, 52.5745404],
						[13.1536878, 52.5747877],
						[13.1534962, 52.5753822],
						[13.15368, 52.5757717],
						[13.1535801, 52.5763231],
						[13.1534481, 52.5767954],
						[13.1535909, 52.5774391],
						[13.1535147, 52.5779004],
						[13.1532085, 52.5782896],
						[13.1531659, 52.5789364],
						[13.1529783, 52.5791679],
						[13.1523649, 52.5801057],
						[13.1519305, 52.5805662],
						[13.1513306, 52.5816213],
						[13.1513449, 52.5820091],
						[13.1512501, 52.582214],
						[13.1506243, 52.582579],
						[13.1498956, 52.5828737],
						[13.1496237, 52.5833664],
						[13.1446371, 52.5820384],
						[13.1438464, 52.581573],
						[13.1429881, 52.5815289],
						[13.142072, 52.5813538],
						[13.1400532, 52.5808088],
						[13.13963, 52.5805861],
						[13.1391695, 52.5803926],
						[13.1374985, 52.5805322],
						[13.1367499, 52.5802856],
						[13.1360227, 52.5801],
						[13.1354833, 52.5800143],
						[13.1353828, 52.580059],
						[13.1326673, 52.5796058],
						[13.1324674, 52.5796072],
						[13.1320432, 52.5798038],
						[13.1317057, 52.5801925],
						[13.1312976, 52.5809281],
						[13.1310784, 52.5816623],
						[13.1304994, 52.5827782],
						[13.130267, 52.5830411],
						[13.1299206, 52.5832223],
						[13.1295276, 52.5832815],
						[13.1283262, 52.5831344],
						[13.1279562, 52.5831125],
						[13.1293554, 52.585937],
						[13.1284347, 52.5860055],
						[13.1289315, 52.587339],
						[13.1297372, 52.5872041],
						[13.1310894, 52.5868634],
						[13.1311964, 52.5868634],
						[13.1327242, 52.5869054],
						[13.1355338, 52.5873364],
						[13.1358997, 52.5874394],
						[13.1380273, 52.5887103],
						[13.1394291, 52.5890412],
						[13.1434545, 52.5896212],
						[13.146252, 52.5906931],
						[13.1491446, 52.591746],
						[13.1516182, 52.5935968],
						[13.1518621, 52.5937798],
						[13.1531839, 52.5949657],
						[13.1538698, 52.5956826],
						[13.1539837, 52.5958016],
						[13.1546096, 52.5964915],
						[13.1549916, 52.5968045],
						[13.1554945, 52.5970905],
						[13.1561824, 52.5973735],
						[13.1570682, 52.5976054],
						[13.1594499, 52.5980364],
						[13.1613396, 52.5983154],
						[13.1643021, 52.5987504],
						[13.1646679, 52.5988074],
						[13.1663318, 52.5979035],
						[13.1668337, 52.5976705],
						[13.1678815, 52.5970189],
						[13.1680955, 52.5970056],
						[13.1700872, 52.5967925],
						[13.1709265, 52.5965439],
						[13.171989, 52.5962875],
						[13.1725681, 52.5960209],
						[13.1733454, 52.5954691],
						[13.1737002, 52.5953176],
						[13.1747343, 52.5951092],
						[13.1763251, 52.5949936],
						[13.1768325, 52.5949247],
						[13.1778784, 52.5949146],
						[13.1783482, 52.5948795],
						[13.1792618, 52.5946282],
						[13.1807212, 52.5941837],
						[13.1812062, 52.5940298],
						[13.1819095, 52.5938881],
						[13.182474, 52.5938442],
						[13.1826584, 52.5938258],
						[13.1833755, 52.5937012],
						[13.1844757, 52.5933769],
						[13.1848454, 52.5931292],
						[13.1852615, 52.5928817],
						[13.1856134, 52.5925518],
						[13.1867986, 52.5920864],
						[13.1880994, 52.5916836],
						[13.1891527, 52.5913931],
						[13.1906197, 52.5906287],
						[13.1908582, 52.5904767],
						[13.1914827, 52.5903092],
						[13.1917395, 52.5901912],
						[13.1927962, 52.5900139],
						[13.1937368, 52.5898334],
						[13.1942346, 52.5897899],
						[13.1952071, 52.5896633],
						[13.1966214, 52.5895184],
						[13.1974786, 52.5893092],
						[13.1978604, 52.5892398],
						[13.1984714, 52.5890156],
						[13.1994672, 52.5888947],
						[13.2003421, 52.5887704],
						[13.2013481, 52.5884199],
						[13.2025676, 52.5878727],
						[13.2035747, 52.5875395],
						[13.2046705, 52.5871528],
						[13.2054031, 52.5868468],
						[13.2067139, 52.5865996],
						[13.207342, 52.5883735],
						[13.2112261, 52.5882263],
						[13.2171889, 52.5872519],
						[13.2166444, 52.5839837],
						[13.21742, 52.5826878],
						[13.2176785, 52.5807635],
						[13.2180663, 52.5791926],
						[13.218188, 52.5786157],
						[13.2183895, 52.5776609],
						[13.2194882, 52.5766397],
						[13.2206768, 52.575929],
						[13.2220336, 52.5747827],
						[13.2233903, 52.5739381],
						[13.2245856, 52.5729771],
						[13.2251772, 52.5724901],
						[13.2255697, 52.5710554],
						[13.2261949, 52.5701711],
						[13.226916, 52.5688989],
						[13.2270846, 52.5679772],
						[13.2273827, 52.5673549],
						[13.2281141, 52.5668117],
						[13.2282544, 52.5666762],
						[13.2284192, 52.566483],
						[13.2285258, 52.5663111],
						[13.2286261, 52.5660364],
						[13.2286641, 52.5657659],
						[13.2286523, 52.5655451],
						[13.2286092, 52.5653401],
						[13.2284962, 52.5650987],
						[13.2283193, 52.5647904],
						[13.2279171, 52.5640892],
						[13.2278753, 52.5639858],
						[13.2278503, 52.5638594],
						[13.2278669, 52.5636312],
						[13.2280953, 52.5633057],
						[13.2299186, 52.5620671],
						[13.2307061, 52.5614353],
						[13.2306973, 52.5613541],
						[13.2308834, 52.561067],
						[13.2311755, 52.5607955],
						[13.232346, 52.5602375],
						[13.238477, 52.5571787],
						[13.2397813, 52.556535],
						[13.2411115, 52.5559488],
						[13.2413352, 52.5559191],
						[13.2455101, 52.5538427],
						[13.2493554, 52.5519277],
						[13.2506018, 52.5513404],
						[13.2517857, 52.550872],
						[13.2530129, 52.5504431],
						[13.2542679, 52.5501356],
						[13.2556183, 52.5498685],
						[13.2569526, 52.5496763],
						[13.2583654, 52.5495769],
						[13.2627741, 52.5494596],
						[13.2703409, 52.5493405],
						[13.270307, 52.5486505],
						[13.2699792, 52.5486537],
						[13.2699758, 52.5485577],
						[13.2701364, 52.5484412],
						[13.270662, 52.5478863],
						[13.2735973, 52.5424316],
						[13.2736135, 52.542285],
						[13.2729549, 52.5404355],
						[13.2725402, 52.5393624],
						[13.2727922, 52.539313],
						[13.2727775, 52.5392662],
						[13.2725901, 52.5387995],
						[13.2730772, 52.5387045],
						[13.2732473, 52.5386595],
						[13.2734349, 52.538606],
						[13.2735908, 52.5385612],
						[13.2739289, 52.5384289],
						[13.2743966, 52.5382315],
						[13.2746728, 52.5380678],
						[13.2748073, 52.5379847],
						[13.2749722, 52.5378763],
						[13.2752276, 52.5376921],
						[13.2753216, 52.5376059],
						[13.2755308, 52.5373671],
						[13.2755928, 52.5372655],
						[13.2755505, 52.5372641],
						[13.2755498, 52.5372505],
						[13.2754724, 52.5372405],
						[13.2756407, 52.5370328],
						[13.2757683, 52.5368641],
						[13.2758104, 52.5368391],
						[13.2758147, 52.5367868],
						[13.2760299, 52.5364444],
						[13.27615, 52.5361828],
						[13.2761636, 52.5361465],
						[13.2762071, 52.5360307],
						[13.2762552, 52.5358553],
						[13.2762143, 52.5358508],
						[13.2762459, 52.5357219],
						[13.2763252, 52.5356987],
						[13.2762749, 52.5356389],
						[13.2802897, 52.5343628],
						[13.2817215, 52.5340744],
						[13.2824665, 52.5339814],
						[13.2823242, 52.5336333],
						[13.2812572, 52.5305794],
						[13.2811858, 52.5304889],
						[13.2808473, 52.5300598],
						[13.2805603, 52.5301489],
						[13.2802138, 52.5302269],
						[13.2799403, 52.5302604],
						[13.2793687, 52.5302917],
						[13.2793254, 52.5303239],
						[13.2792457, 52.5302922],
						[13.2790816, 52.5302929],
						[13.2787837, 52.5302679],
						[13.2782311, 52.530199],
						[13.2779087, 52.530133],
						[13.2776103, 52.5300484],
						[13.2773411, 52.5299544],
						[13.2769868, 52.5297758],
						[13.2765886, 52.5295766],
						[13.2746826, 52.5288671],
						[13.2738552, 52.5288256],
						[13.2731084, 52.5288465],
						[13.2721893, 52.5288914],
						[13.2708982, 52.5289419],
						[13.2697362, 52.528959],
						[13.2689355, 52.5289688],
						[13.2685072, 52.5289509],
						[13.2678895, 52.5289152],
						[13.2664489, 52.528812],
						[13.2660113, 52.5287787],
						[13.265318, 52.5287192],
						[13.2640648, 52.5285782],
						[13.2635047, 52.5284768],
						[13.2629233, 52.5283537],
						[13.2624594, 52.5282627],
						[13.2620049, 52.52815],
						[13.2604165, 52.5276639],
						[13.2598505, 52.5274869],
						[13.259121, 52.5273668],
						[13.2578775, 52.5272665],
						[13.2568799, 52.5272019],
						[13.2498062, 52.5271793],
						[13.2492707, 52.5272798],
						[13.2485721, 52.5274578],
						[13.2483066, 52.5275445],
						[13.2475853, 52.5278268],
						[13.2472391, 52.527999],
						[13.2466811, 52.5283134],
						[13.2463412, 52.5276887],
						[13.246662, 52.5276358],
						[13.2468987, 52.5275322],
						[13.2470236, 52.5274269],
						[13.2471275, 52.527228],
						[13.2472061, 52.5260474],
						[13.2472536, 52.5258173],
						[13.2473487, 52.5254979],
						[13.2474458, 52.5252804],
						[13.2475713, 52.525027],
						[13.2465028, 52.5244816],
						[13.2462314, 52.5245558],
						[13.2461679, 52.524491],
						[13.2450405, 52.524789],
						[13.243999, 52.5250297],
						[13.2422487, 52.5255429],
						[13.242192, 52.5255513],
						[13.2420535, 52.5255674],
						[13.2404788, 52.5259448],
						[13.2402018, 52.525978],
						[13.2399824, 52.5261291],
						[13.2398199, 52.5258583],
						[13.2394544, 52.5259326],
						[13.2391256, 52.5260076],
						[13.2387259, 52.5260791],
						[13.2380872, 52.526125],
						[13.2376284, 52.5261206],
						[13.2365612, 52.526096],
						[13.2362293, 52.5260942],
						[13.2348287, 52.5260034],
						[13.233853, 52.5259199],
						[13.2313242, 52.52573],
						[13.2309086, 52.5258557],
						[13.2288341, 52.5259598],
						[13.2282234, 52.5259945],
						[13.2281311, 52.5258308],
						[13.2280721, 52.5256882],
						[13.2272825, 52.5255923],
						[13.2259167, 52.5257688],
						[13.2247643, 52.5257976],
						[13.2238392, 52.5258803],
						[13.2226908, 52.5260971],
						[13.222042, 52.5261289],
						[13.2218156, 52.5257633],
						[13.2217209, 52.5257927],
						[13.2216148, 52.5256392],
						[13.2217557, 52.5256258],
						[13.221047, 52.5244154],
						[13.2202585, 52.5231777],
						[13.2198803, 52.5222866],
						[13.2196624, 52.5218245],
						[13.2195068, 52.5212261],
						[13.2195275, 52.5204934],
						[13.2199191, 52.5160888],
						[13.22021, 52.5147527],
						[13.2203775, 52.5142853],
						[13.220947, 52.5136221],
						[13.2197518, 52.5135763],
						[13.2196801, 52.5140084],
						[13.2194564, 52.513994],
						[13.2188372, 52.5133743],
						[13.2184779, 52.5133972],
						[13.2174467, 52.5124072],
						[13.2171427, 52.5121568],
						[13.2167603, 52.5119417],
						[13.2164771, 52.5117009],
						[13.2164365, 52.511651],
						[13.2162014, 52.511341],
						[13.2158528, 52.5106853],
						[13.2158675, 52.510681],
						[13.2155245, 52.5102731],
						[13.2151045, 52.5096504],
						[13.2149809, 52.5095096],
						[13.2156956, 52.5093652],
						[13.2155819, 52.5091595],
						[13.2145456, 52.5093678],
						[13.2145174, 52.509327],
						[13.2144632, 52.5092745],
						[13.2142409, 52.5091502],
						[13.2141573, 52.5091212],
						[13.2140851, 52.509101],
						[13.2139789, 52.5090954],
						[13.2139232, 52.5090727],
						[13.2132311, 52.5090972],
						[13.2130117, 52.5091619],
						[13.2128017, 52.5092091],
						[13.2127884, 52.5091866],
						[13.2124329, 52.5092366],
						[13.2123221, 52.5092443],
						[13.2120085, 52.5093064],
						[13.2118605, 52.509347],
						[13.2110126, 52.5085223],
						[13.2104999, 52.5072015],
						[13.2093301, 52.5058751],
						[13.2087621, 52.5050455],
						[13.2093457, 52.5043024],
						[13.2097274, 52.5029098],
						[13.2051704, 52.5013633],
						[13.1990817, 52.499888],
						[13.1981897, 52.499792],
						[13.1952155, 52.4997414],
						[13.1917093, 52.4986912],
						[13.1898203, 52.4975432],
						[13.1894319, 52.4949858],
						[13.1895918, 52.4929407],
						[13.1875725, 52.491232],
						[13.1865954, 52.489298],
						[13.1871454, 52.4871319],
						[13.1898295, 52.4843352],
						[13.190158, 52.4826124],
						[13.1872447, 52.4786565],
						[13.1882878, 52.4741827],
						[13.1881678, 52.4718369],
						[13.1866317, 52.4665459],
						[13.1852946, 52.4625632],
						[13.1812188, 52.4596274],
						[13.1774168, 52.455916],
						[13.1708528, 52.4561195],
						[13.1649516, 52.4535243],
						[13.1618615, 52.4521973],
						[13.1617307, 52.4521372],
						[13.1581196, 52.4495051],
						[13.1545078, 52.4465858],
						[13.1485072, 52.4433709],
						[13.1360589, 52.4423798],
						[13.1332624, 52.4420829],
						[13.1317181, 52.4411044],
						[13.1307965, 52.4414114],
						[13.1294647, 52.4413047],
						[13.1280481, 52.4409609],
						[13.1268795, 52.4411449],
						[13.1241178, 52.4406731],
						[13.1231211, 52.439615],
						[13.1202088, 52.4413553],
						[13.1199306, 52.4415211],
						[13.1197681, 52.4417138],
						[13.1193335, 52.4424399],
						[13.1190963, 52.4428362],
						[13.1190185, 52.4429661],
						[13.1190221, 52.443132],
						[13.1190252, 52.4432776],
						[13.1191357, 52.4437747],
						[13.1190111, 52.4440092],
						[13.1181828, 52.4444138],
						[13.1173457, 52.4448227],
						[13.1166265, 52.4451736],
						[13.1153647, 52.445652],
						[13.1145082, 52.4463579],
						[13.1136393, 52.4470739],
						[13.1132632, 52.447385],
						[13.113023, 52.4475837],
						[13.1122943, 52.4481794],
						[13.1115284, 52.4488055],
						[13.1107689, 52.449428],
						[13.1100556, 52.4500125],
						[13.1092963, 52.4506355],
					],
				],
			},
			properties: {},
		},
	},
	{
		name: "Steglitz-Zehlendorf",
		coords: [13.249999, 52.4333316],
		gen: false,
		polygon: {
			type: "Feature",
			geometry: {
				type: "Polygon",
				coordinates: [
					[
						[13.0883476, 52.4196312],
						[13.0958394, 52.4219738],
						[13.0977237, 52.423466],
						[13.0980539, 52.4240168],
						[13.0980883, 52.4240742],
						[13.0984005, 52.4248889],
						[13.0993138, 52.4253441],
						[13.0999756, 52.4254477],
						[13.1004663, 52.4253041],
						[13.1009493, 52.4251627],
						[13.1016009, 52.4246362],
						[13.1034865, 52.4243022],
						[13.1045678, 52.4239826],
						[13.1054095, 52.4252831],
						[13.1069165, 52.4256456],
						[13.1080218, 52.4266605],
						[13.1127834, 52.4291958],
						[13.1120738, 52.4323102],
						[13.1139305, 52.4329662],
						[13.117218, 52.4361645],
						[13.1221421, 52.4378778],
						[13.1231531, 52.4387089],
						[13.1231211, 52.439615],
						[13.1241178, 52.4406731],
						[13.1268795, 52.4411449],
						[13.1280481, 52.4409609],
						[13.1294647, 52.4413047],
						[13.1307965, 52.4414114],
						[13.1317181, 52.4411044],
						[13.1332624, 52.4420829],
						[13.1360589, 52.4423798],
						[13.1485072, 52.4433709],
						[13.1545078, 52.4465858],
						[13.1581196, 52.4495051],
						[13.1617307, 52.4521372],
						[13.1618615, 52.4521973],
						[13.1649516, 52.4535243],
						[13.1708528, 52.4561195],
						[13.1774168, 52.455916],
						[13.1812188, 52.4596274],
						[13.1852946, 52.4625632],
						[13.1866317, 52.4665459],
						[13.1881678, 52.4718369],
						[13.1945732, 52.4709827],
						[13.1952413, 52.4712527],
						[13.1963228, 52.4713408],
						[13.1966212, 52.4713471],
						[13.1969963, 52.4711694],
						[13.1975927, 52.4706292],
						[13.1977293, 52.4703489],
						[13.1976671, 52.4701086],
						[13.1976177, 52.4697371],
						[13.197732, 52.4693586],
						[13.1979336, 52.4691331],
						[13.1980733, 52.4690206],
						[13.1989763, 52.4687686],
						[13.1992711, 52.4687456],
						[13.1997147, 52.4688164],
						[13.2002936, 52.4689779],
						[13.2006155, 52.4690372],
						[13.2007561, 52.4691031],
						[13.2009803, 52.4692788],
						[13.201573, 52.4698776],
						[13.2017272, 52.4699814],
						[13.202206, 52.4701594],
						[13.2027524, 52.47025],
						[13.2035557, 52.4704724],
						[13.2037478, 52.4704988],
						[13.2041481, 52.4704906],
						[13.2045674, 52.4705268],
						[13.2050129, 52.4705227],
						[13.2058106, 52.4704202],
						[13.2066362, 52.4703242],
						[13.2082611, 52.4699401],
						[13.210195, 52.4696636],
						[13.2103587, 52.4697272],
						[13.2115778, 52.469439],
						[13.2121844, 52.4695564],
						[13.2133475, 52.4696188],
						[13.2137798, 52.4697266],
						[13.2139879, 52.4697966],
						[13.2150712, 52.4699585],
						[13.2161603, 52.4700955],
						[13.2165747, 52.4701224],
						[13.2188217, 52.4700416],
						[13.2201438, 52.4700571],
						[13.2215884, 52.469978],
						[13.2236175, 52.470017],
						[13.2237758, 52.4699855],
						[13.22552, 52.4701266],
						[13.2267982, 52.4703596],
						[13.2286895, 52.4704859],
						[13.2302229, 52.4707472],
						[13.2314741, 52.4707564],
						[13.232684, 52.4704217],
						[13.2338938, 52.4701174],
						[13.2350778, 52.4696698],
						[13.2360675, 52.469533],
						[13.2363478, 52.4694899],
						[13.2366911, 52.469444],
						[13.2370957, 52.469375],
						[13.2372056, 52.4693644],
						[13.2373494, 52.4693306],
						[13.2374965, 52.4693115],
						[13.2380598, 52.4692053],
						[13.2383352, 52.4691693],
						[13.2383476, 52.4691935],
						[13.2390449, 52.469057],
						[13.2390372, 52.4690412],
						[13.2414113, 52.4686317],
						[13.2420062, 52.4685665],
						[13.2427177, 52.468436],
						[13.2430243, 52.4683578],
						[13.2438098, 52.4682472],
						[13.2467534, 52.467738],
						[13.247201, 52.4677019],
						[13.2473674, 52.4677124],
						[13.2478355, 52.4675372],
						[13.2479138, 52.4675327],
						[13.2479671, 52.4675165],
						[13.2523879, 52.4667302],
						[13.2527783, 52.4666968],
						[13.2530888, 52.4665983],
						[13.2546735, 52.4667628],
						[13.2555519, 52.4667361],
						[13.25753, 52.4665726],
						[13.2578194, 52.4665292],
						[13.2589884, 52.4664729],
						[13.2592381, 52.4666774],
						[13.2598593, 52.4668523],
						[13.2608054, 52.4672466],
						[13.2616643, 52.4677623],
						[13.2625189, 52.4675279],
						[13.2624412, 52.4671847],
						[13.2629525, 52.4669523],
						[13.264858, 52.4670255],
						[13.2649893, 52.4670438],
						[13.2665765, 52.4672645],
						[13.2679049, 52.4673863],
						[13.2693555, 52.4675484],
						[13.2723081, 52.4678517],
						[13.272446, 52.4679402],
						[13.2782073, 52.4686215],
						[13.2785923, 52.4685918],
						[13.2786258, 52.4684945],
						[13.2812577, 52.4689034],
						[13.2881531, 52.4703272],
						[13.2884941, 52.47037],
						[13.2891192, 52.4705166],
						[13.2896566, 52.4700671],
						[13.2898873, 52.4698914],
						[13.2901497, 52.4701262],
						[13.2901747, 52.4703259],
						[13.2979829, 52.4688399],
						[13.3035466, 52.4679101],
						[13.3065561, 52.4674199],
						[13.3075544, 52.4678631],
						[13.3082946, 52.4675906],
						[13.3085345, 52.4674566],
						[13.3090597, 52.4677169],
						[13.3095944, 52.4674334],
						[13.30999, 52.4672428],
						[13.3106939, 52.4669318],
						[13.3109332, 52.4670619],
						[13.3111402, 52.4671131],
						[13.3115218, 52.467121],
						[13.3200013, 52.4669971],
						[13.3210337, 52.4669598],
						[13.3281485, 52.464473],
						[13.3293748, 52.4656736],
						[13.3307565, 52.4652402],
						[13.331437, 52.4658215],
						[13.3346602, 52.4662693],
						[13.3348484, 52.4661983],
						[13.3358442, 52.4670035],
						[13.3362902, 52.4674461],
						[13.3374164, 52.4669516],
						[13.3379118, 52.4666445],
						[13.3384772, 52.4663747],
						[13.3387892, 52.4665662],
						[13.3388827, 52.4655533],
						[13.3423242, 52.4634519],
						[13.3429553, 52.4630562],
						[13.3445798, 52.461906],
						[13.344969, 52.4616763],
						[13.3457442, 52.4609995],
						[13.3457794, 52.4609706],
						[13.3463744, 52.4604525],
						[13.347128, 52.4597832],
						[13.3478207, 52.4592622],
						[13.3477134, 52.4591536],
						[13.3476489, 52.4590838],
						[13.3475571, 52.4589782],
						[13.3480377, 52.4588223],
						[13.3483326, 52.4587866],
						[13.3484151, 52.4587633],
						[13.349036, 52.4587569],
						[13.3490712, 52.4578746],
						[13.349081, 52.456778],
						[13.3496272, 52.4567646],
						[13.350909, 52.4566224],
						[13.3509211, 52.4567301],
						[13.3514841, 52.4567698],
						[13.3519218, 52.4568777],
						[13.3524691, 52.4570692],
						[13.3529836, 52.4571248],
						[13.3533289, 52.4570239],
						[13.3537145, 52.4566799],
						[13.3537754, 52.4565376],
						[13.3538697, 52.4563262],
						[13.3540044, 52.4560996],
						[13.3542032, 52.4558599],
						[13.3542161, 52.4556509],
						[13.3559727, 52.4556219],
						[13.3561182, 52.4556154],
						[13.3562495, 52.4552704],
						[13.356581, 52.4546359],
						[13.3566623, 52.4544896],
						[13.356688, 52.4544433],
						[13.3569067, 52.4540933],
						[13.3569026, 52.4540313],
						[13.3572885, 52.4534365],
						[13.3572581, 52.4533254],
						[13.3579301, 52.4522908],
						[13.3582396, 52.451782],
						[13.3584396, 52.4514247],
						[13.3584969, 52.4512546],
						[13.3586288, 52.4510078],
						[13.3587592, 52.4508829],
						[13.3589621, 52.4506166],
						[13.3594649, 52.4496879],
						[13.3597069, 52.4494088],
						[13.3601505, 52.4486349],
						[13.360859, 52.4473195],
						[13.3609503, 52.4471094],
						[13.3610112, 52.4470873],
						[13.3610445, 52.4470183],
						[13.3610696, 52.446976],
						[13.3611288, 52.4469215],
						[13.3612035, 52.4468509],
						[13.3611129, 52.4468186],
						[13.3613103, 52.4464788],
						[13.3615007, 52.4462374],
						[13.3623576, 52.444619],
						[13.3623692, 52.4446036],
						[13.3623896, 52.4445724],
						[13.3624328, 52.4445991],
						[13.3626482, 52.4441438],
						[13.363139, 52.4433225],
						[13.3631154, 52.4433112],
						[13.3634046, 52.4428281],
						[13.3634742, 52.4428219],
						[13.3641977, 52.4415738],
						[13.367117, 52.4367211],
						[13.3675334, 52.4358213],
						[13.3676035, 52.4357095],
						[13.3694403, 52.432655],
						[13.3706376, 52.4308504],
						[13.3712218, 52.4298709],
						[13.3714888, 52.4293111],
						[13.3715607, 52.4291908],
						[13.3716004, 52.4291128],
						[13.3713207, 52.428991],
						[13.371105, 52.428905],
						[13.371156, 52.4287997],
						[13.3709332, 52.4286972],
						[13.3698647, 52.4282103],
						[13.3685629, 52.4276211],
						[13.3683626, 52.4275611],
						[13.3681977, 52.4275543],
						[13.3680722, 52.4275784],
						[13.3679258, 52.4276048],
						[13.3677697, 52.42739],
						[13.3673869, 52.4268693],
						[13.3662834, 52.4253013],
						[13.3641845, 52.4223265],
						[13.3636087, 52.421644],
						[13.3634312, 52.4215014],
						[13.363284, 52.4214324],
						[13.3616206, 52.4209559],
						[13.3613349, 52.4208724],
						[13.3610611, 52.420796],
						[13.3608157, 52.4207215],
						[13.3604993, 52.4206346],
						[13.3602929, 52.4205747],
						[13.3600878, 52.4204786],
						[13.3592109, 52.4200574],
						[13.3592687, 52.4199411],
						[13.3589297, 52.4198563],
						[13.356338, 52.4189172],
						[13.3551775, 52.4184946],
						[13.3547359, 52.41835],
						[13.3535634, 52.4180156],
						[13.3517838, 52.4173635],
						[13.3502247, 52.416802],
						[13.3463481, 52.4155934],
						[13.3455785, 52.4156881],
						[13.3454167, 52.4152935],
						[13.3426696, 52.414218],
						[13.3430772, 52.4135531],
						[13.343172, 52.4126279],
						[13.3429257, 52.4117134],
						[13.3433189, 52.4117201],
						[13.3428514, 52.411559],
						[13.3426461, 52.4114762],
						[13.3420454, 52.411264],
						[13.341672, 52.4111112],
						[13.3357286, 52.4087592],
						[13.33297, 52.4077323],
						[13.3316982, 52.4072564],
						[13.3272543, 52.4057028],
						[13.3233815, 52.4042576],
						[13.3185023, 52.4024268],
						[13.3139011, 52.400538],
						[13.3131891, 52.3999822],
						[13.3120822, 52.3991392],
						[13.3112705, 52.399996],
						[13.3111945, 52.400096],
						[13.3095787, 52.4016028],
						[13.3069, 52.4044025],
						[13.306823, 52.4044824],
						[13.3052092, 52.4059593],
						[13.3022903, 52.4086361],
						[13.3012108, 52.4095868],
						[13.3006618, 52.4100868],
						[13.2999989, 52.4106967],
						[13.2987271, 52.4118645],
						[13.2985881, 52.4119895],
						[13.2959749, 52.4144739],
						[13.295965, 52.4144839],
						[13.2963476, 52.4145448],
						[13.2967219, 52.4147414],
						[13.2969668, 52.4150421],
						[13.2971071, 52.4154418],
						[13.2971036, 52.4157631],
						[13.2969062, 52.4160509],
						[13.2967545, 52.4162498],
						[13.2962463, 52.4160325],
						[13.2958804, 52.4158603],
						[13.2953224, 52.4155899],
						[13.295214, 52.4155339],
						[13.2935085, 52.4146593],
						[13.293023, 52.4144129],
						[13.2884142, 52.4118218],
						[13.2852976, 52.4100885],
						[13.2834326, 52.4090495],
						[13.2815974, 52.4080389],
						[13.2808196, 52.4076261],
						[13.279902, 52.407133],
						[13.2788664, 52.4066425],
						[13.2772803, 52.4058721],
						[13.2757903, 52.4051497],
						[13.2737104, 52.4046805],
						[13.2729633, 52.4044513],
						[13.2714645, 52.4042274],
						[13.2698522, 52.4041741],
						[13.2689845, 52.4042061],
						[13.2683157, 52.404226],
						[13.2664671, 52.4043125],
						[13.2644286, 52.4047576],
						[13.2623258, 52.4054514],
						[13.260988, 52.405941],
						[13.2596606, 52.4062428],
						[13.2583918, 52.4063652],
						[13.2570527, 52.4063861],
						[13.25558, 52.4062835],
						[13.2538958, 52.4061065],
						[13.2531453, 52.4059743],
						[13.252418, 52.4058149],
						[13.2518152, 52.4056681],
						[13.2506709, 52.4053366],
						[13.2497254, 52.4049566],
						[13.2496829, 52.4053615],
						[13.249271, 52.4075171],
						[13.2492347, 52.4078896],
						[13.2488147, 52.4084045],
						[13.2490197, 52.4088544],
						[13.2490776, 52.4114332],
						[13.2490826, 52.4117151],
						[13.2484708, 52.4140608],
						[13.2479399, 52.415772],
						[13.2478107, 52.4163806],
						[13.2475237, 52.4173225],
						[13.2470648, 52.4189173],
						[13.2465848, 52.4199932],
						[13.2461488, 52.4207671],
						[13.2459469, 52.421126],
						[13.2440172, 52.420977],
						[13.2405437, 52.42084],
						[13.2379311, 52.420733],
						[13.2377671, 52.420737],
						[13.2358144, 52.420779],
						[13.2346226, 52.4206],
						[13.2344836, 52.420577],
						[13.2331558, 52.42026],
						[13.232147, 52.420287],
						[13.2296984, 52.420405],
						[13.2287935, 52.420493],
						[13.2285345, 52.42052],
						[13.2272637, 52.4206649],
						[13.2264661, 52.42082],
						[13.2253296, 52.4208236],
						[13.2252542, 52.4208316],
						[13.2252691, 52.4209252],
						[13.2250545, 52.4210828],
						[13.2245661, 52.4208823],
						[13.2236259, 52.4206733],
						[13.2236008, 52.4207633],
						[13.2214946, 52.42015],
						[13.2205707, 52.419933],
						[13.2197962, 52.4197572],
						[13.2191754, 52.4196054],
						[13.2184717, 52.4193834],
						[13.2182247, 52.4193458],
						[13.2164091, 52.4188433],
						[13.2154531, 52.418504],
						[13.2142718, 52.4180553],
						[13.2135797, 52.4178336],
						[13.21305, 52.4177732],
						[13.212696, 52.4176522],
						[13.2116073, 52.4172793],
						[13.2110773, 52.417127],
						[13.2098243, 52.4168239],
						[13.2074058, 52.4163162],
						[13.2058414, 52.4160714],
						[13.2045253, 52.4158373],
						[13.2013363, 52.4155339],
						[13.2003207, 52.4155184],
						[13.1994398, 52.4154072],
						[13.1975931, 52.4155203],
						[13.1972449, 52.4155343],
						[13.1952471, 52.4147942],
						[13.1854907, 52.4115305],
						[13.180073, 52.4096975],
						[13.1737378, 52.4075938],
						[13.1725203, 52.407157],
						[13.1593384, 52.4028733],
						[13.1592015, 52.4027314],
						[13.1592715, 52.3999967],
						[13.1592765, 52.3997757],
						[13.1592325, 52.3995767],
						[13.1587106, 52.3988068],
						[13.1583087, 52.3980279],
						[13.1582656, 52.3979616],
						[13.1578278, 52.397288],
						[13.1577588, 52.396769],
						[13.1578028, 52.396476],
						[13.1578378, 52.3962891],
						[13.1614162, 52.396621],
						[13.1616894, 52.3966465],
						[13.1680425, 52.3972635],
						[13.1685755, 52.3973156],
						[13.170103, 52.3975541],
						[13.1710838, 52.3977749],
						[13.1712567, 52.397521],
						[13.1716037, 52.3956553],
						[13.1693361, 52.3946294],
						[13.1687502, 52.3943924],
						[13.1681926, 52.3943931],
						[13.1681316, 52.3943926],
						[13.1679607, 52.3943915],
						[13.1668541, 52.3943365],
						[13.1659276, 52.3942905],
						[13.1657552, 52.394282],
						[13.1646173, 52.3942262],
						[13.1645659, 52.3942236],
						[13.1645219, 52.3942214],
						[13.1644108, 52.3942158],
						[13.1643277, 52.3942116],
						[13.1635931, 52.3941743],
						[13.163516, 52.3941703],
						[13.1634431, 52.3941667],
						[13.1622819, 52.3941061],
						[13.1613656, 52.3940608],
						[13.1612479, 52.394055],
						[13.1612335, 52.3940542],
						[13.160232, 52.3940055],
						[13.1599966, 52.393994],
						[13.1595331, 52.3939714],
						[13.158938, 52.3939423],
						[13.1588692, 52.393939],
						[13.1576615, 52.3941347],
						[13.1567959, 52.394276],
						[13.1556057, 52.3944666],
						[13.1546689, 52.394618],
						[13.154458, 52.3946521],
						[13.1532905, 52.39484],
						[13.1521957, 52.3949254],
						[13.1511036, 52.3950055],
						[13.151044, 52.3950101],
						[13.1500206, 52.3950893],
						[13.1490163, 52.3951866],
						[13.1489334, 52.3951946],
						[13.1478888, 52.3952952],
						[13.146805, 52.3954003],
						[13.1465788, 52.3954222],
						[13.145976, 52.3954804],
						[13.1458004, 52.3954974],
						[13.1457389, 52.3955034],
						[13.1447861, 52.3958274],
						[13.1438588, 52.3961391],
						[13.1437034, 52.3962537],
						[13.1435124, 52.3963945],
						[13.1433729, 52.3964973],
						[13.1431341, 52.3966734],
						[13.1432816, 52.3967262],
						[13.1432631, 52.3968499],
						[13.1432209, 52.3971237],
						[13.1432045, 52.3972477],
						[13.1428491, 52.3971673],
						[13.1428215, 52.3971611],
						[13.1425639, 52.3971028],
						[13.1424209, 52.3970676],
						[13.1419869, 52.3969608],
						[13.1418185, 52.3970996],
						[13.1415248, 52.3969503],
						[13.1412419, 52.3968064],
						[13.1410941, 52.3967313],
						[13.1409886, 52.3966747],
						[13.1409628, 52.396661],
						[13.1406864, 52.396513],
						[13.1404264, 52.3963736],
						[13.1400864, 52.3962392],
						[13.1383927, 52.3955527],
						[13.1381985, 52.3954495],
						[13.137763, 52.395218],
						[13.1370398, 52.3948336],
						[13.1366507, 52.3946269],
						[13.1364246, 52.3945066],
						[13.1347076, 52.3935634],
						[13.1340845, 52.393175],
						[13.1331368, 52.3925037],
						[13.132181, 52.3918307],
						[13.1320967, 52.3917714],
						[13.1320775, 52.3917579],
						[13.132025, 52.391715],
						[13.1303736, 52.3903662],
						[13.1306957, 52.3901622],
						[13.1307528, 52.3901259],
						[13.1308088, 52.3900904],
						[13.1310083, 52.3899634],
						[13.1313517, 52.3897445],
						[13.1314587, 52.3896763],
						[13.131617, 52.3895754],
						[13.1318429, 52.3894315],
						[13.1318957, 52.3893978],
						[13.1320214, 52.3893104],
						[13.1321946, 52.3891899],
						[13.1323665, 52.3890716],
						[13.1324111, 52.3890393],
						[13.1325686, 52.3889298],
						[13.1327094, 52.3888439],
						[13.1328742, 52.3887434],
						[13.1332989, 52.3886486],
						[13.1337436, 52.3887471],
						[13.1339795, 52.3887359],
						[13.1336222, 52.3880994],
						[13.1333941, 52.387693],
						[13.1333423, 52.3876006],
						[13.1332904, 52.3875082],
						[13.1331808, 52.3873046],
						[13.1329249, 52.3873507],
						[13.1327103, 52.3873897],
						[13.1324889, 52.3874301],
						[13.1322308, 52.3874748],
						[13.1319862, 52.3875171],
						[13.1317178, 52.387505],
						[13.131541, 52.3874968],
						[13.1314443, 52.3874687],
						[13.131305, 52.3872254],
						[13.1311487, 52.3872302],
						[13.1310795, 52.3872364],
						[13.1309682, 52.387267],
						[13.1308059, 52.3873175],
						[13.130679, 52.3873647],
						[13.129833, 52.387847],
						[13.1294843, 52.388048],
						[13.1292748, 52.3881653],
						[13.1291372, 52.388238],
						[13.1286659, 52.3884983],
						[13.12829, 52.3887007],
						[13.1282016, 52.3887432],
						[13.1280662, 52.3888201],
						[13.1276171, 52.3890753],
						[13.1272132, 52.3892911],
						[13.1266966, 52.3895608],
						[13.1268038, 52.3899758],
						[13.1268472, 52.3901406],
						[13.1269211, 52.3904609],
						[13.1269974, 52.3907558],
						[13.1270716, 52.3910686],
						[13.1271238, 52.391266],
						[13.1271623, 52.391424],
						[13.1272197, 52.3916659],
						[13.1294232, 52.3917052],
						[13.129423, 52.3917265],
						[13.1307454, 52.3917571],
						[13.1309077, 52.3917611],
						[13.1309262, 52.391761],
						[13.1311017, 52.391809],
						[13.1311906, 52.3918358],
						[13.1311979, 52.3918361],
						[13.1312003, 52.3918114],
						[13.1312024, 52.3917899],
						[13.1312158, 52.3917904],
						[13.1312229, 52.3917868],
						[13.1311453, 52.3917207],
						[13.1311365, 52.3917204],
						[13.1311082, 52.3916963],
						[13.1310335, 52.3916331],
						[13.1310277, 52.3916357],
						[13.1308585, 52.3916907],
						[13.1305036, 52.3913417],
						[13.1304263, 52.391265],
						[13.1301792, 52.39102],
						[13.1300095, 52.3908518],
						[13.1298524, 52.3906961],
						[13.1301674, 52.3904968],
						[13.1309112, 52.3911719],
						[13.1315034, 52.3917042],
						[13.1315334, 52.3917312],
						[13.1314201, 52.3917026],
						[13.1313298, 52.3916798],
						[13.1313225, 52.3916795],
						[13.1313204, 52.3917006],
						[13.1313179, 52.3917258],
						[13.1313077, 52.3917242],
						[13.1312998, 52.3917277],
						[13.1313782, 52.3917941],
						[13.1313878, 52.3917948],
						[13.1314098, 52.3918165],
						[13.131491, 52.3918818],
						[13.1314977, 52.3918791],
						[13.1315435, 52.3918198],
						[13.1315688, 52.3917934],
						[13.1317457, 52.3917994],
						[13.1317811, 52.3918242],
						[13.1329144, 52.3926206],
						[13.1338776, 52.3933018],
						[13.134493, 52.3936865],
						[13.1362417, 52.3946462],
						[13.1366407, 52.3948584],
						[13.1370641, 52.3950834],
						[13.1375426, 52.3953377],
						[13.1382291, 52.3957028],
						[13.1389502, 52.3959923],
						[13.138368, 52.3959792],
						[13.1385865, 52.3960996],
						[13.1379934, 52.3961203],
						[13.1377939, 52.3961344],
						[13.1374992, 52.3960194],
						[13.1372603, 52.3960251],
						[13.1374377, 52.3961466],
						[13.1371475, 52.3961681],
						[13.1371327, 52.3961691],
						[13.1370392, 52.3961749],
						[13.1369983, 52.3961775],
						[13.136807, 52.3961771],
						[13.1364225, 52.396177],
						[13.1360125, 52.3961769],
						[13.1357979, 52.3961757],
						[13.1357119, 52.3961752],
						[13.1356791, 52.3962363],
						[13.1356134, 52.3963706],
						[13.1356092, 52.3963792],
						[13.1355877, 52.3964235],
						[13.1354523, 52.3967097],
						[13.1353584, 52.3969022],
						[13.1352143, 52.3972174],
						[13.1357162, 52.3974489],
						[13.1360279, 52.3974887],
						[13.1361434, 52.3975035],
						[13.1364109, 52.39753],
						[13.1364743, 52.3975365],
						[13.1365828, 52.3975475],
						[13.136732, 52.3975622],
						[13.1369245, 52.3975814],
						[13.1369369, 52.3975826],
						[13.1369981, 52.3975887],
						[13.1370826, 52.3975972],
						[13.1371657, 52.3976046],
						[13.1372575, 52.3976129],
						[13.1373347, 52.3976192],
						[13.1375331, 52.3976381],
						[13.1376723, 52.39765],
						[13.1380441, 52.3976833],
						[13.1380861, 52.3978072],
						[13.1381026, 52.3978562],
						[13.1366366, 52.3981315],
						[13.1364085, 52.3981948],
						[13.1356653, 52.3984011],
						[13.1354261, 52.3984675],
						[13.1352018, 52.3985292],
						[13.1350357, 52.3985747],
						[13.1349565, 52.3985965],
						[13.1335094, 52.3993665],
						[13.1325231, 52.3986632],
						[13.1284625, 52.3969449],
						[13.1274241, 52.39664],
						[13.1269226, 52.3965713],
						[13.1256609, 52.3966622],
						[13.1247813, 52.3968694],
						[13.1242058, 52.3972752],
						[13.1231598, 52.3978788],
						[13.122003, 52.3985464],
						[13.1202323, 52.3998485],
						[13.1184644, 52.4012812],
						[13.1177504, 52.4021193],
						[13.1163373, 52.4023233],
						[13.1118193, 52.4039855],
						[13.1112041, 52.4045101],
						[13.1111028, 52.4052978],
						[13.1096938, 52.4065358],
						[13.1083253, 52.4072556],
						[13.1093065, 52.4079314],
						[13.10945, 52.4080476],
						[13.1092596, 52.4081613],
						[13.1092671, 52.4081786],
						[13.1091342, 52.4082399],
						[13.1086104, 52.4084755],
						[13.1082135, 52.4085168],
						[13.1076813, 52.408572],
						[13.1075506, 52.4085856],
						[13.1075158, 52.4085892],
						[13.1070015, 52.4086426],
						[13.1067942, 52.4086641],
						[13.106575, 52.4086779],
						[13.1065458, 52.4086798],
						[13.1063827, 52.40869],
						[13.1064158, 52.4087424],
						[13.1064397, 52.4087758],
						[13.1064629, 52.4087742],
						[13.1065539, 52.4087681],
						[13.1066552, 52.4089957],
						[13.1067657, 52.409244],
						[13.1069332, 52.4094856],
						[13.1085061, 52.409243],
						[13.1106306, 52.4094433],
						[13.1106161, 52.4094903],
						[13.1105924, 52.409567],
						[13.1106655, 52.4095735],
						[13.1106102, 52.4097514],
						[13.1105555, 52.4099282],
						[13.1104955, 52.4101224],
						[13.1104245, 52.410352],
						[13.1114512, 52.4104477],
						[13.1117975, 52.4104799],
						[13.1117542, 52.4106212],
						[13.1117027, 52.4107896],
						[13.1116471, 52.4109712],
						[13.1115914, 52.4111531],
						[13.1115481, 52.4112944],
						[13.1115117, 52.4114133],
						[13.1111644, 52.4113811],
						[13.1108638, 52.4113533],
						[13.1101369, 52.411286],
						[13.1101006, 52.411403],
						[13.1100543, 52.4115526],
						[13.1100077, 52.4117029],
						[13.1099679, 52.4118312],
						[13.109905, 52.4120343],
						[13.1096241, 52.4129411],
						[13.109448, 52.4129781],
						[13.1092012, 52.41303],
						[13.1091702, 52.4130365],
						[13.1089272, 52.4130876],
						[13.1086604, 52.4131437],
						[13.1086162, 52.4131532],
						[13.1084757, 52.4131833],
						[13.1083493, 52.4132104],
						[13.1081032, 52.4132632],
						[13.1080759, 52.4132691],
						[13.1078075, 52.4133266],
						[13.1077803, 52.4133325],
						[13.1074729, 52.4132967],
						[13.1073561, 52.413283],
						[13.107256, 52.4133139],
						[13.1071427, 52.4132135],
						[13.1071062, 52.4131811],
						[13.1071237, 52.413099],
						[13.1072547, 52.4128255],
						[13.1073045, 52.4127178],
						[13.1073275, 52.4126335],
						[13.1073622, 52.412435],
						[13.1073918, 52.4122941],
						[13.107406, 52.4122105],
						[13.1074156, 52.412127],
						[13.1074176, 52.4120884],
						[13.1073607, 52.4117978],
						[13.1073019, 52.4115469],
						[13.1072685, 52.411393],
						[13.107203, 52.4110863],
						[13.1071796, 52.4108175],
						[13.1071547, 52.4106826],
						[13.1071533, 52.4105514],
						[13.1071458, 52.4100465],
						[13.1071442, 52.4100153],
						[13.1071408, 52.4099932],
						[13.1071325, 52.4099609],
						[13.1071238, 52.4099347],
						[13.1071051, 52.4099032],
						[13.1070818, 52.4098699],
						[13.1070193, 52.4098146],
						[13.1069668, 52.4097741],
						[13.1068515, 52.4097141],
						[13.1067693, 52.4096757],
						[13.1065488, 52.4096061],
						[13.1061868, 52.4095621],
						[13.1059145, 52.4095487],
						[13.1054691, 52.4097454],
						[13.105124, 52.4098978],
						[13.104626, 52.4097431],
						[13.1043987, 52.4097931],
						[13.1038945, 52.409904],
						[13.1035671, 52.4099759],
						[13.1031787, 52.4100612],
						[13.1010028, 52.4105389],
						[13.1009705, 52.4106817],
						[13.1010274, 52.4109801],
						[13.1010457, 52.4110525],
						[13.1010763, 52.4112742],
						[13.1011003, 52.4114332],
						[13.1011219, 52.411577],
						[13.1010685, 52.4118093],
						[13.101047, 52.4118862],
						[13.1009758, 52.4121861],
						[13.1008116, 52.4127186],
						[13.1007304, 52.4129901],
						[13.1007324, 52.413019],
						[13.1007523, 52.4133451],
						[13.1008521, 52.413733],
						[13.1008591, 52.4137605],
						[13.1005688, 52.4137954],
						[13.1003122, 52.4137846],
						[13.0999494, 52.4137287],
						[13.0998603, 52.4137193],
						[13.0992615, 52.4136288],
						[13.0991106, 52.4136059],
						[13.0981794, 52.4134429],
						[13.0980509, 52.4134052],
						[13.0978825, 52.4133858],
						[13.0978557, 52.4133932],
						[13.0968124, 52.4132024],
						[13.0966191, 52.4131418],
						[13.0964503, 52.4130889],
						[13.097113, 52.4123459],
						[13.0976105, 52.4117817],
						[13.0975575, 52.411768],
						[13.0975887, 52.4117544],
						[13.0975922, 52.4117418],
						[13.0975659, 52.4117199],
						[13.0975449, 52.4117178],
						[13.0975287, 52.4117049],
						[13.0975611, 52.4116912],
						[13.0975867, 52.4116549],
						[13.0976533, 52.4116256],
						[13.097718, 52.4116225],
						[13.0987649, 52.4111603],
						[13.0988531, 52.411119],
						[13.0988991, 52.4110841],
						[13.0989358, 52.4110452],
						[13.0989621, 52.4110034],
						[13.0989774, 52.4109595],
						[13.0989833, 52.410915],
						[13.0989797, 52.4108704],
						[13.0989665, 52.4108264],
						[13.0989441, 52.4107839],
						[13.0989217, 52.4107537],
						[13.0988945, 52.4107249],
						[13.0988504, 52.410683],
						[13.0987739, 52.4106098],
						[13.0986708, 52.4105106],
						[13.0986653, 52.4105135],
						[13.0986591, 52.4105158],
						[13.0986524, 52.4105175],
						[13.0986454, 52.4105186],
						[13.0986382, 52.410519],
						[13.0986309, 52.4105188],
						[13.0986223, 52.4105178],
						[13.0986141, 52.4105159],
						[13.0986065, 52.4105131],
						[13.0985998, 52.4105096],
						[13.0985942, 52.4105055],
						[13.0985566, 52.4104691],
						[13.098553, 52.4104642],
						[13.0985507, 52.410459],
						[13.0985499, 52.4104536],
						[13.0985507, 52.4104482],
						[13.0985529, 52.410443],
						[13.0985565, 52.4104382],
						[13.0985614, 52.4104339],
						[13.0985674, 52.4104301],
						[13.0985744, 52.410427],
						[13.0985821, 52.4104246],
						[13.0985466, 52.410389],
						[13.0987017, 52.4103302],
						[13.0986387, 52.4102608],
						[13.0986134, 52.4102707],
						[13.0984081, 52.410105],
						[13.0983331, 52.4100623],
						[13.0979754, 52.4099395],
						[13.0979817, 52.4098809],
						[13.0973921, 52.40942],
						[13.0934123, 52.4105371],
						[13.0923549, 52.4111158],
						[13.0921238, 52.4112421],
						[13.0907679, 52.4115604],
						[13.090275, 52.4122142],
						[13.0901879, 52.4127306],
						[13.0902096, 52.4133864],
						[13.0902109, 52.4135491],
						[13.0883476, 52.4196312],
					],
				],
			},
			properties: {},
		},
	},
	{
		name: "Tempelhof-Schöneberg",
		coords: [13.3833318, 52.4666648],
		gen: false,
		polygon: {
			type: "Feature",
			geometry: {
				type: "Polygon",
				coordinates: [
					[
						[13.3199923, 52.4671559],
						[13.3200575, 52.4688098],
						[13.3200615, 52.4688638],
						[13.3201052, 52.4696547],
						[13.3201117, 52.4698101],
						[13.3201844, 52.4698583],
						[13.3202544, 52.4698998],
						[13.3202457, 52.4699049],
						[13.3202529, 52.4699087],
						[13.3202264, 52.4699264],
						[13.3202616, 52.469946],
						[13.3202503, 52.4699549],
						[13.3202501, 52.4699678],
						[13.3202551, 52.4699743],
						[13.3205224, 52.4701306],
						[13.3205261, 52.4702659],
						[13.3202551, 52.4704396],
						[13.3202438, 52.470458],
						[13.3201337, 52.4705235],
						[13.3201505, 52.470749],
						[13.3201785, 52.4714899],
						[13.3202211, 52.4725697],
						[13.3202465, 52.4727887],
						[13.3202586, 52.4734821],
						[13.3202643, 52.4735926],
						[13.3203092, 52.4744136],
						[13.3203253, 52.4750189],
						[13.3203248, 52.4753373],
						[13.3203897, 52.4763951],
						[13.3204001, 52.4767523],
						[13.3204008, 52.4768214],
						[13.3204223, 52.4773497],
						[13.3204297, 52.4774712],
						[13.3204861, 52.4774738],
						[13.3224784, 52.4774956],
						[13.3243538, 52.4774807],
						[13.3271553, 52.4774608],
						[13.3282711, 52.4774326],
						[13.3288294, 52.4774374],
						[13.3328676, 52.4774162],
						[13.3329854, 52.4778486],
						[13.3370632, 52.4781335],
						[13.3370669, 52.4785242],
						[13.3370654, 52.4789126],
						[13.337059, 52.4798891],
						[13.3371642, 52.4801151],
						[13.3371689, 52.4803542],
						[13.3370519, 52.4807077],
						[13.3370492, 52.482088],
						[13.3370938, 52.482202],
						[13.3372053, 52.4825283],
						[13.3371242, 52.4825349],
						[13.3371093, 52.4830686],
						[13.3370974, 52.4846883],
						[13.3370854, 52.485677],
						[13.337181, 52.4856736],
						[13.3371359, 52.4859183],
						[13.3370569, 52.4859231],
						[13.3370906, 52.4869342],
						[13.3371474, 52.4880427],
						[13.3372152, 52.4880485],
						[13.3371396, 52.4883984],
						[13.3370568, 52.4884745],
						[13.3369048, 52.4894054],
						[13.3368996, 52.4896794],
						[13.3369497, 52.4903618],
						[13.3369911, 52.4904053],
						[13.3373154, 52.4904859],
						[13.3375528, 52.4907224],
						[13.3370663, 52.4910006],
						[13.337175, 52.4921321],
						[13.3372174, 52.4923137],
						[13.3371253, 52.4923016],
						[13.3373487, 52.4940659],
						[13.3374467, 52.494072],
						[13.337462, 52.4944841],
						[13.3374469, 52.4947871],
						[13.3373334, 52.4947789],
						[13.3372728, 52.4958626],
						[13.3373703, 52.4958482],
						[13.3373855, 52.4959866],
						[13.3373044, 52.4959781],
						[13.3376048, 52.496894],
						[13.3379785, 52.4978387],
						[13.3389388, 52.4994827],
						[13.3391852, 52.4999828],
						[13.336906, 52.5008673],
						[13.3395347, 52.5031907],
						[13.3414287, 52.5049347],
						[13.3415264, 52.5049424],
						[13.3514081, 52.5025413],
						[13.3513967, 52.5025842],
						[13.3576374, 52.5010619],
						[13.3586633, 52.5007739],
						[13.3600544, 52.5003768],
						[13.3603634, 52.5002845],
						[13.3625368, 52.499662],
						[13.3630442, 52.4995624],
						[13.3688402, 52.4987771],
						[13.3690868, 52.4987573],
						[13.3693571, 52.4987357],
						[13.3695331, 52.4988698],
						[13.3697289, 52.4987765],
						[13.369601, 52.4981061],
						[13.3686779, 52.4973847],
						[13.3686362, 52.496877],
						[13.3686038, 52.4965258],
						[13.3685962, 52.4965108],
						[13.3685916, 52.4964588],
						[13.3685913, 52.4964111],
						[13.36856, 52.4960771],
						[13.368539, 52.4958761],
						[13.3685269, 52.4957389],
						[13.3684812, 52.4952231],
						[13.3684522, 52.4949243],
						[13.3684148, 52.4944781],
						[13.3685807, 52.4939555],
						[13.3682291, 52.4933357],
						[13.3691729, 52.4931506],
						[13.3692527, 52.4932859],
						[13.3710816, 52.4929042],
						[13.3710649, 52.4927373],
						[13.3720009, 52.4926097],
						[13.3723924, 52.4925302],
						[13.3765623, 52.4916704],
						[13.3764656, 52.4915151],
						[13.3760886, 52.4911468],
						[13.3760501, 52.4910507],
						[13.3756924, 52.4906809],
						[13.3754648, 52.4904497],
						[13.375413, 52.4893656],
						[13.3748892, 52.4893189],
						[13.374606, 52.4891099],
						[13.373518, 52.4879703],
						[13.3734818, 52.4877505],
						[13.3741755, 52.4877318],
						[13.3740954, 52.4861755],
						[13.3740185, 52.4851671],
						[13.371719, 52.4851739],
						[13.3716065, 52.484979],
						[13.3768548, 52.4849652],
						[13.3847829, 52.4849107],
						[13.3848479, 52.4848716],
						[13.3862684, 52.4848587],
						[13.3862732, 52.4852299],
						[13.3862812, 52.4858229],
						[13.3910787, 52.4858401],
						[13.3942612, 52.4857753],
						[13.3942586, 52.4857242],
						[13.3942541, 52.4854938],
						[13.3942502, 52.4853329],
						[13.3942454, 52.4850678],
						[13.3942422, 52.4849395],
						[13.3942367, 52.4845401],
						[13.3942337, 52.4843358],
						[13.394234, 52.4841256],
						[13.3946131, 52.4840216],
						[13.3979566, 52.4839487],
						[13.3987169, 52.4839328],
						[13.399383, 52.483893],
						[13.4001472, 52.4838407],
						[13.4012255, 52.4837388],
						[13.4023153, 52.4835847],
						[13.4034421, 52.4833999],
						[13.404886, 52.4830993],
						[13.4063457, 52.4827923],
						[13.4063142, 52.4825926],
						[13.4061265, 52.4810907],
						[13.4060789, 52.4809987],
						[13.4061509, 52.4806017],
						[13.406246, 52.4801692],
						[13.4068123, 52.4788793],
						[13.4066327, 52.478229],
						[13.4068134, 52.4781877],
						[13.4108798, 52.4777237],
						[13.4109501, 52.4779019],
						[13.413491, 52.4787059],
						[13.416041, 52.4691537],
						[13.4168805, 52.4653962],
						[13.4215065, 52.4658316],
						[13.4215498, 52.4658357],
						[13.4215591, 52.465764],
						[13.4216195, 52.4653002],
						[13.4216237, 52.4651364],
						[13.4216953, 52.4638101],
						[13.4217124, 52.4634942],
						[13.4219033, 52.4628709],
						[13.4221057, 52.46272],
						[13.4221473, 52.4624995],
						[13.4220286, 52.4616097],
						[13.4219833, 52.4615164],
						[13.4230248, 52.4613266],
						[13.4232379, 52.4604049],
						[13.4224018, 52.4605259],
						[13.421449, 52.4606672],
						[13.4206129, 52.459369],
						[13.421251, 52.4591366],
						[13.4228622, 52.4585422],
						[13.4248407, 52.4578148],
						[13.4265871, 52.4567698],
						[13.4260456, 52.4566605],
						[13.4244282, 52.4566285],
						[13.4227945, 52.4566655],
						[13.4211298, 52.4567611],
						[13.4196272, 52.4546549],
						[13.4176908, 52.4522032],
						[13.4168347, 52.4522419],
						[13.4160773, 52.4508232],
						[13.4159388, 52.45014],
						[13.4157323, 52.4492662],
						[13.4151914, 52.4485837],
						[13.4151055, 52.448476],
						[13.4149885, 52.4480277],
						[13.41477, 52.4470112],
						[13.4145718, 52.4467282],
						[13.4139856, 52.4459438],
						[13.4129983, 52.4435475],
						[13.4118892, 52.4407646],
						[13.4116433, 52.4401198],
						[13.4111729, 52.4388826],
						[13.4109765, 52.4384322],
						[13.4109372, 52.4383447],
						[13.4105856, 52.4374894],
						[13.410446, 52.437009],
						[13.4103801, 52.4361454],
						[13.4097177, 52.4350261],
						[13.409159, 52.4342126],
						[13.4090233, 52.4337747],
						[13.4089819, 52.4335244],
						[13.4088682, 52.4331862],
						[13.4086042, 52.4327269],
						[13.4082262, 52.4321977],
						[13.4077099, 52.4313872],
						[13.4073482, 52.4308473],
						[13.4068768, 52.4301432],
						[13.4067696, 52.4298938],
						[13.406575, 52.4287882],
						[13.4065401, 52.4286027],
						[13.4063455, 52.4278149],
						[13.4062547, 52.4275205],
						[13.4060972, 52.4271172],
						[13.4057743, 52.4264693],
						[13.4054554, 52.4259514],
						[13.4051514, 52.4255153],
						[13.4040904, 52.4241432],
						[13.4036017, 52.4234416],
						[13.4026075, 52.4221665],
						[13.4054683, 52.4217627],
						[13.4048278, 52.4200071],
						[13.4001226, 52.4187739],
						[13.3999243, 52.4185371],
						[13.3994933, 52.4180204],
						[13.3997774, 52.417521],
						[13.4024999, 52.4126958],
						[13.4045523, 52.4129813],
						[13.4057925, 52.4132206],
						[13.4076929, 52.4134691],
						[13.409421, 52.4135172],
						[13.410461, 52.4133879],
						[13.4106109, 52.4133716],
						[13.4150571, 52.412074],
						[13.416027, 52.4116832],
						[13.4169525, 52.4113041],
						[13.4182742, 52.4107583],
						[13.4197674, 52.4104759],
						[13.4195922, 52.4101749],
						[13.4187799, 52.4099948],
						[13.4185012, 52.409234],
						[13.4184154, 52.408766],
						[13.4182991, 52.4079276],
						[13.4184246, 52.4070919],
						[13.419289, 52.4050894],
						[13.4196548, 52.4042704],
						[13.4198825, 52.4037441],
						[13.4201543, 52.4031192],
						[13.4204088, 52.4025354],
						[13.4215648, 52.399996],
						[13.4216757, 52.399752],
						[13.4238125, 52.3950526],
						[13.4253514, 52.3916499],
						[13.4253934, 52.391558],
						[13.4266603, 52.3885443],
						[13.4269982, 52.3878814],
						[13.4274282, 52.3863706],
						[13.4274572, 52.3857526],
						[13.4273282, 52.3855727],
						[13.4270183, 52.3851417],
						[13.4262054, 52.383983],
						[13.4243408, 52.3813161],
						[13.4219312, 52.3778715],
						[13.4209624, 52.3763296],
						[13.4208338, 52.3761269],
						[13.4178656, 52.3762482],
						[13.4126513, 52.3764098],
						[13.4128481, 52.3768739],
						[13.4122402, 52.376815],
						[13.4105104, 52.3766417],
						[13.4098385, 52.3765687],
						[13.4076568, 52.3768057],
						[13.406624, 52.3769046],
						[13.4046723, 52.3769656],
						[13.4040944, 52.3770226],
						[13.4022866, 52.3771986],
						[13.4008548, 52.3773395],
						[13.4006059, 52.3773625],
						[13.400005, 52.3774115],
						[13.3983772, 52.3775265],
						[13.3919242, 52.3777444],
						[13.3916463, 52.3777438],
						[13.3884366, 52.377865],
						[13.3884132, 52.3786491],
						[13.3882321, 52.378694],
						[13.3880651, 52.3792549],
						[13.3881116, 52.3800475],
						[13.3880801, 52.3800828],
						[13.3882281, 52.3809107],
						[13.3880811, 52.3817196],
						[13.38819, 52.3822036],
						[13.388223, 52.3823446],
						[13.387945, 52.3842793],
						[13.387635, 52.3861791],
						[13.387548, 52.3867091],
						[13.387264, 52.3885779],
						[13.3855873, 52.3884938],
						[13.3825337, 52.3883458],
						[13.3823808, 52.3883378],
						[13.3823581, 52.3883373],
						[13.3815625, 52.388311],
						[13.3786594, 52.3881564],
						[13.3783976, 52.3881439],
						[13.3769078, 52.3881399],
						[13.3762139, 52.3881559],
						[13.3749561, 52.3881859],
						[13.3704488, 52.3884648],
						[13.3719542, 52.3937927],
						[13.3684406, 52.3951258],
						[13.3642296, 52.3966778],
						[13.362423, 52.3972332],
						[13.3614078, 52.397667],
						[13.3612628, 52.3977282],
						[13.3606178, 52.3979977],
						[13.35938, 52.3984646],
						[13.3590444, 52.3986462],
						[13.3585781, 52.398907],
						[13.3576804, 52.3993865],
						[13.3564536, 52.3999964],
						[13.3543149, 52.4010643],
						[13.3526866, 52.4020056],
						[13.3507824, 52.402949],
						[13.3503933, 52.4031832],
						[13.3492106, 52.4038949],
						[13.3460631, 52.4058406],
						[13.3459631, 52.4059016],
						[13.3430865, 52.4076754],
						[13.343236, 52.4106814],
						[13.3433189, 52.4117201],
						[13.3429257, 52.4117134],
						[13.343172, 52.4126279],
						[13.3430772, 52.4135531],
						[13.3426696, 52.414218],
						[13.3454167, 52.4152935],
						[13.3455785, 52.4156881],
						[13.3463481, 52.4155934],
						[13.3502247, 52.416802],
						[13.3517838, 52.4173635],
						[13.3535634, 52.4180156],
						[13.3547359, 52.41835],
						[13.3551775, 52.4184946],
						[13.356338, 52.4189172],
						[13.3589297, 52.4198563],
						[13.3592687, 52.4199411],
						[13.3592109, 52.4200574],
						[13.3600878, 52.4204786],
						[13.3602929, 52.4205747],
						[13.3604993, 52.4206346],
						[13.3608157, 52.4207215],
						[13.3610611, 52.420796],
						[13.3613349, 52.4208724],
						[13.3616206, 52.4209559],
						[13.363284, 52.4214324],
						[13.3634312, 52.4215014],
						[13.3636087, 52.421644],
						[13.3641845, 52.4223265],
						[13.3662834, 52.4253013],
						[13.3673869, 52.4268693],
						[13.3677697, 52.42739],
						[13.3679258, 52.4276048],
						[13.3680722, 52.4275784],
						[13.3681977, 52.4275543],
						[13.3683626, 52.4275611],
						[13.3685629, 52.4276211],
						[13.3698647, 52.4282103],
						[13.3709332, 52.4286972],
						[13.371156, 52.4287997],
						[13.371105, 52.428905],
						[13.3713207, 52.428991],
						[13.3716004, 52.4291128],
						[13.3715607, 52.4291908],
						[13.3714888, 52.4293111],
						[13.3712218, 52.4298709],
						[13.3706376, 52.4308504],
						[13.3694403, 52.432655],
						[13.3676035, 52.4357095],
						[13.3675334, 52.4358213],
						[13.367117, 52.4367211],
						[13.3641977, 52.4415738],
						[13.3634742, 52.4428219],
						[13.3634046, 52.4428281],
						[13.3631154, 52.4433112],
						[13.363139, 52.4433225],
						[13.3626482, 52.4441438],
						[13.3624328, 52.4445991],
						[13.3623896, 52.4445724],
						[13.3623692, 52.4446036],
						[13.3623576, 52.444619],
						[13.3615007, 52.4462374],
						[13.3613103, 52.4464788],
						[13.3611129, 52.4468186],
						[13.3612035, 52.4468509],
						[13.3611288, 52.4469215],
						[13.3610696, 52.446976],
						[13.3610445, 52.4470183],
						[13.3610112, 52.4470873],
						[13.3609503, 52.4471094],
						[13.360859, 52.4473195],
						[13.3601505, 52.4486349],
						[13.3597069, 52.4494088],
						[13.3594649, 52.4496879],
						[13.3589621, 52.4506166],
						[13.3587592, 52.4508829],
						[13.3586288, 52.4510078],
						[13.3584969, 52.4512546],
						[13.3584396, 52.4514247],
						[13.3582396, 52.451782],
						[13.3579301, 52.4522908],
						[13.3572581, 52.4533254],
						[13.3572885, 52.4534365],
						[13.3569026, 52.4540313],
						[13.3569067, 52.4540933],
						[13.356688, 52.4544433],
						[13.3566623, 52.4544896],
						[13.356581, 52.4546359],
						[13.3562495, 52.4552704],
						[13.3561182, 52.4556154],
						[13.3559727, 52.4556219],
						[13.3542161, 52.4556509],
						[13.3542032, 52.4558599],
						[13.3540044, 52.4560996],
						[13.3538697, 52.4563262],
						[13.3537754, 52.4565376],
						[13.3537145, 52.4566799],
						[13.3533289, 52.4570239],
						[13.3529836, 52.4571248],
						[13.3524691, 52.4570692],
						[13.3519218, 52.4568777],
						[13.3514841, 52.4567698],
						[13.3509211, 52.4567301],
						[13.350909, 52.4566224],
						[13.3496272, 52.4567646],
						[13.349081, 52.456778],
						[13.3490712, 52.4578746],
						[13.349036, 52.4587569],
						[13.3484151, 52.4587633],
						[13.3483326, 52.4587866],
						[13.3480377, 52.4588223],
						[13.3475571, 52.4589782],
						[13.3476489, 52.4590838],
						[13.3477134, 52.4591536],
						[13.3478207, 52.4592622],
						[13.347128, 52.4597832],
						[13.3463744, 52.4604525],
						[13.3457794, 52.4609706],
						[13.3457442, 52.4609995],
						[13.344969, 52.4616763],
						[13.3445798, 52.461906],
						[13.3429553, 52.4630562],
						[13.3423242, 52.4634519],
						[13.3388827, 52.4655533],
						[13.3387892, 52.4665662],
						[13.3384772, 52.4663747],
						[13.3379118, 52.4666445],
						[13.3374164, 52.4669516],
						[13.3362902, 52.4674461],
						[13.3358442, 52.4670035],
						[13.3348484, 52.4661983],
						[13.3346602, 52.4662693],
						[13.331437, 52.4658215],
						[13.3307565, 52.4652402],
						[13.3293748, 52.4656736],
						[13.3281485, 52.464473],
						[13.3210337, 52.4669598],
						[13.3200013, 52.4669971],
						[13.3199923, 52.4671559],
					],
				],
			},
			properties: {},
		},
	},
	{
		name: "Treptow-Köpenick",
		coords: [13.5666644, 52.4499982],
		gen: false,
		polygon: {
			type: "Feature",
			geometry: {
				type: "Polygon",
				coordinates: [
					[
						[13.4396363, 52.4899002],
						[13.4396798, 52.4900322],
						[13.4396815, 52.4901289],
						[13.4397152, 52.4902456],
						[13.4397655, 52.4903859],
						[13.4398107, 52.4905074],
						[13.4398843, 52.4906049],
						[13.4401045, 52.4907393],
						[13.4404055, 52.4909351],
						[13.440686, 52.4910889],
						[13.4423861, 52.4923765],
						[13.4446428, 52.4941678],
						[13.4451616, 52.494545],
						[13.4455486, 52.494856],
						[13.446909, 52.4948401],
						[13.4474502, 52.4948733],
						[13.4500838, 52.4966885],
						[13.4506551, 52.4970891],
						[13.4510076, 52.4972687],
						[13.4513876, 52.4973922],
						[13.452013, 52.4975232],
						[13.4529296, 52.4977068],
						[13.4531167, 52.4975536],
						[13.4533719, 52.4975354],
						[13.4535828, 52.4975426],
						[13.454066, 52.497565],
						[13.4544446, 52.497486],
						[13.4547627, 52.4974248],
						[13.4550436, 52.4973665],
						[13.4583073, 52.4968071],
						[13.4595955, 52.4965568],
						[13.4621962, 52.4955378],
						[13.4630717, 52.4954437],
						[13.4633549, 52.4954855],
						[13.4641615, 52.4951129],
						[13.4632285, 52.4943345],
						[13.4632699, 52.49417],
						[13.4659382, 52.4930696],
						[13.4702214, 52.4917752],
						[13.472968, 52.4912631],
						[13.4735688, 52.4912004],
						[13.4754399, 52.4904008],
						[13.4763154, 52.4899304],
						[13.4778346, 52.4886813],
						[13.4779461, 52.4879705],
						[13.4784037, 52.4874117],
						[13.4792868, 52.4879871],
						[13.4800125, 52.48804],
						[13.4815253, 52.4877771],
						[13.4821589, 52.4875193],
						[13.4824179, 52.4871764],
						[13.4827868, 52.4860888],
						[13.4851047, 52.4870836],
						[13.4860314, 52.4875315],
						[13.4868897, 52.4877771],
						[13.4876828, 52.4878844],
						[13.4887351, 52.487866],
						[13.4892758, 52.4878137],
						[13.4902657, 52.4876087],
						[13.4910096, 52.487359],
						[13.4914136, 52.4871841],
						[13.4930015, 52.485637],
						[13.4938577, 52.4838766],
						[13.4941951, 52.4831241],
						[13.4966361, 52.4833156],
						[13.4967425, 52.4833152],
						[13.4968842, 52.4833047],
						[13.4971152, 52.4833333],
						[13.4979782, 52.4833066],
						[13.4982784, 52.4832121],
						[13.4985909, 52.4831226],
						[13.4995903, 52.482874],
						[13.5005855, 52.4826829],
						[13.5013119, 52.4825956],
						[13.5014175, 52.482589],
						[13.5015412, 52.4825664],
						[13.5018784, 52.4824586],
						[13.5019663, 52.4824494],
						[13.5031442, 52.4801895],
						[13.5041913, 52.4782815],
						[13.5043372, 52.4780671],
						[13.5077876, 52.4749148],
						[13.5116586, 52.4754898],
						[13.513461, 52.475746],
						[13.5140361, 52.4757774],
						[13.5144996, 52.4757669],
						[13.5149888, 52.4757356],
						[13.5154866, 52.475678],
						[13.5158643, 52.4755996],
						[13.5163106, 52.475448],
						[13.5205678, 52.4740103],
						[13.5217732, 52.4751713],
						[13.5242372, 52.4733847],
						[13.5306615, 52.4678355],
						[13.5323867, 52.4690538],
						[13.5380258, 52.4731424],
						[13.5400771, 52.4748311],
						[13.5420083, 52.4739528],
						[13.5429199, 52.4738569],
						[13.5484666, 52.4732732],
						[13.5481195, 52.4738221],
						[13.5489949, 52.4738848],
						[13.5494842, 52.4738221],
						[13.5503596, 52.4736757],
						[13.5506125, 52.4736479],
						[13.5508832, 52.4736182],
						[13.5532396, 52.4736172],
						[13.5536126, 52.4736234],
						[13.5549945, 52.4735711],
						[13.5567454, 52.4733568],
						[13.5578612, 52.4732313],
						[13.5583978, 52.4732208],
						[13.5591916, 52.4732052],
						[13.5628738, 52.4733045],
						[13.5652255, 52.4738273],
						[13.5654487, 52.4735764],
						[13.5666932, 52.4737384],
						[13.566985, 52.4737489],
						[13.5671138, 52.473958],
						[13.5673713, 52.4742299],
						[13.5675429, 52.4744076],
						[13.5682472, 52.4746775],
						[13.5693711, 52.4751082],
						[13.5716457, 52.4760126],
						[13.5736713, 52.4767968],
						[13.5736884, 52.4765511],
						[13.5737914, 52.4764727],
						[13.5739202, 52.4764727],
						[13.574006, 52.4765563],
						[13.5739631, 52.4768648],
						[13.5746596, 52.4778699],
						[13.5751561, 52.4794786],
						[13.5800141, 52.4810102],
						[13.5818628, 52.4799688],
						[13.5825705, 52.4801481],
						[13.5838233, 52.4804593],
						[13.5863986, 52.4811282],
						[13.5911121, 52.4789192],
						[13.593172, 52.4778685],
						[13.5939951, 52.4774736],
						[13.5957984, 52.4765825],
						[13.5965108, 52.4761486],
						[13.5975579, 52.4757042],
						[13.5979184, 52.4755473],
						[13.5983991, 52.4751343],
						[13.5995835, 52.4744861],
						[13.6002959, 52.4741671],
						[13.601798, 52.4734352],
						[13.6031509, 52.4728513],
						[13.6042012, 52.472813],
						[13.6084584, 52.4728496],
						[13.6084204, 52.4721488],
						[13.6084161, 52.4720698],
						[13.608364, 52.4711086],
						[13.6115655, 52.470523],
						[13.6113751, 52.470657],
						[13.6117357, 52.4705919],
						[13.6121769, 52.4705008],
						[13.6151637, 52.4699999],
						[13.6153837, 52.4700229],
						[13.6169014, 52.4711138],
						[13.6173853, 52.4711978],
						[13.6206058, 52.4706599],
						[13.6213507, 52.4705149],
						[13.6213367, 52.4704309],
						[13.6212538, 52.46987],
						[13.6212898, 52.4685041],
						[13.6219347, 52.4684431],
						[13.6218547, 52.4671883],
						[13.6216518, 52.4669253],
						[13.6214118, 52.4666123],
						[13.6216548, 52.4665703],
						[13.6227026, 52.4663994],
						[13.6229056, 52.4665243],
						[13.6230876, 52.4666353],
						[13.6233365, 52.4667843],
						[13.6230706, 52.4670853],
						[13.6228356, 52.4677342],
						[13.6228096, 52.4687221],
						[13.6230675, 52.4687371],
						[13.6231835, 52.4687451],
						[13.6234735, 52.4687641],
						[13.6251862, 52.4687681],
						[13.6250912, 52.4689241],
						[13.6249232, 52.4690121],
						[13.6245883, 52.4691001],
						[13.624589, 52.4691085],
						[13.6246055, 52.4693079],
						[13.6246233, 52.469523],
						[13.6246573, 52.469973],
						[13.6247562, 52.470179],
						[13.6248182, 52.4703129],
						[13.6251831, 52.4735596],
						[13.625785, 52.4737536],
						[13.6260979, 52.4737916],
						[13.6264459, 52.4737686],
						[13.6269858, 52.4735976],
						[13.6275217, 52.4735326],
						[13.6279457, 52.4735136],
						[13.6283496, 52.4735746],
						[13.6291155, 52.4738486],
						[13.6301333, 52.4744285],
						[13.6305142, 52.4747035],
						[13.6311331, 52.4750315],
						[13.6352925, 52.4758744],
						[13.6361004, 52.4760694],
						[13.6370342, 52.4762944],
						[13.6377951, 52.4767133],
						[13.6398907, 52.4776063],
						[13.6413875, 52.4781972],
						[13.6418994, 52.4783962],
						[13.6427013, 52.4788081],
						[13.6433882, 52.4792581],
						[13.6441331, 52.4791251],
						[13.644304, 52.4790941],
						[13.6450909, 52.4789532],
						[13.6475803, 52.4788735],
						[13.6478818, 52.4788555],
						[13.6485685, 52.4787438],
						[13.6495716, 52.4784275],
						[13.6503929, 52.4781635],
						[13.6516471, 52.4777208],
						[13.6523772, 52.4774709],
						[13.6527348, 52.4773094],
						[13.6539903, 52.4769095],
						[13.6558534, 52.4765374],
						[13.6568972, 52.4758636],
						[13.658259, 52.4750887],
						[13.658781, 52.4748487],
						[13.6596688, 52.4746198],
						[13.6598078, 52.4745508],
						[13.6600598, 52.4744328],
						[13.6609316, 52.4741698],
						[13.6614586, 52.4740019],
						[13.6620835, 52.4737989],
						[13.6626954, 52.4736659],
						[13.6638172, 52.4737229],
						[13.6648111, 52.4737769],
						[13.66705, 52.4742463],
						[13.6672387, 52.4742885],
						[13.6673548, 52.4738566],
						[13.6675744, 52.4737296],
						[13.6689689, 52.473442],
						[13.6695042, 52.4732859],
						[13.6700979, 52.4730912],
						[13.6705792, 52.4728381],
						[13.6716118, 52.4722826],
						[13.6733901, 52.471377],
						[13.675248, 52.4706635],
						[13.6764148, 52.4700191],
						[13.6786406, 52.4692358],
						[13.6789789, 52.4689326],
						[13.6794047, 52.4685303],
						[13.6797509, 52.46822],
						[13.6800255, 52.467957],
						[13.6800971, 52.4678964],
						[13.6801764, 52.4678255],
						[13.6804631, 52.4676273],
						[13.6808301, 52.4673775],
						[13.6810041, 52.4672539],
						[13.6827698, 52.4660701],
						[13.6843634, 52.4656654],
						[13.6853924, 52.4654106],
						[13.6865756, 52.4651343],
						[13.6875475, 52.4650773],
						[13.6882402, 52.4650373],
						[13.6884257, 52.4650221],
						[13.6892063, 52.4650154],
						[13.6898322, 52.4650099],
						[13.690988, 52.4647405],
						[13.692482, 52.4645497],
						[13.6940544, 52.4644158],
						[13.6954452, 52.4641899],
						[13.6953633, 52.4640177],
						[13.6956195, 52.4640059],
						[13.6959238, 52.4635013],
						[13.6963616, 52.4628054],
						[13.6967753, 52.4621878],
						[13.6970915, 52.4617183],
						[13.6975326, 52.4604962],
						[13.6976347, 52.4595404],
						[13.6976519, 52.4592954],
						[13.697693, 52.4584876],
						[13.6984871, 52.4572882],
						[13.6986478, 52.4565833],
						[13.6986738, 52.4564522],
						[13.6985777, 52.4558643],
						[13.6985275, 52.4556095],
						[13.6983491, 52.4552557],
						[13.6990423, 52.4551799],
						[13.7002501, 52.4549839],
						[13.7014876, 52.4548674],
						[13.7017172, 52.4548603],
						[13.7031323, 52.4548548],
						[13.7038516, 52.4548059],
						[13.7046159, 52.4547558],
						[13.7052454, 52.455627],
						[13.7054028, 52.4566575],
						[13.705593, 52.457705],
						[13.7054841, 52.4587874],
						[13.7053381, 52.4595148],
						[13.7052641, 52.4598659],
						[13.7040932, 52.459999],
						[13.703354, 52.4600499],
						[13.7031764, 52.4604541],
						[13.7029858, 52.4611196],
						[13.7028097, 52.46227],
						[13.702564, 52.4633169],
						[13.7021044, 52.4643668],
						[13.7019815, 52.4645182],
						[13.7011768, 52.4655337],
						[13.7005746, 52.466297],
						[13.7001095, 52.4669193],
						[13.6996243, 52.4675666],
						[13.6993374, 52.4679407],
						[13.6990878, 52.4682756],
						[13.7000956, 52.4682528],
						[13.7012674, 52.4682236],
						[13.7016656, 52.4678007],
						[13.7019096, 52.4675439],
						[13.7024621, 52.4672068],
						[13.7037662, 52.4665125],
						[13.7048279, 52.4660154],
						[13.7057189, 52.4655535],
						[13.7066655, 52.4651666],
						[13.7079109, 52.4647093],
						[13.7090723, 52.4642576],
						[13.7115166, 52.4632916],
						[13.71157, 52.4632833],
						[13.7122227, 52.4631768],
						[13.7133559, 52.4630002],
						[13.7138089, 52.4630377],
						[13.7146879, 52.4630326],
						[13.71597, 52.4629154],
						[13.7161928, 52.4624887],
						[13.7166592, 52.4618406],
						[13.7174051, 52.4608286],
						[13.7189293, 52.4589138],
						[13.7196523, 52.4579456],
						[13.7205872, 52.4567554],
						[13.7206382, 52.4567144],
						[13.7271555, 52.4521377],
						[13.7290727, 52.4507874],
						[13.7314428, 52.4505126],
						[13.7317179, 52.4504539],
						[13.7327234, 52.4503645],
						[13.7334269, 52.4505446],
						[13.7343489, 52.4507338],
						[13.7348193, 52.4506708],
						[13.7355665, 52.450598],
						[13.7356009, 52.4502492],
						[13.7364655, 52.4499148],
						[13.737151, 52.4497908],
						[13.7377221, 52.449652],
						[13.7407017, 52.4493207],
						[13.7415517, 52.4491894],
						[13.7424346, 52.4490337],
						[13.743302, 52.4489348],
						[13.7447888, 52.4492338],
						[13.7455341, 52.4490831],
						[13.7464588, 52.4489395],
						[13.7481831, 52.4487588],
						[13.7492512, 52.4486702],
						[13.749632, 52.4485554],
						[13.7498895, 52.4484663],
						[13.7502404, 52.4483313],
						[13.7526159, 52.4478705],
						[13.7532839, 52.4476679],
						[13.7539824, 52.4472936],
						[13.755032, 52.4465368],
						[13.7553944, 52.4460026],
						[13.7564442, 52.4461753],
						[13.756017, 52.4455969],
						[13.7556043, 52.4450208],
						[13.7543794, 52.4432795],
						[13.754527, 52.4432498],
						[13.7546158, 52.4432323],
						[13.7570845, 52.4429615],
						[13.7568708, 52.4424734],
						[13.7565332, 52.4416135],
						[13.7559084, 52.4400262],
						[13.755333, 52.4384158],
						[13.7609356, 52.43788],
						[13.7611541, 52.4377047],
						[13.760993, 52.4373633],
						[13.7607664, 52.4371125],
						[13.7605901, 52.4369475],
						[13.7604381, 52.4367747],
						[13.7602889, 52.4366065],
						[13.7599875, 52.4361671],
						[13.7590862, 52.4362443],
						[13.7574651, 52.4364004],
						[13.756094, 52.4365364],
						[13.75445, 52.4366808],
						[13.7563115, 52.4416104],
						[13.7533995, 52.4415474],
						[13.7504957, 52.4414823],
						[13.7461786, 52.4392536],
						[13.7440739, 52.4381437],
						[13.743651, 52.4379187],
						[13.7431981, 52.4359769],
						[13.7429292, 52.434832],
						[13.7428762, 52.4345991],
						[13.7431082, 52.4344351],
						[13.7428313, 52.4331502],
						[13.7427823, 52.4329282],
						[13.7404096, 52.4330432],
						[13.7383089, 52.4332952],
						[13.738195, 52.4330512],
						[13.738115, 52.4328792],
						[13.7375511, 52.4329472],
						[13.7327528, 52.4335311],
						[13.73109, 52.433867],
						[13.7303351, 52.434065],
						[13.7288363, 52.4344999],
						[13.7267796, 52.4352598],
						[13.72447, 52.4363997],
						[13.724105, 52.4365797],
						[13.7235511, 52.4367086],
						[13.7230662, 52.4370566],
						[13.7230482, 52.4369306],
						[13.7230172, 52.4367206],
						[13.7232181, 52.4366366],
						[13.724075, 52.4362707],
						[13.7260427, 52.4352978],
						[13.7264887, 52.4351028],
						[13.7265617, 52.4350758],
						[13.7269886, 52.4348899],
						[13.7278405, 52.4345839],
						[13.7298952, 52.433924],
						[13.731058, 52.433642],
						[13.7322219, 52.4334051],
						[13.7328488, 52.4333211],
						[13.7354304, 52.4329482],
						[13.7374991, 52.4326732],
						[13.738023, 52.4326042],
						[13.7400567, 52.4323333],
						[13.7399587, 52.4319063],
						[13.7398478, 52.4314104],
						[13.7394848, 52.4298276],
						[13.7389709, 52.4289576],
						[13.7415946, 52.4283737],
						[13.7415216, 52.4281828],
						[13.7415935, 52.4279806],
						[13.7412286, 52.4273938],
						[13.7409687, 52.4265389],
						[13.7408607, 52.4264929],
						[13.7394629, 52.4265579],
						[13.7376562, 52.4267379],
						[13.7374182, 52.4265089],
						[13.7336599, 52.4228233],
						[13.7334219, 52.4226023],
						[13.7317252, 52.4210225],
						[13.7315113, 52.4208245],
						[13.7312683, 52.4205995],
						[13.7309574, 52.4203095],
						[13.7311684, 52.4198866],
						[13.7311814, 52.4197781],
						[13.7311904, 52.4196451],
						[13.7311954, 52.4192332],
						[13.7310824, 52.4188362],
						[13.7304705, 52.4181153],
						[13.7300466, 52.4173904],
						[13.7298236, 52.4169174],
						[13.7297307, 52.4167114],
						[13.7296247, 52.4165015],
						[13.7295967, 52.4161775],
						[13.7296167, 52.4159865],
						[13.7296577, 52.4158185],
						[13.7298087, 52.4155476],
						[13.7306706, 52.4146667],
						[13.7309485, 52.4144797],
						[13.7310435, 52.4143237],
						[13.7315695, 52.4137398],
						[13.7323104, 52.4129959],
						[13.7326903, 52.4126149],
						[13.7333072, 52.412046],
						[13.7335792, 52.411909],
						[13.7337782, 52.411771],
						[13.7339052, 52.4114891],
						[13.7340221, 52.4111301],
						[13.7341331, 52.4109631],
						[13.7343561, 52.4108291],
						[13.735059, 52.4105432],
						[13.7357989, 52.4102992],
						[13.7363268, 52.4101232],
						[13.7364648, 52.4100473],
						[13.7366498, 52.4099483],
						[13.7371537, 52.4094673],
						[13.7374547, 52.4090974],
						[13.7376857, 52.4085514],
						[13.7382486, 52.4078915],
						[13.738881, 52.4074038],
						[13.7386562, 52.4072438],
						[13.7384342, 52.4069986],
						[13.7377037, 52.4067148],
						[13.7369825, 52.4065136],
						[13.736314, 52.4063585],
						[13.7359217, 52.4062342],
						[13.7355581, 52.4061159],
						[13.735385, 52.4059606],
						[13.734899, 52.4050897],
						[13.7347494, 52.404693],
						[13.7344785, 52.4039518],
						[13.7343229, 52.4036494],
						[13.7342204, 52.4032599],
						[13.734243, 52.4026603],
						[13.7342258, 52.4023658],
						[13.7343257, 52.4021318],
						[13.7343339, 52.4020499],
						[13.7340884, 52.4021389],
						[13.7340242, 52.4021338],
						[13.7339373, 52.4019785],
						[13.7338394, 52.4018949],
						[13.7334121, 52.4018236],
						[13.7332808, 52.401764],
						[13.7332415, 52.4017025],
						[13.7333657, 52.4015771],
						[13.7333585, 52.4015744],
						[13.733157, 52.4013594],
						[13.7329668, 52.4012281],
						[13.7326174, 52.4010201],
						[13.7320668, 52.4007218],
						[13.7316699, 52.4005292],
						[13.7315602, 52.4001691],
						[13.7302554, 52.3996528],
						[13.7300907, 52.399653],
						[13.729901, 52.3996805],
						[13.7295915, 52.3998532],
						[13.7295666, 52.3999386],
						[13.7295752, 52.4000257],
						[13.7294667, 52.400188],
						[13.7294053, 52.4002119],
						[13.7292013, 52.4001216],
						[13.7291259, 52.4000995],
						[13.7290589, 52.4001012],
						[13.7289835, 52.4000859],
						[13.7289444, 52.4000518],
						[13.7290613, 52.3999066],
						[13.7290164, 52.3998178],
						[13.7288823, 52.3997667],
						[13.7282155, 52.3999005],
						[13.7280761, 52.399974],
						[13.7276271, 52.4001844],
						[13.7275965, 52.4002271],
						[13.7275212, 52.4002306],
						[13.7273284, 52.400183],
						[13.7272414, 52.3999543],
						[13.7270764, 52.3998263],
						[13.7263473, 52.3995777],
						[13.7258168, 52.3995286],
						[13.7256104, 52.3995715],
						[13.7254404, 52.399698],
						[13.7253479, 52.3995546],
						[13.7250404, 52.3993602],
						[13.7249509, 52.3992442],
						[13.7248975, 52.3990889],
						[13.7246457, 52.3988432],
						[13.7244196, 52.3988144],
						[13.7240899, 52.3986712],
						[13.7236848, 52.3985452],
						[13.7234053, 52.3983422],
						[13.7231707, 52.3982929],
						[13.7229446, 52.3982965],
						[13.7228664, 52.3982795],
						[13.7227378, 52.398184],
						[13.7202717, 52.3989443],
						[13.7199149, 52.3990502],
						[13.7192835, 52.3988287],
						[13.7188116, 52.3987437],
						[13.7186274, 52.3987285],
						[13.7181139, 52.3987818],
						[13.7175894, 52.3989154],
						[13.7174361, 52.3990436],
						[13.7169823, 52.399633],
						[13.7167536, 52.3997271],
						[13.7158352, 52.3997056],
						[13.7151449, 52.399281],
						[13.7144467, 52.39908],
						[13.7141197, 52.3989044],
						[13.7139937, 52.398703],
						[13.7140327, 52.3986552],
						[13.7138003, 52.3982848],
						[13.7137863, 52.3982404],
						[13.7136911, 52.3981176],
						[13.7135793, 52.3980272],
						[13.7133809, 52.3979334],
						[13.7128782, 52.3977989],
						[13.7126884, 52.3977973],
						[13.7120767, 52.3975536],
						[13.7117719, 52.3972823],
						[13.7114116, 52.3971631],
						[13.7110173, 52.3967826],
						[13.7107894, 52.3965952],
						[13.7100836, 52.3961153],
						[13.7097557, 52.3962878],
						[13.7097022, 52.3962371],
						[13.7096307, 52.3961601],
						[13.7093068, 52.395853],
						[13.7091661, 52.3956116],
						[13.7087384, 52.3953723],
						[13.7078739, 52.3951311],
						[13.7075859, 52.3951463],
						[13.7071937, 52.3951972],
						[13.7065592, 52.3950757],
						[13.7062216, 52.3947873],
						[13.7054481, 52.3940249],
						[13.7045371, 52.3934838],
						[13.7035592, 52.3932463],
						[13.7033448, 52.3932446],
						[13.7025575, 52.3933613],
						[13.7024686, 52.3933351],
						[13.7020024, 52.3930843],
						[13.7018583, 52.3930075],
						[13.7014131, 52.392466],
						[13.7012104, 52.3922019],
						[13.7007961, 52.3918347],
						[13.7003818, 52.3913832],
						[13.7001853, 52.3911434],
						[13.69994, 52.3910573],
						[13.6996763, 52.3909488],
						[13.6995537, 52.3908907],
						[13.6992746, 52.3907316],
						[13.6987719, 52.3905819],
						[13.6985392, 52.3906983],
						[13.6985153, 52.3910976],
						[13.698626, 52.3913318],
						[13.6988123, 52.3914011],
						[13.6990374, 52.3917642],
						[13.6990317, 52.3918798],
						[13.6987599, 52.3918601],
						[13.6983019, 52.3917213],
						[13.6980771, 52.3915299],
						[13.6980532, 52.3913582],
						[13.6980561, 52.3913564],
						[13.6978255, 52.3912246],
						[13.6977455, 52.3910494],
						[13.6979786, 52.3908939],
						[13.6976501, 52.3904803],
						[13.697685, 52.3901496],
						[13.6976197, 52.3899833],
						[13.6975162, 52.3898515],
						[13.6972561, 52.3897324],
						[13.6968396, 52.3896839],
						[13.6965504, 52.3894431],
						[13.6963927, 52.3893118],
						[13.6962303, 52.3891566],
						[13.6959672, 52.3889724],
						[13.6954325, 52.3888788],
						[13.694643, 52.3882847],
						[13.6941491, 52.3878405],
						[13.6937441, 52.3876221],
						[13.6934811, 52.3874596],
						[13.6931972, 52.387223],
						[13.6930079, 52.3870461],
						[13.6928808, 52.3869793],
						[13.6927683, 52.3868023],
						[13.6925969, 52.3867048],
						[13.6924965, 52.3866868],
						[13.6923309, 52.3866055],
						[13.6921743, 52.3864954],
						[13.6921092, 52.3864159],
						[13.6916657, 52.3861162],
						[13.6913554, 52.3859194],
						[13.690909, 52.3856468],
						[13.690516, 52.3854681],
						[13.6900935, 52.3853093],
						[13.6897361, 52.3852626],
						[13.6894319, 52.3852465],
						[13.6893048, 52.3852032],
						[13.6891336, 52.3852376],
						[13.6890833, 52.3852105],
						[13.6889358, 52.3852847],
						[13.6889063, 52.385328],
						[13.6886909, 52.3854528],
						[13.6885345, 52.3855216],
						[13.688452, 52.3856264],
						[13.6882987, 52.3858452],
						[13.6882367, 52.3858741],
						[13.6880303, 52.386073],
						[13.6877459, 52.3854859],
						[13.687589, 52.3851769],
						[13.6868154, 52.3853056],
						[13.6866408, 52.385022],
						[13.6865755, 52.3847926],
						[13.6861226, 52.3839977],
						[13.6861786, 52.3838622],
						[13.6870527, 52.3838816],
						[13.6873148, 52.3833195],
						[13.6879182, 52.3829569],
						[13.689728, 52.3832502],
						[13.692552, 52.3820208],
						[13.6933553, 52.381687],
						[13.6934071, 52.3816646],
						[13.6943464, 52.3812603],
						[13.6951722, 52.3809579],
						[13.6962275, 52.3811501],
						[13.6983008, 52.3814836],
						[13.6988373, 52.3814992],
						[13.6988161, 52.3811314],
						[13.698506, 52.3806715],
						[13.6981176, 52.3799593],
						[13.697209, 52.3774401],
						[13.6993201, 52.3776874],
						[13.6994248, 52.3775125],
						[13.6999986, 52.3775142],
						[13.7004189, 52.377509],
						[13.7005905, 52.3772272],
						[13.7006144, 52.3771562],
						[13.7002647, 52.3762722],
						[13.7001362, 52.375993],
						[13.6999754, 52.3755988],
						[13.6992219, 52.3748987],
						[13.69918, 52.3747153],
						[13.6989029, 52.3744925],
						[13.6987078, 52.3741153],
						[13.6986502, 52.3740885],
						[13.6979861, 52.3734148],
						[13.6974047, 52.3730747],
						[13.6970449, 52.37254],
						[13.6966061, 52.3722267],
						[13.6963345, 52.3720106],
						[13.6956923, 52.37131],
						[13.6950859, 52.3706899],
						[13.6948611, 52.3705643],
						[13.694562, 52.3702945],
						[13.6939478, 52.3699628],
						[13.6937174, 52.3697466],
						[13.6932459, 52.3695255],
						[13.6928454, 52.3691736],
						[13.6927026, 52.368949],
						[13.6926859, 52.3687981],
						[13.6927022, 52.3687126],
						[13.6928005, 52.3685197],
						[13.6929756, 52.3683284],
						[13.6929142, 52.3680412],
						[13.6925093, 52.3679698],
						[13.6920354, 52.3679382],
						[13.6912845, 52.367776],
						[13.6913172, 52.3676116],
						[13.6914348, 52.3674891],
						[13.6915525, 52.3674438],
						[13.6921221, 52.3673311],
						[13.6922563, 52.3672573],
						[13.6922178, 52.3672104],
						[13.6920233, 52.3671903],
						[13.6919411, 52.3671921],
						[13.6918946, 52.3672407],
						[13.6914783, 52.3672996],
						[13.6914264, 52.367402],
						[13.690961, 52.3676101],
						[13.6905969, 52.3677378],
						[13.690323, 52.36781],
						[13.6899916, 52.3678572],
						[13.6897012, 52.3678171],
						[13.6892575, 52.3678492],
						[13.6895503, 52.3676579],
						[13.6892954, 52.3675305],
						[13.6889199, 52.3674117],
						[13.6881172, 52.3673517],
						[13.6872377, 52.3673069],
						[13.686685, 52.3677465],
						[13.685382, 52.3685186],
						[13.6848098, 52.3687939],
						[13.6828575, 52.3695075],
						[13.6821646, 52.3696403],
						[13.6814138, 52.3695501],
						[13.6812823, 52.3695837],
						[13.6811426, 52.3695586],
						[13.681022, 52.3694698],
						[13.6808001, 52.3694866],
						[13.6806686, 52.3695035],
						[13.6804878, 52.3694868],
						[13.6801452, 52.3694266],
						[13.6800028, 52.3694149],
						[13.6799781, 52.369435],
						[13.6796549, 52.3694402],
						[13.6794932, 52.3694352],
						[13.6793727, 52.3694554],
						[13.6792056, 52.3694471],
						[13.6790822, 52.3693616],
						[13.6788986, 52.3693248],
						[13.6784136, 52.3691959],
						[13.6776707, 52.3687401],
						[13.6774897, 52.3686178],
						[13.6774075, 52.3685675],
						[13.6773554, 52.3685692],
						[13.677106, 52.3684503],
						[13.6770785, 52.3683966],
						[13.6769909, 52.3683916],
						[13.6769114, 52.3683531],
						[13.676747, 52.368318],
						[13.6765742, 52.3681755],
						[13.676429, 52.3681303],
						[13.6761411, 52.3679577],
						[13.675889, 52.367884],
						[13.6754889, 52.3677198],
						[13.6753957, 52.3676696],
						[13.6750203, 52.3675574],
						[13.6749271, 52.3675205],
						[13.6748449, 52.3675189],
						[13.6747243, 52.367482],
						[13.6746531, 52.3674636],
						[13.6746229, 52.3674385],
						[13.6744777, 52.3673681],
						[13.67439, 52.3673447],
						[13.6741845, 52.367328],
						[13.6741077, 52.367276],
						[13.6739899, 52.3672392],
						[13.6739077, 52.3672376],
						[13.6738138, 52.367222],
						[13.6718258, 52.3667929],
						[13.6715095, 52.3666563],
						[13.6711913, 52.3664872],
						[13.6709147, 52.3662382],
						[13.6707346, 52.3659416],
						[13.6704311, 52.365309],
						[13.6700236, 52.3647889],
						[13.669927, 52.364702],
						[13.6695445, 52.3646095],
						[13.6694612, 52.3646049],
						[13.6693722, 52.3645724],
						[13.6689122, 52.3644753],
						[13.6685106, 52.3642332],
						[13.6682833, 52.364041],
						[13.6681696, 52.3639193],
						[13.667785, 52.3635487],
						[13.6671883, 52.3629857],
						[13.6670177, 52.3627679],
						[13.6668624, 52.3626115],
						[13.6667695, 52.3624795],
						[13.6666653, 52.3623625],
						[13.6664719, 52.361935],
						[13.6663561, 52.3616256],
						[13.6663161, 52.3612757],
						[13.6662761, 52.3610277],
						[13.666204, 52.3607971],
						[13.666149, 52.3607068],
						[13.666164, 52.3605133],
						[13.6660255, 52.360219],
						[13.6658189, 52.359839],
						[13.6657525, 52.3596351],
						[13.6657844, 52.3593801],
						[13.6658391, 52.3591101],
						[13.6659316, 52.3588552],
						[13.6660279, 52.3585874],
						[13.6661261, 52.3583254],
						[13.6662065, 52.3581895],
						[13.6662027, 52.3581184],
						[13.6658794, 52.3581215],
						[13.6655366, 52.3581791],
						[13.665196, 52.3581749],
						[13.6649095, 52.3581304],
						[13.6645759, 52.3579853],
						[13.6644419, 52.3579106],
						[13.6641694, 52.3577841],
						[13.6631589, 52.3570655],
						[13.6630085, 52.3569102],
						[13.6628132, 52.3565364],
						[13.6627003, 52.3562661],
						[13.6624393, 52.3558593],
						[13.6623828, 52.3557083],
						[13.6624037, 52.355461],
						[13.6624341, 52.3552927],
						[13.6625235, 52.3551797],
						[13.6626482, 52.3550813],
						[13.66222, 52.3548168],
						[13.6621145, 52.3546478],
						[13.6621241, 52.3545798],
						[13.6621508, 52.354447],
						[13.6620246, 52.3542144],
						[13.6618626, 52.3541857],
						[13.6615995, 52.3540592],
						[13.6610639, 52.353894],
						[13.6608947, 52.3537747],
						[13.6601219, 52.3535161],
						[13.6599716, 52.353496],
						[13.6595723, 52.3533092],
						[13.6589637, 52.3528118],
						[13.6591702, 52.3526464],
						[13.6591326, 52.3525415],
						[13.6589792, 52.3523571],
						[13.6584278, 52.3521405],
						[13.6578994, 52.35205],
						[13.6575, 52.3519114],
						[13.6571406, 52.3516663],
						[13.6570805, 52.3516007],
						[13.656976, 52.3513126],
						[13.6568723, 52.3507561],
						[13.6568323, 52.3506138],
						[13.6567593, 52.349935],
						[13.6567757, 52.3496748],
						[13.6565602, 52.3492221],
						[13.6562067, 52.3487761],
						[13.6560079, 52.3484822],
						[13.6559525, 52.3481815],
						[13.6561621, 52.3479922],
						[13.6561178, 52.3478165],
						[13.6555657, 52.3472085],
						[13.6548757, 52.346793],
						[13.6545224, 52.3465194],
						[13.6542299, 52.3462796],
						[13.6536835, 52.3459384],
						[13.6536117, 52.3458675],
						[13.6534847, 52.3457222],
						[13.6533025, 52.3455601],
						[13.6531424, 52.3453],
						[13.6527945, 52.3448388],
						[13.6525128, 52.3443084],
						[13.6522911, 52.3438476],
						[13.6518586, 52.3433928],
						[13.6516019, 52.3430972],
						[13.6511462, 52.3426071],
						[13.6516508, 52.3397218],
						[13.6516963, 52.3394629],
						[13.6509493, 52.3389501],
						[13.6506923, 52.3388515],
						[13.6501056, 52.3387162],
						[13.6488434, 52.338348],
						[13.6487205, 52.3383627],
						[13.6484305, 52.3382763],
						[13.6477558, 52.3382449],
						[13.6474464, 52.3382725],
						[13.6459201, 52.3386342],
						[13.6455311, 52.3387797],
						[13.6452444, 52.3391402],
						[13.6447567, 52.3396179],
						[13.6445047, 52.3399147],
						[13.6428165, 52.3399413],
						[13.640857, 52.3418863],
						[13.638712, 52.3431862],
						[13.6371731, 52.3447301],
						[13.6368259, 52.3452308],
						[13.6363196, 52.3468193],
						[13.6367058, 52.348147],
						[13.6377965, 52.3482126],
						[13.6381413, 52.3570977],
						[13.6382664, 52.3572803],
						[13.638306, 52.3577527],
						[13.6385658, 52.3581683],
						[13.6387247, 52.3587511],
						[13.6384658, 52.3591636],
						[13.6384365, 52.3597365],
						[13.638711, 52.3602449],
						[13.63899, 52.3606488],
						[13.6390837, 52.3609622],
						[13.6398594, 52.3616949],
						[13.6402163, 52.36191],
						[13.6411507, 52.3630955],
						[13.6437478, 52.3642671],
						[13.6447264, 52.3648014],
						[13.6460993, 52.3652595],
						[13.646683, 52.3657509],
						[13.6469443, 52.3660707],
						[13.6471965, 52.367059],
						[13.6472688, 52.3680262],
						[13.6469643, 52.3687011],
						[13.6468709, 52.3689538],
						[13.6467454, 52.369576],
						[13.6468304, 52.3701544],
						[13.643552, 52.3704647],
						[13.6421026, 52.37081],
						[13.6425398, 52.3714274],
						[13.642832, 52.3725635],
						[13.6428473, 52.3738972],
						[13.642682, 52.3775092],
						[13.6332327, 52.3762426],
						[13.6285143, 52.3813573],
						[13.6194577, 52.3781638],
						[13.6092937, 52.3746391],
						[13.6075285, 52.3740231],
						[13.6060958, 52.3736972],
						[13.6057984, 52.3736103],
						[13.6066173, 52.3750148],
						[13.6067646, 52.375424],
						[13.606802, 52.3760339],
						[13.6065068, 52.37677],
						[13.6056888, 52.3776833],
						[13.6054759, 52.3779645],
						[13.6063277, 52.3786052],
						[13.606385, 52.3791258],
						[13.6056637, 52.3801089],
						[13.6053197, 52.3804696],
						[13.6048827, 52.380916],
						[13.6044937, 52.38121],
						[13.6037288, 52.38165],
						[13.6034448, 52.3819975],
						[13.6032238, 52.3823355],
						[13.6030169, 52.3828402],
						[13.6019017, 52.3835777],
						[13.601739, 52.3837055],
						[13.6013892, 52.383939],
						[13.6008245, 52.3841121],
						[13.6004922, 52.3843091],
						[13.6003262, 52.3845088],
						[13.5997621, 52.3849752],
						[13.5995461, 52.3852759],
						[13.5993946, 52.3854066],
						[13.5992651, 52.3854234],
						[13.5990064, 52.385404],
						[13.5982607, 52.3855171],
						[13.5970374, 52.3857608],
						[13.5961291, 52.3859838],
						[13.594955, 52.3872377],
						[13.5949144, 52.387559],
						[13.5952503, 52.3881545],
						[13.5952768, 52.3884926],
						[13.5951943, 52.3887551],
						[13.5950112, 52.3889519],
						[13.5946741, 52.3889821],
						[13.5943278, 52.3889235],
						[13.5940484, 52.3895308],
						[13.5938532, 52.3897015],
						[13.5936711, 52.3899604],
						[13.5936543, 52.3902278],
						[13.5936997, 52.3904897],
						[13.5938705, 52.3908682],
						[13.5939023, 52.3912021],
						[13.5939934, 52.3916323],
						[13.5942369, 52.3921192],
						[13.5941642, 52.3924405],
						[13.5941089, 52.3925354],
						[13.5938172, 52.3927942],
						[13.5933476, 52.3932842],
						[13.5927502, 52.3938303],
						[13.5927049, 52.3938174],
						[13.5904811, 52.3933175],
						[13.5880027, 52.3925479],
						[13.5822636, 52.39134],
						[13.5807131, 52.3910637],
						[13.5759793, 52.3902844],
						[13.5743887, 52.3901191],
						[13.5707974, 52.3893474],
						[13.5668923, 52.3886074],
						[13.5658158, 52.3884572],
						[13.5642134, 52.3881399],
						[13.5633512, 52.3881301],
						[13.5615167, 52.3881563],
						[13.5586653, 52.3882356],
						[13.5539867, 52.3883906],
						[13.5466996, 52.3886879],
						[13.5354996, 52.3889902],
						[13.5348344, 52.3907526],
						[13.5348604, 52.3915387],
						[13.5351662, 52.393521],
						[13.5353719, 52.3939958],
						[13.5356567, 52.3949922],
						[13.5358384, 52.3955562],
						[13.5366931, 52.3972162],
						[13.5363047, 52.397839],
						[13.5372184, 52.3980389],
						[13.5370852, 52.3982566],
						[13.5373611, 52.3987557],
						[13.5382053, 52.3999605],
						[13.5384298, 52.400603],
						[13.5383798, 52.4006028],
						[13.537045, 52.3999832],
						[13.5370033, 52.3999847],
						[13.5360281, 52.3996089],
						[13.5351948, 52.3992973],
						[13.5337295, 52.3988874],
						[13.5314436, 52.397976],
						[13.531389, 52.3979601],
						[13.5297109, 52.3973028],
						[13.5263976, 52.3989391],
						[13.5245757, 52.3993822],
						[13.524015, 52.399605],
						[13.5234776, 52.3998005],
						[13.522789, 52.4000717],
						[13.5217874, 52.400578],
						[13.5185924, 52.4011009],
						[13.5172029, 52.4015374],
						[13.5168374, 52.4016153],
						[13.5160508, 52.4017829],
						[13.516031, 52.4021738],
						[13.5170398, 52.4028345],
						[13.5167171, 52.4033352],
						[13.5170533, 52.4035398],
						[13.5170219, 52.4035997],
						[13.5187597, 52.4046873],
						[13.5192807, 52.4049456],
						[13.5189773, 52.4051428],
						[13.5205664, 52.406248],
						[13.5207283, 52.4063266],
						[13.5206595, 52.4064015],
						[13.5213011, 52.4068993],
						[13.5217104, 52.4072555],
						[13.5218424, 52.4074056],
						[13.5221196, 52.4079129],
						[13.5224541, 52.4086087],
						[13.5228486, 52.4096301],
						[13.5232964, 52.4107912],
						[13.5231821, 52.4107878],
						[13.5228726, 52.4110416],
						[13.5234288, 52.4116683],
						[13.522957, 52.4121338],
						[13.5233979, 52.4124309],
						[13.5231626, 52.4126393],
						[13.5235647, 52.4131969],
						[13.5236575, 52.4131714],
						[13.5241327, 52.4135961],
						[13.5226983, 52.4141462],
						[13.5226438, 52.4143357],
						[13.5223308, 52.4144405],
						[13.5223985, 52.4145322],
						[13.5223041, 52.4145543],
						[13.5229117, 52.4154488],
						[13.5223656, 52.4164192],
						[13.5221994, 52.4168639],
						[13.5220934, 52.4171945],
						[13.5222007, 52.4171988],
						[13.5221928, 52.4177866],
						[13.5222198, 52.4183107],
						[13.5221001, 52.4183029],
						[13.5220515, 52.4184343],
						[13.5207856, 52.4195381],
						[13.5206018, 52.4201525],
						[13.5204508, 52.4208148],
						[13.5201348, 52.4210147],
						[13.519908, 52.421304],
						[13.519466, 52.4213921],
						[13.5191921, 52.4216506],
						[13.5192771, 52.4217022],
						[13.518875, 52.4221344],
						[13.5187184, 52.4222512],
						[13.5199375, 52.4251446],
						[13.5201749, 52.426027],
						[13.5203661, 52.4260501],
						[13.5204662, 52.4262432],
						[13.5205057, 52.4265574],
						[13.5204517, 52.4265675],
						[13.520432, 52.4265915],
						[13.5202823, 52.4266203],
						[13.5199511, 52.4267279],
						[13.5197724, 52.4267526],
						[13.5156786, 52.4270097],
						[13.5140917, 52.4272022],
						[13.5136811, 52.4272668],
						[13.5116809, 52.4276593],
						[13.5107558, 52.4278708],
						[13.5097524, 52.4281683],
						[13.5085549, 52.4285653],
						[13.5084702, 52.4285007],
						[13.5072583, 52.4289565],
						[13.5007655, 52.4321017],
						[13.497976, 52.43341],
						[13.4931866, 52.435702],
						[13.4882342, 52.4381717],
						[13.4842104, 52.4400502],
						[13.480441, 52.4418715],
						[13.478685, 52.4427362],
						[13.4786129, 52.442772],
						[13.478547, 52.4428037],
						[13.4784971, 52.4428034],
						[13.4784027, 52.4428671],
						[13.4782747, 52.4430063],
						[13.4781606, 52.443104],
						[13.4776699, 52.4433511],
						[13.4766797, 52.4438326],
						[13.4765395, 52.4438817],
						[13.4762727, 52.4439386],
						[13.476163, 52.4439753],
						[13.4751364, 52.444469],
						[13.4742022, 52.4449198],
						[13.4700555, 52.4469305],
						[13.4649915, 52.4494729],
						[13.4639358, 52.4502418],
						[13.4630174, 52.4511311],
						[13.4622192, 52.4520569],
						[13.4608012, 52.4541031],
						[13.4600906, 52.4551064],
						[13.4592323, 52.4563616],
						[13.4577131, 52.4581556],
						[13.4569705, 52.459118],
						[13.4569992, 52.4592033],
						[13.4571139, 52.4592539],
						[13.457234, 52.4592582],
						[13.457264, 52.4592087],
						[13.4573529, 52.4592357],
						[13.459492, 52.4591786],
						[13.4637041, 52.4590656],
						[13.4715476, 52.4588624],
						[13.4722406, 52.4588451],
						[13.4744335, 52.4587783],
						[13.4749713, 52.4587633],
						[13.4749512, 52.4591937],
						[13.4749768, 52.4594705],
						[13.4751825, 52.4599101],
						[13.4754454, 52.4603268],
						[13.475804, 52.4609152],
						[13.4766429, 52.4615619],
						[13.4771968, 52.4619892],
						[13.4776765, 52.4626972],
						[13.4781339, 52.4633695],
						[13.4785501, 52.4639858],
						[13.4784771, 52.4649879],
						[13.4784173, 52.4651331],
						[13.4783366, 52.4652375],
						[13.477629, 52.4659105],
						[13.4769546, 52.4665545],
						[13.4763569, 52.4671266],
						[13.4761859, 52.4672635],
						[13.4755979, 52.4676555],
						[13.4751541, 52.4680203],
						[13.4740125, 52.4691207],
						[13.4729597, 52.4701325],
						[13.4728854, 52.4702003],
						[13.4726717, 52.4704044],
						[13.4719926, 52.4710576],
						[13.471289, 52.4717381],
						[13.4707617, 52.4722546],
						[13.4700471, 52.473026],
						[13.4697915, 52.4733394],
						[13.4695973, 52.4737287],
						[13.4695889, 52.473804],
						[13.4696043, 52.4739922],
						[13.4697902, 52.4743987],
						[13.4699678, 52.4748049],
						[13.47038, 52.4760111],
						[13.4705734, 52.4769091],
						[13.4699438, 52.4773526],
						[13.4690888, 52.4779516],
						[13.4681143, 52.4786409],
						[13.466633, 52.4796839],
						[13.4640048, 52.481536],
						[13.4611547, 52.4835385],
						[13.4611798, 52.4837022],
						[13.4585642, 52.4855156],
						[13.4581365, 52.4858194],
						[13.4571227, 52.4850526],
						[13.4560669, 52.4842532],
						[13.4504433, 52.4871484],
						[13.4503267, 52.4870854],
						[13.4487256, 52.4880276],
						[13.4486583, 52.4879826],
						[13.4467642, 52.4889535],
						[13.4444472, 52.4874576],
						[13.442613, 52.4883799],
						[13.4396363, 52.4899002],
					],
				],
			},
			properties: {},
		},
	},
];
