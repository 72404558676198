import { useTranslation } from "react-i18next";
import { useRef, useEffect, ChangeEvent } from "react";
import { DocumentData } from "firebase/firestore";
import { useGetCurrentUser } from "../../../../../hooks/useGetCurrentUser";
import { handleAddComment } from "../../../../../firebase/comments/handleComments";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { setOpenJoinProjectWindow } from "../../../../../redux/api/userSlice";

interface ICommentInput {
	setComment: (arg: string) => void;
	value: string;
	selectedCard: DocumentData;
	allComments: DocumentData[] | undefined;
	refetch: () => void;
	handleViewModOpen: () => void;
	isEditing?: boolean;
	setIsEditing?: (arg: boolean) => void;
	commentToEditId?: string;
	commentToReplyId?: string;
	isReplying?: boolean;
	setIsReplying?: (arg: boolean) => void;
	reply_Comment_SubmittedBy?: string;
	handleRef?: (arg: HTMLDivElement) => void;
}

const CommentInput = ({
	value,
	setComment,
	selectedCard,
	allComments,
	refetch,
	handleViewModOpen,
	isEditing,
	setIsEditing,
	commentToEditId,
	commentToReplyId,
	isReplying,
	setIsReplying,
	reply_Comment_SubmittedBy,
	handleRef,
}: ICommentInput) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const initialValueRef = useRef(value);
	const textareaRef = useRef<HTMLTextAreaElement>(null);
	const buttonsRef = useRef<HTMLDivElement | null>(null);
	const { user } = useGetCurrentUser();
	const userID = user?.uid;
	const photoURL = user?.photoURL;
	const font = "14px Poppins";

	const hasUserJoinedProject = useAppSelector(
		(state) => state.user.openJoinProjectWindow
	);

	const handleCancel = () => {
		if (isEditing && setIsEditing) setIsEditing(false);
		if (isReplying && setIsReplying) setIsReplying(false);
		setComment("");
	};

	const getTextWidth = (text: string, font: string): number => {
		const canvas = document.createElement("canvas");
		const context = canvas.getContext("2d");
		if (context) {
			context.font = font;
			const metrics = context.measureText(text);
			return metrics.width + 16;
		}
		return 0;
	};

	const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
		const inputValue = e.target.value;
		if (user) {
			setComment(inputValue);
		}
	};

	useEffect(() => {
		initialValueRef.current = value;
	}, []);

	// Adjust the height of the textarea dynamically
	useEffect(() => {
		if (textareaRef.current) {
			textareaRef.current.style.height = "auto";
			textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
		}
	}, [value]);

	return (
		<div ref={buttonsRef} className="mt-[8px] w-full">
			<div className="relative w-full">
				{reply_Comment_SubmittedBy && (
					<span className="absolute top-1/2 left-[16px] Poppins transform -translate-y-[18px] pointer-events-none text-[#94979C] text-[14px]">
						{"@" + reply_Comment_SubmittedBy}
					</span>
				)}
				<textarea
					ref={textareaRef}
					onChange={handleChange}
					onFocus={() =>
						textareaRef.current?.scrollIntoView({ behavior: "smooth" })
					}
					value={value}
					className={`baseline w-full py-[14px] pt-[10px] pr-[16px] rounded-[8px] border-2 border-[#e8e8e8] text-[14px] mb-[8px] placeholder-midGray focus:outline-outlineBlue focus:bg-white focus:placeholder-transparent`}
					style={
						(isReplying || isEditing) && reply_Comment_SubmittedBy
							? {
									paddingLeft: `${
										getTextWidth("@" + reply_Comment_SubmittedBy, font) + 16
									}px`,
									overflow: "hidden",
									resize: "none",
									lineHeight: "1.5",
							  }
							: {
									paddingLeft: "16px",
									overflow: "hidden",
									resize: "none",
									lineHeight: "1.5",
							  }
					}
					placeholder={
						reply_Comment_SubmittedBy
							? ""
							: t("ranking.detailedView.comments.placeholder")
					}
					onClick={() => {
						if (!user) {
							handleViewModOpen();
							textareaRef.current?.blur();
							return;
						}
						if (!hasUserJoinedProject) {
							dispatch(setOpenJoinProjectWindow(true));
							textareaRef.current?.blur();
							return;
						}
					}}
					rows={1}
				/>
			</div>
			{(!!value || isEditing || isReplying) && (
				<div
					className="flex gap-[16px] justify-end items-center"
					ref={handleRef}
				>
					<button
						onClick={handleCancel}
						className="rounded-[30px] py-[8px] px-[18px] border-2 border-[#f0f0f0] w-[105px]"
					>
						Cancel
					</button>
					<button
						onClick={async () => {
							if (userID && photoURL && allComments) {
								await handleAddComment(
									selectedCard,
									allComments,
									value,
									userID,
									[],
									commentToEditId,
									commentToReplyId,
									reply_Comment_SubmittedBy
								);
								handleCancel();
								refetch();
							}
						}}
						disabled={value.length === 0 || initialValueRef.current === value}
						className="rounded-[30px] py-[8px] px-[18px] bg-[#242629] text-white w-[105px] disabled:bg-[#DFE1E5] disabled:text-[#94979C]"
					>
						Post
					</button>
				</div>
			)}
		</div>
	);
};

export default CommentInput;
