import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSlice";
import userReducer from "../api/userSlice";
import locationReducer from "../api/locationSlice";
import spotsReducer from "../api/spotsSlice";
import mapReducer from "../api/mapSlice";
import projectCardsReducer from "../api/projectCardsSlice";
import elementsViewReducer from "../api/elementsViewSlice";

export const store = configureStore({
	reducer: {
		[apiSlice.reducerPath]: apiSlice.reducer,
		user: userReducer,
		map: mapReducer,
		spots: spotsReducer,
		location: locationReducer,
		projectCards: projectCardsReducer,
		elementsView: elementsViewReducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({}).concat([apiSlice.middleware]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
