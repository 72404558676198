import { DocumentData } from "@firebase/firestore";
import {
	selectSpot,
	setHoveredCardId,
	setSelectedCard,
} from "../redux/api/spotsSlice";
import {
	setScrollLevel,
	setSpotCardView,
} from "../redux/api/elementsViewSlice";
import { zoomDetailedView } from "../redux/api/locationSlice";
import useSetQuery from "../hooks/useSetQuery";
import { useAppDispatch } from "../redux/hooks";

const useSelectCreatedSpot = () => {
	// const setQuery = useSetQuery();
	const dispatch = useAppDispatch();

	const onSubmitHandler = (spot: DocumentData) => {
		// setQuery(spot.id);
		// const scrollLevel = getCurrentScroll();
		// dispatch(setSelectedCard(spot));

		dispatch(setSpotCardView(false));
		dispatch(setSelectedCard(null));

		dispatch(selectSpot(spot));
		// dispatch(setHoveredCardId(null));

		dispatch(zoomDetailedView([spot.latitude, spot.longitude]));
		// scrollLevel && dispatch(setScrollLevel(scrollLevel));
	};

	return { onSubmitHandler };
};

export default useSelectCreatedSpot;
