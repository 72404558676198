import AboutSection from "./AboutSection";
import AboutParagraph from "./AboutParagraph";
import {useTranslation} from "react-i18next";
import heart from "../../assets/about/heart.svg";
import {paths} from "../../constants";
import {Link} from "react-router-dom";

const ColumnLeft = () => {
  const {t} = useTranslation();

  return (
    <AboutSection title={t("about.column-left.title")}>
      <AboutParagraph
        icon={heart}
        title={t("about.column-left.section_1.title")}
      >
        <p>{t("about.column-left.section_1.text_1")}</p>
        <p>{t("about.column-left.section_1.text_2")}</p>
      </AboutParagraph>
      <AboutParagraph title={t("about.column-left.section_2.title")}>
        <p>{t("about.column-left.section_2.text_1")}</p>
        <p>{t("about.column-left.section_2.text_2")}</p>
      </AboutParagraph>
      <AboutParagraph title={t("about.column-left.section_3.title")}>
        <p>{t("about.column-left.section_3.text_1")}</p>
        <p>{t("about.column-left.section_3.text_2")}</p>
        <div>
          <p>{t("about.column-left.section_3.text_3")}</p>
          <Link className="text-sm underline" to={paths.qv}>
            {t("about.column-left.bottom-link")}
          </Link>
        </div>
      </AboutParagraph>
    </AboutSection>
  );
};

export default ColumnLeft;
