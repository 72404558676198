import { useState } from "react";
import DetailedViewContainer from "../../../../../shared/DetailedViewContainer";
import DetailedViewTitle from "../../../../../shared/DetailedViewTitle";
import SpotTag from "../../../../../shared/SpotTag";
import ImageFullScreen from "./ImageFullScreen";
import ReportButton from "./ReportButton";
import ShareButton from "./ShareButton";
import EditButton from "./EditButton";
import { DocumentData } from "firebase/firestore";
import { useGetSpotSubmittedByQuery } from "../../../../../redux/api/apiSlice";
import { useGetCurrentUser } from "../../../../../hooks/useGetCurrentUser";
import { avatars } from "../../../../../assets/avatar";

interface Props {
	type: string;
	tags: string[];
	selectedCard: DocumentData;
	section: string;
	mySpotsActiveTab: string;
	ranking: number;
}

const CardInfo = ({
	type,
	tags,
	selectedCard,
	section,
	mySpotsActiveTab,
	ranking,
}: Props) => {
	const [isImageOpened, setIsImageOpened] = useState(false);

	const tagsInTotal = [type, ...(selectedCard.secondaryTags || [])];

	const { user } = useGetCurrentUser();
	const userId = user?.uid;
	const { data: submittedBy } = useGetSpotSubmittedByQuery(
		selectedCard.submitted_by
	);

	const closeImageFull = () => {
		setIsImageOpened(false);
	};

	return (
		<DetailedViewContainer>
			{isImageOpened && (
				<ImageFullScreen
					image={selectedCard.picture}
					closeImageFull={closeImageFull}
				/>
			)}

			<div className="w-[250px] flex flex-col gap-2">
				<DetailedViewTitle>{selectedCard.streetName}</DetailedViewTitle>
				<div className="flex gap-x-4 gap-y-2 items-center flex-wrap">
					{tagsInTotal.map((tag: string) => (
						<SpotTag key={tag} type={tag} />
					))}
				</div>
				<div className="text-sm">{selectedCard.description}</div>
			</div>
			<div>
				<button
					onClick={() => setIsImageOpened(true)}
					className="w-[337px] h-[204px] rounded-[10px] overflow-hidden mb-4 relative"
				>
					<img
						className="w-full h-full object-cover"
						src={selectedCard.picture}
						alt="card"
					/>
					<span className="absolute top-[8px] right-[8px] w-[40px] h-[40px] rounded-full bg-[#000000] text-[20px] font-Poppins flex items-center justify-center text-white">
						{ranking}
					</span>
				</button>
				<div className="flex justify-between">
					{!submittedBy ? (
						"Loading"
					) : (
						<div className="flex gap-2 -translate-x-1">
							<img
								className="w[35px] h-[35px] rounded-full overflow-hidden"
								src={
									submittedBy.photoURL ||
									avatars[
										submittedBy.defaultAvatarIndex as keyof typeof avatars
									]
								}
								alt="avatar"
							/>
							<div className="text-[#A0A0A0] text-sm">
								<p>
									{submittedBy.name} @{submittedBy.username}
								</p>
								<div className="flex gap-1">
									<p>{selectedCard.date},</p>
									<p>{selectedCard.time}</p>
								</div>
							</div>
						</div>
					)}
					<div className="gap-6 flex">
						<ShareButton
							street={selectedCard.streetName}
							image={selectedCard.picture}
						/>
						{userId === selectedCard.submitted_by ? (
							<EditButton selectedCard={selectedCard} />
						) : (
							<ReportButton spotId={selectedCard.id} />
						)}
					</div>
				</div>
			</div>
		</DetailedViewContainer>
	);
};

export default CardInfo;
