import React from "react";
interface Props {
  radio: boolean;
}

const Radio = ({radio}: Props) => {
  return (
    <div>
      <div className="w-[14px] h-[14px] bg-white rounded-full flex justify-center items-center cursor-pointer -translate-y-[1px]">
        <div
          className={`bg-blue-500 w-[10px] h-[10px] rounded-full ${
            !radio ? "hidden" : ""
          }`}
        ></div>
      </div>
    </div>
  );
};

export default Radio;
