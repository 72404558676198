import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Heart } from "../../assets/editProfile/heart.svg";
import { ReactComponent as Favorite } from "../../assets/editProfile/favorite.svg";
import { ReactComponent as Close } from "../../assets/editProfile/closeButton.svg";
import ReactMapGL, { Marker } from "react-map-gl";
import { mapboxAccessToken, mapStyle, paths } from "../../constants";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setViewState } from "../../redux/api/locationSlice";
import ProfileEditButtons from "./ProfileEditButtons";
import { useNavigate } from "react-router";

interface ISpot {
	favoriteSpot: {
		longitude: number;
		latitude: number;
		zoom: number;
	} | null;
	handle: (event: any) => void;
}

const FavoriteSpot = ({ favoriteSpot, handle }: ISpot) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const [mapOpened, setMapOpened] = useState(false);
	const [spot, setSpot] = useState<{
		longitude: number;
		latitude: number;
		zoom: number;
	} | null>(null);

	const viewState = useAppSelector((state) => state.location.viewState);

	const toggleMap = () => {
		setMapOpened(!mapOpened);
	};

	const handleCancel = () => {
		navigate(paths.editProfile);
		setMapOpened(!mapOpened);
	};

	const handleSaveSpot = () => {
		navigate(paths.editProfile);
		setMapOpened(!mapOpened);
		setSpot(spot);
		return;
	};

	useEffect(() => {
		setSpot(favoriteSpot);
	}, [favoriteSpot]);

	return (
		<div className="flex-col">
			<div className="flex-col py-2">{t("editProfile.favSpot")}</div>
			<div
				className="flex bg-cover cursor-pointer items-center justify-center rounded-[8px] overflow-hidden w-full h-[7.5rem]"
				onClick={toggleMap}
			>
				{!spot && (
					<div className="absolute p-2 rounded-[8px] bg-white/70 z-30">
						<Heart className="w-full mx-auto" />
						<div className="w-full pt-2 text-midGray text-sm">
							{t("editProfile.mapText")}
						</div>
					</div>
				)}
				<div className="flex-col w-full h-full cursor-pointer items-center text-center justify-center">
					<ReactMapGL
						{...(spot === null ? { ...viewState } : { ...spot })}
						mapboxAccessToken={mapboxAccessToken}
						mapStyle={mapStyle}
					>
						{spot ? (
							<Marker latitude={spot.latitude} longitude={spot.longitude}>
								<Favorite />
							</Marker>
						) : (
							<></>
						)}
					</ReactMapGL>
				</div>
			</div>
			{mapOpened && (
				<div className="fixed top-0 bottom-0 right-0 left-0 w-screen h-screen border-none bg-black/50 z-50">
					<div
						className="w-[90%] h-[90%] mx-auto top-4 rounded-lg border-none overflow-hidden"
						style={{
							transform: "translate(-50%, -50%)",
							top: "50%",
							left: "50%",
							position: "absolute",
						}}
					>
						<div
							className="absolute z-50 w-[19.25rem] bg-white/70 p-3 rounded-lg text-center"
							style={{
								transform: "translate(-50%, -50%)",
								top: "20%",
								left: "50%",
								position: "absolute",
							}}
						>
							{t("editProfile.mapZoom")}
						</div>
						<Close
							onClick={toggleMap}
							className="absolute z-50 top-3 right-3"
						/>
						<ReactMapGL
							{...viewState}
							onMove={(e) => dispatch(setViewState(e.viewState))}
							mapboxAccessToken={mapboxAccessToken}
							mapStyle={mapStyle}
							onClick={handle}
						>
							{spot?.latitude && spot?.longitude && (
								<Marker
									latitude={spot.latitude}
									longitude={spot.longitude}
									anchor="bottom"
								>
									<Favorite />
								</Marker>
							)}
						</ReactMapGL>
						<ProfileEditButtons
							onSubmit={handleSaveSpot}
							onCancel={handleCancel}
							submitLabel={t("editProfile.save")}
							cancelLabel={t("editProfile.cancel")}
							cancelButtonStyle="rounded-full bg-white text-[#878787] border-[2px] border-[#878787] w-[10rem] h-[3.5rem]"
							submitButtonStyle="text-white rounded-full bg-midGreen py-1"
							position="bottom-[1rem] right-[1rem]"
						/>
					</div>
				</div>
			)}
		</div>
	);
};

export default FavoriteSpot;
