import { useAppSelector } from "../../redux/hooks";
import Activity from "./activity/Activity";
import ChangeSection from "./ChangeSection";
import SpotsMenu from "./spotCards/SpotMenu";
import MySpots from "./spotCards/MySpots";
import Mission from "./spotCards/Mission";

const Section = () => {
	const section = useAppSelector((state) => state.elementsView.section);
	const spotCardView = useAppSelector(
		(state) => state.elementsView.spotCardView
	);
	const sections = {
		Ranking: <SpotsMenu />,
		Activity: <Activity />,
		MySpots: <MySpots />,
		Mission: <Mission />,
	};
	return (
		<div className="w-[690px] h-full">
			{!spotCardView && <ChangeSection />}
			<div className={`${spotCardView ? "h-[100%]" : "h-[95%]"} flex flex-col`}>
				{sections[section]}
			</div>
		</div>
	);
};

export default Section;
