import { collection, DocumentData, getDocs } from "firebase/firestore";
import { db } from "./firebase";
import { Project } from "../interfaces";

export const getProjects = async () => {
	try {
		const projectData: Project[] = [];
		const projectsDocs = await getDocs(collection(db, "projects"));

		projectsDocs.forEach((doc) => {
			const projectDoc = doc.data() as Project;
			projectData.push({ ...projectDoc, id: doc.id });
		});

		if (projectData.length === 0) {
			throw new Error("There are no projects available");
		}

		return projectData;
	} catch (e) {
		console.error(e);
	}
};
