import badParking from "./bad-parking.svg";
import badRoud from "./bad-roud.svg";
import bikesForSale from "./bikes-for-sale.svg";
import carFree from "./car-free.svg";
import chargingStation from "./charging-station.svg";
import construction from "./construction.svg";
import dangerous from "./dangerous.svg";
import freeAir from "./free-air.svg";
import junction from "./junction.svg";
import lessTraffic from "./less-traffic.svg";
import locking from "./locking.svg";
import other from "./other.svg";
import planning from "./planning.svg";
import rental from "./rental.svg";
import roadCondition from "./road-condition.svg";
import traffic from "./traffic.svg";
import tube from "./tube.svg";
import unsafe from "./unsafe.svg";
import repair from "./repair.svg";
import { ITagsIcons } from "../../interfaces";

export const tagsIcons: ITagsIcons = {
  "Bad Parking": badParking,
  Dangerous: dangerous,
  "Bad road": badRoud,
  Construction: construction,
  Traffic: traffic,
  Junction: junction,
  "Feels unsafe": unsafe,
  "Bad Planning": planning,
  Other: other,
  "Less traffic": lessTraffic,
  "Car free": carFree,
  "Road Condition": roadCondition,
  "Safe junction": junction,
  "Bike repairs": repair,
  "Free air": freeAir,
  "Charging station": chargingStation,
  "Bikes for sale": bikesForSale,
  "Rental bikes": rental,
  "Ideal for locking": locking,
  "Inner tube vending machine": tube,
};
