import useDisableScroll from "../../hooks/useDisableScroll";
import useGetViewport from "../../hooks/useGetViewport";
import ChangeLanguage from "../../shared/ChangeLanguage";
import BurgerFooter from "./BurgerFooter";
import BurgerItem from "./BurgerItem";
import useGetBurgerLinks from "../../hooks/useGetBurgerLinks";
import {language} from "../../assets/burgerMenu";

interface Props {
  isVisible: boolean;
}
const BurgerMenu = ({isVisible}: Props) => {
  const isMobile = useGetViewport();
  useDisableScroll(isMobile, isVisible);
  const menuLinks = useGetBurgerLinks();

  return (
    <div
      className={`w-screen md:w-[373px] z-50 h-[calc(100vh-89px)] md:h-auto md:border-2 border-black transition-all duration-500 ${
        isVisible ? "translate-x-0" : "translate-x-full md:translate-x-[600px]"
      } bg-white absolute mt-[46px] -right-10 md:right-0 top-12 rounded-[10px] p-10 flex flex-col justify-between`}
    >
      <div className="flex flex-col gap-y-6 md:mb-4 ">
        {menuLinks.map((menuItem: any) => (
          <BurgerItem
            key={menuItem.link}
            link={menuItem.link}
            text={menuItem.text}
            image={menuItem.img}
          />
        ))}
        <div className="flex gap-4 ">
          <img src={language} alt="language-icon" />
          <ChangeLanguage />
        </div>
      </div>
      <BurgerFooter />
    </div>
  );
};

export default BurgerMenu;
