import {useTranslation} from "react-i18next";
import Section from "../../Section";

const Managing = () => {
  const {t} = useTranslation();
  return (
    <Section title={t("policy.managing.title")}>
      <p>{t("policy.managing.text_1")}</p>
    </Section>
  );
};

export default Managing;
