import { useEffect, useRef, useState } from "react";

const useClickOutside = <T extends HTMLElement>(exceptionItemId?: string) => {
	const [isVisible, setIsVisible] = useState(false);
	const element = useRef<T | null>(null);
	const clickOutSide = (isVisible: boolean) => setIsVisible(isVisible);

	useEffect(() => {
		function handleClickOutside(e: MouseEvent) {
			const target = e.target as HTMLElement;

			const closestElement = target.closest(`#${exceptionItemId}`);

			if (target.id === exceptionItemId || closestElement) return;

			if (element.current && !element.current?.contains(e.target as Node)) {
				setIsVisible(false);
			}
		}
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);
	return { element, isVisible, clickOutSide };
};

export default useClickOutside;
