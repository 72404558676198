interface Props {
	children: React.ReactNode;
}
const DetailedViewContainer = ({ children }: Props) => {
	return (
		<div className="p-6 w-full border-2 border-[#E8E8E8] rounded-xl flex justify-between gap-4">
			{children}
		</div>
	);
};

export default DetailedViewContainer;
