import { Link, useLocation } from "react-router-dom";
import {
	setSelectedProjectLink,
	setSelectedProjectId,
} from "../../redux/api/projectCardsSlice";
import { useAppDispatch } from "../../redux/hooks";

interface Props {
	children: React.ReactNode;
	linkTo: string;
	id: string;
}

const CardWrapper = ({ children, linkTo, id }: Props) => {
	const dispatch = useAppDispatch();

	const handleCardClick = () => {
		dispatch(setSelectedProjectLink(linkTo));
		dispatch(setSelectedProjectId(id));
	};
	const { pathname } = useLocation();

	return (
		<div
			className={`h-[432px] w-[316px] relative shadow-cardShadow rounded-xl `}
		>
			<Link
				onMouseDown={handleCardClick}
				to={linkTo}
				className={`border-[3px] ${
					pathname === linkTo ? "border-[#3a85f4]" : "border-transparent"
				}  absolute top-0 left-0 w-full h-full rounded-xl  hover:border-[#3a85f4]  transition-all duration-150`}
			></Link>
			{children}
		</div>
	);
};

export default CardWrapper;
