import MemberCard from "./MemberCard";
import {
  fullstack,
  brand,
  frontend,
  director,
  data,
  product,
  tech,
  ui,
  you,
} from "../../assets/about/members-parry";
import {useTranslation} from "react-i18next";

const Team = () => {
  const {t} = useTranslation();

  return (
    <div className="w-full hidden lg:block ">
      <div className="text-2xl font-bold">
        <p>Team</p>
      </div>
      <div className="flex gap-4 xl:gap-x-8 items-center xl:pl-16 mb-32 justify-center ">
        <div className="flex flex-col xl:flex-row gap-x-4 gap-y-8 xl:gap-y-8 translate-y-5 xl:translate-y-0">
          <MemberCard
            text="Brand"
            icon={brand}
            name={"Andrea"}
            position={"xl:-translate-y-8"}
          />
          <MemberCard
            text="UI"
            icon={ui}
            name={"Charles"}
            position={"xl:-translate-y-16"}
          />
        </div>
        <div className="flex flex-col gap-y-8 ">
          <MemberCard text="Tech Lead" icon={tech} name={"Denise"} />
          <MemberCard text="Full Stack" icon={fullstack} name={"Edwards"} />
        </div>
        <div className="flex flex-col gap-y-8 translate-y-6">
          <MemberCard text="Product" icon={product} name={"Franziska"} />
          <MemberCard text="Managing Director" icon={director} name={"Max"} />
        </div>
        <div className="flex flex-col gap-y-8 -translate-y-6">
          <MemberCard text="Data Analyst" icon={data} name={"Paul"} />
          <MemberCard text="Frontend" icon={frontend} name={"Volodymyr"} />
        </div>
        <MemberCard
          size={"text-[12px]"}
          personal
          text={t("about.part")}
          icon={you}
          name={t("about.you")}
        />
      </div>
    </div>
  );
};

export default Team;
