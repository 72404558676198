import { useTranslation } from "react-i18next";

import Loader from "../../../shared/Loader";

interface ISubmitPlaceButton {
	category: string;
	district: string;
	isFetching: boolean;
	isLoading: boolean;
	editPlace: boolean;
	secondaryTags: boolean;
	placeUrl: string | null;
	setIsOpen: (arg: boolean) => void;
}

const SubmitPlaceButton = ({
	category,
	district,
	isFetching,
	isLoading,
	editPlace,
	secondaryTags,
	placeUrl,
	setIsOpen,
}: ISubmitPlaceButton) => {
	const { t } = useTranslation();

	return (
		<>
			<button
				type="submit"
				className="relative flex w-full transition-all justify-center bg-[#242629] rounded-[30px] overflow-hidden py-[18px] text-center text-white disabled:bg-[#DFE1E5] disabled:text-[#94979C] hover:bg-[#0A0B0E] hover:text-[#ffffff]"
				disabled={
					!category ||
					(!district && isFetching) ||
					isLoading ||
					isFetching ||
					secondaryTags ||
					!placeUrl
				}
			>
				{isLoading ? (
					<div className="w-[24px] h-[24px]">
						<Loader
							childClassName={"w-[24px] h-[24px] border-[5px] text-[#333536]"}
						/>
					</div>
				) : editPlace ? (
					t("addSpot.update")
				) : (
					t("addSpot.submit")
				)}
			</button>
			{editPlace && (
				<button
					className="group flex w-full gap-[8px] items-center mt-[12px] transition-all justify-center bg-white border-2 border-[#D42124] rounded-[30px] py-[16px] text-center text-[#D42124] disabled:bg-[#DFE1E5] disabled:text-[#94979C] hover:bg-[#D42124] hover:text-[#ffffff]"
					type="button"
					onClick={() => setIsOpen(true)}
				>
					<svg
						className="w-5 h-5 fill-current text-[#D42124] group-hover:text-white transition-colors"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 20 20"
					>
						<path d="M8.98149 9.06279C8.69398 8.7034 8.16956 8.64513 7.81018 8.93264C7.45079 9.22015 7.39252 9.74457 7.68003 10.104L8.93024 11.6667L7.68003 13.2295C7.39252 13.5889 7.45079 14.1133 7.81018 14.4008C8.16956 14.6883 8.69398 14.63 8.98149 14.2706L9.99743 13.0007L11.0134 14.2706C11.3009 14.63 11.8253 14.6883 12.1847 14.4008C12.5441 14.1133 12.6023 13.5889 12.3148 13.2295L11.0646 11.6667L12.3148 10.104C12.6023 9.74457 12.5441 9.22015 12.1847 8.93264C11.8253 8.64513 11.3009 8.7034 11.0134 9.06279L9.99743 10.3327L8.98149 9.06279Z" />
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M10.8308 1.25C12.6717 1.25 14.1641 2.74239 14.1641 4.58335H17.4974C17.9576 4.58335 18.3307 4.95645 18.3307 5.41669C18.3307 5.87692 17.9576 6.25002 17.4974 6.25002H16.664V14.5834C16.664 16.8846 14.7986 18.7501 12.4974 18.7501H7.49735C5.19615 18.7501 3.33065 16.8846 3.33067 14.5834L3.33073 6.25002H2.4974C2.03716 6.25002 1.66406 5.87692 1.66406 5.41669C1.66406 4.95645 2.03716 4.58335 2.4974 4.58335H5.83075C5.83075 2.74239 7.32314 1.25 9.1641 1.25H10.8308ZM7.49742 4.58335C7.49742 3.66287 8.24362 2.91667 9.1641 2.91667H10.8308C11.7512 2.91667 12.4974 3.66287 12.4974 4.58335H7.49742ZM14.9974 6.25002V14.5834C14.9974 15.9641 13.8781 17.0834 12.4974 17.0834H7.49735C6.11663 17.0834 4.99733 15.9641 4.99734 14.5834L4.9974 6.25002H14.9974Z"
						/>
					</svg>
					{t("addSpot.delete")}
				</button>
			)}
		</>
	);
};

export default SubmitPlaceButton;
