import { useEffect } from "react";
import { Modal } from "./Modal";
import { User } from "firebase/auth";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { setOpenClaimTokensWindow } from "../redux/api/userSlice";

import parries from "../assets/shared/parriesGuide.png";
import avatarGuide from "../assets/shared/avatarGuide.png";
import happyParry from "../assets/shared/happyParryGuide.png";
import rightArrow from "../assets/shared/rightArrowGuide.svg";

interface IProps {
	handleClose: () => void;
	// isOpenedFrom: string | null | "Project";
	isOpen: boolean;
}

export const TokenGuideModal = ({
	handleClose,
	// isOpenedFrom,
	isOpen,
}: IProps) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	return (
		<Modal
			isOpen={isOpen}
			handleClose={handleClose}
			modalContainerClassName={
				"max-w-[1275px] w-[80vw] max-h-[622px] relative pb-[100px] pt-[120px] min-h-[622px] max-h-none overflow-y-auto overflow-x-hidden"
			}
			authStep="tokenGuide"
		>
			<div className="max-w-[1080px] w-[90%] my-0 mx-auto">
				<div className="flex items-center justify-center gap-[13px]">
					<div className="flex flex-col gap-[30px] max-w-[320px] items-center min-h-[285px]">
						<div className="min-h-[125px] h-full flex items-center justify-center">
							<img src={parries} alt="parries" />
						</div>
						<div className="text-center">
							<h4 className="font-poppinsBold text-[32px] mb-[8px]">
								{t("ranking.detailedView.parry.projectGuideParryTitle")}
							</h4>
							<p className="font-poppins">
								{t("ranking.detailedView.parry.projectGuide")}
							</p>
						</div>
					</div>
					<div>
						<img src={rightArrow} alt="arrow" />
					</div>
					<div className="flex flex-col gap-[30px] max-w-[320px] items-center min-h-[285px]">
						<div className="min-h-[125px] h-full flex items-center justify-center">
							<img src={avatarGuide} alt="parries" />
						</div>

						<div className="text-center">
							<h4 className="font-poppinsBold text-[32px] mb-[8px]">
								{t("ranking.detailedView.parry.tokenGuideTitle")}
							</h4>
							<p className="font-poppins">
								{t("ranking.detailedView.parry.tokenGuideDesc")}
							</p>
						</div>
					</div>
					<div>
						<img src={rightArrow} alt="arrow" />
					</div>
					<div className="flex flex-col gap-[30px] max-w-[320px] items-center min-h-[285px]">
						<div className="min-h-[125px] h-full flex items-center justify-center">
							<img src={happyParry} alt="parries" />
						</div>
						<div className="text-center">
							<h4 className="font-poppinsBold text-[32px] mb-[8px]">
								{t("ranking.detailedView.parry.happyParryGuide")}
							</h4>
							<p className="font-poppins">
								{t("ranking.detailedView.parry.happyParryDesc")}
							</p>
						</div>
					</div>
				</div>
				<div className="mt-8">
					<button
						onClick={() => {
							dispatch(setOpenClaimTokensWindow(true));
							handleClose();
						}}
						className="py-[12px] mx-auto pl-[114px] pr-[114px] bg-black rounded-[38px] text-white text-base flex items-center gap-[6px] hover:bg-primaryBlack transition-all"
					>
						{t("ranking.detailedView.parry.guideButton")}
					</button>
				</div>
			</div>
		</Modal>
	);
};

export default TokenGuideModal;
