import { useTranslation } from "react-i18next";
import { radicalChange } from "../../assets/start-page";
import { paths } from "../../constants";
import Footer from "../Footer";
import ColumnLeft from "./ColumnLeft";
import ColumnRight from "./ColumnRight";
import Team from "./Team";
import TeamMobile from "./TeamMobile";
import { useEffect } from "react";
import i18n from "../../translations/i18n";

const AppBase = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex justify-center px-6 mt-10 md:px-20">
        <div className="max-w-[1280px] ">
          <div className="flex flex-col w-full justify-center mb-[60px] gap-y-4 lg:gap-x-12 lg:flex-row">
            <ColumnLeft />
            <ColumnRight />
          </div>
          <Team />
          <TeamMobile />
          <div className="flex flex-col items-center justify-center px-4 my-12">
            <p className="mb-5 text-lg">{t("start-page.partnership")}</p>
            <a target="_blank" rel="noopener noreferrer" href={paths.radical}>
              <img src={radicalChange} alt="radical-change" />
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export const AboutApp = () => {
  useEffect(() => {
    i18n.changeLanguage("en");
  }, []);

  return (
    <>
      <AppBase />
    </>
  );
};


export const AboutAppDe = () => {
  return (
    <>
      <AppBase />
    </>
  );
};
