import { useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { sendVerificationCode } from "../../firebase/verification";

import { VerificationStep } from "../../types";
import Loader from "../../shared/Loader";

import { validationSchema } from "../../utils/validation";
import { allowedCountries } from "../../constants";

import "./phoneInputStyles.css";

interface IRequestSms {
	handleGoToStep: (arg: VerificationStep) => void;
	closeVerifyWindow: () => void;
	setConfirmationResult: (arg: any) => void;
	setFullPhoneNumber: (arg: string) => void;
}

const RequestSms = ({
	handleGoToStep,
	closeVerifyWindow,
	setConfirmationResult,
	setFullPhoneNumber,
}: IRequestSms) => {
	const { t } = useTranslation();
	const [countryCode, setCountryCode] = useState("49");

	const [error, setError] = useState({ state: false, message: "" });
	const [isLoading, setIsLoading] = useState(false);

	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(validationSchema),
		values: {
			phoneNumber: "",
		},
	});

	const onSubmit = async (data: any) => {
		setError({ state: false, message: "" });
		setIsLoading(true);

		const fullPhoneNumber = `+${countryCode}${data.phoneNumber}`;
		setFullPhoneNumber(fullPhoneNumber);
		try {
			const data = await sendVerificationCode(
				fullPhoneNumber,
				setConfirmationResult
			);

			if (data && data.error) {
				setError({ state: true, message: data.error });
				return;
			}

			handleGoToStep("confirmSMS");
		} catch (err: any) {
			// setError({ state: true, message: err });

			console.error(err);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className="max-w-[312px] w-[100%] my-0 mx-auto flex flex-col gap-[32px]">
			<div>
				<span className="uppercase text-base font-normal mb-[4px]">
					{t("profileModal.step", { step: "2" })}
				</span>
				<h3 className="font-poppinsBold text-[22px]">
					{t("verification.chooseOption.chooseTitle")}
				</h3>
			</div>

			<form onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-full">
				<label htmlFor="country" className="mb-[16px] font-medium">
					{t("verification.phoneTitle")}
				</label>
				<div className="flex gap-[8px] mb-[8px]">
					<PhoneInput
						country={"de"}
						value={countryCode}
						onChange={(code) => setCountryCode(code)}
						containerStyle={{
							width: "85px",
							display: "flex",
							alignItems: "center",
						}}
						inputStyle={{
							width: "85px",
							border: "2px solid #D9D9D9",
							padding: "8px",
							paddingRight: "2px",
							height: "40px",
							paddingLeft: "40px",
							fontSize: "14px",
							pointerEvents: "none",
							borderRadius: "8px",
							textAlign: "center",
						}}
						onlyCountries={allowedCountries.map((item) => item.toLowerCase())}
						disableDropdown={false}
						disableCountryCode
						countryCodeEditable={false}
						placeholder={""}
					/>
					<Controller
						name="phoneNumber"
						control={control}
						render={({ field }) => (
							<input
								id={"country"}
								type="text"
								{...field}
								placeholder="Enter phone number"
								style={{
									border: "2px solid #D9D9D9",
									padding: "8px",
									height: "40px",
									fontSize: "14px",
									borderRadius: "8px",
									flex: 1,
								}}
							/>
						)}
					/>
				</div>
				{errors.phoneNumber && (
					<p className="mb-[8px] text-error">{errors.phoneNumber.message}</p>
				)}
				{error.state && <p className="mb-[8px] text-error">{error.message}</p>}
				<p className="text-[#7f7f7f] text-[14px] mb-[60px]">
					{t("verification.phoneInfo")}
				</p>
				<button
					disabled={isLoading}
					type="submit"
					className="relative flex items-center gap-[8px] bg-black py-[16px] w-full justify-center font-poppinsBold px-[40px] rounded-[50px] text-white mb-[16px] disabled:bg-[#DFE1E5]"
				>
					{isLoading ? (
						<div className="w-[24px] h-[24px]">
							<Loader
								childClassName={"w-[24px] h-[24px] border-[5px] text-[#333536]"}
							/>
						</div>
					) : (
						t("verification.phoneSubmit")
					)}
				</button>
				<button
					onClick={() => {
						closeVerifyWindow();
						handleGoToStep("basic");
					}}
					className="underline text-[14px] font-medium"
				>
					{t("verification.phoneCancel")}
				</button>
			</form>
		</div>
	);
};

export default RequestSms;
