import {useLottie} from "lottie-react";

const useGetAnimation = (animation: any) => {
  const options = {
    animationData: animation,
    loop: true,
  };
  const {View} = useLottie(options);
  return View;
};

export default useGetAnimation;
