import {qvPhoto} from "../../assets/qv";
import Levels from "./Levels";
import Map from "./Map";
import Parry from "./Parry";
import RXC from "./RXC";
import Token from "./Token";
import Vorteile from "./Vorteile";
import Voting from "./Voting";

const QvBlocks = () => {
  return (
    <div className="hidden xl:flex justify-between w-full max-w-[1200px]">
      <div>
        <div className="mb-12 ml-[75px]">
          <img src={qvPhoto} alt="qv" />
        </div>
        <RXC />
        <div className="flex flex-col items-end max-w-[735px]">
          <div className="z-20">
            <Vorteile />
          </div>
          <Levels />
          <Map />
        </div>
      </div>
      <div className=" flex flex-col items-end mt-[110px] gap-y-12 h-screen relative">
        <div className="absolute top-0 right-0 flex flex-col items-end">
          <Parry />
          <Voting />
          <Token />
        </div>
      </div>
    </div>
  );
};

export default QvBlocks;
