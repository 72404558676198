import { useTranslation } from "react-i18next";
import { danger, goodSpot, useful } from "../../../../assets/map";
import { setSpotCardView } from "../../../../redux/api/elementsViewSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { useSearchParams } from "react-router-dom";

interface Props {
	category: string;
	district: string;
	section: string;
	mySpotsActiveTab: string;
}

const NavBar = ({ category, district, section, mySpotsActiveTab }: Props) => {
	const dispatch = useAppDispatch();
	const sortBy = useAppSelector((state) => state.spots.sortBy);
	const { t } = useTranslation();
	const [searchParams, setSearchParams] = useSearchParams();

	const images = {
		Useful: useful,
		Dangerous: danger,
		"Bike Friendly": goodSpot,
	};
	const back = () => {
		dispatch(setSpotCardView(false));
		searchParams.delete("id");
		setSearchParams(searchParams);
	};
	return (
		<div className="flex justify-between absolute w-full bg-white top-0 items-center">
			<button
				onClick={back}
				className="bg-black rounded-[30px] text-white pr-4 pl-2 h-10 text-sm mr-4 flex items-center gap-2 whitespace-nowrap"
			>
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M8.95734 9.08185L14.6228 3.77047C15.0258 3.39274 15.6586 3.41315 16.0363 3.81606C16.414 4.21898 16.3936 4.85181 15.9907 5.22954L10.3252 10.5409C9.48243 11.3311 9.48243 12.6689 10.3252 13.4591L15.9907 18.7705C16.3936 19.1482 16.414 19.781 16.0363 20.1839C15.6586 20.5869 15.0258 20.6073 14.6228 20.2295L8.95734 14.9182C7.27171 13.3379 7.27171 10.6622 8.95734 9.08185Z"
						fill="white"
					/>
				</svg>
				{section === "MySpots" && mySpotsActiveTab === "Created" ? (
					<svg
						width="16"
						height="20"
						viewBox="0 0 16 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M12 8.03516C12 10.2443 10.2091 12.0352 8 12.0352C5.79086 12.0352 4 10.2443 4 8.03516C4 5.82602 5.79086 4.03516 8 4.03516C10.2091 4.03516 12 5.82602 12 8.03516ZM10 8.03516C10 9.13973 9.10457 10.0352 8 10.0352C6.89543 10.0352 6 9.13973 6 8.03516C6 6.93059 6.89543 6.03516 8 6.03516C9.10457 6.03516 10 6.93059 10 8.03516Z"
							fill="white"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M16 8.03516C16 11.0134 13.1309 15.4454 10.8291 18.5219C9.3915 20.4433 6.6085 20.4433 5.17086 18.5219C2.86907 15.4454 0 11.0134 0 8.03516C0 3.06202 3.58172 0.0351562 8 0.0351562C12.4183 0.0351562 16 3.06202 16 8.03516ZM14 8.03516C14 8.99784 13.4955 10.4775 12.5171 12.2928C11.5778 14.0358 10.3494 15.8246 9.22775 17.3237C8.59003 18.1761 7.40996 18.1761 6.77225 17.3237C5.65064 15.8246 4.42216 14.0358 3.48287 12.2928C2.50454 10.4775 2 8.99784 2 8.03516C2 6.05371 2.70067 4.59135 3.72224 3.61872C4.75888 2.63173 6.24749 2.03516 8 2.03516C9.75251 2.03516 11.2411 2.63173 12.2778 3.61872C13.2993 4.59135 14 6.05371 14 8.03516Z"
							fill="white"
						/>
					</svg>
				) : (
					<svg
						width="24"
						height="25"
						viewBox="0 0 24 25"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M7.01562 14.9849C8.28614 16.2815 10.0569 17.0859 12.0156 17.0859C13.9743 17.0859 15.7451 16.2815 17.0156 14.9849M7.01562 14.9849C6.92999 14.8975 6.84662 14.8079 6.76562 14.7161C5.67649 13.4821 5.01562 11.8612 5.01562 10.0859C5.01562 6.21994 8.14963 3.08594 12.0156 3.08594C15.8816 3.08594 19.0156 6.21994 19.0156 10.0859C19.0156 11.8612 18.3548 13.4821 17.2656 14.7161C17.1846 14.8079 17.1013 14.8975 17.0156 14.9849M7.01562 14.9849V19.0859C7.01562 20.4257 8.3071 21.3866 9.59032 21.0016L11.4409 20.4464C11.8158 20.3339 12.2154 20.3339 12.5903 20.4464L14.4409 21.0016C15.7242 21.3866 17.0156 20.4257 17.0156 19.0859V14.9849"
							stroke="white"
							strokeWidth="2"
						/>
						<path
							d="M11.6546 6.84248C11.7995 6.53884 12.2317 6.53884 12.3766 6.84248L13.0742 8.30433C13.1325 8.42653 13.2487 8.51094 13.3829 8.52864L14.9888 8.74032C15.3223 8.78429 15.4559 9.19536 15.2119 9.42699L14.0371 10.5421C13.9389 10.6354 13.8946 10.7719 13.9192 10.9051L14.2141 12.4978C14.2754 12.8286 13.9257 13.0826 13.63 12.9221L12.2064 12.1495C12.0874 12.0849 11.9438 12.0849 11.8248 12.1495L10.4012 12.9221C10.1055 13.0826 9.75585 12.8286 9.81711 12.4978L10.112 10.9051C10.1367 10.7719 10.0923 10.6354 9.9941 10.5421L8.81936 9.42699C8.57535 9.19536 8.70891 8.78429 9.04247 8.74032L10.6483 8.52864C10.7826 8.51094 10.8987 8.42653 10.9571 8.30433L11.6546 6.84248Z"
							fill="white"
						/>
					</svg>
				)}
			</button>
			<div className="flex gap-2 ">
				<div className="overflow-x-auto no-scrollbar">
					<div className="flex w-max gap-4">
						<div
							className={`flex bg-[#F1F1F1]  h-10 pl-2 pr-4 rounded-full py-2 items-center text-sm leading-[19px] gap-2`}
						>
							<img
								className="w-6 h-6"
								src={images[category as keyof typeof images]}
								alt="category-icon"
							/>
							<span className="whitespace-nowrap">
								{t(`ranking.categories.${category}`)}
							</span>
						</div>
						<div className="bg-[#F1F1F1] py-[10px] px-4 rounded-full text-sm whitespace-nowrap">
							{district}
						</div>
						<div className="bg-[#F1F1F1] py-[10px] px-4 rounded-full text-sm whitespace-nowrap">
							{t(`filter.${sortBy.sortType}`)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NavBar;
