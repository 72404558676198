import {Link} from "react-router-dom";
import {paths} from "../../constants";
import {useTranslation} from "react-i18next";

interface Props {
  icon: string;
  text: string;
  name: string;
  size?: string;
  position?: string;
  personal?: boolean;
}
const MemberCard = ({icon, text, name, size, position, personal}: Props) => {
  const {t} = useTranslation();
  return (
    <div
      className={`w-[155px] h-[177px] bg-white border-4 border-black rounded-[34px] p-3 flex flex-col justify-between ${position}`}
    >
      <div>
        <p className="font-bold text-lg">{name}</p>
        <div className={size ? size : "text-lg"}>
          <p className="inline">{text}</p>
          {personal && (
            <Link to={paths.imprint} className=" ml-1 underline ">
              {t("about.part-link")}
            </Link>
          )}
        </div>
      </div>
      <div className="flex justify-end">
        <img src={icon} alt="member-icon" />
      </div>
    </div>
  );
};

export default MemberCard;
