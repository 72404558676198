import {useTranslation} from "react-i18next";
import {radicalChange} from "../../assets/start-page";
import {paths} from "../../constants";
import Footer from "../Footer";
import Header from "../header/Header";
import ColumnLeft from "./ColumnLeft";
import ColumnRight from "./ColumnRight";
import Team from "./Team";
import TeamMobile from "./TeamMobile";

const About = () => {
  const {t} = useTranslation();
  return (
    <>
      <Header>
        <div className="relative flex items-center justify-center text-2xl font-bold">
          <p>{t("about.header-button")}</p>
        </div>
      </Header>
      <div className="mt-[178px] px-6 md:px-20 flex justify-center">
        <div className="max-w-[1280px] ">
          <div className="flex flex-col w-full justify-center mb-[60px] gap-y-4 lg:gap-x-12 lg:flex-row">
            <ColumnLeft />
            <ColumnRight />
          </div>
          <Team />
          <TeamMobile />
          <div className="flex flex-col items-center justify-center px-4 my-12">
            <p className="mb-5 text-lg">{t("start-page.partnership")}</p>
            <a target="_blank" rel="noopener noreferrer" href={paths.radical}>
              <img src={radicalChange} alt="radical-change" />
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default About;
