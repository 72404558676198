import {useTranslation} from "react-i18next";
import {
  about,
  home,
  initiatives,
  startInitiative,
  support,
  twitter,
  voting,
} from "../assets/burgerMenu";
import {paths} from "../constants";

const useGetBurgerLinks = () => {
  const {t} = useTranslation();
  const menu = [
    {
      text: t("header.burger.home"),
      img: home,
      link: paths.startPage,
    },
    {
      text: t("header.burger.initiatives"),
      img: initiatives,
      link: paths.projects,
    },
    {
      text: t("header.burger.startInitiative"),
      img: startInitiative,
      link: paths.newProject,
    },
    {
      text: t("header.burger.about"),
      img: about,
      link: paths.about,
    },
    {
      text: t("header.burger.voting"),
      img: voting,
      link: paths.qv,
    },
    {
      text: "Twitter",
      img: twitter,
      link: paths.twitter,
    },
    {
      text: t("header.burger.support"),
      img: support,
      link: paths.support,
    },
  ];

  return menu;
};

export default useGetBurgerLinks;
