interface Props {
  text: string;
  copyMessage: boolean;
}
const CopyMessage = ({text, copyMessage}: Props) => {
  return (
    <div
      className={`absolute bottom-7 text-[12px] bg-black px-2 p-1 text-white rounded  ${
        copyMessage
          ? "opacity-100"
          : "opacity-0 transition-opacity duration-500"
      }`}
    >
      {text}
    </div>
  );
};

export default CopyMessage;
