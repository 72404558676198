import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {footerLogo} from "../assets/start-page";
import {paths} from "../constants";
import useScrollToTop from "../hooks/useScrollToTop";

interface FooterLink {
  path: string;
  text: string;
}

const Footer = () => {
  const {t} = useTranslation();
  const footerLinks: FooterLink[] = [
    {path: paths.contact, text: t("footer.links.contact")},
    {path: paths.imprint, text: t("footer.links.imprint")},
    {path: paths.policy, text: t("footer.links.policy")},
    {path: paths.terms, text: t("footer.links.terms")},
  ];

  const scrollTop = useScrollToTop();

  return (
    <footer className="w-full bg-black flex justify-center">
      <div className="w-[1440px] pt-[180px] lg:pt-[113px] pb-[117px] lg:pb-[72px] px-6 lg:px-24">
        <div className="flex flex-col justify-center items-center mb-20">
          <img className="mb-8" src={footerLogo} alt="footer-logo" />
          <div className="text-white text-center max-w-[480px]">
            <p>{t("footer.text_1")}</p>
            <p>{t("footer.text_2")}</p>
          </div>
        </div>
        <nav className="flex flex-col lg:flex-row lg:justify-between items-center gap-y-6 text-white">
          <div className="flex flex-col lg:flex-row items-center gap-6 order-1 lg:order-2">
            {footerLinks.map((link: FooterLink) => (
              <Link onClick={scrollTop} to={link.path} key={link.path}>
                {link.text}
              </Link>
            ))}
          </div>
          <p className="order-2 lg:order-1">2024 © CitizenTech </p>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;
