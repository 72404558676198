import Footer from "../Footer";
import Header from "../header/Header";

import {useTranslation} from "react-i18next";
import QvBlocks from "./QvBlocks";
import QvMobile from "./QvMobile";

const QvInfo = () => {
  const {t} = useTranslation();
  return (
    <>
      <Header>
        <div className="flex items-center justify-center text-2xl relative">
          <p className="font-bold text-2xl">{t("qv.title")}</p>
        </div>
      </Header>
      <div className="w-screen flex justify-center xl:pl-[113px] xl:pr-[130px] px-6 pt-[180px] xl:pt-[130px]">
        <QvBlocks />
        <QvMobile />
      </div>
      <Footer />
    </>
  );
};

export default QvInfo;
