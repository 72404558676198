interface Props {
  title: string;
  children: React.ReactNode;
  icon?: string;
}
const AboutParagraph = ({title, children, icon}: Props) => {
  return (
    <div className="text-sm ">
      <div className="flex">
        {icon && <img className="mr-[6px]" src={icon} alt="icon" />}
        <p className="font-bold">{title}</p>
      </div>
      <div className="flex flex-col gap-y-5">{children}</div>
    </div>
  );
};

export default AboutParagraph;
