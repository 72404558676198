import { useTranslation } from "react-i18next";
import { rankingStyles } from "../../constants";
import { changeSection } from "../../redux/api/elementsViewSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import SpotsNavSvg from "../../shared/SpotsNavSvg";

const ChangeSection = () => {
	const dispatch = useAppDispatch();
	const section = useAppSelector((state) => state.elementsView.section);
	const { t } = useTranslation();
	return (
		<nav
			className={` flex w-full gap-8 mb-[20px] px-2 ${rankingStyles.navbarHeight.style}`}
		>
			<button
				onClick={() => dispatch(changeSection("Ranking"))}
				className="flex gap-2"
			>
				<SpotsNavSvg name="Ranking" />
				<span
					className={section === "Ranking" ? "text-outlineBlue" : "text-black"}
				>
					{t("ranking.ranking")}
				</span>
			</button>
			<button
				onClick={() => dispatch(changeSection("MySpots"))}
				className="flex gap-2"
			>
				<SpotsNavSvg name="MySpots" />
				<span
					className={section === "MySpots" ? "text-outlineBlue" : "text-black"}
				>
					{t("ranking.mySpots")}
				</span>
			</button>
			<button
				onClick={() => dispatch(changeSection("Activity"))}
				className="flex gap-2"
			>
				<SpotsNavSvg name="Activity" />
				<span
					className={section === "Activity" ? "text-outlineBlue" : "text-black"}
				>
					{t("ranking.activity")}
				</span>
			</button>
			<button
				onClick={() => dispatch(changeSection("Mission"))}
				className="flex gap-2"
			>
				<SpotsNavSvg name="Mission" />
				<span
					className={section === "Mission" ? "text-outlineBlue" : "text-black"}
				>
					{t("ranking.mission")}
				</span>
			</button>
		</nav>
	);
};

export default ChangeSection;
