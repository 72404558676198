import {useTranslation} from "react-i18next";
import Section from "../../Section";

const Contacting = () => {
  const {t} = useTranslation();
  return (
    <Section title={t("policy.contact.title")}>
      <p>{t("policy.contact.text_1")}</p>
      <p>
        http:/
        <span className="relative left-1">/www.parry.info</span>
      </p>
      <p>{t("policy.contact.text_3")}</p>
      <p>{t("policy.contact.text_4")}</p>
      <p>{t("policy.contact.text_5")}</p>
    </Section>
  );
};

export default Contacting;
