import { doc, updateDoc, DocumentData, getDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setTokens } from "../../redux/api/userSlice";
import { updateSpot } from "../../redux/api/spotsSlice";
import { calcVotesPercantageByDistrict } from "../../utils/calcVotesPercantage";
import { updateTokensForSelectedProject } from "../../redux/thunks";

import { IProjectUserInfo } from "../../redux/api/userSlice";
interface IUpdateObject {
	date: number;
	submitted_by: string;
	tokens: number;
}

const getDistrictPercents = (
	allSpots: DocumentData[],
	selectedSpot: DocumentData
) => {
	const distrSpots: DocumentData[] = [];
	allSpots.forEach((spot) => {
		if (
			spot.district === selectedSpot.district &&
			spot.id !== selectedSpot.id
		) {
			const clonedSpot = structuredClone(spot);
			distrSpots.push(clonedSpot);
		}
	});
	const votes = calcVotesPercantageByDistrict(
		distrSpots,
		selectedSpot.district,
		selectedSpot
	);
	const updatedSpot = votes.find((spot) => spot.id === selectedSpot.id);
	return updatedSpot;
};

const useHandleVotesChange = () => {
	const dispatch = useAppDispatch();
	const spots = useAppSelector((state) => state.spots.spotsData);

	const addVotesInfo = async (
		spotId: string,
		userID: string,
		updateObj: IUpdateObject | null,
		selectedSpot: DocumentData,
		userTokens: number,
		type: "increase" | "decrease"
	) => {
		const currSpot = structuredClone(selectedSpot);

		if (type === "increase") {
			currSpot.total_votes = currSpot.total_votes + 1;
		} else {
			currSpot.total_votes = currSpot.total_votes - 1;
		}

		if (updateObj === null && currSpot.votes[userID]) {
			delete currSpot.votes[userID];
		} else {
			currSpot.votes[userID] = updateObj;
		}

		const currTokens =
			userTokens !== null
				? userTokens -
				  (updateObj?.tokens || 0) +
				  (selectedSpot?.votes[userID]?.tokens || 0)
				: undefined;

		const updatedSpot = getDistrictPercents(spots, currSpot);

		if (updatedSpot) {
			dispatch(updateSpot(updatedSpot));
			dispatch(updateTokensForSelectedProject(currTokens));
		}
	};

	return { addVotesInfo };
};

export default useHandleVotesChange;

export const useUpdateSpotOnFirebase = () => {
	const selectedProjectId = useAppSelector(
		(state) => state.projectCards.selectedProjectId
	);

	const updateSpotOnFirebase = async (
		spotId: string,
		userID: string,
		tokens: number,
		updatedCard: any
	) => {
		const spotRef = doc(db, "spots", spotId);
		const userRef = doc(db, "users", userID);
		const userDoc = await getDoc(userRef);

		try {
			await updateDoc(spotRef, updatedCard);
			if (typeof tokens !== "undefined" && userDoc.exists()) {
				const userData = userDoc.data();
				const isJoinedToProjects = userData.isJoinedToProjects || [];

				const updatedProjects = isJoinedToProjects.map(
					(project: IProjectUserInfo) => {
						if (project.project === selectedProjectId) {
							return {
								...project,
								tokens: tokens,
							};
						}
						return project;
					}
				);
				await updateDoc(userRef, { isJoinedToProjects: updatedProjects });
			} else {
				console.error("User document not found");
			}
		} catch (error) {
			console.error("Error updating vote and total_votes: ", error);
		}
	};

	return { updateSpotOnFirebase };
};
