import { useTranslation } from "react-i18next";
import Section from "../../Section";
import SubTitle from "../../SubTitle";

const Collection = () => {
  const { t } = useTranslation();
  return (
    <Section title={t("policy.collection.title")}>
      <p>{t("policy.collection.text_1")}</p>
      <p>{t("policy.collection.text_2")}</p>
      <ul className="list-disc pl-5">
        <li>{t("policy.collection.point_1")}</li>
        <li>{t("policy.collection.point_2")}</li>
        <li>{t("policy.collection.point_3")}</li>
        <li>{t("policy.collection.point_4")}</li>
        <li>{t("policy.collection.point_5")}</li>
        <li>{t("policy.collection.point_6")}</li>
      </ul>
      <p>{t("policy.collection.text_3")}</p>
      <p>{t("policy.collection.text_4")}</p>
      <p>{t("policy.collection.text_5")}</p>
      <div>
        <SubTitle>{t("policy.collection.cloud_title")}</SubTitle>
        <p>{t("policy.collection.cloud_text")}</p>
      </div>
      <div>
        <SubTitle>{t("policy.collection.app-check_title")}</SubTitle>
        <p>{t("policy.collection.app-check_text")}</p>
      </div>
      <div>
        <SubTitle>{t("policy.collection.auth_title")}</SubTitle>
        <p>{t("policy.collection.auth_text")}</p>
      </div>
      <div>
        <SubTitle>{t("policy.collection.crash_title")}</SubTitle>
        <p>{t("policy.collection.crash_text")}</p>
      </div>
      <div>
        <SubTitle>{t("policy.collection.distribution_title")}</SubTitle>
        <p>{t("policy.collection.distribution_text_1")}</p>
        <p>{t("policy.collection.distribution_text_2")}</p>
      </div>
      <div>
        <SubTitle>{t("policy.collection.cloud_firestore_title")}</SubTitle>
        <p>{t("policy.collection.cloud_firestore_text")}</p>
      </div>
      <div>
        <SubTitle>{t("policy.collection.cloud_storage_title")}</SubTitle>
        <p>{t("policy.collection.cloud_storage_text")}</p>
      </div>
      <div>
        <p>
          {t("policy.collection.info-from_text_1")}{" "}
          <a
            className="underline"
            href="https://firebase.google.com/support/privacy"
          >
            Privacy and Security in Firebase docs
          </a>{" "}
          {t("policy.collection.info-from_text_2")}
        </p>
      </div>
    </Section>
  );
};

export default Collection;
