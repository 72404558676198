import React from "react";
import { useTranslation } from "react-i18next";
import useClickOutside from "../../hooks/useClickOutside";
import LogOutIcon from "../../assets/profile/logout.png";
import { signOutUser } from "../../firebase/auth";

const ProfileModalSidebar = () => {
	const { t } = useTranslation();
	const { element, clickOutSide, isVisible } =
		useClickOutside<HTMLDivElement>();
	return (
		<div
			className={
				"w-[130px] sticky flex flex-col justify-between p-4 border-r-[1px] h-[454px]"
			}
		>
			<div className={" flex-col font-poppins"}>
				<div className={"font-poppins font-medium text-sm h-[44px]"}>
					{t("profileModal.profile")}
				</div>
				<div
					className={"text-gray-400 font-poppins font-medium text-sm h-[44px]"}
				>
					{t("profileModal.tokens")}
				</div>
				<div
					className={"text-gray-400 font-poppins font-medium text-sm h-[44px]"}
				>
					{t("profileModal.network")}
				</div>
			</div>
			<div ref={element} className={"relative"}>
				{isVisible && (
					<div
						className={
							"absolute left-6 bottom-8 w-[140px] rounded-xl overflow-hidden"
						}
					>
						<button
							onClick={signOutUser}
							className={
								"bg-[#ED3C22] w-full font-medium text-sm text-white text-center p-[11px]"
							}
						>
							{t("profileModal.logout")}
						</button>
						<button
							className={
								"bg-black w-full font-medium text-sm text-center text-white p-[11px]"
							}
							onClick={() => clickOutSide(!isVisible)}
						>
							{t("profileModal.cancel")}
						</button>
					</div>
				)}
				<button onClick={() => clickOutSide(!isVisible)} className={"flex"}>
					<img className={"w-[18px]"} src={LogOutIcon} alt={"log out icon"} />
					<div className={"text-xs font-medium ml-2"}>
						{t("profileModal.logout")}
					</div>
				</button>
			</div>
		</div>
	);
};

export default ProfileModalSidebar;
