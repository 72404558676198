import {
	getAuth,
	createUserWithEmailAndPassword,
	signInWithEmailAndPassword,
	updateProfile,
	signInWithPopup,
	GoogleAuthProvider,
	OAuthProvider,
	User,
	signInWithEmailLink,
} from "firebase/auth";
import { app, db } from "./firebase";
import { deleteCookie, setCookie } from "../utils/cookies";
import { providerGoogleAuth } from "./utils/auth-google-provider-create";
import { providerApple } from "./utils/auth-apple-provider-create";
import { doc, setDoc } from "firebase/firestore";
import { getDatabase, ref, set } from "firebase/database";

export const signupProvider = async (email: string, password: string) => {
	const auth = getAuth(app);
	try {
		const userCredentials = await createUserWithEmailAndPassword(
			auth,
			email,
			password
		);
		const currentUser = auth.currentUser;

		if (currentUser) {
			const token = currentUser.getIdToken();

			setCookie("token", token);
		}
		return userCredentials.user;
	} catch (err: any) {
		return err.error;
	}
};

export const loginProvider = async (email: string, password: string) => {
	const auth = getAuth(app);
	try {
		const userCredentials = await signInWithEmailAndPassword(
			auth,
			email,
			password
		);
		const currentUser = auth.currentUser;

		if (currentUser) {
			const token = await currentUser.getIdToken();
			setCookie("token", token);
		}
		return { user: userCredentials.user };
	} catch (err: any) {
		if (err.code === "auth/wrong-password") return { error: "Wrong password" };
		else if (err.code === "auth/user-not-found")
			return { error: "User not found" };
		else if ((err.code = "too-many-requests"))
			return { error: "To many requests" };
		else return { error: "Something went wrong" };
	}
};

export const signInWithGoogle = async () => {
	const auth = getAuth();
	const currentUser = auth.currentUser;
	try {
		const result = await signInWithPopup(auth, providerGoogleAuth);
		const credential = GoogleAuthProvider.credentialFromResult(result);
		const token = credential?.accessToken;

		token && setCookie("token", token);
	} catch (error: any) {
		const errorCode = error.code;
		const errorMessage = error.message;

		console.error(errorCode, errorMessage);
	}
};

export const signInWithApple = async (
	successCb?: () => void
): Promise<User | void> => {
	try {
		const auth = getAuth();
		const result = await signInWithPopup(auth, providerApple);

		const user = result.user;

		const credential = OAuthProvider.credentialFromResult(result);
		const accessToken = credential?.accessToken;
		const idToken = credential?.idToken;

		idToken && setCookie("token", accessToken);

		successCb?.();
		return user;
	} catch (error: any) {
		const errorCode = error.code;
		const errorMessage = error.message;

		console.error(errorCode, errorMessage);
	}
};

export const signInByEmailLink = () => {
	const auth = getAuth();
	const email = localStorage.getItem("emailForSignIn") || "";

	return signInWithEmailLink(auth, email, window.location.href);
};

export const getCurrentUserInfo = () => {
	const auth = getAuth();
	const user = auth.currentUser;
	return user;
};

export const signOutUser = () => {
	const auth = getAuth();
	auth.signOut().then(() => deleteCookie("token"));
};

export const createProfileInFirestore = async () => {
	const user = getCurrentUserInfo();

	if (user) {
		const users = doc(db, "users", user.uid);

		const {
			proactiveRefresh,
			auth,
			stsTokenManager,
			tenantId,
			metadata,
			providerId,
			reloadListener,
			providerData,
			displayName: username,
			...restFields
		} = user as User & {
			proactiveRefresh?: any;
			auth: any;
			stsTokenManager: any;
			reloadListener: any;
		};

		await setDoc(
			users,
			{
				...restFields,
				username,
				isJoinedToProjects: [],
				isUserVerified: false,
			},
			{ merge: true }
		);

		return user;
	}
};
