import { twMerge } from "tailwind-merge";
import { AuthButton } from "./AuthButton";
import { useDebounceCallback } from "../../hooks/useDebounceCallback";
import { useParams } from "react-router";
import { emailSchema } from "../../utils/validation";
import {
	fetchSignInMethodsForEmail,
	getAuth,
	sendSignInLinkToEmail,
} from "firebase/auth";
import { ChangeEvent, useEffect, useState } from "react";
import { useDebounce } from "../../hooks/useDebounce";
import { AuthStep } from "../../types";
import { useTranslation } from "react-i18next";

interface IProps {
	handleGoToStep: (newAuthStep: AuthStep) => void;
}

export const LoginForm = ({ handleGoToStep }: IProps) => {
	const { t } = useTranslation();
	const [email, setEmail] = useState("");
	const [error, setError] = useState(" ");
	const [emailIsValid, setEmailIsValid] = useState(false);
	const { projectName } = useParams();

	const debouncedEmail = useDebounce(email);

	const debouncedValidateEmail = useDebounceCallback(async () => {
		try {
			setError("");
			await emailSchema.validateAt("email", { email });

			const auth = getAuth();
			const signInMethods = await fetchSignInMethodsForEmail(auth, email);

			if (signInMethods.length > 0) {
				setError("");
				setEmailIsValid(true);
				localStorage.setItem("emailForSignIn", email);
			} else {
				setEmailIsValid(false);
				setError(t("auth.login.emailNotRegistered"));
			}

			return signInMethods;
		} catch (validationError) {
			setEmailIsValid(false);
			setError(t("auth.login.invalidEmail"));
		}
	});

	useEffect(() => {
		debouncedValidateEmail();
	}, [email]);

	const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
		setEmail(e.target.value);
	};

	const handleSubmit = async () => {
		try {
			localStorage.setItem("isUserNameConfirmed", "true");
			const REDIRECT_DOMAIN = projectName
				? `${process.env.REACT_APP_WEBSITE_URL}/project/${projectName}"`
				: process.env.REACT_APP_WEBSITE_URL!;

			const actionCodeSettings = {
				url: REDIRECT_DOMAIN,
				handleCodeInApp: true,
			};

			const auth = getAuth();

			await sendSignInLinkToEmail(auth, email, actionCodeSettings);

			handleGoToStep("loginConfirm");
		} catch {
			setError(t("auth.login.failedSendLink"));
		}
	};

	return (
		<div>
			<h3 className="uppercase text-base font-normal">
				{t("auth.login.step", { step: "1" })}
			</h3>
			<h1 className="font-semibold text-2xl mt-2">{t("header.login")}</h1>
			<div className="mt-10">
				<p className="">{t("auth.login.yourEmail")}</p>
				<label>
					<input
						type="email"
						placeholder={t("auth.signUp.emailPlaceHolder")}
						onChange={handleEmailChange}
						required
						className={twMerge("formField", "border-2 !py-2 !pl-2 mt-1")}
					/>
				</label>
				<p className="h-6 text-error text-end">
					{debouncedEmail && error ? error : " "}
				</p>
			</div>
			<AuthButton
				text={t("auth.login.requestLink")}
				className="mt-20"
				disabled={!!error || !emailIsValid}
				onClick={handleSubmit}
			/>
			<div className="flex gap-2 justify-center items-center mt-6">
				<p>{t("auth.login.notRegistered")}</p>
				<button
					className="underline"
					onClick={() => handleGoToStep("createAccount")}
				>
					{t("auth.login.createAccount")}
				</button>
			</div>
		</div>
	);
};
