import {useTranslation} from "react-i18next";
import BlockContainer from "./BlockContainer";
import {graphic} from "../../assets/qv";

const Voting = () => {
  const {t} = useTranslation();
  return (
    <BlockContainer styles="w-[745px] overflow-hidden">
      <div className="p-8 xl:p-[66px] gap-8 xl:gap-20 xl:pb-[110px] bg-white flex flex-col xl:flex-row items-center xl:items-start">
        <img className="w-[215px] h-[215px]" src={graphic} alt="graphic" />
        <div className="flex flex-col gap-5 leading-[22px]">
          <p>{t("qv.voting.text_1")}</p>
          <p>{t("qv.voting.text_2")}</p>
          <p>
            {t("qv.voting.text_3")}
            <span className="font-bold">{t("qv.voting.span_1")}</span>
            {t("qv.voting.text_4")}
          </p>
          <p>
            {t("qv.voting.text_5")}
            <span className="font-bold">{t("qv.voting.span_2")}</span>
            {t("qv.voting.text_6")}
          </p>
          <p>{t("qv.voting.text_7")}</p>
        </div>
      </div>
    </BlockContainer>
  );
};

export default Voting;
