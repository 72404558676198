interface Props {
  children: React.ReactNode;
  title?: string;
}

const Section = ({ children, title }: Props) => {
  return (
    <div>
      {title && (
        <p className="text-left text-2xl lg:text-4xl font-poppinsBold mb-6">
          {title}
        </p>
      )}
      <div className="mb-6 lg:text-2xl text-justify flex flex-col gap-y-6">
        {children}
      </div>
    </div>
  );
};

export default Section;
