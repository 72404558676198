import Search from "./Search";
import ParryMap from "./ParryMap";
const MapBox = () => {
	return (
		<div className=" flex-1 h-full relative rounded-xl overflow-hidden">
			<Search />
			<ParryMap />
		</div>
	);
};

export default MapBox;
