import { useState, useEffect } from "react";

export interface IDropPhoto {
	placeUrl: null | string;
	placeFile: null | File;
	sizeError: boolean;
}

const useSetImage = () => {
	const [placeImage, setPlaceImage] = useState<IDropPhoto>({
		placeUrl: null,
		placeFile: null,
		sizeError: false,
	});

	const setSpotPic = () => {
		const file = placeImage.placeFile;
		if (file) {
			const fileData = new Blob([file], { type: "image/type" });
			const imageUrl = URL.createObjectURL(fileData);
			setPlaceImage((prevData) => ({
				...prevData,
				placeUrl: imageUrl,
			}));
		}
	};

	useEffect(() => {
		const changeSpotPic = async () => {
			await setSpotPic();
		};
		changeSpotPic();
	}, [placeImage.placeFile]);

	const handlePlacePhotoChange = (file: File) => {
		setPlaceImage((prevData) => ({
			...prevData,
			placeFile: file,
			sizeError: false,
		}));
	};

	const handleSizeError = (arg: boolean) => {
		setPlaceImage((prevData) => ({
			...prevData,
			sizeError: arg,
		}));
	};

	const handleRemoveImage = () =>
		setPlaceImage((prevData) => ({
			placeUrl: null,
			placeFile: null,
			sizeError: false,
		}));

	return {
		placeImage,
		setPlaceImage,
		handlePlacePhotoChange,
		handleSizeError,
		handleRemoveImage,
	};
};

export default useSetImage;
