import {useTranslation} from "react-i18next";
import {tagsIcons} from "../assets/tagsIcons";
import {ITagsIcons} from "../interfaces";

interface Props {
  type: string;
}

const SpotTag = ({type}: Props) => {
  const {t} = useTranslation();

  return (
    <div className="flex justify-center items-center">
      <img
        className="mr-1 -translate-y-[2px]"
        src={tagsIcons[type as keyof ITagsIcons]}
        alt={tagsIcons[type as keyof ITagsIcons]}
      />
      <span className={` border-white text-[12px]`}>
        {t(`ranking.tags.${type}`)}
      </span>
    </div>
  );
};

export default SpotTag;
