import { useTranslation } from "react-i18next";
import { setMySpotsActiveTab } from "../../../redux/api/elementsViewSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

const MySpotsTabs = () => {
	const { t } = useTranslation();

	const dispatch = useAppDispatch();

	const activeTab = useAppSelector(
		(state) => state.elementsView.mySpotsActiveTab
	);
	return (
		<div className="mb-3">
			<button
				onClick={() => dispatch(setMySpotsActiveTab("Created"))}
				className={`w-[50%] py-[7px] border-2 ${
					activeTab === "Created" ? "border-[#3A85F4]" : "border-[#E8E8E8]"
				} text-center rounded-tl-[10px] rounded-bl-[10px]`}
			>
				{t("ranking.created_tab")}
			</button>
			<button
				onClick={() => dispatch(setMySpotsActiveTab("Voted"))}
				className={`w-[50%] py-[7px] border-2 ${
					activeTab === "Voted" ? "border-[#3A85F4]" : "border-[#E8E8E8]"
				} text-center rounded-br-[10px] rounded-tr-[10px]`}
			>
				{t("ranking.voted_tab")}
			</button>
		</div>
	);
};

export default MySpotsTabs;
