import {
  fullstack,
  brand,
  frontend,
  director,
  data,
  product,
  tech,
  ui,
  you,
} from "../../assets/about/members-parry";

import {useTranslation} from "react-i18next";
import MemberCard from "./MemberCard";

const TeamMobile = () => {
  const {t} = useTranslation();

  return (
    <div className="lg:hidden">
      <div className="text-2xl font-bold mb-4">
        <p>Team</p>
      </div>
      <div className="flex flex-col items-center">
        <div className="grid grid-cols-2 md:grid-cols-3 gap-8 w-max">
          <div className="flex items-center justify-center">
            <MemberCard text="Brand" icon={brand} name={"Andrea"} />
          </div>
          <div className="flex items-center justify-center">
            <MemberCard text="UI" icon={ui} name={"Charles"} />
          </div>
          <div className="flex items-center justify-center">
            <MemberCard text="Tech Lead" icon={tech} name={"Denise"} />
          </div>
          <div className="flex items-center justify-center">
            <MemberCard text="Full Stack" icon={fullstack} name={"Edwards"} />
          </div>
          <div className="flex items-center justify-center">
            <MemberCard text="Product" icon={product} name={"Franziska"} />
          </div>
          <div className="flex items-center justify-center">
            <MemberCard text="Managing Director" icon={director} name={"Max"} />
          </div>
          <div className="flex items-center justify-center">
            <MemberCard text="Data Analyst" icon={data} name={"Paul"} />
          </div>
          <div className="flex items-center justify-center">
            <MemberCard text="Frontend" icon={frontend} name={"Volodymyr"} />
          </div>
          <div className="flex items-center justify-center">
            <MemberCard
              size={"text-[12px]"}
              personal
              text={t("about.part")}
              icon={you}
              name={t("about.you")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamMobile;
