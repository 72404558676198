import {useTranslation} from "react-i18next";
import Section from "../../Section";

const Exercising = () => {
  const {t} = useTranslation();
  return (
    <Section title={t("policy.exercising.title")}>
      <p>{t("policy.exercising.text_1")}</p>
      <p>{t("policy.exercising.text_2")}</p>
    </Section>
  );
};

export default Exercising;
