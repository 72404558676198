import {useTranslation} from "react-i18next";
import {boardBig} from "../../../assets/map";
import {paths} from "../../../constants";

const Activity = () => {
  const {t} = useTranslation();
  return (
    <div className="w-full h-full flex justify-center items-center ">
      <div className="flex flex-col items-center">
        <div className="w-[87px] h-11 mb-4">
          <img className="w-full h-full" src={boardBig} alt="board-big" />
        </div>
        <div className=" text-center">
          <div className="mb-8">
            <p>{t("activity.support_text_1")}</p>
            <p>{t("activity.support_text_2")}</p>
          </div>
          <button className="w-[277px] bg-black box-border h-[54px] px-10 text-lg rounded-full text-white hover:bg-white transition-all duration-200 border-4 border-black group">
            <a
              className="relative border-black flex items-center justify-center"
              target="_blank"
              rel="noopener noreferrer"
              href={paths.support}
            >
              <p className="group-hover:opacity-0 opacity-100  transition-all duration-200">
                {t("activity.support_button")}
              </p>
              <span className="group-hover:opacity-100 opacity-0 transition-all duration-200 absolute text-2xl ">
                🫶
              </span>
            </a>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Activity;
