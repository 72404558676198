import Alarm from "./Alarm";
import { twMerge } from 'tailwind-merge'

interface Props {
  placeholder: string;
  margin?: string;
  register: any;
  name: string;
  type: string;
  error: string | undefined;
  className?: string
  alarmClassName?: string;
  setFocusStatus?: (value: boolean) => void;
}

const Input = ({placeholder, margin, register, name, type, error, className, alarmClassName, setFocusStatus}: Props) => {
  return (
    <div className={`${margin} w-full`}>
      <input
        type={type}
        className={twMerge(`formField ${error ? "border-2 border-red-600" : ""} mb-2`, className)}
        placeholder={placeholder}
        {...register(name)}
        onFocus={() => setFocusStatus?.(true)}
        onBlur={() => setFocusStatus?.(false)}
      />
      {error && <Alarm error={error} className={alarmClassName} />}
    </div>
  );
};

export default Input;
