import { useTranslation } from "react-i18next";
import { DocumentData } from "firebase/firestore";
import DragDropPhoto from "../../../shared/DragDropPhoto";

import { IDropPhoto } from "../../../hooks/useSetImage";
import trashIcon from "../../../assets/dragndrop/delete.svg";

interface IAddPhoto {
	placeImage: IDropPhoto;
	handleRemoveImage: () => void;
	handlePlacePhotoChange: (file: File) => void;
	handleSizeError: (arg: boolean) => void;
	setImageCrop: (arg: boolean) => void;
}

const AddPhoto = ({
	placeImage,
	handleRemoveImage,
	handlePlacePhotoChange,
	handleSizeError,
	setImageCrop,
}: IAddPhoto) => {
	const { t } = useTranslation();

	const handleChangeWithCrop = (file: File) => {
		handlePlacePhotoChange(file);
		setImageCrop(true);
	};

	return (
		<div className="grid grid-cols-[140px,_auto] gap-[40px] bg-[#ffffff] p-[16px] rounded-[10px] mb-[16px]">
			<div>
				<h4 className="text-xl text-[#1a1a1a]">{t("addSpot.photo")}</h4>
				<p className="text-midGray poppins text-sm">
					{t("addSpot.photo_text")}
				</p>
			</div>
			<div className="grid gap-4 w-[100%]">
				{!!placeImage.placeUrl ? (
					<div className="overflow-hidden rounded-[8px] w-[240px] relative">
						<img
							className="w-[240px] h-[140px] object-cover"
							src={placeImage.placeUrl}
							alt="SpotPhoto"
						/>
						<button
							onClick={handleRemoveImage}
							className="p-[12px] rounded-full bg-white block absolute z-2 bottom-[8px] right-[8px] opacity-70"
						>
							<img src={trashIcon} alt="icon" />
						</button>
					</div>
				) : (
					<DragDropPhoto
						handleChange={handleChangeWithCrop}
						onSizeError={handleSizeError}
						addPlaceClassName={"w-full"}
					/>
				)}
			</div>
		</div>
	);
};

export default AddPhoto;
