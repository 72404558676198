import {useTranslation} from "react-i18next";
import Section from "../../Section";

const ChildrenPol = () => {
  const {t} = useTranslation();
  return (
    <Section title={t("policy.children.title")}>
      <p>{t("policy.children.text_1")}</p>
      <p>{t("policy.children.text_2")}</p>
    </Section>
  );
};

export default ChildrenPol;
