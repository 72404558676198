import React, { useEffect, useRef, useState } from "react";
import { avatars } from "../../assets/avatar";
import Header from "../header/Header";
import { getAuth, updateProfile, onAuthStateChanged } from "firebase/auth";
import { doc, updateDoc } from "firebase/firestore";
import { uploadImageToStorage } from "../../firebase/utils/uploadImage";
import { useTranslation } from "react-i18next";
import { db } from "../../firebase/firebase";
import { useGetCurrentUser } from "../../hooks/useGetCurrentUser";
import FavoriteSpot from "./FavoriteSpot";
import About from "./About";
import Interests from "./Interests";
import ProfileInfo from "./ProfileInfo";
import ProfileEditButtons from "./ProfileEditButtons";
import useUserProfileInfo from "../../hooks/useUserProfileInfo";
import { interestsList, paths } from "../../constants";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase/firebase";

const EditProfile = () => {
	const { user } = useGetCurrentUser();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const userID = user?.uid;
	const { userName, about, interests, spot } = useUserProfileInfo(`${userID}`);
	const [profilePic, setProfilePic] = useState<null | File>(null);
	const [firebaseUserInfo, setFirebaseUserInfo] = useState(auth.currentUser);

	const [newName, setNewName] = useState(user?.displayName || "");
	const [photoURL, setPhotoURL] = useState<string>(
		user?.photoURL || avatars[1]
	);

	const [newAbout, setNewAbout] = useState("");
	const [newInterests, setNewInterests] = useState<string[]>([]);
	const [newSpot, setNewSpot] = useState<{
		longitude: number;
		latitude: number;
		zoom: number;
	} | null>(null);

	const [initialValues, setInitialValues] = useState({
		name: user?.displayName || "",
		photo: user?.photoURL || avatars[1],
		nickName: userName,
		about: about || "",
		interests: interests || [],
		spot: spot || null,
	});

	useEffect(() => {
		onAuthStateChanged(auth, setFirebaseUserInfo);
	}, []);

	useEffect(() => {
		if (firebaseUserInfo?.photoURL) {
			setPhotoURL(firebaseUserInfo.photoURL);
		}
	}, [firebaseUserInfo?.photoURL]);

	useEffect(() => {
		if (about !== null) {
			setNewAbout(about);
		}
		if (interests) {
			setNewInterests(interests);
		}
		if (spot !== null) {
			setNewSpot(spot);
		}
	}, [about, interests, spot]);

	useEffect(() => {
		if (user?.displayName) {
			setInitialValues((values) => ({
				...values,
				name: `${user?.displayName}`,
			}));
		}
		if (user?.photoURL) {
			setInitialValues((values) => ({
				...values,
				photo: `${user?.photoURL}`,
			}));
		}
		if (about !== null) {
			setInitialValues((values) => ({ ...values, about: about }));
		}
		if (interests) {
			setInitialValues((values) => ({ ...values, interests: interests }));
		}
		if (spot !== null) {
			setInitialValues((values) => ({ ...values, spot: spot }));
		}
	}, [user?.displayName, about, interests, spot, user?.photoURL]);

	const newNameHandler = (event: React.ChangeEvent<HTMLInputElement>) =>
		setNewName(event.target.value);
	const newPhotoHandler = (url: string) => setPhotoURL(url);
	const newAboutHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) =>
		setNewAbout(event.target.value);
	const newInterestsHandler = (interests: string[]) =>
		setNewInterests(interests);
	const newSpotHandler = (event: any) => {
		const { lngLat } = event;
		setNewSpot({
			longitude: lngLat.lng,
			latitude: lngLat.lat,
			zoom: 16,
		});
	};

	const updateProfilePic = async () => {
		const auth = getAuth();
		const user = auth.currentUser;

		try {
			if (user && profilePic) {
				const userId = user.uid;
				const userRef = doc(db, "users", userId);
				const photourl = await uploadImageToStorage(profilePic, userId);

				await updateProfile(auth.currentUser, {
					photoURL: photourl,
				});
				await updateDoc(userRef, { photoURL: photourl });
			}
		} catch (e) {
			console.log(e);
		}
	};

	const handleSaveChanges = async () => {
		if (!userID) return;
		const auth = getAuth();
		const currentUser = auth.currentUser;
		const userRef = doc(db, "users", userID);
		const trimmedName = newName.trim();
		const trimmedAbout = newAbout.trim();

		if (trimmedName && currentUser) {
			await updateProfile(currentUser, { displayName: trimmedName });
		}
		if (currentUser && photoURL) {
			await updateProfilePic();
		}
		await updateDoc(userRef, { about: trimmedAbout });
		await updateDoc(userRef, { interests: newInterests });
		if (newSpot) {
			await updateDoc(userRef, { favoriteSpot: newSpot });
		}
		await navigate(-1);
		sessionStorage.setItem("isModalOpen", "true");
	};

	const handleCancelChanges = () => {
		setNewName(initialValues.name);
		setPhotoURL(initialValues.photo);
		setNewAbout(initialValues.about);
		setNewInterests(initialValues.interests);
		setNewSpot(initialValues.spot);
		navigate(-1);
		sessionStorage.setItem("isModalOpen", "true");
	};

	return (
		<>
			<Header>
				<div className="flex w-1/2 items-center justify-center text-2xl">
					<p>{t("editProfile.title")}</p>
				</div>
			</Header>
			<div className="pt-[200px] lg:pt-[215px] mx-auto w-full mb-[82px] flex flex-col items-center font-poppins">
				<div className="flex-col">
					<ProfileInfo
						user={`${user?.displayName}`}
						name={newName}
						handleChange={newNameHandler}
						setPhotoURL={newPhotoHandler}
						photoURL={photoURL}
						setProfilePic={setProfilePic}
						profilePic={profilePic}
					/>
					<About about={newAbout} handle={newAboutHandler} />
					<Interests
						topics={interestsList}
						saved={interests || []}
						handle={newInterestsHandler}
					/>
					<FavoriteSpot favoriteSpot={newSpot} handle={newSpotHandler} />
				</div>
			</div>
			<ProfileEditButtons
				onSubmit={handleSaveChanges}
				onCancel={handleCancelChanges}
				submitLabel={t("editProfile.save")}
				cancelLabel={t("editProfile.cancel")}
				cancelButtonStyle="underline h-[3.5rem] mx-6"
				submitButtonStyle="text-white rounded-full bg-midGreen py-1"
				position="bottom-[2rem] right-[5rem]"
			/>
		</>
	);
};

export default EditProfile;
