import { useState, useEffect } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import EditAvatarModal from "./EditAvatarModal";

interface IProfile {
	user: string;
	name?: string;
	handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	photoURL: string;
	setPhotoURL: (arg: string) => void;
	setProfilePic: (arg: File | null) => void;
	profilePic: string | null | File;
}

const ProfileInfo = ({
	user,
	name,
	handleChange,
	setPhotoURL,
	photoURL,
	setProfilePic,
	profilePic,
}: IProfile) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [sizeError, setSizeError] = useState<boolean>(false);
	const { t } = useTranslation();

	const handleClose = () => {
		setIsOpen(false);
	};

	const updateProfilePic = async () => {
		const file = profilePic;
		if (file) {
			const fileData = new Blob([file], { type: "image/type" });
			const imageUrl = URL.createObjectURL(fileData);
			setPhotoURL(imageUrl);
		}
		handleClose();
	};

	useEffect(() => {
		const updateAvatar = async () => {
			await updateProfilePic();
		};
		updateAvatar();
	}, [profilePic]);

	const handleAvatarChange = async (file: File) => {
		setProfilePic(file);
		setSizeError(false);
	};

	return (
		<>
			<div className="block pb-2">{t("editProfile.name")}</div>
			<input
				className="h-[40px] w-custom rounded-[8px] bg-primaryGray p-3 placeholder-primaryBlack focus:outline-outlineBlue focus:bg-white focus:placeholder-transparent"
				id="name"
				type="text"
				placeholder={`${user}`}
				value={name}
				onChange={handleChange}
			/>
			<div className="flex items-center gap-2">
				<div
					className={
						"flex justify-center relative h-[114px] w-[114px] mt-2 overflow-hidden rounded-full"
					}
				>
					<img className={"h-[114px] w-[114px]"} src={photoURL} alt="avatar" />
				</div>
				<button
					onClick={() => setIsOpen(true)}
					className="flex cursor-pointer text-outlineBlue"
				>
					{t("editProfile.picture")}
				</button>
			</div>
			<div className="w-custom h-[1px] bg-underline mt-3"></div>
			<EditAvatarModal
				isOpen={isOpen}
				handleClose={handleClose}
				handleChange={handleAvatarChange}
				setSizeError={setSizeError}
				sizeError={sizeError}
			/>
		</>
	);
};

export default ProfileInfo;
