import {google, appstore} from "../assets/start-page/index";

interface Props {
  app: string;
}

const AppButton = ({app}: Props) => {
  const buttonData = {
    google: {
      link: "https://play.google.com/store/apps/details?id=com.parrybicycle&gl=DE",
      image: google,
      width: "w-[175px]",
    },
    appstore: {
      link: "https://apps.apple.com/de/app/parry-cycling-community/id1640666861?l/",
      image: appstore,
      width: "w-[160px]",
    },
  };

  return (
    <button
      className={`${
        buttonData[app as keyof typeof buttonData].width
      }  border-black text-white h-[60px] rounded-xl`}
    >
      <a
        target="_blank"
        href={buttonData[app as keyof typeof buttonData].link}
        rel="noopener noreferrer"
        className="h-full"
      >
        <div className="w-full h-full">
          <img
            alt="app"
            className="w-full h-full"
            src={buttonData[app as keyof typeof buttonData].image}
          />
        </div>
      </a>
    </button>
  );
};

export default AppButton;
