import { useEffect, useRef } from "react";

function useDebounce(callback: Function, delay: number) {
	const debounceRef = useRef<NodeJS.Timeout | null>(null);

	useEffect(() => {
		return () => {
			if (debounceRef.current !== null) {
				clearTimeout(debounceRef.current);
			}
		};
	}, []);

	function debouncedFunction(...args: any[]) {
		if (debounceRef.current !== null) {
			clearTimeout(debounceRef.current);
		}
		debounceRef.current = setTimeout(() => {
			callback(...args);
		}, delay);
	}

	return debouncedFunction;
}

export default useDebounce;
