import { useTranslation } from 'react-i18next';

interface IModalSpots {
  submitted: number | string;
  voted: number | string;

}

const ProfileModalSpots = ({submitted, voted}: IModalSpots) => {
  const { t } = useTranslation();

  return (
    <div className={"flex justify-center w-[288px] border-b-[1px] my-2"}>
    <div
      className={
        "flex flex-col justify-center items-center w-[80px] m-2"
      }
    >
      <p className={"font-medium text-3xl"}>{submitted}</p>
      <p className={"text-gray-400 font-medium text-center text-sm"}>
        {t("profileModal.submitted")}
      </p>
    </div>
    <div
      className={
        "flex flex-col justify-center items-center w-[80px] m-2"
      }
    >
      <p className={"font-medium text-3xl"}>{voted}</p>
      <p className={"text-gray-400 font-medium text-center text-sm"}>
        {t("profileModal.voted")}
      </p>
    </div>
  </div>
  )
}

export default ProfileModalSpots;