const handleTime = (num: number) => (num < 10 ? "0" + num : num);
export const getTime = (date: Date) => {
	const day = handleTime(date.getDate());
	const month = handleTime(date.getMonth() + 1);
	const year = date.getFullYear();
	const hours = handleTime(date.getHours());
	const minutes = handleTime(date.getMinutes());
	return `${day}.${month}.${year} ${hours}:${minutes}`;
};

export const getDayMonthYear = () => {
	const now = new Date(Date.now());

	const day = String(now.getDate()).padStart(2, "0");
	const month = String(now.getMonth() + 1).padStart(2, "0");
	const year = now.getFullYear();

	const formattedDate = `${day}.${month}.${year}`;

	return formattedDate;
};

export const getHoursAndMinutes = () => {
	const now = new Date(Date.now());

	let hours = now.getHours();
	const minutes = String(now.getMinutes()).padStart(2, "0");
	const ampm = hours >= 12 ? "pm" : "am";

	hours = hours % 12;
	hours = hours ? hours : 12; // Handle midnight (0 hours) as 12 am

	return `${hours}:${minutes} ${ampm}`;
};

export const formatCurrentTime = (): string => {
	const now = new Date();

	const months = now.getMonth() + 1;
	const days = now.getDate();
	const years = now.getFullYear();

	let hours = now.getHours();
	const minutes = now.getMinutes();
	const ampm = hours >= 12 ? "PM" : "AM";

	hours = hours % 12;
	hours = hours ? hours : 12; // the hour '0' should be '12'

	const monthStr = months < 10 ? "0" + months : months;
	const dayStr = days < 10 ? "0" + days : days;
	const hourStr = hours < 10 ? "0" + hours : hours;
	const minuteStr = minutes < 10 ? "0" + minutes : minutes;

	return `${monthStr}.${dayStr}.${years} ${hourStr}:${minuteStr} ${ampm}`;
};
