import {useTranslation} from "react-i18next";
import BlockContainer from "./BlockContainer";
import arrowDown from "../../assets/shared/arrow-down.svg";

const Vorteile = () => {
  const {t} = useTranslation();
  return (
    <BlockContainer styles="max-w-[735px] overflow-hidden xl:h-[465px]">
      <div className="h-full bg-[#A3E577] px-8 py-[66px] xl:p-[66px] relative">
        <div className="top-[25px] left-[89px] absolute flex gap-8">
          <img className="translate-y-[10px]" src={arrowDown} alt="" />
          <img src={arrowDown} alt="" />
        </div>
        <div className="bottom-[25px] left-1/2 absolute flex gap-4">
          <img src={arrowDown} alt="" />
          <img src={arrowDown} alt="" />
        </div>
        <div className="mb-6 text-3xl xl:text-5xl font-bold flex justify-between">
          <p>{t("qv.vorteile.title")}</p>
          <div className="-translate-y-3 flex gap-2">
            <img className="translate-y-[20px]" src={arrowDown} alt="" />
            <img src={arrowDown} alt="" />
          </div>
        </div>
        <div className="flex flex-col gap-5 leading-[22px]">
          <p>
            {t("qv.vorteile.text_1")}{" "}
            <span className="font-bold">{t("qv.vorteile.span_1")}</span>
          </p>
          <p>
            {t("qv.vorteile.text_2")}{" "}
            <span className="font-bold">{t("qv.vorteile.span_2")}</span>
          </p>
          <p>
            {t("qv.vorteile.text_3")}{" "}
            <span className="font-bold">{t("qv.vorteile.span_3")}</span>{" "}
            <span>{t("qv.vorteile.text_4")}</span>
          </p>
        </div>
      </div>
    </BlockContainer>
  );
};

export default Vorteile;
