import {character, qvPhoto} from "../../assets/qv";
import Levels from "./Levels";
import Map from "./Map";
import Parry from "./Parry";
import RXC from "./RXC";
import Token from "./Token";
import Vorteile from "./Vorteile";
import Voting from "./Voting";

const QvMobile = () => {
  return (
    <div className="xl:hidden flex justify-between w-full md:max-w-[700px] flex-col gap-y-8 items-center">
      <div className="relative">
        <Parry />
      </div>
      <div className="flex justify-center">
        <img src={qvPhoto} alt="qv" className="max-w-[400px] w-full" />
      </div>
      <RXC />
      <Voting />
      <Vorteile />
      <Token />
      <Levels />
      <Map />
      <div className="w-full flex justify-end mb-8">
        <img
          className="xl:hidden w-[92px]"
          src={character}
          alt="character-icon"
        />
      </div>
    </div>
  );
};

export default QvMobile;
