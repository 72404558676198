import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useGetPath from "../../../hooks/useGetPath";
import { IProjectCard } from "../../../interfaces";
import { setSelectedProjectLink } from "../../../redux/api/projectCardsSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import ProjectCard from "../../projects/ProjectCard";
import useGetCardsInfo from "../../../hooks/useGetCardsInfo";
import useGetProjects from "../../../hooks/useGetProjects";
import { Project } from "../../../interfaces";
import { projectImage } from "../../../assets/shared";
import { ProjectList } from "../../projects/ProjectList";
import withExtraComponent from "../../../utils/HOCs/withExtraComponent";

const NewProjectsList = withExtraComponent(
	ProjectList,
	"flex gap-x-4 gap-y-6 w-full justify-start flex-wrap overflow-y-auto h-full scroll-transparent scroll-thumb-green scroll-wide py-3 px-2"
);

const ProjectsModal = () => {
	const { t } = useTranslation();
	// useGetProjects();
	const cards = useAppSelector((state) => state.projectCards.projects);

	const pathName = useGetPath();
	const dispatch = useAppDispatch();
	useEffect(() => {
		dispatch(setSelectedProjectLink(pathName));
	});

	return (
		<div className="max-w-[1057px] h-[568px] bg-white rounded-2xl px-4 pt-6 ">
			<div className="border-b border-[#C8D2C2] ml-2 mr-10 mb-2">
				<p className="font-poppinsBold text-2xl mb-1">
					{t("projects-modal.title")}
				</p>
				<p className="text-[16px] mb-4">{t("projects-modal.text")}</p>
			</div>
			<div className="h-[456px] w-full overflow-hidden">
				<NewProjectsList />
			</div>
		</div>
	);
};

export default ProjectsModal;
