import { useEffect } from "react";
import { setIsMobile } from "../redux/api/elementsViewSlice";
import { useAppDispatch, useAppSelector } from "../redux/hooks";

const useGetViewport = () => {
  const dispatch = useAppDispatch();

  const isMobile = useAppSelector((state) => state.elementsView.isMobile);
  useEffect(() => {
    dispatch(setIsMobile(document.documentElement.clientWidth));
    window.addEventListener("resize", () => {
      dispatch(setIsMobile(document.documentElement.clientWidth));
    });
    return () => {
      window.removeEventListener("resize", () => {
        setIsMobile(document.documentElement.clientWidth);
      });
    };
  }, []);
  return isMobile;
};

export default useGetViewport;
