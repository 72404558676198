import {useEffect, useRef} from "react";

const useHandleScrollHide = () => {
  const element = useRef<any>(null);

  useEffect(() => {
    const showScroll = () => {
      element.current?.classList.remove("scroll-thumb-white");
      element.current?.classList.add("scroll-thumb-lightgray");
    };
    const hideScroll = () => {
      const scroll = element.current?.scrollTop;
      setTimeout(() => {
        const currentScroll = element.current?.scrollTop;
        if (currentScroll === scroll) {
          element.current?.classList.remove("scroll-thumb-lightgray");
          element.current?.classList.add("scroll-thumb-white");
        }
      }, 1000);
    };
    element.current?.addEventListener("scroll", showScroll);
    element.current?.addEventListener("scrollend", hideScroll);
    return () => {
      element.current?.removeEventListener("scroll", showScroll);
      element.current?.removeEventListener("scrollend", showScroll);
    };
  }, []);

  return element;
};

export default useHandleScrollHide;
