import { LngLatLike } from "mapbox-gl";
import { Marker } from "react-map-gl";
interface Props {
	center: LngLatLike;
	isSearchingPlace: boolean;
	onMarkerDragEnd?: (arg: [number, number]) => void;
}

const MapMarker = ({ center, isSearchingPlace, onMarkerDragEnd }: Props) => {
	const [lng, lat] = center as [number, number];

	const handleDragEnd = (event: any) => {
		if (onMarkerDragEnd) {
			const newLngLat = [event.lngLat.lng, event.lngLat.lat] as [
				number,
				number
			];
			onMarkerDragEnd(newLngLat);
		}
	};

	return (
		<Marker
			longitude={lng}
			latitude={lat}
			anchor="bottom"
			draggable={!isSearchingPlace}
			{...(!isSearchingPlace ? { onDragEnd: handleDragEnd } : {})}
		>
			<svg
				width="44"
				height="50"
				viewBox="0 0 44 48"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g filter="url(#filter0_d_3719_52137)">
					<path
						d="M22 2C31.45 2 40 9.406 40 20.86C40 28.496 33.9925 37.535 22 48C10.0075 37.535 4 28.496 4 20.86C4 9.406 12.55 2 22 2Z"
						fill="#1A1A1A"
						fillOpacity="0.9"
						shapeRendering="crispEdges"
					/>
					{isSearchingPlace && (
						<path
							d="M22 4C30.3658 4 38 10.5308 38 20.86C38 24.1508 36.7023 27.9359 33.8307 32.2633C31.1796 36.2584 27.2538 40.614 22 45.3295C16.7462 40.614 12.8204 36.2584 10.1693 32.2633C7.29772 27.9359 6 24.1508 6 20.86C6 10.5308 13.6342 4 22 4Z"
							stroke="white"
							strokeWidth="4"
							shapeRendering="crispEdges"
						/>
					)}
				</g>
				<defs>
					<filter
						id="filter0_d_3719_52137"
						x="0"
						y="0"
						width="44"
						height="54"
						filterUnits="userSpaceOnUse"
						colorInterpolationFilters="sRGB"
					>
						<feFlood floodOpacity="0" result="BackgroundImageFix" />
						<feColorMatrix
							in="SourceAlpha"
							type="matrix"
							values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
							result="hardAlpha"
						/>
						<feOffset dy="2" />
						<feGaussianBlur stdDeviation="2" />
						<feComposite in2="hardAlpha" operator="out" />
						<feColorMatrix
							type="matrix"
							values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
						/>
						<feBlend
							mode="normal"
							in2="BackgroundImageFix"
							result="effect1_dropShadow_3719_52137"
						/>
						<feBlend
							mode="normal"
							in="SourceGraphic"
							in2="effect1_dropShadow_3719_52137"
							result="shape"
						/>
					</filter>
				</defs>
			</svg>
		</Marker>
	);
};

export default MapMarker;
