import React, { useEffect, useState } from "react";
import { AuthButton } from "./AuthButton";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { paths } from "../../constants";
import { useTranslation } from "react-i18next";
import { appleLogo, emailIcon, googleLogo } from "../../assets/auth";
import {
	createProfileInFirestore,
	signInWithApple,
	signInWithGoogle,
} from "../../firebase/auth";
import { SignUpForm } from "./SignUpForm";
import { AuthStep } from "../../types";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { getAuth } from "firebase/auth";
import { isProjectRoute } from "../../utils/checkRoute";

interface IProps {
	authStep: AuthStep;
	handleClose: () => void;
	handleGoToStep: (newStep: AuthStep) => void;
}

export const AuthOptions = ({
	authStep,
	handleClose,
	handleGoToStep,
}: IProps) => {
	const { t } = useTranslation();

	const isUserInDataBase = async () => {
		const userID = getAuth().currentUser?.uid;
		const docRef = doc(db, "users", `${userID}`);
		const docSnap = await getDoc(docRef);
		return !!docSnap.data();
	};
	const navigate = useNavigate();
	const location = useLocation();

	const handleCreateUserInDataBase = (
		isUserInDB: boolean,
		nextStep: AuthStep
	) => {
		const isRankingEnabled = isProjectRoute(location.pathname);
		if (isUserInDB) {
			if (!isRankingEnabled) {
				navigate(paths.projects);
			}

			handleClose();
		} else {
			createProfileInFirestore().then(() => {
				localStorage.setItem("first_login", "true");
				handleGoToStep(nextStep);
			});
		}
	};

	const variantsButtons = [
		{
			key: 1,
			title: t(
				`auth.buttons.${
					authStep !== "createAccount" ? "google" : "googleSignUp"
				}`
			),
			icon: googleLogo,
			onClick: () =>
				signInWithGoogle().then(() => {
					isUserInDataBase().then((isUserInDB) =>
						handleCreateUserInDataBase(isUserInDB, "googleSignUp")
					);
				}),
		},
		{
			key: 2,
			title: t(
				`auth.buttons.${authStep !== "createAccount" ? "apple" : "appleSignUp"}`
			),
			icon: appleLogo,
			onClick: () => {
				signInWithApple(() => {
					isUserInDataBase().then((isUserInDB) =>
						handleCreateUserInDataBase(isUserInDB, "appleSignUp")
					);
				});
			},
		},
		{
			key: 3,
			title: t("auth.buttons.email"),
			icon: emailIcon,
			condition: authStep === "basic",
			onClick: () => handleGoToStep("loginEmail"),
		},
	];

	return (
		<>
			<h2 className="font-semibold text-2xl text-center	mb-6">
				{t(`auth.${authStep !== "createAccount" ? "title" : "signUpTitle"}`)}
			</h2>
			<p className="font-medium	text-base mb-8">
				{t(
					`auth.${
						authStep !== "createAccount" ? "description" : "signUpDescription"
					}`
				)}
			</p>
			<div className="flex flex-col	gap-y-4 mb-3">
				{variantsButtons.map(
					({ key, icon, title, onClick, condition = true }) =>
						condition && (
							<AuthButton
								layoutType="secondary"
								text={title}
								key={key}
								icon={icon}
								onClick={onClick}
							/>
						)
				)}
			</div>
			<div className="flex items-center gap-x-4 w-full mb-2">
				<hr className="bg-[#D9D9D9] w-full h-px" />
				<span>{t("auth.or")}</span>
				<hr className="bg-[#D9D9D9] w-full h-px" />
			</div>
			{authStep === "basic" ? (
				<>
					<AuthButton
						onClick={() => handleGoToStep("createAccount")}
						text={t("auth.buttons.createAccount")}
						className="mb-16 mt-5"
					/>
					<p className="text-sm	font-medium	text-[#A0A0A0] text-center mb-2">
						{t("auth.agreementText")}{" "}
						<Link to={paths.terms} target="_blank" className="underline">
							{t("footer.links.terms")}
						</Link>{" "}
						{t("auth.and")}{" "}
						<Link to={paths.policy} target="_blank" className="underline">
							{t("footer.links.policy")}
						</Link>
						{t("auth.zu")}
					</p>
				</>
			) : (
				<SignUpForm handleGoToStep={(newStep) => handleGoToStep(newStep)} />
			)}
		</>
	);
};
