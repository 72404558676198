import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {ReactComponent as Favorite} from "../../assets/editProfile/favorite.svg";
import ReactMapGL, {Marker} from "react-map-gl";
import {mapboxAccessToken, mapStyle} from "../../constants";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {setViewState} from "../../redux/api/locationSlice";

interface IModalMap {
  spot: {
    longitude: number;
    latitude: number;
    zoom: number;
  } | null;
}

const ProfileModalMap = ({spot}: IModalMap) => {
  const {t} = useTranslation();
  const viewState = useAppSelector((state) => state.location.viewState);

  return (
    <div className="flex-col  w-[18rem] mt-2 mb-4">
      <p>{t("profileModal.favSpot")}</p>
      <div className="flex mt-1 w-full h-[7.5rem] rounded-xl overflow-hidden border-b-[1px]">
        <div className="flex-col w-full h-[full] cursor-pointer items-center text-center justify-center">
          <ReactMapGL
            {...(spot === null ? {...viewState} : {...spot})}
            mapboxAccessToken={mapboxAccessToken}
            mapStyle={mapStyle}
          >
            {spot ? (
              <Marker latitude={spot.latitude} longitude={spot.longitude}>
                <Favorite />
              </Marker>
            ) : (
              <></>
            )}
          </ReactMapGL>
        </div>
      </div>
    </div>
  );
};

export default ProfileModalMap;
