import { useEffect, useRef } from 'react';

export const useDebounceCallback = <T extends (...args: any[]) => Promise<any>>(
  callback: T,
  delay = 500
) => {
  const timeoutRef = useRef<NodeJS.Timeout>();

  const debouncedCallback = (...args: Parameters<T>) => {
    clearTimeout(timeoutRef.current!);
    return new Promise<any>((resolve, reject) => {
      timeoutRef.current = setTimeout(async () => {
        try {
          const result = await callback(...args);
          resolve(result);
        } catch (error) {
          reject(error);
        }
      }, delay);
    });
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current!);
    };
  }, []);

  return debouncedCallback;
};