import {Link} from "react-router-dom";
import {paths} from "../constants";
import {useTranslation} from "react-i18next";

const HeaderLink = () => {
  const {t} = useTranslation();
  return (
    <div className="flex w-full justify-center items-center text-lg font-poppins font-semibold">
      <Link
        className="border-4 border-black rounded-full px-6 py-2 transition-all duration-300 hover:bg-black hover:text-white"
        to={paths.projects}
      >
        {t("header.initiatives")}
      </Link>
    </div>
  );
};

export default HeaderLink;
