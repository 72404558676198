import {useState} from "react";
import {Button, Input, Radio, Textarea, Title} from "../../shared/index";
import {useForm} from "react-hook-form";
import {IContactForm} from "../../interfaces";
import {yupResolver} from "@hookform/resolvers/yup";
import {contactFormSchema} from "../../utils/validation";
import {parrot, parrotSent} from "../../assets/shared/index";

import {useTranslation} from "react-i18next";
import {addNewInitiative} from "../../firebase/addInitiative";
import Loader from "../../shared/Loader";
import Arrows from "./Arrows";
import {getTime} from "../../utils/getTime";
import {useNotifyDiscordMutation} from "../../redux/api/apiSlice";

const ContactForm = () => {
  const {t} = useTranslation();
  const [radio, setRadio] = useState(false);
  const [isSucceed, setIsSucceed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: {errors, isValid},
    getValues,
  } = useForm<IContactForm>({
    mode: "onSubmit",
    resolver: yupResolver(contactFormSchema),
    values: {userName: "", email: "", initiative: ""},
  });

  const [notifyDiscord] = useNotifyDiscordMutation();

  const onSubmit = async () => {
    try {
      if (!radio) return;
      setIsLoading(true);
      const date = new Date();
      const newInitiative = {...getValues(), date: date.toISOString()};
      const body = {
        content: "project alert",
        tts: false,
        embeds: [
          {
            title: `A new project was filed at ${getTime(date)}`,
            description: getValues().initiative,
          },
        ],
      };
      const discordOk = await notifyDiscord(body);

      const firebaseResponse = await addNewInitiative(newInitiative);
      //@ts-ignore
      if (!discordOk.data) {
        setIsLoading(false);
        console.log("Discord: Something went wrong");
        return;
      }
      if (!firebaseResponse.success) {
        setIsLoading(false);
        console.log("Firebase: Something went wrong");
        return;
      }
      setIsLoading(false);
      setIsSucceed(true);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="bg-lightGreen relative w-[708px] rounded-xl px-10 py-8">
      {isLoading && (
        <div className="absolute top-0 left-0 w-full h-full z-40 rounded-xl">
          <Loader />
        </div>
      )}
      <div className="absolute bottom-[-30px] right-[-55px] flex flex-col justify-center items-end">
        <div className={`mb-2 relative z-30 ${isSucceed ? "block" : "hidden"}`}>
          <div className="flex w-[266px] text-center h-[133px] justify-center items-center text-sm bg-textWindow bg-no-repeat">
            <p className="px-6 pt-3 pb-6">{t("contact.form.message")}</p>
          </div>
        </div>
        <div>
          <img src={isSucceed ? parrotSent : parrot} alt="parrot-icon" />
        </div>
      </div>
      <form className="z-0 relative" onSubmit={handleSubmit(onSubmit)}>
        <Arrows />
        <div>
          <div className="mb-4 relative">
            <Title margin="mb-2">{t("contact.form.comment.title")}</Title>
            <Textarea
              placeholder={t("contact.form.comment.placeholder")}
              register={register}
              name="initiative"
              type="text"
              error={errors.initiative?.message}
            />
          </div>
          <div className="mb-6">
            <Title margin="mb-2">{t("contact.form.personal.title")}</Title>
            <div className="flex justify-between gap-6">
              <Input
                placeholder="Name"
                register={register}
                margin="mb-4"
                name="userName"
                type="text"
                error={errors.userName?.message}
              />
              <Input
                placeholder="E-Mail"
                register={register}
                margin="mb-4"
                name="email"
                type="email"
                error={errors.email?.message}
              />
            </div>
          </div>
          <button
            type="button"
            onClick={() => setRadio(!radio)}
            className="flex mb-6 items-center gap-x-2 cursor-pointer"
          >
            <Radio radio={radio} />
            <Title>{t("contact.form.agreement")}</Title>
          </button>
          <Button text={t("contact.form.send")} disabled={!isValid || !radio} />
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
