import { share } from "../../../../../assets/shared";
import useClickOutside from "../../../../../hooks/useClickOutside";
import ShareSpotWindow from "./ShareSpotWindow";
interface Props {
	street: string;
	image: string;
}

const ShareButton = ({ image, street }: Props) => {
	const { element, isVisible, clickOutSide } =
		useClickOutside<HTMLDivElement>();

	return (
		<>
			{isVisible && (
				<div className="fixed z-[100] flex items-center justify-center w-screen h-screen bg-black/50 top-0 left-0">
					<div ref={element}>
						<ShareSpotWindow image={image} street={street} />
					</div>
				</div>
			)}
			<button
				onClick={() => clickOutSide(true)}
				className="bg-[#F1F1F1] flex items-center justify-center h-8 w-8 rounded-full"
			>
				<img src={share} alt="share" />
			</button>
		</>
	);
};

export default ShareButton;
