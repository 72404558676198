import {useTranslation} from "react-i18next";
import Section from "../../Section";

const GDPRRights = () => {
  const {t} = useTranslation();
  return (
    <Section title={t("policy.gdpr-rights.title")}>
      <p>{t("policy.gdpr-rights.text_1")}</p>
      <p>{t("policy.gdpr-rights.text_2")}</p>
      <ul className="list-disc pl-10">
        <li>{t("policy.gdpr-rights.point_1")}</li>
        <li>{t("policy.gdpr-rights.point_2")}</li>
        <li>{t("policy.gdpr-rights.point_3")}</li>
        <li>{t("policy.gdpr-rights.point_4")}</li>
        <li>{t("policy.gdpr-rights.point_5")}</li>
        <li>{t("policy.gdpr-rights.point_6")}</li>
      </ul>
    </Section>
  );
};

export default GDPRRights;
