import React, {ChangeEventHandler, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

interface IAbout {
  about: string;
  handle: ChangeEventHandler<HTMLTextAreaElement>;
}

const About = ({about, handle}: IAbout) => {
  const {t} = useTranslation();
  const placeholderText = t("editProfile.aboutPlaceholder");
  const [placeholder, setPlaceholder] = useState(placeholderText);
  useEffect(() => {
    about !== "" ? setPlaceholder(`${about}`) : setPlaceholder(placeholderText);
  }, [about]);

  return (
    <div className="flex-col border-b-[1px] border-underline pb-3 mb-3">
      <div className="flex-col py-2">{t("editProfile.about")}</div>
      <textarea
        id="name"
        placeholder={placeholder}
        className="h-[120px] w-custom rounded-[8px] bg-primaryGray p-3 placeholder-midGray focus:outline-outlineBlue focus:bg-white focus:placeholder-transparent resize-none"
        value={about}
        onChange={handle}
      />
    </div>
  );
};

export default About;
