import {useTranslation} from "react-i18next";
import {rxc} from "../../assets/qv";
import BlockContainer from "./BlockContainer";
import TextBlock from "./TextBlock";
import {paths} from "../../constants";

const RXC = () => {
  const {t} = useTranslation();
  const paragraphs = [
    t("qv.radical.text_1"),
    t("qv.radical.text_2"),
    t("qv.radical.text_3"),
  ];

  return (
    <BlockContainer styles="xl:max-w-[409px] xl:h-[723px]  overflow-hidden">
      <div className="px-8 py-[60px] xl:p-[60px] bg-[#F0FD62] h-full  flex flex-col gap-10  justify-between">
        <div>
          <div className="flex justify-center mb-10">
            <img src={rxc} alt="radical-change" />
          </div>
          <TextBlock paragraphs={paragraphs} />
        </div>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={paths.radical}
          className="bg-black py-4  text-white rounded-full whitespace-nowrap text-center"
        >
          Besuche radicalXchange
        </a>
      </div>
    </BlockContainer>
  );
};

export default RXC;
