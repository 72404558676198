import { useAppSelector } from "../redux/hooks";
import { joinedProjectsIds } from "../redux/api/userSlice";

const useIsJoinedToProject = () => {
	const currentOpenedProject = useAppSelector(
		(state) => state.projectCards.selectedProjectId
	);
	const joinedProjects = useAppSelector(joinedProjectsIds);
	const projects = useAppSelector((state) => state.user.isJoinedToProjects);

	const isUserClaimedTokens = projects.find(
		(project) => project.project === currentOpenedProject
	);

	const joinedToProject =
		currentOpenedProject !== null
			? joinedProjects.includes(currentOpenedProject)
			: false;

	return {
		hasUserJoinedProject: joinedToProject,
		hasUserClaimedVerification: isUserClaimedTokens?.claimedVerifyTokens,
	};
};

export default useIsJoinedToProject;
