interface Props {
  children: React.ReactNode;
  title: string;
  textAlign?: string;
  marginBottom?: string;
}
const Section = ({children, title, textAlign, marginBottom}: Props) => {
  return (
    <div
      className={`flex flex-col max-w-[515px] ${textAlign} ${marginBottom} items-center text-center lg:text-left`}
    >
      <p className="font-bold text-[36px] mb-6">{title}</p>
      <div className="text-2xl flex flex-col gap-y-6">{children}</div>
    </div>
  );
};

export default Section;
