import {useSearchParams} from "react-router-dom";

const useSetQuery = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setQuery = (query: string) => {
    searchParams.set("id", query);
    setSearchParams(searchParams);
  };
  return setQuery;
};

export default useSetQuery;
