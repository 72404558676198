import { useEffect } from "react";

const useDisableScroll = (isMobile: boolean, isVisible: boolean) => {
  useEffect(() => {
    if (isMobile && isVisible) {
      document.body.style.height = "100%";
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.height = "auto";
      document.body.style.overflow = "auto";
    }
  }, [isMobile, isVisible]);
};

export default useDisableScroll;
