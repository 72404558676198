import { IFeature } from "../../../interfaces";
import { placeMarker } from "../../../redux/api/locationSlice";
import { useAppDispatch } from "../../../redux/hooks";

interface Props {
  location: IFeature;
  clickOutSide: (isVisible: boolean) => void;
}

const LocationResult = ({ location, clickOutSide }: Props) => {
  const dispatch = useAppDispatch();

  const selectLocation = (location: IFeature) => {
    dispatch(placeMarker(location));
    clickOutSide(false);
  };
  return (
    <div className="p-2 border-b last:border-none">
      <button onClick={() => selectLocation(location)}>
        {location.place_name}
      </button>
    </div>
  );
};

export default LocationResult;
