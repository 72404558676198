import Section from "./Section";
import AppButton from "../../shared/AppButton";
import PhoneSlider from "./PhoneSlider";
import { useTranslation } from "react-i18next";

const Phone = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col lg:flex-row items-center justify-center gap-8 lg:gap-5 mb-[60px] px-4 lg:px-12">
      <PhoneSlider />
      <div className="order-1 lg:order-2 flex flex-col gap-y-6">
        <Section textAlign="lg:items-start" title={t("start-page.voice.title")}>
          <p>{t("start-page.voice.text_1")}</p>
          <p>{t("start-page.voice.text_2")}</p>
        </Section>
        <div className="hidden lg:flex items-center gap-4 ">
          <AppButton app={"appstore"} />
          <AppButton app={"google"} />
        </div>
      </div>
    </div>
  );
};

export default Phone;
