import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LngLatLike } from "mapbox-gl";
import { BERLIN_COORDINATES } from "../../constants";
import { IDistrict, IFeature, IViewState } from "../../interfaces";

interface initialState {
	locationData: null | IFeature;
	center: LngLatLike | [number, number];
	zoom: number;
	viewState: IViewState;
	markerLocation: LngLatLike | [number, number] | null;
	addPlace: null | [number, number];
	placeCreationStatus: "idle" | "created";
	editPlace: boolean;
}

// type IAddPlace = Omit<IViewState, "zoom">;

const initialState: initialState = {
	locationData: null,
	center: BERLIN_COORDINATES,
	zoom: 12,
	markerLocation: null,
	viewState: {
		longitude: 13.4090828,
		latitude: 52.520815,
		zoom: 12,
	},
	addPlace: null,
	placeCreationStatus: "idle",
	editPlace: false,
};

export const locationSlice = createSlice({
	name: "location",
	initialState,
	reducers: {
		setLocation: (state, action: PayloadAction<IFeature>) => {
			state.locationData = action.payload;
			state.center = action.payload.center;
		},
		setDisrict: (state, action: PayloadAction<IDistrict>) => {
			state.center = action.payload.coords;
			if (action.payload.name === "All") {
				state.zoom = 3;
				return;
			}
			state.zoom = 12.5;
		},
		setViewState: (state, action: PayloadAction<any>) => {
			state.viewState = action.payload;
		},
		setCenter: (state, action: PayloadAction<[number, number]>) => {
			state.center = action.payload;
		},
		placeMarker: (state, action: PayloadAction<IFeature>) => {
			state.markerLocation = action.payload.center;
		},
		zoomDetailedView: (state, action: PayloadAction<[number, number]>) => {
			state.center = action.payload;
			state.zoom = 14.5;
		},
		setAddPlace: (state, action: PayloadAction<[number, number] | null>) => {
			state.addPlace = action.payload;
		},
		setPlaceCreationStatus: (
			state,
			action: PayloadAction<"idle" | "created">
		) => {
			state.placeCreationStatus = action.payload;
		},
		setEditPlace: (state, action: PayloadAction<boolean>) => {
			state.editPlace = action.payload;
		},
	},
});

export const {
	setLocation,
	setDisrict,
	setViewState,
	setCenter,
	placeMarker,
	zoomDetailedView,
	setAddPlace,
	setPlaceCreationStatus,
	setEditPlace,
} = locationSlice.actions;
export default locationSlice.reducer;
