import { User } from "@firebase/auth";
import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { boolean } from "yup";
import { AuthStep } from "../../types";
import { RootState } from "../app/store";

interface AuthInfo {
	openAuthModal?: boolean;
	authStep?: AuthStep;
	isOpenedFrom?: null | "Project";
}

interface IVerifyInfo {
	isOpen: boolean;
	openedFrom: "profile" | "voting";
}

export interface IProjectUserInfo {
	project: string;
	claimedVerifyTokens: boolean;
	tokens: number;
}

interface IInitialState {
	userData: null | User;
	// tokens: number | null;
	verifyWindow: IVerifyInfo;
	hasUserVerified: boolean;
	isJoinedToProjects: IProjectUserInfo[];
	auth: AuthInfo;
	openClaimTokensWindow: boolean;
	openJoinProjectWindow: boolean;
	openGuideWindow: boolean;
	submittedSpotsLength: number;
	votedSpotsLength: number;
}

const initialState: IInitialState = {
	userData: null,
	// tokens: null,
	auth: { openAuthModal: false, authStep: "basic", isOpenedFrom: null },
	openClaimTokensWindow: false,
	verifyWindow: { isOpen: false, openedFrom: "profile" },
	hasUserVerified: false,
	isJoinedToProjects: [],
	openJoinProjectWindow: false,
	openGuideWindow: false,
	submittedSpotsLength: 0,
	votedSpotsLength: 0,
};

export const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		setUser: (state, action: PayloadAction<User | null>) => {
			state.userData = action.payload;
		},
		setTokens: (
			state,
			action: PayloadAction<{ currentProject: string; tokens: number }>
		) => {
			const { currentProject, tokens } = action.payload;

			const projectIndex = state.isJoinedToProjects.findIndex(
				(p: IProjectUserInfo) => p.project === currentProject
			);

			if (projectIndex !== -1) {
				state.isJoinedToProjects[projectIndex].tokens = tokens;
			}
		},
		setVerifyWindow: (state, action: PayloadAction<IVerifyInfo>) => {
			state.verifyWindow = { ...state.verifyWindow, ...action.payload };
		},
		setHasUserVerified: (state, action: PayloadAction<boolean>) => {
			state.hasUserVerified = action.payload;
		},
		setIsJoinedToProjects: (
			state,
			action: PayloadAction<IProjectUserInfo | IProjectUserInfo[]>
		) => {
			if (Array.isArray(action.payload)) {
				state.isJoinedToProjects = action.payload;
				return;
			}
			const projectIds = state.isJoinedToProjects.map(
				(item: IProjectUserInfo) => item.project
			);
			if (projectIds.includes(action.payload.project)) return;
			state.isJoinedToProjects.push(action.payload);
		},
		setAuth: (state, action: PayloadAction<AuthInfo>) => {
			state.auth = { ...state.auth, ...action.payload };
		},
		setOpenClaimTokensWindow: (state, action: PayloadAction<boolean>) => {
			state.openClaimTokensWindow = action.payload;
		},
		setOpenJoinProjectWindow: (state, action: PayloadAction<boolean>) => {
			state.openJoinProjectWindow = action.payload;
		},
		setOpenGuideWindow: (state, action: PayloadAction<boolean>) => {
			state.openGuideWindow = action.payload;
		},
		setSubmittedSpotsLength: (state, action: PayloadAction<number>) => {
			state.submittedSpotsLength = action.payload;
		},
		setVotedSpotsLength: (state, action: PayloadAction<number>) => {
			state.votedSpotsLength = action.payload;
		},
	},
});

export const {
	setUser,
	setTokens,
	setVerifyWindow,
	setHasUserVerified,
	setIsJoinedToProjects,
	setAuth,
	setOpenClaimTokensWindow,
	setOpenJoinProjectWindow,
	setOpenGuideWindow,
	setSubmittedSpotsLength,
	setVotedSpotsLength,
} = userSlice.actions;
export default userSlice.reducer;

export const joinedProjectsIds = createSelector(
	(state: RootState) => state.user.isJoinedToProjects,
	(joinedProjects) => {
		return joinedProjects.map((item: IProjectUserInfo) => item.project);
	}
);

export const getCurrentTokens = createSelector(
	(state: RootState) => state.user.isJoinedToProjects,
	(state: RootState) => state.projectCards.selectedProjectId,
	(joinedProjects, selectedProject) => {
		if (!selectedProject || !joinedProjects) return 0;

		return (
			joinedProjects.find((project) => project.project === selectedProject)
				?.tokens || 0
		);
	}
);
