import { DocumentData } from "@firebase/firestore";
import { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { cutString } from "../../../utils/cutString";
import SpotTag from "../../../shared/SpotTag";
import {
	selectSpot,
	setHoveredCardId,
	setSelectedCard,
} from "../../../redux/api/spotsSlice";
import {
	setScrollLevel,
	setSpotCardView,
} from "../../../redux/api/elementsViewSlice";
import { zoomDetailedView } from "../../../redux/api/locationSlice";
import useSetQuery from "../../../hooks/useSetQuery";

interface Props {
	spot: DocumentData;
	ranking: number;
	getCurrentScroll: () => number | undefined;
}

const SpotCard = ({ spot, ranking, getCurrentScroll }: Props) => {
	const dispatch = useAppDispatch();

	const section = useAppSelector((state) => state.elementsView.section);
	const mySpotsActiveTab = useAppSelector(
		(state) => state.elementsView.mySpotsActiveTab
	);
	const selectedSpot = useAppSelector((state) => state.spots.selectedSpot)!;
	const spotCard = useRef<HTMLButtonElement>(null)!;
	const setQuery = useSetQuery();
	useEffect(() => {
		spotCard.current?.addEventListener("mouseenter", () => {
			dispatch(setHoveredCardId(spot.id));
		});
		spotCard.current?.addEventListener("mouseleave", () => {
			dispatch(setHoveredCardId(null));
		});
		return () => {
			spotCard.current?.removeEventListener("mouseenter", () => {
				dispatch(setHoveredCardId(spot.id));
			});
			spotCard.current?.removeEventListener("mouseleave", () => {
				dispatch(setHoveredCardId(null));
			});
		};
	}, []);

	useEffect(() => {
		if (selectedSpot?.id === spot.id)
			spotCard.current?.scrollIntoView({ behavior: "smooth" });
	}, [selectedSpot]);

	const onClickHandler = () => {
		setQuery(spot.id);
		const scrollLevel = getCurrentScroll();
		dispatch(setSelectedCard(spot));
		dispatch(selectSpot(spot));
		dispatch(setHoveredCardId(null));
		dispatch(setSpotCardView(true));
		dispatch(zoomDetailedView([spot.latitude, spot.longitude]));
		scrollLevel && dispatch(setScrollLevel(scrollLevel));
	};

	const renderSecondaryTags = (tags: string[]) => {
		if (!tags || tags.length === 0) return;
		return (
			<div className="flex">
				<SpotTag type={spot.secondaryTags[0]} />
				{tags.length > 1 && (
					<span className="ml-4 translate-y-[2px]">+{tags.length - 1}</span>
				)}
			</div>
		);
	};
	const rankingWidth = () => {
		return 16 + 8 * String(ranking).length;
	};
	return (
		<button
			ref={spotCard}
			onClick={onClickHandler}
			id={spot.id}
			className={`h-[116px] relative group bg-white mb-4 w-full rounded-[10px] shadow-spotShadow hover:shadow-none overflow-hidden flex justify-start  `}
		>
			{section === "MySpots" && mySpotsActiveTab === "Created" ? null : (
				<div
					style={{ width: rankingWidth() }}
					className="bg-black rounded-full absolute top-2 h-6 left-2 text-white font-poppinsBold text-[12px] leading-3 z-40 flex items-center justify-center"
				>
					<p className="translate-y-[1px]">{ranking}</p>
				</div>
			)}
			<div
				className={`transition-all duration-100 hover:border-2 hover:border-outlineBlue ${
					selectedSpot?.id === spot.id ? "border-2 border-outlineBlue" : ""
				} rounded-[10px] z-40 absolute w-full h-full`}
			></div>
			<div className="w-[30%] h-full relative">
				<div
					style={{ borderColor: spot.color }}
					className="absolute right-0 top-1/2 h-16 w-16 flex justify-center items-center rounded-full bg-white border-8  -translate-y-1/2 translate-x-1/2"
				>
					{spot.total_votes}
				</div>
				<img
					src={spot.picture}
					className="w-full h-full object-cover"
					alt="spot-card"
				/>
			</div>
			<div className="flex p-4 pl-11 w-[70%] justify-between">
				<div className="flex flex-col items-start">
					<div className="mb-2 text-sm leading-5 font-poppinsBold">
						{(spot.streetName && cutString(spot.streetName, 40)) || ""}
					</div>
					<div className=" gap-4 flex mb-2 text-[12px] items-center leading-4">
						<SpotTag type={spot.type} />
						<div>{renderSecondaryTags(spot.secondaryTags)}</div>
					</div>
					<div className="text-[12px] w-full text-start leading-4 h-8 ">
						{cutString(spot.description || "")}
					</div>
				</div>
				<div className="text-[12px] w-16 text-[#717171]">{spot.date}</div>
			</div>
		</button>
	);
};

export default SpotCard;
