import { useState } from "react";
import { useTranslation } from "react-i18next";

import { arrayUnion } from "firebase/firestore";
import DetailedViewTitle from "./DetailedViewTitle";
import { useGetCurrentUser } from "../hooks/useGetCurrentUser";
import {
	setOpenClaimTokensWindow,
	setIsJoinedToProjects,
} from "../redux/api/userSlice";
import useUserProfileInfo from "../hooks/useUserProfileInfo";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { setTokens } from "../redux/api/userSlice";
import { Modal } from "./Modal";

import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";
import Lottie from "react-lottie";
import claimTokensPary from "../assets/detailedView/claimTokensPary.png";
import claimTokensParyDe from "../assets/detailedView/claimTokensParyDe.png";
import claimTokensIcon from "../assets/detailedView/claimTokensIcon.svg";
import claimVerificationTokensEn from "../assets/shared/parryClaimVerification.png";
import claimVerificationTokensDe from "../assets/shared/parryClaimVerificationDe.png";
import claimTokensAnimation from "../assets/animation/tokensCompleted.json";

import { ProjectTokens } from "../interfaces";
import { ProjectText } from "../interfaces";
import { getCurrentTokens } from "../redux/api/userSlice";
import { updateTokensForSelectedProject } from "../redux/thunks";

interface IClaimTokens {
	projectName: ProjectText | undefined;
	setIsClaimedTokens: (arg: boolean) => void;
	isOpen: boolean;
	hasUserVerified: boolean;
	projectTokens: ProjectTokens;
}

const ClaimTokensWindow = ({
	setIsClaimedTokens,
	isOpen,
	projectName,
	hasUserVerified,
	projectTokens,
}: IClaimTokens) => {
	const { t, i18n } = useTranslation();
	const dispatch = useAppDispatch();

	const currentOpenedProject = useAppSelector(
		(state) => state.projectCards.selectedProjectId
	);
	const currentTokens = useAppSelector(getCurrentTokens);

	const currentLanguage = i18n.language;

	const defaultOptions = {
		loop: false,
		autoplay: true,
		animationData: claimTokensAnimation,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice",
		},
	};

	const [startAnimation, setStartAnimation] = useState(false);
	const { user } = useGetCurrentUser();
	const userId = user?.uid;

	const claimTokens = async (addTokens: number) => {
		if (!userId || !currentOpenedProject || currentTokens === null) return;
		try {
			const userRef = doc(db, "users", userId);
			const claimedTokens = currentTokens + addTokens;
			const projectUserDetails = {
				project: currentOpenedProject,
				claimedVerifyTokens: hasUserVerified ? true : false,
				tokens: claimedTokens,
			};

			if (currentTokens !== null) {
				await updateDoc(userRef, {
					tokens: claimedTokens,
				});
				await updateDoc(userRef, {
					isJoinedToProjects: arrayUnion(projectUserDetails),
				});
				dispatch(updateTokensForSelectedProject(claimedTokens));
			}

			setStartAnimation(true);
			dispatch(setIsJoinedToProjects(projectUserDetails));
			localStorage.setItem("userJoinedToProjects", currentOpenedProject);

			setTimeout(() => {
				setIsClaimedTokens(true);
				setStartAnimation(false);
				dispatch(setOpenClaimTokensWindow(false));
			}, 2000);
		} catch (err) {
			console.error(err);
		}
	};

	const claimBtnText =
		currentLanguage === "en"
			? `${t("verification.claimTokens")} ${
					hasUserVerified
						? projectTokens.projectJoin + projectTokens.userVerified
						: projectTokens.projectJoin
			  } ${t("verification.claimTokensToken")}`
			: `${
					hasUserVerified
						? projectTokens.projectJoin + projectTokens.userVerified
						: projectTokens.projectJoin
			  } ${t("verification.claimTokensToken")} ${t(
					"verification.claimTokens"
			  )}`;

	return (
		<Modal
			modalContainerClassName={`relative max-w-[567px] max-h-[622px] h-full overflow-y-auto overflow-x-hidden rounded-[10px] bg-white p-[40px]`}
			isOpen={isOpen}
			handleClose={() => dispatch(setOpenClaimTokensWindow(false))}
			closeButtonHidden={true}
			contentContainerClassName={`flex self-center`}
		>
			<div className="flex items-center justify-center bg-white relative w-full h-full">
				<div className="max-w-[342px] w-full flex flex-col justify-center items-center gap-[52px]">
					<div className={`text-center ${startAnimation && "invisible"}`}>
						<p>{t("verification.projectWelcome")}</p>
						<DetailedViewTitle>
							{currentLanguage === "en" ? projectName?.en : projectName?.de}
						</DetailedViewTitle>
					</div>

					<div className="justify-center rounded-full relative flex gap-[24px]">
						{hasUserVerified && (
							<img
								className="w-[200px] h-[200px] object-contain"
								src={
									currentLanguage === "en"
										? claimVerificationTokensEn
										: claimVerificationTokensDe
								}
								alt="Claim tokens pary"
							/>
						)}

						<img
							className={`${
								hasUserVerified && "w-[200px] h-[200px] object-contain"
							}`}
							src={
								currentLanguage === "en" ? claimTokensPary : claimTokensParyDe
							}
							alt="Claim tokens pary"
						/>
					</div>

					<div className={`w-full ${startAnimation && "invisible"}`}>
						<button
							onClick={() => {
								hasUserVerified
									? claimTokens(
											projectTokens.projectJoin + projectTokens.userVerified
									  )
									: claimTokens(projectTokens.projectJoin);
							}}
							className="bg-black py-[16px] text-white rounded-[50px] w-full flex text-center items-center justify-center px-[30px] gap-[8px]"
						>
							<img src={claimTokensIcon} alt="tokens icon" />
							<span>{claimBtnText}</span>
						</button>
					</div>
				</div>
				{startAnimation && (
					<div className="absolute inset-0 flex items-center justify-center">
						<Lottie options={defaultOptions} />
					</div>
				)}
			</div>
		</Modal>
	);
};

export default ClaimTokensWindow;
