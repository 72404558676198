interface Props {
  children: React.ReactNode;
  title: string;
}

const AboutSection = ({title, children}: Props) => {
  return (
    <div className="w-full max-w-none lg:w-1/2 lg:max-w-[600px]">
      <div className="text-2xl font-bold mb-4">
        <p>{title}</p>
      </div>
      <div className="flex flex-col gap-y-5">{children}</div>
    </div>
  );
};

export default AboutSection;
