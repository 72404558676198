import { useAppDispatch } from "../../../../../redux/hooks";
import { setEditPlace } from "../../../../../redux/api/locationSlice";
import { DocumentData } from "firebase/firestore";

const EditButton = ({ selectedCard }: DocumentData) => {
	const dispatch = useAppDispatch();

	return (
		<button
			onClick={() => dispatch(setEditPlace(true))}
			className="bg-[#F1F1F1] flex items-center justify-center h-8 w-8 rounded-full"
		>
			<svg
				width="14"
				height="14"
				viewBox="0 0 14 14"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7877 1.21665C12.0067 0.435598 10.7403 0.435597 9.95929 1.21665L0.921724 10.2542C0.546652 10.6293 0.335938 11.138 0.335938 11.6684V13.0018C0.335938 13.3699 0.634414 13.6684 1.0026 13.6684H2.33594C2.86637 13.6684 3.37508 13.4577 3.75015 13.0826L12.7877 4.04507C13.5688 3.26402 13.5688 1.99769 12.7877 1.21665ZM10.9021 2.15945C11.1624 1.89911 11.5846 1.89911 11.8449 2.15946C12.1053 2.4198 12.1053 2.84192 11.8449 3.10226L10.825 4.12215L9.88222 3.17934L10.9021 2.15945ZM8.93941 4.12215L9.88222 5.06496L2.80734 12.1398C2.68232 12.2649 2.51275 12.3351 2.33594 12.3351H1.66927V11.6684C1.66927 11.4916 1.73951 11.322 1.86453 11.197L8.93941 4.12215Z"
					fill="#333536"
				/>
			</svg>
		</button>
	);
};

export default EditButton;
