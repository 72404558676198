import {token} from "../../assets/qv";
import BlockContainer from "./BlockContainer";
import TextBlock from "./TextBlock";
import {useTranslation} from "react-i18next";

const Token = () => {
  const {t} = useTranslation();
  const paragraphs = [t("qv.token.text_1"), t("qv.token.text_2")];

  return (
    <BlockContainer styles="max-w-[409px] overflow-hidden">
      <div className="bg-white">
        <div>
          <img src={token} alt="token" />
        </div>
        <div className="px-8 pt-6 pb-[55px] xl:p-[55px]">
          <TextBlock paragraphs={paragraphs} />
          <p className="mt-5">
            {t("qv.token.text_3")} {'"'}
            {t("qv.token.span")}
            {'"'} {t("qv.token.text_4")}
          </p>
        </div>
      </div>
    </BlockContainer>
  );
};

export default Token;
