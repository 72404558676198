import {ISortType} from "../../../interfaces";
import {selectSortType} from "../../../redux/api/spotsSlice";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import Select from "../../../shared/Select";
import SingleCategory from "./SingleCategory";
import {categories, sortTypes} from "../../../constants";

const Categories = () => {
  const dispatch = useAppDispatch();

  const selectedItem = useAppSelector((state) => state.spots.sortBy);

  const handleSelect = (item: ISortType) => {
    dispatch(selectSortType(item));
  };
  return (
    <div className="flex justify-between gap-4">
      <div className="flex gap-4">
        {categories.map((category) => (
          <SingleCategory key={category.name} category={category} />
        ))}
      </div>
      <Select
        translate="filter"
        selectedItem={selectedItem}
        data={sortTypes}
        handleSelect={handleSelect}
        containerClass={"w-[177px]"}
        paddingTop={"pt-4"}
      />
    </div>
  );
};

export default Categories;
