import {useTranslation} from "react-i18next";
import Section from "../../Section";

const Resources = () => {
  const {t} = useTranslation();
  return (
    <Section title={t("policy.resources.title")}>
      <p>{t("policy.resources.text_1")}</p>
    </Section>
  );
};

export default Resources;
