import { twMerge } from "tailwind-merge";

interface Props {
	children: React.ReactNode;
	additionalStyles?: string;
}

const Wrapper = ({ children, additionalStyles }: Props) => {
	return (
		<div className="w-screen flex flex-col 2xl:items-center items-start">
			<div
				className={twMerge(
					"max-w-[1440px] pl-[80px] pt-[180px] pr-16 flex gap-[160px]",
					additionalStyles
				)}
			>
				{children}
			</div>
		</div>
	);
};

export default Wrapper;
