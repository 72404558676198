import { DocumentData } from "@firebase/firestore";
import { useEffect } from "react";
import { Marker } from "react-map-gl";
import { tagsIcons } from "../assets/tagsIcons";
import {
	selectSpot,
	setSelectedCard,
	setSpotsInView,
} from "../redux/api/spotsSlice";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import useSetQuery from "../hooks/useSetQuery";
import { useGetCurrentUser } from "../hooks/useGetCurrentUser";
import { roundTokens } from "../utils/createVoteInfo";
import { setEditPlace } from "../redux/api/locationSlice";
import useHandleUserVotes from "../hooks/useHandleUserVotes";

interface Props {
	spot: DocumentData;
	zoom: number;
	isVisible: boolean;
}

const MapSpot = ({ spot, zoom, isVisible }: Props) => {
	const { user } = useGetCurrentUser();
	const userID = user?.uid;

	const dispatch = useAppDispatch();
	const selectedSpot = useAppSelector((state) => state.spots.selectedSpot);
	const isMapLoaded = useAppSelector((state) => state.map.isMapLoaded);
	const hoveredCardId = useAppSelector((state) => state.spots.hoveredCardId);
	const setQuery = useSetQuery();
	const onClickHandler = () => {
		dispatch(setEditPlace(false));
		dispatch(setSelectedCard(spot));
		dispatch(selectSpot(spot));
		setQuery(spot.id);
	};

	const spotVotingTokens = userID ? spot.votes[userID]?.tokens : null;

	const currUserVotes = roundTokens(spotVotingTokens);

	const { userVoteColor } = useHandleUserVotes(
		spot.category || "",
		currUserVotes
	);

	useEffect(() => {
		isMapLoaded && dispatch(setSpotsInView({ spot, isVisible }));
	}, [isVisible, isMapLoaded]);

	return (
		<Marker
			onClick={onClickHandler}
			longitude={spot.latitude}
			latitude={spot.longitude}
		>
			<span
				id={spot.id}
				className={`${
					spot.id && (hoveredCardId === spot.id || selectedSpot?.id === spot.id)
						? "spotSelected"
						: "hoverSpot"
				} `}
			>
				{zoom >= 14.5 ? (
					<div className="relative">
						<img
							className="w-7 h-7 p-1 bg-white rounded-full absolute top-2 left-2 -translate-x-[0.5px] -translate-y-[1px]"
							src={tagsIcons[spot.type]}
							alt="tag-icon"
						/>
						<svg
							width="42"
							height="49"
							viewBox="-2 -2 45 54"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M42 21.0529C42 33.2324 29.7505 43.4012 23.9626 47.5263C22.1704 48.8036 19.8296 48.8036 18.0374 47.5263C12.2495 43.4012 -1.63888e-08 33.2324 0 21.0529C1.56456e-08 9.42572 9.40202 0 21 0C32.598 0 42 9.42572 42 21.0529Z"
								fill={spot.color}
							/>
						</svg>
						{spotVotingTokens && (
							<div className="absolute bottom-[-8px] right-[-15px] bg-white px-[5px] py-[2px] min-w-[32px] justify-center rounded-[12px] flex gap-[5px] z-10 items-center">
								<span className="text-[12px]">{currUserVotes}</span>
								<div
									style={{ background: userVoteColor }}
									className="min-w-[8px] max-w-[8px] h-[8px] rounded-full w-full"
								></div>
							</div>
						)}
					</div>
				) : (
					<svg
						width="22"
						height="22"
						viewBox="-2 -2 22 22"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<circle cx="9" cy="9" r="9" fill={spot.color} />
					</svg>
				)}
			</span>
		</Marker>
	);
};

export default MapSpot;
