import { useState, useRef, useEffect, useMemo } from "react";

import { chooseAnimation } from "../utils/chooseAnim";

import VotesPopUp from "../components/mapbox/appPlace/VotesPopUp";
import CircularVotesBar from "./CircularVotesBar";

import Lottie from "react-lottie";
import { roundTokens } from "../utils/createVoteInfo";
import useHandleUserVotes from "../hooks/useHandleUserVotes";

export interface IVotes {
	date: number;
	submitted_by: string;
	tokens: number;
}

interface IAddVotes {
	votes: number;
	color: string;
	percantage: number;
	voteInfo: IVotes | undefined;
	handleChange: (str: string) => void;
	category: string;
}

const VoteSystem = ({
	votes,
	color,
	percantage,
	handleChange,
	voteInfo,
	category,
}: IAddVotes) => {
	const [animationKey, setAnimationKey] = useState(0);
	const [showVotesPopUp, setShowVotesPopUp] = useState(false);

	const currVotes = useMemo(() => {
		return roundTokens(voteInfo?.tokens || 0);
	}, [voteInfo]);

	const { userVoteColor } = useHandleUserVotes(category || "", currVotes);

	const timeoutRef = useRef<NodeJS.Timeout | null>(null);

	const animation = chooseAnimation(color);
	const defaultOptions = {
		loop: false,
		autoplay: true,
		animationData: animation,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice",
		},
	};

	const handleVote = (action: "increase" | "decrease") => {
		handleChange(action);
		setShowVotesPopUp(true);
		if (action === "increase") {
			setAnimationKey((prevKey) => prevKey + 1);
		}
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}

		timeoutRef.current = setTimeout(() => {
			setShowVotesPopUp(false);
		}, 2000);
	};

	useEffect(() => {
		return () => {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}
		};
	}, []);

	return (
		<div className="flex items-center gap-[16px] justify-self-center">
			<button
				onClick={() => handleVote("decrease")}
				className="flex items-center justify-center rounded-full w-[48px] h-[48px] poppins text-primaryBlack bg-[#f1f1f1]"
				type="button"
			>
				—
			</button>
			<div className="relative">
				<CircularVotesBar
					votes={votes}
					percantage={percantage}
					color={color}
					voteInfo={voteInfo}
					userVotesColor={userVoteColor}
				/>
				<VotesPopUp
					background={userVoteColor}
					voteInfo={voteInfo?.tokens}
					userVotes={currVotes}
					isVisible={showVotesPopUp}
				/>
			</div>
			<div className="relative">
				<button
					onClick={() => handleVote("increase")}
					type="button"
					style={{ backgroundColor: userVoteColor }}
					className={`flex items-center justify-center rounded-full w-[48px] h-[48px] poppins text-primaryBlack relative z-[5]`}
				>
					+
				</button>
				{showVotesPopUp && (
					<div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
						<Lottie
							width={140}
							height={140}
							options={defaultOptions}
							key={animationKey}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default VoteSystem;
