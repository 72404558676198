import { DocumentData } from "firebase/firestore";
import SingleComment from "./SingleComment";
import { useGetCurrentUser } from "../../../../../hooks/useGetCurrentUser";

interface Props {
	replies: DocumentData[];
	showReplies: boolean;
	selectedCard: DocumentData;
	allComments: DocumentData[] | undefined;
	refetch: () => void;
	handleViewModOpen: () => void;
	isMoreOptionsOpen: DocumentData;
	setIsMoreOptionsOpen: (arg: string) => void;
}
const Replies = ({
	replies,
	showReplies,
	selectedCard,
	allComments,
	refetch,
	handleViewModOpen,
	isMoreOptionsOpen,
	setIsMoreOptionsOpen,
}: Props) => {
	const { user } = useGetCurrentUser();
	return (
		<>
			{showReplies && (
				<div className="flex flex-col gap-y-2 mb-2 mt-[8px]">
					{replies.map((reply: DocumentData) => {
						return reply.deleted ? (
							<div
								key={reply.id}
								className="tracking-wider text-[#717171] text-[12px]"
							>
								{"> > > "}Comment deleted by user.{" < < <"}
							</div>
						) : (
							<div key={reply.id} className="ml-7">
								<SingleComment
									replyTo={reply.replyTo}
									comment={reply}
									currentUser={user?.uid}
									selectedCard={selectedCard}
									allComments={allComments}
									refetch={refetch}
									handleViewModOpen={handleViewModOpen}
									isMoreOptionsOpen={isMoreOptionsOpen}
									setIsMoreOptionsOpen={setIsMoreOptionsOpen}
								/>
							</div>
						);
					})}
				</div>
			)}
		</>
	);
};

export default Replies;
