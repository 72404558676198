import { useTranslation } from "react-i18next";
import { useState, useEffect, useRef } from "react";
import { InterestDetails } from "../../editProfile/Interests";
import i18next from "i18next";
import { tags } from "../../../constants";

interface ITags {
	category: string;
	handleChange: (arg: string[]) => void;
	addedTags: string[];
}

const TagsToPlace = ({ category, handleChange, addedTags }: ITags) => {
	const { t } = useTranslation();
	const lang = i18next.language;
	const prevCategoryRef = useRef(category);

	const [selectedTags, setSelectedTags] = useState<string[]>(addedTags);

	const handleSelect = (id: string) => {
		const arr = [...selectedTags];
		arr.includes(id)
			? setSelectedTags(arr.filter((item) => item !== id))
			: arr.push(id) && setSelectedTags(arr);
	};

	useEffect(() => handleChange(selectedTags), [selectedTags]);

	useEffect(() => {
		if (prevCategoryRef.current !== category) {
			setSelectedTags([]);
		}
	}, [category]);

	const tagsArr =
		category === "Dangerous"
			? tags.dangerous
			: category === "Useful"
			? tags.useful
			: tags.bikeFriendly;

	return (
		<div className="grid grid-cols-[140px,_auto] gap-[40px] bg-[#ffffff] p-[16px] rounded-[10px] mb-[16px]">
			<div>
				<h4 className="text-xl text-[#1a1a1a]">{t("addSpot.tag")}</h4>
				<p className="text-midGray poppins text-sm">{t("addSpot.tag_text")}</p>
			</div>
			<div className="flex flex-wrap gap-2 w-[85%] text-primaryBlack pt-4">
				{tagsArr.map(({ id, icon, en, de }: InterestDetails) => {
					const title = lang === "de" ? de : en;
					return (
						<div
							className={`flex cursor-pointer justify-center gap-1 rounded-full text-[12px] py-2 px-3 ${
								selectedTags[0] === en
									? "outline outline-2 outline-[#3A85F4]"
									: selectedTags.slice(1).includes(en)
									? "outline outline-2 outline-black"
									: "outline outline-1 outline-lightGray"
							}
                        `}
							key={id}
							onClick={() => {
								handleSelect(en);
							}}
						>
							<div>{icon}</div>
							<div>{title}</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default TagsToPlace;
