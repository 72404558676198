import { useEffect } from "react";
import { filterSpots, sortSpots } from "../../../../redux/api/spotsSlice";
import { useAppSelector, useAppDispatch } from "../../../../redux/hooks";
import CardInfo from "./cardInfo/CardInfo";
import Comments from "./comments/Comments";
import DistrictInfo from "./DistrictInfo";
import NavBar from "./NavBar";

const DetailedView = () => {
	const dispatch = useAppDispatch();

	const selectedCard = useAppSelector((state) => state.spots.selectedCard)!;
	const spots = useAppSelector((state) => state.spots.spotsData);
	const section = useAppSelector((state) => state.elementsView.section);
	const mySpotsActiveTab = useAppSelector(
		(state) => state.elementsView.mySpotsActiveTab
	);

	const voters = () => {
		let votersCount = 0;
		for (const _ of Object.values(selectedCard.votes)) {
			votersCount += 1;
		}
		return votersCount;
	};

	useEffect(() => {
		dispatch(filterSpots());
		dispatch(sortSpots());
	}, [spots]);

	return (
		<div className="h-full bg-white flex flex-col gap-y-4 relative">
			<NavBar
				category={selectedCard.category}
				district={selectedCard.district}
				section={section}
				mySpotsActiveTab={mySpotsActiveTab}
			/>
			<div
				style={{
					boxSizing: "border-box",
					scrollbarWidth: "thin", // For Firefox
					scrollbarColor: "transparent transparent", // For Firefox
				}}
				className="flex flex-col gap-y-4 mt-14 overflow-y-auto overflow-x-hidden scroll-transparent w-full"
			>
				<CardInfo
					selectedCard={selectedCard}
					type={selectedCard.type}
					tags={selectedCard.secondaryTags ? selectedCard.secondaryTags : []}
					section={section}
					ranking={selectedCard.ranking}
					mySpotsActiveTab={mySpotsActiveTab}
				/>
				<DistrictInfo selectedCard={selectedCard} voters={voters()} />

				<Comments selectedCard={selectedCard} />
			</div>
		</div>
	);
};

export default DetailedView;
