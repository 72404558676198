import { useState, useEffect } from "react";

import LoudParry from "../../../../assets/shared/loudParry.svg";
import { Link } from "react-router-dom";
import { paths } from "../../../../constants";
import { getUserCount } from "../../../../firebase/utils/getMissionInfo";

interface IMissionInfo {
	userCount: number;
	spotsCount: number;
}

const MissionHeader = () => {
	const [info, setInfo] = useState<IMissionInfo | null | undefined>(null);

	useEffect(() => {
		const getData = async () => {
			const data = await getUserCount();
			setInfo(data);
		};
		getData();
	}, []);

	return (
		<div className="flex gap-x-4">
			<div className="basis-1/2 flex gap-x-[26px] min-h-[168px] p-[16px] rounded-[10px] border-2 border-[#e8e8e8]">
				<div>
					<img src={LoudParry} alt="Parry" />
				</div>
				<div className="flex flex-col gap-y-4">
					<div className="text-[14px]">
						<p>Initiator:</p>
						<p className="font-poppinsBold text-[16px]">Parry</p>
					</div>
					<div className="text-[14px]">
						<p>Project created:</p>
						<p className="font-poppinsBold text-[16px]">01.04.2023</p>
					</div>
				</div>
			</div>
			<div className="basis-1/2 flex flex-col gap-y-4">
				<div className="flex gap-x-[24px] p-4 border-2 border-[#e8e8e8] rounded-[10px]">
					<div className="text-[14px]">
						<p>Members:</p>
						<p className="text-[16px] font-poppinsBold">
							{info?.userCount || 0}
						</p>
					</div>
					<div className="text-[14px]">
						<p>Spots:</p>
						<p className="text-[16px] font-poppinsBold">
							{info?.spotsCount || 0}
						</p>
					</div>
				</div>
				<div className="p-4 border-2 border-[#e8e8e8] rounded-[10px] text-[14px]">
					<p className="font-poppinsBold">More info</p>
					<Link to={paths.about}>https://parry.info/about-us</Link>
				</div>
			</div>
		</div>
	);
};

export default MissionHeader;
