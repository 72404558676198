import { DocumentData } from "@firebase/firestore";
interface ICategories {
	Useful: DocumentData[];
	Dangerous: DocumentData[];
	"Bike Friendly": DocumentData[];
}
export const SPOT_COLORS = {
	Dangerous: {
		TOP: "#E53700",
		MIDDLE: "#FF6915",
		LOW: "#FF9D67",
	},
	"Bike Friendly": {
		TOP: "#3B7F2F",
		MIDDLE: "#73BF5A",
		LOW: "#A3E577",
	},
	Useful: {
		TOP: "#123E96",
		MIDDLE: "#3F91FF",
		LOW: "#72BEF7",
	},
};

const getMaxValue = (spots: DocumentData[]) => {
	return spots.reduce((maxItem, currItem) => {
		if (currItem.total_votes > maxItem.total_votes) return currItem;
		else return maxItem;
	});
};

const calculatePercent = (spots: DocumentData[]) => {
	if (!spots.length) return [];
	const ref = getMaxValue(spots);
	const topValue = ref.total_votes;

	spots.forEach((spot) => {
		spot.percantage = Math.round((spot.total_votes / topValue) * 100);
	});

	const sortedSpots = spots.slice().sort((a, b) => b.percantage - a.percantage);
	return sortedSpots.map((spot, i) => {
		let color;
		let level;
		if (i === 0) {
			color = SPOT_COLORS[spot.category as keyof typeof SPOT_COLORS].TOP;
			level = "High";
		} else {
			const priority = (i + 1) / sortedSpots.length;
			if (priority <= 0.2) {
				color = SPOT_COLORS[spot.category as keyof typeof SPOT_COLORS].TOP;
				level = "High";
			} else if (priority > 0.2 && priority <= 0.5) {
				color = SPOT_COLORS[spot.category as keyof typeof SPOT_COLORS].MIDDLE;
				level = "Middle";
			} else {
				color = SPOT_COLORS[spot.category as keyof typeof SPOT_COLORS].LOW;
				level = "Low";
			}
		}
		return {
			...spot,
			color,
			level,
			districtRank: `${i + 1}/${sortedSpots.length}`,
		};
	});
};

export const calcVotesPercantage = (spots: DocumentData[]) => {
	const allDistricts = spots.map((spot) => spot.district);
	const uniqueDistricts = Array.from(new Set(allDistricts));

	const newSpots: DocumentData[] = [];
	uniqueDistricts.forEach((district) => {
		const districtsSpots = spots.filter((spot) => spot.district === district);
		const categories: ICategories = {
			Useful: [],
			Dangerous: [],
			"Bike Friendly": [],
		};

		districtsSpots.forEach((spot) => {
			if (spot.category && !isNaN(spot.latitude) && !isNaN(spot.longitude)) {
				categories[spot.category as keyof typeof categories].push(spot);
			}
		});

		for (const category of Object.values(categories)) {
			const handledCategory = calculatePercent(category);
			newSpots.push(...handledCategory);
		}
	});
	return newSpots;
};

export const calcVotesPercantageByDistrict = (
	districtSpots: DocumentData[],
	selectDistrict: string,
	currSpot: DocumentData
) => {
	const newSpots: DocumentData[] = [];

	const districtAddSpots = districtSpots.concat(currSpot);

	const categories: ICategories = {
		Useful: [],
		Dangerous: [],
		"Bike Friendly": [],
	};

	districtAddSpots.forEach((spot) => {
		if (spot.category && !isNaN(spot.latitude) && !isNaN(spot.longitude)) {
			categories[spot.category as keyof typeof categories].push(spot);
		}
	});

	for (const category of Object.values(categories)) {
		const handledCategory = calculatePercent(category);
		newSpots.push(...handledCategory);
	}

	return newSpots;
};
