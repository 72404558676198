import { DocumentData } from "firebase/firestore";
import { handleCommentsUser, sortComments } from "./handleComments";

export const getReplies = async (
	replies: DocumentData[],
	parentComment: DocumentData
) => {
	replies.forEach((reply: DocumentData) => {
		const repliedComment = replies.find(
			(replyComment: DocumentData) => replyComment.reply_comment_id === reply.id
		);
		if (repliedComment) reply.hasReply = true;
	});
	const repliesWithUserData = await Promise.all(
		replies.map(async (reply: DocumentData) => await handleCommentsUser(reply))
	);
	const filteredReplies = repliesWithUserData.filter(
		(reply: DocumentData) =>
			reply.deleted || !reply.deleted || (reply.deleted && reply.hasReply)
	);

	filteredReplies.forEach((reply: DocumentData) => {
		if (reply.reply_comment_id === parentComment.id) {
			reply.replyTo = parentComment.username;
			return;
		}
		const repliedComment = filteredReplies.find(
			(replyComment: DocumentData) => replyComment.id === reply.reply_comment_id
		);
		reply.replyTo = repliedComment?.username;
	});

	const sortedReplies = sortComments(filteredReplies);
	return sortedReplies;
};
