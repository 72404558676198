import {paths} from "../../constants";
import useNavigateToPage from "../../hooks/useNavigateToPage";
import Header from "../header/Header";
import Footer from "../Footer";
import {useTranslation} from "react-i18next";

interface Props {
  title: string;
  children: React.ReactNode;
}
const PagesContainer = ({title, children}: Props) => {
  const navigateToProjects = useNavigateToPage(paths.projects);
  const {t} = useTranslation();
  return (
    <>
      <Header>
        <div className="flex w-full justify-center items-center text-2xl font-poppinsBold ">
          <button onClick={navigateToProjects}>
            {t("header.initiatives")}
          </button>
        </div>
      </Header>
      <div className="pt-[178px] max-w-[1191px] lg:pt-[162px] mx-auto w-full px-4 md:px-20 mb-[72px] lg:mb-[112px] ">
        <div className="text-[40px] lg:text-[60px] flex items-center leading-[56px] lg:leading-[76px] justify-center font-poppinsBold w-full mb-12 lg:mb-[72px] text-center">
          {title}
        </div>
        {children}
      </div>
      <Footer />
    </>
  );
};

export default PagesContainer;
