import { useTranslation } from "react-i18next";
import { Modal } from "../../shared/Modal";
import { Link } from "react-router-dom";
import { paths } from "../../constants";
import { AuthButton } from "./AuthButton";
import { useAppDispatch } from "../../redux/hooks";
import eyes from "../../assets/auth/notLoggedIn.png";

interface isViewModOpen {
	isOpen: boolean;
	handleClose: () => void;
	hangleSignIn: () => void;
	hangleLogIn: () => void;
}

const ViewModModal = ({
	isOpen,
	handleClose,
	hangleSignIn,
	hangleLogIn,
}: isViewModOpen) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	return (
		<Modal
			isOpen={isOpen}
			handleClose={handleClose}
			modalContainerClassName={
				"max-w-[566px] relative pt-[100px] px-[40px] pb-[32px] rounded-xl"
			}
		>
			<div className="max-w-[345px] w-[100%] my-0 mx-auto overflow-auto flex flex-col items-center">
				<div className="mb-[28px]">
					<div className="flex flex-col items-center mb-[36px]">
						<img src={eyes} alt="eyes icon" />
					</div>
					<h3 className="text-left text-[#000000] text-[24px] font-poppins mb-[8px]">
						{t("auth.viewMod.title")}
					</h3>
					<p className="text-[#000000] text-[16px] font-poppins">
						{t("auth.viewMod.description")}
					</p>
				</div>
				<div className="flex flex-col w-full mb-[74px]">
					<AuthButton
						onClick={() => hangleSignIn()}
						text={t("auth.buttons.registrieren")}
						className="mb-[12px]"
					/>
					<div className="flex mb-[14px] items-center gap-[12px]">
						<span className="w-1/2 h-[1px] block bg-[#D9D9D9]"></span>or
						<span className="w-1/2 h-[1px] block bg-[#D9D9D9]"></span>
					</div>
					<AuthButton
						onClick={() => hangleLogIn()}
						text={t("auth.buttons.anmelden")}
						className="mb-[12px]"
						layoutType={"secondary"}
					/>
				</div>
				<p className="text-sm font-medium text-[#A0A0A0] text-center">
					{t("auth.agreementText")}{" "}
					<Link to={paths.terms} target="_blank" className="underline">
						{t("footer.links.terms")}
					</Link>{" "}
					{t("auth.and")}{" "}
					<Link to={paths.policy} target="_blank" className="underline">
						{t("footer.links.policy")}
					</Link>
					{t("auth.zu")}
				</p>
			</div>
		</Modal>
	);
};

export default ViewModModal;
