import { useEffect, useRef, useState } from "react";
import { search } from "../../../assets/map/index";
import { IFeature } from "../../../interfaces";
import LocationResult from "./LocationResult";
import { useLazyGetLocationQuery } from "../../../redux/api/apiSlice";
import useClickOutside from "../../../hooks/useClickOutside";
import { useAppSelector } from "../../../redux/hooks";

const Search = () => {
	const [query, setQuery] = useState("");
	const [locations, setLocations] = useState<IFeature[]>([]);
	const mapWidth = useAppSelector((state) => state.map.mapSize.width);

	const { element, isVisible, clickOutSide } =
		useClickOutside<HTMLDivElement>();
	const [getLocations] = useLazyGetLocationQuery();

	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (mapWidth && inputRef.current) {
			inputRef.current.style.maxWidth = mapWidth - 84 + "px";
		}
	}, [mapWidth]);

	useEffect(() => {
		const getQueryLocation = async () => {
			if (query.length < 3) return;
			const { data } = await getLocations(query);
			if (data) {
				const { features } = data;
				setLocations(features);
			}
		};
		getQueryLocation();
	}, [query]);

	const openSearchModal = () => {
		clickOutSide(true);
		inputRef.current?.focus();
	};

	return (
		<div
			ref={element}
			className={`flex absolute top-4 left-4 z-20 flex-col items-start`}
		>
			<div
				onClick={openSearchModal}
				className="flex bg-white cursor-pointer p-3 mb-2 rounded-full justify-center items-center"
			>
				<img src={search} alt="search-icon" />
				<input
					onChange={(e) => {
						setQuery(e.target.value);
					}}
					ref={inputRef}
					className={`${
						isVisible ? "max-w-full w-[285px] pl-2" : "w-0"
					} transition-all duration-600 outline-none`}
					type="text"
				/>
			</div>
			{!!locations.length && isVisible && query.length >= 3 && (
				<div className="bg-white py-2 px-4 rounded-xl w-[333px]">
					{locations?.map((location: IFeature) => (
						<LocationResult
							clickOutSide={clickOutSide}
							key={location.id}
							location={location}
						/>
					))}
				</div>
			)}
		</div>
	);
};

export default Search;
