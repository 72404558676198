import { useTranslation } from "react-i18next";
import SingleAddCategory from "./SingleAddCategory";

import { categories } from "../../../constants";

interface IAddCategories {
	handleCategory: (arg: string) => void;
	placeDataCategory: string;
}

const AddCategories = ({
	handleCategory,
	placeDataCategory,
}: IAddCategories) => {
	const { t } = useTranslation();

	return (
		<div className="grid grid grid-cols-[140px,_auto] gap-[40px] bg-[#ffffff] p-[16px] rounded-[10px] mb-[16px]">
			<h4 className="text-xl text-[#1a1a1a]">{t("addSpot.category")}</h4>
			<div className="flex gap-4">
				{categories.map((category) => (
					<SingleAddCategory
						key={category.name}
						category={category}
						selectCategory={handleCategory}
						selectedCategory={placeDataCategory}
					/>
				))}
			</div>
		</div>
	);
};

export default AddCategories;
