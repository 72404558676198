interface Props {
  children: React.ReactNode;
  styles?: string;
  noMb?: boolean;
}

const BlockContainer = ({children, styles, noMb}: Props) => {
  return (
    <div
      className={`border-[5px] border-black rounded-[39px] w-full ${styles} ${
        noMb ? "" : "xl:mb-12 mb-0"
      }`}
    >
      {children}
    </div>
  );
};

export default BlockContainer;
