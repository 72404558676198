type Coordinate = [number, number];

type CoordinatePath = Coordinate[];

type MultiPath = CoordinatePath[];

export const getMaxBounds = (
	coordinates: number[][][]
): [Coordinate, Coordinate] => {
	let minX = Infinity,
		minY = Infinity,
		maxX = -Infinity,
		maxY = -Infinity;

	coordinates.forEach((polygon) => {
		polygon.forEach((point) => {
			// Check and cast each point to a Coordinate if it has exactly two numbers
			if (point.length === 2) {
				const [lon, lat] = point as Coordinate; // Ensuring each point is a valid Coordinate
				if (lon < minX) minX = lon;
				if (lat < minY) minY = lat;
				if (lon > maxX) maxX = lon;
				if (lat > maxY) maxY = lat;
			}
		});
	});

	return [
		[minX, minY], // Southwest corner
		[maxX, maxY], // Northeast corner
	];
};
