import { Modal } from "../../shared/Modal";

import { useTranslation } from "react-i18next";
import DragDropPhoto from "../../shared/DragDropPhoto";

import parry from "../../assets/dragndrop/parry.svg";
import parryHand from "../../assets/dragndrop/parry-hand.svg";

interface IEditAvatarModal {
	isOpen: boolean;
	handleClose: () => void;
	handleChange: (file: any) => void;
	sizeError: boolean;
	setSizeError: (arg: boolean) => void;
}

const EditAvatarModal = ({
	isOpen,
	handleClose,
	handleChange,
	sizeError,
	setSizeError,
}: IEditAvatarModal) => {
	const { t } = useTranslation();
	const handleSizeError = () => {
		setSizeError(true);
	};

	return (
		<Modal
			isOpen={isOpen}
			handleClose={handleClose}
			modalContainerClassName={
				"max-w-[566px] max-h-[622px] h-[100%] relative pb-20 rounded-xl overflow-visible"
			}
		>
			<div className="max-w-[342px] w-[100%] my-0 mx-auto pt-6 overflow-auto">
				<div className="edit__avatar-header mb-[32px]">
					<span className="font-poppins font-medium text-sm mb-[4px]">
						{t("editAvatarModal.edit")}
					</span>
					<h4 className="font-poppins text-[22px] font-semibold">
						{t("editAvatarModal.upload")}
					</h4>
				</div>
				<DragDropPhoto
					handleChange={handleChange}
					onSizeError={handleSizeError}
				/>
				{sizeError && (
					<p className="text-[#FF1517] font-medium">
						{t("editAvatarModal.error")}
					</p>
				)}
			</div>
			<div className="absolute bottom-0">
				<img src={parry} alt="parry" />
				<img
					className="absolute bottom-[-10px] right-0 z-[200]"
					src={parryHand}
					alt="parry"
				/>
			</div>
		</Modal>
	);
};

export default EditAvatarModal;
