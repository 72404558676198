import {useTranslation} from "react-i18next";
import Section from "../../Section";

const Automatic = () => {
  const {t} = useTranslation();
  return (
    <Section title={t("policy.automatic.title")}>
      <p>{t("policy.automatic.text_1")}</p>
      <p>{t("policy.automatic.text_2")}</p>
    </Section>
  );
};

export default Automatic;
