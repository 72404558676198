import { useState } from "react";
import { useTranslation } from "react-i18next";
import { qrCode } from "../../../../../assets/map";
import DetailedViewTitle from "../../../../../shared/DetailedViewTitle";
import SingleComment from "./SingleComment";
import Loader from "../../../../../shared/Loader";
import CommentInput from "./CommentInput";
import { useGetSpotCommentsQuery } from "../../../../../redux/api/apiSlice";
import { DocumentData } from "firebase/firestore";
import useRestrictions from "../../../../../hooks/useRestrictions";
import ViewModModal from "../../../../auth/ViewModModal";
import { useGetCurrentUser } from "../../../../../hooks/useGetCurrentUser";
import { Comment } from "../../../../../interfaces";

interface Props {
	selectedCard: DocumentData;
}

const Comments = ({ selectedCard }: Props) => {
	const [comment, setComment] = useState("");
	const [isMoreOptionsOpen, setIsMoreOptionsOpen] = useState<string[]>([]);

	const { user } = useGetCurrentUser();
	const { t } = useTranslation();
	const { data, refetch } = useGetSpotCommentsQuery(selectedCard.id);

	const handleMoreButton = (id: string) => {
		const newArr: string[] = [...isMoreOptionsOpen];
		if (newArr.length >= 1) {
			if (id === newArr[0]) {
				setIsMoreOptionsOpen([]);
				return;
			}
			newArr[0] = id;
			setIsMoreOptionsOpen(newArr);
		}

		setIsMoreOptionsOpen([id]);
	};

	let dataLength: number = 0;
	if (data) {
		const comments = data as Comment[];

		dataLength = comments.filter((item) => item.deleted === false).length;

		for (let comment of comments) {
			dataLength += comment.replies.filter(
				(item) => item.deleted === false
			).length;
		}
	}
	const {
		isViewMod,
		hangleSignIn,
		hangleLogIn,
		handleViewModClose,
		handleViewModOpen,
	} = useRestrictions();

	return (
		<div
			className={`px-6 relative pb-[10px] ${data && !data.length && "pt-2"}`}
		>
			<div className="mb-2">
				<DetailedViewTitle>
					{t("ranking.detailedView.comments.title")}
					{data && " (" + dataLength + ")"}
				</DetailedViewTitle>
				<CommentInput
					setComment={setComment}
					value={comment}
					selectedCard={selectedCard}
					allComments={data}
					refetch={refetch}
					handleViewModOpen={handleViewModOpen}
				/>
			</div>
			{!data ? (
				<div className="relative py-4">
					<Loader noAbsolute={true} />
				</div>
			) : (
				<div className="flex flex-col gap-y-4">
					{!!data.length ? (
						data.map((comment: any, i: number) => {
							return (
								<SingleComment
									key={i}
									comment={comment}
									currentUser={user?.uid}
									selectedCard={selectedCard}
									allComments={data}
									refetch={refetch}
									handleViewModOpen={handleViewModOpen}
									isMoreOptionsOpen={isMoreOptionsOpen}
									setIsMoreOptionsOpen={handleMoreButton}
								/>
							);
						})
					) : (
						<div className="flex justify-between">
							{/* <div className="w-[509px]">
								<p className="text-sm text-[#A0A0A0]">
									{t("ranking.detailedView.comments.noComments")}
								</p>
							</div>
							<div className="w-[72px] h-[72px] absolute top-2 right-0">
								<img src={qrCode} className="w-full h-full" alt="qr-code" />
							</div> */}
						</div>
					)}
				</div>
			)}
			<ViewModModal
				isOpen={isViewMod}
				handleClose={handleViewModClose}
				hangleSignIn={hangleSignIn}
				hangleLogIn={hangleLogIn}
			/>
		</div>
	);
};

export default Comments;
