import { ButtonHTMLAttributes, DetailedHTMLProps } from "react";
import { twMerge } from "tailwind-merge";
import Loader from "../../shared/Loader";

interface IProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  text: string;
  isLoading?: boolean;
  icon?: string;
  textClassName?: string;
  className?: string;
  layoutType?: "primary" | "secondary";
}

export const AuthButton = ({
  textClassName,
  className,
  text,
  icon,
  disabled,
  isLoading,
  layoutType = "primary",
  ...restProps
}: IProps) => {
  return (
    <button
      disabled={disabled}
      className={twMerge(
        `h-14 w-full flex justify-center items-center gap-x-2.5 rounded-full border-2 p-4 ${
          layoutType === "primary"
            ? disabled
              ? "bg-[#E8E8E8] border-lightGray"
              : "bg-black"
            : "bg-white  border-gray-300"
        }`,
        className
      )}
      {...restProps}
    >
      {icon && <img src={icon} alt={icon} />}
      <div
        className={`text-base font-semibold ${
          layoutType === "primary"
            ? disabled
              ? "text-darkGray"
              : "text-white"
            : ""
        } ${textClassName}`}
      >
        {isLoading ? <Loader noAbsolute parrentClassName="bg-transparent h-7 w-7" childClassName="text-white h-[100%] w-[100%] border-4" /> : <p>{text}</p>}
      </div>
    </button>
  );
};
