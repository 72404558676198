import {useLocation} from "react-router";

const useScrollToTop = () => {
  const location = useLocation();
  const scrollToTop = (e: any): void => {
    const linkPath = e.target.closest("a").href.split("/").at(-1);
    const currentPath = location.pathname.slice(1);
    if (linkPath === currentPath) return;
    else window.scrollTo({top: 0, left: 0, behavior: "smooth"});
  };
  return scrollToTop;
};

export default useScrollToTop;
