import { useTranslation } from "react-i18next";
import { IVotes } from "../components/mapbox/appPlace/AddVotes";
import { roundTokens } from "../utils/createVoteInfo";

interface Props {
	color: string;
	percantage: number;
	level?: string | null;
	votes?: number;
	voteInfo?: IVotes;
	userVotesColor: string;
}
const CircularVotesBar = ({
	level,
	percantage,
	color,
	votes,
	voteInfo,
	userVotesColor,
}: Props) => {
	const { t } = useTranslation();

	const radius = 72.5;
	const circumference = 2 * Math.PI * radius;
	const dashOffset = circumference - (percantage / 100) * circumference;

	const votesNum = votes && votes > 1 ? "mult" : "single";

	return (
		<div className="w-[160px] h-[160px] flex items-center justify-center relative">
			<div className="text-xl font-poppins max-w-[118px] text-center">
				{level
					? t(`ranking.detailedView.districtInfo.${level}`)
					: `${votes} ${t(
							`ranking.detailedView.districtInfo.votes-${votesNum}`
					  )}`}
			</div>
			{voteInfo && (
				<div className="absolute bottom-0 right-0 bg-white px-[5px] py-[2px] w-[50px] justify-center rounded-[20px] flex gap-[5px] z-10 items-center">
					<span className="text-[16px]">{roundTokens(voteInfo.tokens)}</span>
					<div
						style={{ background: userVotesColor }}
						className="w-[12px] h-[12px] rounded-full"
					></div>
				</div>
			)}
			<svg
				className="-rotate-90 absolute"
				width="160"
				height="160"
				viewBox="0 0 160 160"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<circle
					cx="50%"
					cy="50%"
					r={radius}
					strokeWidth="12"
					fill="transparent"
					stroke="#F1F1F1"
				/>
				<circle
					style={{ color: color }}
					className="stroke-current"
					cx="50%"
					cy="50%"
					r={radius}
					strokeWidth="12"
					fill="transparent"
					strokeDasharray={circumference}
					strokeDashoffset={dashOffset}
					strokeLinecap="round"
				/>
			</svg>
		</div>
	);
};

export default CircularVotesBar;
