import { Link } from "react-router-dom";
import { paths } from "../../constants";

interface Props {
  link: string;
  text: string;
  image: string;
}

const BurgerItem = ({ link, text, image }: Props) => {
  return (
    <Link
      target={
        link === paths.twitter || link === paths.support ? "_blank" : "_self"
      }
      to={link}
    >
      <div className="flex gap-4 text-lg hover:text-[#868686] transition-all duration-200 active:text-blue-500">
        <img src={image} alt="menu-icon" />
        <p>{text}</p>
      </div>
    </Link>
  );
};

export default BurgerItem;
