import {useTranslation} from "react-i18next";
import Section from "../../Section";

const Acceptance = () => {
  const {t} = useTranslation();
  return (
    <Section title={t("policy.acceptance.title")}>
      <p>{t("policy.acceptance.text_1")}</p>
    </Section>
  );
};

export default Acceptance;
