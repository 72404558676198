import { zoomInIcon, zoomOutIcon } from "../../../assets/map/index";
import ZoomButon from "../../../shared/ZoomButon";

interface Props {
  zoomHandler: (zoom: boolean) => void;
}

const Zoom = ({ zoomHandler }: Props) => {
  return (
    <div className="h-24 flex absolute bottom-4 right-4 z-20 flex-col items-center justify-center bg-white w-12 rounded-full">
      <ZoomButon imgSrc={zoomInIcon} onClickHandler={() => zoomHandler(true)} />
      <span className="h-[2px] w-8 bg-gray-100"></span>
      <ZoomButon
        imgSrc={zoomOutIcon}
        onClickHandler={() => zoomHandler(false)}
      />
    </div>
  );
};

export default Zoom;
