import { doc, updateDoc, getDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";

import { IProjectUserInfo } from "../../redux/api/userSlice";

export const claimUpdatedtokens = async (
	claimedTokens: number,
	userId: string | undefined,
	currentOpenedProject: string | null
) => {
	if (!userId || !currentOpenedProject) return;

	const userRef = doc(db, "users", userId);
	const userSnap = await getDoc(userRef);
	const currentProjects: IProjectUserInfo[] =
		userSnap.data()?.isJoinedToProjects || [];

	const updatedProjects = currentProjects.map((project) => {
		if (project.project === currentOpenedProject) {
			return { ...project, claimedVerifyTokens: true, tokens: claimedTokens };
		}
		return project;
	});

	await updateDoc(userRef, {
		isJoinedToProjects: updatedProjects,
	});
};
