import { twMerge } from 'tailwind-merge'
interface Props {
  error: string;
  className?: string;
}

const Alarm = ({ error, className }: Props) => {
  return (
    <div className={twMerge(`text-sm flex text-center justify-center items-center bg-red-600 p-1 text-white rounded-lg`, className)}>
      {error}
    </div>
  );
};

export default Alarm;
