import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import claimVerificationTokensEn from "../../assets/shared/parryClaimVerification.png";
import claimVerificationTokensDe from "../../assets/shared/parryClaimVerificationDe.png";
import claimTokensIcon from "../../assets/detailedView/claimTokensIcon.svg";

import DetailedViewTitle from "../../shared/DetailedViewTitle";
import { useGetCurrentUser } from "../../hooks/useGetCurrentUser";
import useUserProfileInfo from "../../hooks/useUserProfileInfo";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setTokens } from "../../redux/api/userSlice";
import Lottie from "react-lottie";
import claimTokensAnimation from "../../assets/animation/tokensCompleted.json";

import withModal from "../../utils/HOCs/withModal";

import { doc, updateDoc, getDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";

import { IProjectUserInfo } from "../../redux/api/userSlice";
import { getCurrentTokens } from "../../redux/api/userSlice";
import { updateTokensForSelectedProject } from "../../redux/thunks";
import { claimUpdatedtokens } from "../../firebase/utils/updateProjectsVerification";

interface IClaimVerificationTokens {
	closeVerifyWindow: () => void;
	verificationTokens?: number;
}

const ClaimVerificationTokens = ({
	closeVerifyWindow,
	verificationTokens,
}: IClaimVerificationTokens) => {
	const { t, i18n } = useTranslation();
	const dispatch = useAppDispatch();
	const currentTokens = useAppSelector(getCurrentTokens);
	const currentOpenedProject = useAppSelector(
		(state) => state.projectCards.selectedProjectId
	);

	const currentLanguage = i18n.language;

	const defaultOptions = {
		loop: false,
		autoplay: true,
		animationData: claimTokensAnimation,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice",
		},
	};

	const [startAnimation, setStartAnimation] = useState(false);
	const { user } = useGetCurrentUser();
	const userId = user?.uid;

	const claimTokens = async () => {
		if (!userId || currentTokens === null) return;

		try {
			if (verificationTokens) {
				const claimedTokens = currentTokens + verificationTokens;

				if (currentTokens !== null) {
					await claimUpdatedtokens(claimedTokens, userId, currentOpenedProject);
					dispatch(updateTokensForSelectedProject(claimedTokens));
				}
			}
			setStartAnimation(true);

			setTimeout(() => {
				closeVerifyWindow();
				setStartAnimation(false);
			}, 2000);
		} catch (err) {
			console.error(err);
		}
	};

	const claimBtnText =
		i18n.language === "en"
			? `${t("verification.claimTokens")} ${verificationTokens} ${t(
					"verification.claimTokensToken"
			  )}`
			: `${verificationTokens} ${t("verification.claimTokensToken")} ${t(
					"verification.claimTokens"
			  )}`;
	return (
		<div className="max-w-[342px] w-[100%] my-0 mx-auto relative">
			<div className={`mb-[16px] ${startAnimation && "invisible"}`}>
				<DetailedViewTitle>
					{t("verification.claimTokensTitle")}
				</DetailedViewTitle>
			</div>

			<p
				className={`text-[#676a70] font-medium mb-[68px]  ${
					startAnimation && "invisible"
				}`}
			>
				{t("verification.claimTokensDescription")}
			</p>

			<div className="w-full flex justify-center mb-[45px] ">
				<div className="w-[212px] h-[180px] relative overflow-hidden flex items-center">
					<img
						className="overflow-hidden"
						src={
							currentLanguage === "en"
								? claimVerificationTokensEn
								: claimVerificationTokensDe
						}
						alt="Claim tokens pary"
					/>
				</div>
			</div>

			<div className={`w-full  ${startAnimation && "invisible"}`}>
				<button
					onClick={claimTokens}
					className="bg-black py-[16px] text-white rounded-[50px] w-full flex text-center items-center justify-center px-[30px] gap-[8px]"
				>
					<img src={claimTokensIcon} alt="tokens icon" />{" "}
					<span>
						{verificationTokens
							? claimBtnText
							: t("verification.claimTokensGetStarted")}
						{/* { || t("verification.claimTokensCollect")} */}
					</span>
				</button>
			</div>
			{startAnimation && (
				<div className="absolute inset-0 flex items-center justify-center">
					<Lottie options={defaultOptions} />
				</div>
			)}
		</div>
	);
};

export default ClaimVerificationTokens;

export const ClaimVerificationTokensModal = withModal(ClaimVerificationTokens);
