import { useEffect } from "react";
import { getProjects } from "../firebase/getProjects";
import { useAppDispatch } from "../redux/hooks";
import { setProjects } from "../redux/api/projectCardsSlice";

const useGetProjects = () => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		const getNewProjects = async () => {
			const projects = await getProjects();

			if (projects) dispatch(setProjects(projects));
		};

		getNewProjects();
	}, []);
};

export default useGetProjects;
