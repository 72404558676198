import { twMerge } from "tailwind-merge";

interface Props {
  noAbsolute?: boolean;
  parrentClassName?: string;
  childClassName?: string;
}
const Loader = ({noAbsolute = false, parrentClassName, childClassName}: Props) => {
  return (
    <div
      className={twMerge(`${
        noAbsolute ? "" : "absolute"
      } top-0 left-0 w-full h-full flex justify-center items-center bg-white/60`, parrentClassName)}
    >
      <div
        className={twMerge("inline-block h-12 w-12 text-lime-600 animate-spin rounded-full border-8 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]", childClassName)}
        role="status"
      ></div>
    </div>
  );
};

export default Loader;
