import dragIcon from "../assets/dragndrop/drag.svg";
import { FileUploader } from "react-drag-drop-files";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

const fileTypes = ["JPEG", "PNG", "GIF", "JPG", "SVG"];

interface IDropFiles {
	handleChange: (file: File) => void;
	onSizeError: (arg: boolean) => void;
	addPlaceClassName?: string;
}

const DragDropPhoto = ({
	handleChange,
	onSizeError,
	addPlaceClassName,
}: IDropFiles) => {
	const { t } = useTranslation();

	const dragndrop = (
		<div
			className={twMerge(
				`rounded-2xl w-80 h-52 border-[#707070] border-2 border-dashed flex justify-center items-center flex-col gap-[16px] cursor-copy mb-[24px]`,
				addPlaceClassName
			)}
		>
			<div className="bg-black w-[50px] h-[50px] flex justify-center items-center rounded-full">
				<img src={dragIcon} alt="dragIcon" />
			</div>
			<div className="">
				<p className="font-poppins text-[#707070] font-medium text-sm max-w-[200px] text-center">
					{t("editAvatarModal.dragText")}{" "}
					<span className="text-black underline">
						{t("editAvatarModal.choose")}
					</span>
				</p>
			</div>
		</div>
	);

	return (
		<FileUploader
			label="Drag and drop file here or choose file"
			multiple={false}
			maxSize={25}
			handleChange={handleChange}
			name="file"
			types={fileTypes}
			children={dragndrop}
			onSizeError={onSizeError}
		/>
	);
};

export default DragDropPhoto;
