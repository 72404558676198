import { auth } from "./firebase"; // adjust the path according to your setup
import {
	RecaptchaVerifier,
	signInWithPhoneNumber,
	updatePhoneNumber,
	PhoneAuthProvider,
} from "firebase/auth";
import { db } from "./firebase";
import { doc, updateDoc } from "firebase/firestore";

export const sendVerificationCode = async (
	phoneNumber: string,
	setVerificationId: any
) => {
	const appVerifier = await window.recaptchaVerifier;

	try {
		const confirmationResult = await signInWithPhoneNumber(
			auth,
			phoneNumber,
			appVerifier
		);
		setVerificationId(confirmationResult.verificationId);
	} catch (error: any) {
		console.error("Error during signInWithPhoneNumber:", error);
		if (error.code === "auth/invalid-phone-number") {
			return {
				error:
					"The phone number entered is invalid. Please enter a valid phone number.",
			};
		} else if (error.code === "auth/quota-exceeded") {
			return { error: "SMS quota exceeded. Please try again later." };
		} else if (error.code === "auth/too-many-requests") {
			return { error: "Too many requests. Please try again later." };
		} else if (error.code === "auth/captcha-check-failed") {
			return { error: "Captcha verification failed. Please try again." };
		} else if (error.code === "auth/operation-not-allowed") {
			return {
				error:
					"Phone number authentication is not enabled. Please enable it in the Firebase console.",
			};
		} else if (error.code === "auth/app-not-authorized") {
			return {
				error:
					"This app is not authorized to use Firebase Authentication. Please check your configuration.",
			};
		} else if (error.code === "auth/credential-already-in-use") {
			return {
				error:
					"Credential already in use. Please try a different phone number.",
			};
		} else if (error.code === "auth/invalid-app-credential") {
			return {
				error:
					"The authentication credential used by the app is invalid. Please try again or contact support.",
			};
		} else {
			return { error: `Something went wrong: ${error.message}` };
		}
	}
};

export const verifyCode = async (
	verificationId: string,
	verificationCode: string,
	userId: string
) => {
	const credential = PhoneAuthProvider.credential(
		verificationId,
		verificationCode
	);

	if (auth.currentUser) {
		try {
			await updatePhoneNumber(auth.currentUser, credential);

			const userDocRef = doc(db, "users", userId);
			await updateDoc(userDocRef, {
				phoneNumber: auth.currentUser.phoneNumber,
				isUserVerified: true,
			});
		} catch (error: any) {
			if (error.code === "auth/invalid-verification-code") {
				return { error: "Invalid verification code" };
			} else if (error.code === "auth/credential-already-in-use") {
				return { error: "Credential already in use" };
			} else if (error.code === "auth/user-disabled") {
				return { error: "User disabled" };
			} else if (error.code === "auth/operation-not-allowed") {
				return { error: "Operation not allowed" };
			} else if (error.code === "auth/requires-recent-login") {
				return { error: "Requires recent login" };
			} else if (
				error.code === "auth/account-exists-with-different-credential"
			) {
				return {
					error:
						"An account already exists with the same email address but different sign-in credentials. Please use a different sign-in method.",
				};
			} else {
				return {
					error:
						"Error during phone number update or Firestore document update",
				};
			}
		}
	} else {
		return { error: "No current user" };
	}
};
