import { useState, useEffect } from "react";
import { SPOT_COLORS } from "../utils/calcVotesPercantage";

const useHandleUserVotes = (category: string, currVotes: number) => {
	const [userVoteColor, setUserVoteColor] = useState("");

	useEffect(() => {
		if (isNaN(currVotes) || currVotes === 0) {
			setUserVoteColor("#f1f1f1");
			return;
		}
		if (currVotes <= 4) {
			setUserVoteColor(SPOT_COLORS[category as keyof typeof SPOT_COLORS].LOW);
		} else if (currVotes <= 9) {
			setUserVoteColor(
				SPOT_COLORS[category as keyof typeof SPOT_COLORS].MIDDLE
			);
		} else {
			setUserVoteColor(SPOT_COLORS[category as keyof typeof SPOT_COLORS].TOP);
		}
	}, [category, currVotes]);

	return { userVoteColor };
};

export default useHandleUserVotes;
