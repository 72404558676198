import { flag } from "../../../../../assets/shared";
import ReportModal from "./ReportModal";
import { useState } from "react";
import { useGetCurrentUser } from "../../../../../hooks/useGetCurrentUser";
import ViewModModal from "../../../../auth/ViewModModal";
import useRestrictions from "../../../../../hooks/useRestrictions";

interface IReportButton {
	spotId: string;
}

const ReportButton = ({ spotId }: IReportButton) => {
	const { user } = useGetCurrentUser();
	const [isOpen, setIsOpen] = useState(false);

	const {
		isViewMod,
		hangleSignIn,
		hangleLogIn,
		handleViewModClose,
		handleViewModOpen,
	} = useRestrictions();

	const handleClose = () => {
		setIsOpen(false);
	};

	return (
		<>
			<button
				onClick={() => {
					if (user) {
						setIsOpen(true);
					} else {
						handleViewModOpen();
					}
				}}
				className="bg-[#F1F1F1] flex items-center justify-center h-8 w-8 rounded-full"
			>
				<img src={flag} alt="share" />
			</button>
			<ReportModal isOpen={isOpen} handleClose={handleClose} spotId={spotId} />
			<ViewModModal
				isOpen={isViewMod}
				handleClose={handleViewModClose}
				hangleSignIn={hangleSignIn}
				hangleLogIn={hangleLogIn}
			/>
		</>
	);
};

export default ReportButton;
