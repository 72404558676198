import { Input } from "../../shared";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ISignUp } from "../../interfaces";
import { signUpSchema } from "../../utils/validation";
import { createProfileInFirestore } from "../../firebase/auth";
import { AuthButton } from "./AuthButton";
import { useTranslation } from "react-i18next";
import {
	collection,
	doc,
	getDocs,
	query,
	updateDoc,
	where,
} from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { useDebounce } from "../../hooks/useDebounce";
import { useCallback, useEffect, useState } from "react";
import { useDebounceCallback } from "../../hooks/useDebounceCallback";
import {
	createUserWithEmailAndPassword,
	fetchSignInMethodsForEmail,
	getAuth,
	sendEmailVerification,
	updateProfile,
	signOut,
} from "firebase/auth";
import { AuthStep } from "../../types";

interface IProps {
	handleGoToStep: (newStep: AuthStep) => void;
}

export const SignUpForm = ({ handleGoToStep }: IProps) => {
	const [availableUserName, setAvailableUserName] = useState(false);
	const [userNameFocusStatus, setUserNameFocusStatus] = useState(false);
	const [emailNameFocusStatus, setEmailNameFocusStatus] = useState(false);
	const [loading, setLoading] = useState(false);
	const { projectName } = useParams();

	const { t } = useTranslation();

	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues,
		watch,
		setError,
	} = useForm<ISignUp>({
		mode: "all",
		values: { userName: "", email: "" },
		shouldUnregister: false,
	});

	localStorage.setItem("isUserNameConfirmed", "false");

	const { userName, email } = watch();
	const debouncedValidateUserName = useDebounceCallback(
		async (value: string) => {
			try {
				await signUpSchema.validateAt("userName", { userName: value });
				setError("userName", {});

				return true;
			} catch (error) {
				setError("userName", { message: (error as Error)?.message });
			}
		}
	);

	const debouncedValidateEmail = useDebounceCallback(async () => {
		try {
			await signUpSchema.validateAt("email", { email });

			const auth = getAuth();
			const signInMethods = await fetchSignInMethodsForEmail(auth, email);

			if (signInMethods.length > 0) {
				setError("email", { message: t("auth.signUp.emailRegistered") });
			} else {
				setError("email", {});
			}

			return signInMethods.length === 0;
		} catch (error) {
			setError("email", { message: t("auth.login.invalidEmail") });
		}
	});

	const checkUsernameAvailability = useCallback(async () => {
		try {
			const q = query(
				collection(db, "users"),
				where("username", "==", userName.toLowerCase())
			);
			const querySnapshot = await getDocs(q);

			return querySnapshot.empty;
		} catch (error) {
			console.error("Error checking username availability:", error);
			return false;
		}
	}, [userName]);

	const validateUsername = useCallback(async () => {
		if (!userName) {
			return;
		}

		const isAvailable = await checkUsernameAvailability();

		if (isAvailable) {
			setAvailableUserName(true);
			return;
		}

		setAvailableUserName(false);
		setError("userName", {
			type: "manual",
			message: t("auth.signUp.userRegistered"),
		});
	}, [checkUsernameAvailability, setError, t, userName]);

	const debouncedUserName = useDebounce(userName);

	useEffect(() => {
		setAvailableUserName(false);
	}, [userName]);

	useEffect(() => {
		debouncedValidateEmail();
	}, [email]);

	useEffect(() => {
		const validateUserNameAndCheckAvailability = async () => {
			const isValid = await debouncedValidateUserName(debouncedUserName);

			if (isValid) {
				validateUsername();
			}
		};

		validateUserNameAndCheckAvailability();
	}, [debouncedUserName]);

	const onSubmit = async () => {
		const { email } = getValues();

		try {
			setLoading(true);

			const REDIRECT_DOMAIN = projectName
				? `${process.env.REACT_APP_WEBSITE_URL}/project/${projectName}"`
				: `${process.env.REACT_APP_WEBSITE_URL}/projects"`;

			const password = window.crypto.randomUUID();
			localStorage.setItem("userPassword", password);

			const auth = getAuth();
			const userCredential = await createUserWithEmailAndPassword(
				auth,
				email,
				password
			);
			const user = userCredential.user;
			const token = await user?.getIdToken();

			const actionCodeSettings = {
				url: `${REDIRECT_DOMAIN}/?token=${token}`,
				handleCodeInApp: true,
			};

			await createProfileInFirestore().then(() => {
				user && updateProfile(user, { displayName: userName });
				const userID = user?.uid;
				const userRef = doc(db, "users", userID);
				updateDoc(userRef, { username: userName });
			});

			localStorage.setItem("first_login", "true");
			localStorage.setItem("emailForSignIn", email);
			localStorage.setItem("userId", user.uid);

			await signOut(auth);

			await sendEmailVerification(user, actionCodeSettings);

			handleGoToStep("signUpConfirm");
		} catch (err) {
			console.error(err);
			setError("email", { message: t("auth.signUp.failedCreateAccount") });
		} finally {
			setLoading(false);
		}
	};

	const generateConditionForUserNameField = <T, K, D>({
		errorPresent,
		basic,
		defaultRes,
		input = "username",
	}: {
		errorPresent: T;
		basic: K;
		defaultRes?: D;
		input?: "email" | "username";
	}) => {
		const debounceVariable = input === "username" ? debouncedUserName : email;
		const error =
			input === "username" ? errors.userName?.message : errors.email?.message;

		if (debounceVariable && error) {
			return errorPresent;
		}

		const inputFocusStatus =
			input === "username" ? userNameFocusStatus : emailNameFocusStatus;

		if (!inputFocusStatus) {
			return defaultRes !== undefined ? defaultRes : basic;
		}

		return basic;
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="flex flex-col">
				<span className="text-black font-medium	">
					{t("auth.signUp.enterUserName")}
				</span>
				<Input
					placeholder={t("auth.signUp.userNamePlaceholder")}
					register={register}
					name="userName"
					type="text"
					error={generateConditionForUserNameField({
						errorPresent: errors.userName?.message,
						basic: availableUserName ? t("auth.signUp.avalaible") : " ",
						defaultRes: " ",
					})}
					setFocusStatus={setUserNameFocusStatus}
					className={`!pl-2 border border-solid border-gray-300 rounded-md py-1 mt-2 mb-0 h-10 ${generateConditionForUserNameField(
						{
							errorPresent: "border-2 !border-error",
							basic: "!border-gray-300",
						}
					)}`}
					alarmClassName={`justify-end !bg-white text-error h-6 pt-0 ${generateConditionForUserNameField(
						{
							errorPresent: "text-error",
							basic: "text-midGreen",
						}
					)}`}
				/>
			</div>
			<label>
				<span className="text-black font-medium	">
					{t("auth.signUp.enterEmail")}
				</span>
				<Input
					placeholder={t("auth.signUp.emailPlaceHolder")}
					register={register}
					margin="mb-0"
					name="email"
					type="email"
					setFocusStatus={setEmailNameFocusStatus}
					error={generateConditionForUserNameField({
						errorPresent: errors.email?.message,
						basic: " ",
						input: "email",
					})}
					className={`pl-2 border border-solid border-gray-300 rounded-md py-1 mt-2 mb-0 h-10 ${generateConditionForUserNameField(
						{
							errorPresent: "border-2 !border-error",
							basic: " ",
							input: "email",
						}
					)}`}
					alarmClassName={`justify-end bg-white text-red h-6 pt-0 ${generateConditionForUserNameField(
						{
							errorPresent: "text-error",
							basic: "text-midGreen",
							input: "email",
						}
					)}`}
				/>
			</label>
			<AuthButton
				className="mt-2"
				isLoading={loading}
				disabled={
					!!errors.email?.message ||
					!!errors.userName?.message ||
					!availableUserName
				}
				text={t("auth.signUp.confirmSignUp")}
			/>
		</form>
	);
};
