import {useTranslation} from "react-i18next";
import {interestsList} from "../../constants";
import i18next from "i18next";

interface IModalInterests {
  interests: string[];
}

interface ITopicsList {
  id: string;
  icon: string;
  en: string;
  de: string;
}

const ProfileModalInterests = ({interests}: IModalInterests) => {
  const {t} = useTranslation();
  const lang = i18next.language;

  return (
    <div className="flex-col w-[18rem] pt-2">
      <p>{t("profileModal.interests")}</p>

      <div className="flex border-b-[1px] text-balance text-sm pb-2">
        <div className="flex flex-wrap gap-2 w-full text-primaryBlack pt-3">
          {interestsList.filter(item => interests.includes(item.id)).map(({id, icon, en, de}: ITopicsList)=> {
            const title = lang === "de" ? de : en;
            return <div
            className="flex justify-center cursor-default gap-1 rounded-full text-[12px] py-2 px-3 outline outline-2 outline-black"
            key={id}
          >
            <div>{icon}</div>
            <div>{title}</div>
          </div>

})}
        </div>
      </div>
    </div>
  );
};

export default ProfileModalInterests;
