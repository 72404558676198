import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { DocumentData } from "@firebase/firestore";
import SpotCard from "./SpotCard";
import Loader from "../../../shared/Loader";
import { useEffect } from "react";
import { sortSpots } from "../../../redux/api/spotsSlice";
import { useTranslation } from "react-i18next";
import { useGetCurrentUser } from "../../../hooks/useGetCurrentUser";
import useHandleScrollHide from "../../../hooks/useHandleScrollHide";
import { handleAddComment } from "../../../firebase/comments/handleComments";

const SpotsCardList = () => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const cardList = useHandleScrollHide();
	const getCurrentScroll = () => cardList.current?.scrollTop;
	const { user } = useGetCurrentUser();
	const userID = user?.uid;

	const sortBy = useAppSelector((state) => state.spots.sortBy);
	const filteredSpots = useAppSelector((state) => state.spots.filteredSpots);
	const mapIsLoaded = useAppSelector((state) => state.map.isMapLoaded);
	let sortedSpots = useAppSelector((state) => state.spots.sortedSpots);
	const section = useAppSelector((state) => state.elementsView.section);
	const mySpotsActiveTab = useAppSelector(
		(state) => state.elementsView.mySpotsActiveTab
	);

	if (section === "MySpots" && mySpotsActiveTab === "Created") {
		sortedSpots = sortedSpots.filter((item) => item.submitted_by === userID);
	} else if (section === "MySpots" && mySpotsActiveTab === "Voted") {
		sortedSpots = sortedSpots.filter((item) => {
			for (let vote in item.votes) {
				if (vote === userID && item.submitted_by !== userID) return item;
			}
		});
	}

	useEffect(() => {
		dispatch(sortSpots());
		if (cardList.current) {
		}
	}, [sortBy, filteredSpots]);

	return (
		<div
			ref={cardList}
			className={`overflow-y-auto flex-1 scroll-transparent scroll-thin pr-2`}
		>
			{!mapIsLoaded ? (
				<div className="relative w-full h-full flex items-center justify-center">
					<Loader />
				</div>
			) : (
				<>
					<div>
						{sortedSpots?.map((spot: DocumentData, index: number) => (
							<SpotCard
								key={spot.id}
								spot={spot}
								ranking={spot.ranking}
								getCurrentScroll={getCurrentScroll}
							/>
						))}
					</div>
					{/* {!sortedSpots.length && (
						<div className="h-full w-full flex justify-end items-end ">
							<div className="flex  items-start">
								<div className=" flex flex-col items-end">
									<div className="flex w-[266px] text-center h-[133px] justify-center items-center text-sm bg-textWindow bg-no-repeat">
										<p className="p-6">
											{!categories.length
												? t("ranking.no-spots")
												: t("ranking.empty-district")}
										</p>
									</div>
									<div className="mr-16">
										<img src={parryNoSpots} alt="parry-no-spots" />
									</div>
								</div>
								{!!categories.length && (
									<img className="ml-4" src={qrCode} alt="qr-code" />
								)}
							</div>
						</div>
					)} */}
				</>
			)}
		</div>
	);
};

export default SpotsCardList;
