import {cross} from "../../../../../assets/shared";

interface Props {
  image: string;
  closeImageFull: () => void;
}

const ImageFullScreen = ({image, closeImageFull}: Props) => {
  return (
    <div className="fixed w-screen h-screen bg-black top-0 left-0 z-[100]">
      <div className="h-full w-full flex relative justify-center items-center">
        <button
          onClick={closeImageFull}
          className="bg-[#E8E8E8] absolute top-12 right-12 h-12 w-12 rounded-full flex items-center justify-center"
        >
          <img src={cross} alt="cross" />
        </button>
        <div className="h-[504px] w-[833px] rounded-3xl overflow-hidden">
          <img
            className="w-full h-full object-cover"
            src={image}
            alt="full-screen"
          />
        </div>
      </div>
    </div>
  );
};

export default ImageFullScreen;
