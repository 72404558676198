import {useTranslation} from "react-i18next";
import Section from "../../Section";

const Firebase = () => {
  const {t} = useTranslation();
  return (
    <Section title={t("policy.firebase.title")}>
      <p>{t("policy.firebase.text_1")}</p>
      <p>
        {t("policy.firebase.text_2")}
        <a
          target="_blank"
          rel="noopener noreferrer"
          className="underline"
          href="https://policies.google.com/privacy"
        >
          Google in their Privacy Policy
        </a>
      </p>
      <p>
        {t("policy.firebase.text_3")}{" "}
        <a
          target="_blank"
          className="underline"
          href="https://policies.google.com/privacy"
          rel="noopener noreferrer"
        >
          safeguarding your data.
        </a>
      </p>
      <p>
        {t("policy.firebase.text_4")}
        <a
          target="_blank"
          className="underline"
          rel="noopener noreferrer"
          href=" https://policies.google.com/technologies/partner-sitesy"
        >
          How Google uses data when you use our partners' sites or apps webpage.
        </a>
      </p>
      <p>{t("policy.firebase.text_5")}</p>
      <p>{t("policy.firebase.text_6")}</p>
    </Section>
  );
};

export default Firebase;
