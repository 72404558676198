import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IMapSize {
  height: number | undefined;
  width: number | undefined;
}
interface initialState {
  mapSize: IMapSize;
  isMapLoaded: boolean;
}

const initialState: initialState = {
  mapSize: {
    height: undefined,
    width: undefined,
  },
  isMapLoaded: false,
};

export const mapSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setMapSize: (state, action: PayloadAction<IMapSize>) => {
      state.mapSize = action.payload;
    },
    setIsMapLoaded: (state, action: PayloadAction<boolean>) => {
      state.isMapLoaded = action.payload;
    },
  },
});

export const { setMapSize, setIsMapLoaded } = mapSlice.actions;
export default mapSlice.reducer;
