import {useTranslation} from "react-i18next";
import BlockContainer from "./BlockContainer";
import {friend} from "../../assets/qv";

const Parry = () => {
  const {t} = useTranslation();
  return (
    <BlockContainer styles="xl:w-[745px]">
      <img
        className="z-30 absolute -top-12  xl:-top-[110px] right-[50px] xl:right-[125px] w-[56px] xl:w-auto"
        src={friend}
        alt=""
      />
      <div className="pt-7 rounded-[39px] px-4 xl:px-9 pb-[90px] xl:pb-[105px] font-bold text-3xl xl:text-5xl bg-white relative xl:leading-[67px] leading-[44px]">
        {t("qv.parry.text_1")} {t("qv.parry.text_2")}
      </div>
    </BlockContainer>
  );
};

export default Parry;
