import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import Select from "../../../shared/Select";
import Categories from "./Categories";
import SpotsCardList from "./SpotsCardList";
import { regions2 as districts } from "../../../constants";
import { filterSpots, selectDistrict } from "../../../redux/api/spotsSlice";
import { setDisrict } from "../../../redux/api/locationSlice";
import DetailedView from "./detailedView/DetailedView";
import { useTranslation } from "react-i18next";

const SpotsMenu = () => {
	const dispatch = useAppDispatch();
	const selectedItem = useAppSelector((state) => state.spots.selectedDistrict);
	const spotCardView = useAppSelector(
		(state) => state.elementsView.spotCardView
	);

	const { t } = useTranslation();

	const handleSelect = (item: any) => {
		dispatch(selectDistrict(item));
		dispatch(setDisrict(item));
		dispatch(filterSpots());
	};

	return (
		<>
			{spotCardView ? (
				<DetailedView />
			) : (
				<>
					<div className="h-145px w-full mb-7 pr-2">
						<Select
							all
							title={t("ranking.select-area")}
							selectedItem={selectedItem}
							data={districts}
							handleSelect={handleSelect}
							containerClass={"mb-3"}
							alighWithMap={true}
						/>
						<Categories />
					</div>
					<SpotsCardList />
				</>
			)}
		</>
	);
};

export default SpotsMenu;
