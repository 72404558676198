import { useEffect } from "react";

import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import {
	selectDistrict,
	filterSpots,
	sortSpots,
} from "../../../redux/api/spotsSlice";

import Categories from "./Categories";
import DetailedView from "./detailedView/DetailedView";
import SpotsCardList from "./SpotsCardList";
import MySpotsTabs from "./MySpotsTabs";

const MySpots = () => {
	const dispatch = useAppDispatch();

	const spotCardView = useAppSelector(
		(state) => state.elementsView.spotCardView
	);

	useEffect(() => {
		dispatch(
			selectDistrict({
				name: "All",
				coords: [13.2833322, 52.499998],
				gen: true,
			})
		);
		dispatch(filterSpots());
		dispatch(sortSpots());
	}, []);

	return (
		<>
			{spotCardView ? (
				<DetailedView />
			) : (
				<>
					<div className="h-145px w-full mb-8 pr-2">
						<MySpotsTabs />
						<Categories />
					</div>
					<SpotsCardList />
				</>
			)}
		</>
	);
};

export default MySpots;
