import { PropsWithChildren } from "react";

interface Props {
	title: string;
	projectCard?: boolean;
}

const TextSection = ({
	children,
	title,
	projectCard,
}: PropsWithChildren<Props>) => {
	return (
		<div className="text-start">
			<div className="mb-[14px]">
				<p className="text-xl font-poppinsBold max-w-[270px] line-clamp-2">{title}</p>
			</div>
			<div className="text-[#676a70] w-[270px] text-[0.8125rem] leading-5 line-clamp-5 text-ellipsis">
				{children}
			</div>
		</div>
	);
};

export default TextSection;
