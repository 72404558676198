import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { IFeatureColection } from "../../interfaces";
import { getUserById } from "../../firebase/getUser";
import { getComments } from "../../firebase/comments/getComments";

export const apiSlice = createApi({
	reducerPath: "userApi",
	baseQuery: fetchBaseQuery({
		baseUrl: "https://api.mapbox.com/geocoding/v5/mapbox.places/",
	}),
	endpoints: (builder) => ({
		getLocation: builder.query<IFeatureColection, string>({
			query: (searchQuery) =>
				`https://api.mapbox.com/geocoding/v5/mapbox.places/${searchQuery}.json?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`,
		}),
		getAddress: builder.query({
			query: (coordinates) => {
				const [longitude, latitude] = coordinates.split(",");
				return `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&accept-language=de&format=json`;
			},
		}),
		getSpotSubmittedBy: builder.query({
			async queryFn(id) {
				const submittedBy = await getUserById(id);

				if (submittedBy)
					return {
						data: {
							name: submittedBy.name,
							username: submittedBy.username,
							defaultAvatarIndex: submittedBy.defaultAvatarIndex,
							photoURL: submittedBy.photoURL,
						},
					};
				else throw new Error("User not found");
			},
		}),
		getSpotComments: builder.query({
			async queryFn(spotId) {
				const comments = await getComments(spotId);
				return {
					data: comments.map((comment) => ({
						...comment,
						updatedOn: comment.updatedOn?.toDate().toISOString() || null,
						replies: comment.replies.map((reply: any) => ({
							...reply,
							updatedOn: reply.updatedOn?.toDate().toISOString() || null,
						})),
					})),
				};
			},
		}),
		notifyDiscord: builder.mutation({
			async queryFn(notificationBody) {
				const discordResponse = await fetch(
					"https://discord.com/api/webhooks/1149650553509511178/4WHwGgvmV3EXtQFFh3BecVQceIU6bHXacyvz5l-0dydhiisvhWJZScIRsmKtiMhGjp9H",
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify(notificationBody),
					}
				);
				return { data: discordResponse.ok };
			},
		}),
	}),
});

export const {
	useLazyGetLocationQuery,
	useNotifyDiscordMutation,
	useGetSpotSubmittedByQuery,
	useGetSpotCommentsQuery,
	useLazyGetAddressQuery,
} = apiSlice;
