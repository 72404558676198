import BlockContainer from "./BlockContainer";
import {character, map} from "../../assets/qv";
import {useTranslation} from "react-i18next";

const Map = () => {
  const {t} = useTranslation();

  return (
    <BlockContainer styles="xl:max-w-[419px]">
      <div className="pb-8 xl:pb-[66px] relative flex flex-col items-center">
        <img
          className="hidden xl:absolute -top-[125px] right-0 translate-x-full"
          src={character}
          alt="character-icon"
        />
        <div className="p-8 pl-0 xl:pt-[87px] xl:pr-[76px] xl:mb-6 xl:pl-[43px] text-center ">
          <img className="xl:mb-8" src={map} alt="map" />
        </div>
        <div className="px-8 xl:px-[66px]">
          <p>{t("qv.map.text_1")}</p>
        </div>
      </div>
    </BlockContainer>
  );
};

export default Map;
