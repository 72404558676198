import * as yup from "yup";
import { allowedCountries } from "../constants";
import "yup-phone-lite";

export const loginSchema = yup.object().shape({
	email: yup.string().email().required("required"),
	password: yup
		.string()
		.required("required")
		.min(6, "Too short")
		.matches(
			/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/,
			"should contain one letter and one number"
		),
});

export const signUpSchema = yup.object().shape({
	email: yup
		.string()
		.email()
		.required("required")
		.matches(
			/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
			"Invalid email address"
		),
	userName: yup.string().required("required").min(3, "Too short"),
});

export const emailSchema = yup.object().shape({
	email: yup.string().email().required("required"),
});

export const contactFormSchema = yup.object().shape({
	userName: yup
		.string()
		.required("required")
		.matches(/^[a-zA-Z]+$/, "invalid name"),
	email: yup.string().email().required("required"),
	initiative: yup.string().required("required"),
});

export const validationSchema = yup.object().shape({
	phoneNumber: yup
		.string()
		.required("Required")
		.phone(allowedCountries, "Invalid Phone Number"),
});
