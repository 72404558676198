import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface initialState {
	spotCardView: boolean;
	spotCardListSroll: number | undefined;
	section: "Ranking" | "Activity" | "MySpots" | "Mission";
	isMobile: boolean;
	mySpotsActiveTab: "Created" | "Voted";
}

const initialState: initialState = {
	spotCardView: false,
	spotCardListSroll: undefined,
	section: "Ranking",
	isMobile: false,
	mySpotsActiveTab: "Created",
};

export const elementsViewSlice = createSlice({
	name: "elementsView",
	initialState,
	reducers: {
		setSpotCardView: (state, action: PayloadAction<boolean>) => {
			state.spotCardView = action.payload;
		},
		setScrollLevel: (state, action: PayloadAction<number>) => {
			state.spotCardListSroll = action.payload;
		},
		changeSection: (
			state,
			action: PayloadAction<"Ranking" | "Activity" | "MySpots" | "Mission">
		) => {
			state.section = action.payload;
		},
		setIsMobile: (state, action: PayloadAction<number>) => {
			state.isMobile = action.payload < 768;
		},
		setMySpotsActiveTab: (
			state,
			action: PayloadAction<"Created" | "Voted">
		) => {
			state.mySpotsActiveTab = action.payload;
		},
	},
});

export const {
	setSpotCardView,
	setScrollLevel,
	changeSection,
	setIsMobile,
	setMySpotsActiveTab,
} = elementsViewSlice.actions;
export default elementsViewSlice.reducer;
