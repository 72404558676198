import { useRef } from "react";
import { Cropper } from "react-advanced-cropper";
import "react-advanced-cropper/dist/style.css";
import { Modal } from "./Modal";
import { useTranslation } from "react-i18next";

interface ICropper {
	imgURL: string;
	saveChanges: (file: File) => void;
	isOpen: boolean;
	handleClose: () => void;
}

const NewCropper = ({ imgURL, saveChanges, isOpen, handleClose }: ICropper) => {
	const { t } = useTranslation();

	const cropperRef = useRef<null | any>(null);
	const onCrop = (): File | null => {
		const cropper = cropperRef.current;
		if (cropper) {
			const canvas = cropper.getCanvas();
			if (canvas) {
				const dataUrl = canvas.toDataURL("image/png");
				const blob = dataURLtoBlob(dataUrl);
				const file = new File([blob], "cropped-image.png", {
					type: "image/png",
				});
				return file;
			}
		}
		return null;
	};

	const dataURLtoBlob = (dataURL: string): Blob => {
		const byteString = atob(dataURL.split(",")[1]);
		const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
		const ab = new ArrayBuffer(byteString.length);
		const ia = new Uint8Array(ab);
		for (let i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}
		return new Blob([ab], { type: mimeString });
	};

	const stencilProps = {
		aspectRatio: 5 / 3,
		movable: true,
		resizable: true,
		lines: true,
		handlers: true,
		grid: true,
	};

	return (
		<Modal
			//   onSaveHandler={() => onSaveHandler(onCrop())}
			handleClose={handleClose}
			isOpen={isOpen}
			modalContainerClassName={
				"max-w-[710px] relative p-[60px] rounded-xl max-h-[750px] overflow-y-auto scroll-transparent"
			}
		>
			<div className="">
				<div className="flex justify-center flex-col items-center mb-[16px]">
					<Cropper
						ref={cropperRef}
						src={imgURL}
						stencilProps={stencilProps}
						className="max-w-[600px] h-[450px] object-contain"
						// imageClassName="h-[600px]"
					/>
				</div>
				<div>
					<button
						className="group flex w-full gap-[8px] items-center transition-all justify-center bg-[#0A0B0E] rounded-[30px] py-[14px] text-center text-white font-poppins disabled:bg-[#DFE1E5] disabled:text-[#94979C]"
						type="button"
						onClick={() => {
							const file = onCrop();
							if (file) {
								saveChanges(file);
								handleClose();
							}
						}}
					>
						{t("addSpot.apply")}
					</button>
					<button
						className={`flex w-full items-center border-2 border-[#F0F0F0] mt-[16px] transition-all justify-center rounded-[30px] py-[14px] text-center text-[#16181C] text-[16px] font-poppins hover:border-[#BFC2C7] hover:dg-[#FAFAFA] transition-all
							 "bg-white"
						`}
						type="button"
						onClick={handleClose}
					>
						{t("addSpot.cancel")}
					</button>
				</div>
			</div>
		</Modal>
	);
};
export default NewCropper;
