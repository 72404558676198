import { DocumentData } from "@firebase/firestore";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISortType } from "../../interfaces";
import { IDistrict } from "../../interfaces";
import { sortByDate, sortByVotes } from "../../utils/sortBy";
import { regions } from "../../constants";
import { Region } from "../../constants";

interface initialState {
	spotsData: DocumentData[];
	categories: string[];
	selectedSpot: DocumentData | null;
	sortBy: {
		name: string;
		sortType: string;
	};
	filteredSpots: DocumentData[];
	sortedSpots: DocumentData[];
	selectedDistrict: Region;
	hoveredCardId: string | null;
	selectedCard: DocumentData | null;
	spotsInView: DocumentData[];
	categorySelected: boolean;
}
const initialState: initialState = {
	spotsData: [],
	categories: ["Useful", "Bike Friendly", "Dangerous"],
	selectedSpot: null,
	sortBy: {
		name: "Most votes",
		sortType: "votes",
	},
	filteredSpots: [],
	sortedSpots: [],
	selectedDistrict: { name: "All" },
	hoveredCardId: null,
	selectedCard: null,
	spotsInView: [],
	categorySelected: false,
};

const addRank = (data: DocumentData[]) => {
	const rankedData = data.map((spot: DocumentData, index: number) => ({
		...spot,
		ranking: index + 1,
	}));
	return rankedData;
};

export const spotsSlice = createSlice({
	name: "spots",
	initialState,
	reducers: {
		setSpots: (state, action: PayloadAction<DocumentData[]>) => {
			state.spotsData = action.payload;
			// state.filteredSpots = action.payload.filter(
			// 	(spot) => regions.indexOf(spot.district) !== -1
			// );
			state.filteredSpots = action.payload; // changed
		},
		updateSpot: (state, action: PayloadAction<DocumentData>) => {
			const index = state.spotsData.findIndex(
				(obj) => obj.id === action.payload.id
			);

			state.spotsData[index] = action.payload;
			if (state.selectedCard && state.selectedCard.id === action.payload.id) {
				state.selectedCard = action.payload;
			}
		},
		handleCategories: (state, action: PayloadAction<string>) => {
			state.categories =
				state.categories.indexOf(action.payload) !== -1
					? state.categories.filter(
							(category: string) => category !== action.payload
					  )
					: [...state.categories, action.payload];
		},
		filterSpots: (state) => {
			const handleSpotsFilter = (spots: DocumentData[]) => {
				const filteredByCategory = spots.filter(
					(spot: DocumentData) => state.categories.indexOf(spot.category) !== -1
				);
				if (state.selectedDistrict.name === "All") {
					return filteredByCategory;
				}
				if (state.selectedDistrict.name === "Berlin")
					return filteredByCategory.filter(
						(spot) => regions.indexOf(spot.district) !== -1
					);
				const filteredByDistrict = filteredByCategory.filter(
					(spot: DocumentData) => spot.district === state.selectedDistrict.name
				);
				return filteredByDistrict;
			};
			state.filteredSpots = handleSpotsFilter(state.spotsData);
		},
		sortSpots: (state) => {
			const sortedSpots =
				state.sortBy.sortType === "votes"
					? sortByVotes(state.filteredSpots)
					: state.sortBy.sortType === "recent"
					? sortByDate(state.filteredSpots, true)
					: sortByDate(state.filteredSpots, false);
			state.sortedSpots = addRank(sortedSpots);
		},
		selectSpot: (state, action: PayloadAction<DocumentData | null>) => {
			state.selectedSpot = action.payload;
		},
		selectSortType: (state, action: PayloadAction<ISortType>) => {
			state.sortBy = action.payload;
		},
		selectDistrict: (state, action: PayloadAction<Region>) => {
			state.selectedDistrict = action.payload;
		},
		setHoveredCardId: (state, action: PayloadAction<string | null>) => {
			state.hoveredCardId = action.payload;
		},
		setSelectedCard: (state, action: PayloadAction<DocumentData | null>) => {
			state.selectedCard = action.payload;
		},
		setSpotsInView: (
			state,
			action: PayloadAction<{ spot: DocumentData; isVisible: boolean }>
		) => {
			if (action.payload.isVisible) {
				state.spotsInView = [...state.spotsInView, action.payload.spot];
			} else
				state.spotsInView = state.spotsInView.filter(
					(spot: DocumentData) => spot.id !== action.payload.spot.id
				);
		},
		setCategorySelected: (state, action: PayloadAction<boolean>) => {
			state.categorySelected = action.payload;
		},
	},
});

export const {
	setSpots,
	handleCategories,
	sortSpots,
	selectSpot,
	selectSortType,
	selectDistrict,
	filterSpots,
	setHoveredCardId,
	setSelectedCard,
	setSpotsInView,
	updateSpot,
	setCategorySelected,
} = spotsSlice.actions;
export default spotsSlice.reducer;
