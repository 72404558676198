import { setTokens } from "./api/userSlice";
import { AppDispatch, RootState } from "./app/store";

export const updateTokensForSelectedProject =
	(newTokens: number) => (dispatch: AppDispatch, getState: () => RootState) => {
		const state = getState();

		const selectedProjectId = state.projectCards.selectedProjectId;

		if (!selectedProjectId) {
			console.error("No selected project found");
			return;
		}

		dispatch(
			setTokens({ currentProject: selectedProjectId, tokens: newTokens })
		);
	};
