import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
	apiKey: "AIzaSyBPxSA8QyQN5LKvrwQm3T2TB2Xnj6VWt44",
	authDomain: "parry-eu.firebaseapp.com",
	projectId: "parry-eu",
	storageBucket: "parry-eu.appspot.com",
	messagingSenderId: "446030581993",
	appId: "1:446030581993:web:83435cb2a832716f40a078",
	measurementId: "G-LSGB1L26JX",
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
