import { useTranslation } from "react-i18next";
import { filterSpots, handleCategories } from "../../../redux/api/spotsSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

interface Props {
  category: {
    img: string;
    text: string;
    name: string;
  };
}

const SingleCategory = ({ category }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedCategories = useAppSelector((state) => state.spots.categories);

  const handleCategorySelect = () => {
    dispatch(handleCategories(category.name));
    dispatch(filterSpots());
  };

  return (
    <button
      onClick={handleCategorySelect}
      className={`flex justify-center h-10 px-2 pr-4 border-2 ${
        selectedCategories.indexOf(category.name) !== -1 && "border-outlineBlue"
      } rounded-full items-center text-sm leading-[19px] gap-2`}
    >
      <img className="w-6 h-6" src={category.img} alt="category-icon" />
      <span>{t(`ranking.categories.${category.name}`)}</span>
    </button>
  );
};

export default SingleCategory;
