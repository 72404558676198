import { useTranslation } from "react-i18next";
import { useState, useRef, useEffect, useCallback } from "react";
import Replies from "./Replies";
import { DocumentData } from "firebase/firestore";
import { avatars } from "../../../../../assets/avatar";
import CommentInput from "./CommentInput";
import ReportModal from "../cardInfo/ReportModal";
import arrow from "../../../../../assets/detailedView/arrow-comments.svg";
import DeletePlaceModal from "../../../appPlace/DeletePlaceModal";
import { deleteComment } from "../../../../../firebase/comments/handleComments";
import useClickOutside from "../../../../../hooks/useClickOutside";

interface Props {
	comment: DocumentData;
	replyTo?: string;
	currentUser: string | undefined;
	selectedCard: DocumentData;
	allComments: DocumentData[] | undefined;
	refetch: () => void;
	handleViewModOpen: () => void;
	isMoreOptionsOpen: DocumentData;
	setIsMoreOptionsOpen: (arg: string) => void;
}
const SingleComment = ({
	comment,
	replyTo,
	currentUser,
	selectedCard,
	allComments,
	refetch,
	handleViewModOpen,
	isMoreOptionsOpen,
	setIsMoreOptionsOpen,
}: Props) => {
	const { t, i18n } = useTranslation();
	const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

	const [reportModalOpen, setReportModalOpen] = useState(false);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [isReplying, setIsReplying] = useState(false);
	const [value, setValue] = useState("");
	const [showReplies, setShowReplies] = useState(false);

	const displayReplies = async () => {
		setShowReplies(!showReplies);
	};

	const replyRef = useRef<HTMLDivElement | null>(null);

	const handleRef = (element: HTMLDivElement) => {
		if (element) {
			replyRef.current = element;
		}
	};

	useEffect(() => {
		const handleLanguageChange = (lng: string) => {
			setCurrentLanguage(lng);
		};

		i18n.on("languageChanged", handleLanguageChange);

		return () => {
			i18n.off("languageChanged", handleLanguageChange);
		};
	}, [i18n]);

	useEffect(() => {
		replyRef.current?.scrollIntoView({ behavior: "smooth" });
	}, [replyRef.current, replyRef, isReplying, isEditing]);

	// const setOpenOptions = useCallback(() => {
	// 	setIsMoreOptionsOpen(comment.id);
	// }, [setIsMoreOptionsOpen, comment.id]);

	const { element, isVisible, clickOutSide } =
		useClickOutside<HTMLButtonElement>("option-btn");

	useEffect(() => {
		if (isVisible === false && isMoreOptionsOpen.length !== 0) {
			setIsMoreOptionsOpen(comment.id);
		}
	}, [isVisible]);

	const handleReportModalClose = () => {
		setReportModalOpen(false);
	};

	const handleDeleteModalClose = () => {
		setDeleteModalOpen(false);
	};

	const handleDeleteComment = async () => {
		await deleteComment(comment.id, selectedCard.id, comment.repliesAmount > 0);
		handleDeleteModalClose();
		refetch();
	};

	return (
		<div>
			{comment.deleted ? (
				<div className="tracking-wider text-[#717171] text-[12px]">
					{"> > > "}Comment deleted by user.{" < < <"}
				</div>
			) : (
				<>
					<div className="">
						<div className="grid grid-cols-[auto_1fr] gap-[16px]">
							<div>
								<img
									className="w-10 h-10 rounded-full object-cover"
									src={
										avatars[comment.avatarIndex as keyof typeof avatars] ||
										comment.avatarIndex
									}
									alt=""
								/>
							</div>
							<div className="grid grid-cols-2">
								<div className="flex gap-[8px] col-start-1 col-end-2 row-start-1 row-end-2">
									<p className="text-[#16181C] text-[14px]">
										{comment.username}
									</p>
									<p className="text-[#94979C] text-[14px]">
										{comment.updatedOn && "edited"} {comment.date}{" "}
										{comment.time}
									</p>
								</div>
								<div className="flex justify-end items-start shrink grow gap-[10px] col-start-2 col-end-3 row-start-1 row-end-2">
									{currentUser === comment.submitted_by ? (
										<button
											onClick={() => {
												if (!currentUser) {
													handleViewModOpen();
													return;
												}
												setIsEditing(true);
												setValue(comment.comments);
											}}
										>
											<svg
												width="16"
												height="16"
												viewBox="0 0 16 16"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M13.7877 2.21665C13.0067 1.4356 11.7403 1.4356 10.9593 2.21665L1.92172 11.2542C1.54665 11.6293 1.33594 12.138 1.33594 12.6684V14.0018C1.33594 14.3699 1.63441 14.6684 2.0026 14.6684H3.33594C3.86637 14.6684 4.37508 14.4577 4.75015 14.0826L13.7877 5.04507C14.5688 4.26402 14.5688 2.99769 13.7877 2.21665ZM11.9021 3.15945C12.1624 2.89911 12.5846 2.89911 12.8449 3.15946C13.1053 3.4198 13.1053 3.84192 12.8449 4.10226L11.825 5.12215L10.8822 4.17934L11.9021 3.15945ZM9.93941 5.12215L10.8822 6.06496L3.80734 13.1398C3.68232 13.2649 3.51275 13.3351 3.33594 13.3351H2.66927V12.6684C2.66927 12.4916 2.73951 12.322 2.86453 12.197L9.93941 5.12215Z"
													fill={isEditing ? "#3E85F0" : "#16181C"}
												/>
											</svg>
										</button>
									) : (
										<button
											onClick={() => {
												if (!currentUser) {
													handleViewModOpen();
													return;
												}
												setIsReplying(true);
											}}
										>
											<svg
												width="16"
												height="16"
												viewBox="0 0 16 16"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M6.5617 2.51291C6.8446 2.27726 6.88291 1.85689 6.64726 1.57399C6.41162 1.29109 5.99125 1.25278 5.70835 1.48843L2.13403 4.46571C1.17407 5.26532 1.17408 6.73954 2.13403 7.53915L5.70834 10.5164C5.99125 10.7521 6.41161 10.7138 6.64726 10.4309C6.88291 10.148 6.8446 9.72761 6.5617 9.49197L3.17064 6.66732H9.9974C11.8383 6.66732 13.3307 8.1597 13.3307 10.0007V14.0007C13.3307 14.3688 13.6292 14.6673 13.9974 14.6673C14.3656 14.6673 14.6641 14.3688 14.6641 14.0007V10.0007C14.6641 7.42332 12.5747 5.33398 9.9974 5.33398H3.17492L6.5617 2.51291Z"
													fill={isReplying ? "#3E85F0" : "#16181C"}
												/>
											</svg>
										</button>
									)}
									<div className="relative" onClick={() => clickOutSide(true)}>
										<button
											onClick={() => {
												if (!currentUser) {
													handleViewModOpen();
													return;
												}
												if (comment.id) {
													setIsMoreOptionsOpen(comment.id);
												}
											}}
										>
											<svg
												width="16"
												height="16"
												viewBox="0 0 16 16"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
												id="option-btn"
											>
												<path
													d="M9.33073 3.99935C9.33073 4.73573 8.73378 5.33268 7.9974 5.33268C7.26102 5.33268 6.66406 4.73573 6.66406 3.99935C6.66406 3.26297 7.26102 2.66602 7.9974 2.66602C8.73378 2.66602 9.33073 3.26297 9.33073 3.99935Z"
													fill={
														isVisible &&
														comment.id &&
														isMoreOptionsOpen[0] === comment.id
															? "#3E85F0"
															: "#16181C"
													}
												/>
												<path
													d="M9.33073 7.99935C9.33073 8.73573 8.73378 9.33268 7.9974 9.33268C7.26102 9.33268 6.66406 8.73573 6.66406 7.99935C6.66406 7.26297 7.26102 6.66602 7.9974 6.66602C8.73378 6.66602 9.33073 7.26297 9.33073 7.99935Z"
													fill={
														isVisible &&
														comment.id &&
														isMoreOptionsOpen[0] === comment.id
															? "#3E85F0"
															: "#16181C"
													}
												/>
												<path
													d="M9.33073 11.9993C9.33073 12.7357 8.73378 13.3327 7.9974 13.3327C7.26102 13.3327 6.66406 12.7357 6.66406 11.9993C6.66406 11.263 7.26102 10.666 7.9974 10.666C8.73378 10.666 9.33073 11.263 9.33073 11.9993Z"
													fill={
														isVisible &&
														comment.id &&
														isMoreOptionsOpen[0] === comment.id
															? "#3E85F0"
															: "#16181C"
													}
												/>
											</svg>
										</button>

										<button
											ref={element}
											onClick={() => {
												currentUser === comment.submitted_by
													? setDeleteModalOpen(true)
													: setReportModalOpen(true);
											}}
											style={{
												boxShadow: `
    												0 0 0 0 rgba(0, 0, 0, 0.1),
    												1px 1px 3px 0 rgba(0, 0, 0, 0.1),
    												4px 4px 6px 0 rgba(0, 0, 0, 0.09),
    												8px 9px 8px 0 rgba(0, 0, 0, 0.05),
    												15px 17px 9px 0 rgba(0, 0, 0, 0.01),
    												23px 26px 10px 0 rgba(0, 0, 0, 0)
  												`,
											}}
											className={`absolute flex gap-[8px] items-center z-[10] left-[-100px] px-[17px] py-[4px] bg-white border-2 border-[#DFE1E5] rounded-[8px] ${
												isVisible &&
												comment.id &&
												isMoreOptionsOpen[0] === comment?.id
													? "block"
													: "hidden"
											}`}
										>
											{currentUser === comment.submitted_by ? (
												<svg
													width="16"
													height="16"
													viewBox="0 0 16 16"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M7.33743 6.91622C7.10162 6.62871 6.67152 6.5821 6.37676 6.8121C6.08201 7.04211 6.03422 7.46164 6.27002 7.74915L7.2954 8.99935L6.27002 10.2496C6.03422 10.5371 6.08201 10.9566 6.37676 11.1866C6.67152 11.4166 7.10162 11.37 7.33743 11.0825L8.17067 10.0665L9.00392 11.0825C9.23972 11.37 9.66983 11.4166 9.96458 11.1866C10.2593 10.9566 10.3071 10.5371 10.0713 10.2496L9.04594 8.99935L10.0713 7.74915C10.3071 7.46164 10.2593 7.04211 9.96458 6.8121C9.66983 6.5821 9.23972 6.62871 9.00392 6.91622L8.17067 7.93216L7.33743 6.91622Z"
														fill="#16181C"
													/>
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M8.85415 0.666016C10.364 0.666016 11.588 1.85992 11.588 3.33268H14.3219C14.6993 3.33268 15.0053 3.63116 15.0053 3.99935C15.0053 4.36754 14.6993 4.66602 14.3219 4.66602H13.6384V11.3327C13.6384 13.1736 12.1084 14.666 10.221 14.666H6.1202C4.23283 14.666 2.70281 13.1736 2.70283 11.3327L2.70288 4.66602H2.01941C1.64194 4.66602 1.33594 4.36754 1.33594 3.99935C1.33594 3.63116 1.64194 3.33268 2.01941 3.33268H4.7533C4.7533 1.85992 5.97731 0.666016 7.4872 0.666016H8.85415ZM6.12025 3.33268C6.12025 2.5963 6.73225 1.99935 7.4872 1.99935H8.85415C9.60909 1.99935 10.2211 2.5963 10.2211 3.33268H6.12025ZM12.2714 4.66602V11.3327C12.2714 12.4373 11.3534 13.3327 10.221 13.3327H6.1202C4.98777 13.3327 4.06977 12.4372 4.06978 11.3327L4.06983 4.66602H12.2714Z"
														fill="#16181C"
													/>
												</svg>
											) : (
												<svg
													width="16"
													height="16"
													viewBox="0 0 16 16"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M4.06983 2C2.55994 2 1.33594 3.19391 1.33594 4.66667V13.3333C1.33594 13.7015 1.64194 14 2.01941 14C2.39688 14 2.70288 13.7015 2.70288 13.3333V9.33333H6.54812C6.85161 10.4835 7.922 11.3333 9.19588 11.3333H12.2715C13.7814 11.3333 15.0054 10.1394 15.0054 8.66667V6C15.0054 4.52724 13.7814 3.33333 12.2715 3.33333H9.76268C9.4812 2.55654 8.7217 2 7.82893 2H4.06983ZM7.14546 8H2.70288V4.66667C2.70288 3.93029 3.31489 3.33333 4.06983 3.33333H7.82893C8.20641 3.33333 8.51241 3.63181 8.51241 4V6.66667C8.51241 7.40305 7.90041 8 7.14546 8ZM9.19588 10C8.64172 10 8.16457 9.67834 7.94997 9.216C9.06722 8.88094 9.87936 7.86632 9.87936 6.66667V4.66667H12.2715C13.0265 4.66667 13.6385 5.26362 13.6385 6V8.66667C13.6385 9.40305 13.0265 10 12.2715 10H9.19588Z"
														fill="#16181C"
													/>
												</svg>
											)}

											{currentUser === comment.submitted_by
												? t("ranking.detailedView.comments.delete")
												: t("ranking.detailedView.comments.report")}
										</button>
									</div>
								</div>
								<div className="text-sm flex gap-[6px] col-start-1 col-end-3 row-start-2 row-end-3">
									{replyTo && !isEditing && (
										<span className="text-[#717171]">
											@
											{replyTo === "deleted" && currentLanguage === "en"
												? "deleted"
												: replyTo === "deleted" && currentLanguage === "en"
												? "gelöscht"
												: replyTo}
										</span>
									)}
									{isEditing ? (
										<CommentInput
											value={value}
											setComment={setValue}
											selectedCard={selectedCard}
											allComments={allComments}
											refetch={refetch}
											handleViewModOpen={handleViewModOpen}
											isEditing={isEditing}
											setIsEditing={setIsEditing}
											commentToEditId={comment.id}
											handleRef={handleRef}
										/>
									) : (
										<p className="max-w-[440px]">{comment.comments}</p>
									)}
								</div>
							</div>
						</div>
					</div>

					{isReplying && (
						<CommentInput
							value={value}
							setComment={setValue}
							selectedCard={selectedCard}
							allComments={allComments}
							refetch={refetch}
							handleViewModOpen={handleViewModOpen}
							isEditing={isEditing}
							setIsEditing={setIsEditing}
							commentToReplyId={comment.id}
							isReplying={isReplying}
							setIsReplying={setIsReplying}
							reply_Comment_SubmittedBy={comment.username}
							handleRef={handleRef}
						/>
					)}
				</>
			)}
			{!!comment.repliesAmount && (
				<div>
					<div className="w-full flex mt-2 ml-1">
						<button
							onClick={displayReplies}
							className="text-[12px] text-[#16181C] flex items-center gap-[8px] poppinsBold"
						>
							{t("ranking.detailedView.comments.replies")}{" "}
							{"(" + comment.repliesAmount + ")"}{" "}
							<img
								className={`${
									showReplies ? "rotate-0" : "rotate-180"
								} transition-all`}
								src={arrow}
								alt="arrow"
							/>
						</button>
					</div>
					<Replies
						showReplies={showReplies}
						replies={comment.replies}
						selectedCard={selectedCard}
						allComments={allComments}
						refetch={refetch}
						handleViewModOpen={handleViewModOpen}
						isMoreOptionsOpen={isMoreOptionsOpen}
						setIsMoreOptionsOpen={setIsMoreOptionsOpen}
					/>
				</div>
			)}
			<ReportModal
				isOpen={reportModalOpen}
				handleClose={handleReportModalClose}
				comment={comment.comments}
				commentId={comment.id}
				spotId={selectedCard.id}
				currentUser={currentUser}
			/>
			<DeletePlaceModal
				isOpen={deleteModalOpen}
				handleClose={handleDeleteModalClose}
				handleDelete={handleDeleteComment}
				deleteComments={true}
			/>
		</div>
	);
};

export default SingleComment;
