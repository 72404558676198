import { useEffect, useLayoutEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Header from "../header/Header";
import MapBox from "./map/MapBox";
import ProjectsModal from "./projectsModal/ProjectsModal";
import Section from "./Section";
import AddPlace from "./appPlace/AddPlace";
import PlaceCreated from "./appPlace/PlaceCreated";
import ParryDetailedView from "./map/ParryDetailedView";
import ClaimTokensWindow from "../../shared/ClaimTokensWindow";
import MobileNoAccess from "../mobileNoAccess/MobileNoAccess";
import OutAreaParry from "./map/OutAreaParry";
import { ClaimVerificationTokensModal } from "../verification/ClaimVerificationTokens";

import { getSpots } from "../../firebase/getSpots";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
	selectSpot,
	setSelectedCard,
	setSpots,
} from "../../redux/api/spotsSlice";
import { setSpotCardView } from "../../redux/api/elementsViewSlice";
import { setVerifyWindow } from "../../redux/api/userSlice";
import {
	zoomDetailedView,
	setPlaceCreationStatus,
} from "../../redux/api/locationSlice";

import useClickOutside from "../../hooks/useClickOutside";
import useIsJoinedToProject from "../../hooks/useIsJoinedToProject";
import { useGetCurrentUser } from "../../hooks/useGetCurrentUser";
import { currentProject } from "../../redux/api/projectCardsSlice";

import { bigArrow } from "../../assets/shared";
import { Project } from "../../interfaces";

const MapPage = () => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const { hasUserJoinedProject, hasUserClaimedVerification } =
		useIsJoinedToProject();
	const { element, isVisible, clickOutSide } =
		useClickOutside<HTMLDivElement>();
	const [searchParams] = useSearchParams();
	const { user } = useGetCurrentUser();

	const [isParentRendered, setIsParentRendered] = useState(false);
	const [isClaimedTokens, setIsClaimedTokens] = useState(false);
	const [openClaimVerifyTokens, setOpenClaimVerifyTokens] = useState(false);

	const hasUserVerified = useAppSelector((state) => state.user.hasUserVerified);
	const spots = useAppSelector((state) => state.spots.spotsData);
	const selectedSpot = useAppSelector((state) => state.spots.selectedSpot);
	const addingPlace = useAppSelector((state) => state.location.addPlace);
	const editPlace = useAppSelector((state) => state.location.editPlace);
	const creationStatus = useAppSelector(
		(state) => state.location.placeCreationStatus
	);
	const clickOutsideArea = useAppSelector(
		(state) => state.projectCards.clickOutsideArea
	);
	const currentSelectedProject: Project | undefined =
		useAppSelector(currentProject);
	// console.log(currentSelectedProject);

	const isOpen = useAppSelector((state) => state.user.openClaimTokensWindow);
	const isMobile = useAppSelector((state) => state.elementsView.isMobile);

	const id = searchParams.get("id");

	useEffect(() => {
		const loadSpots = async () => {
			const spots = await getSpots();
			if (!spots) return;
			dispatch(setSpots(spots));
			setIsParentRendered(true);
		};
		loadSpots();
	}, []);

  useEffect(() => {
    if (creationStatus === "created") {
      const timeId = setTimeout(() => {
        dispatch(setPlaceCreationStatus("idle"));
      }, 2000);

			return () => clearTimeout(timeId);
		}
	}, [creationStatus]);

	useEffect(() => {
		if (isClaimedTokens === true && !hasUserVerified) {
			dispatch(setVerifyWindow({ isOpen: true, openedFrom: "profile" }));
		}
	}, [isClaimedTokens, hasUserVerified]);

	useEffect(() => {
		if (!hasUserClaimedVerification && currentSelectedProject) {
			setOpenClaimVerifyTokens(true);
		}
	}, [currentSelectedProject, currentSelectedProject?.id]);

	useLayoutEffect(() => {
		if (!id) return;
		const selectedSpot = spots.find((spot) => spot.id === id);
		if (!selectedSpot) return;
		dispatch(setSelectedCard(selectedSpot));
		dispatch(selectSpot(selectedSpot));
		dispatch(zoomDetailedView([selectedSpot.latitude, selectedSpot.longitude]));
		dispatch(setSpotCardView(true));
	}, [spots]);

	const handleClaimVerifyTokensClose = () => {
		setOpenClaimVerifyTokens(false);
	};

	return (
		<>
			<Header>
				<div className="flex items-center justify-center text-2xl relative">
					<button onClick={() => clickOutSide(true)} className="flex gap-2">
						{currentSelectedProject && currentSelectedProject.name?.en}
						<span>
							<img src={bigArrow} alt="big-arrow" />
						</span>
					</button>
				</div>
			</Header>
			{isMobile ? (
				<MobileNoAccess />
			) : (
				<>
					{isVisible && (
						<div className="absolute z-[100] flex items-center justify-center w-screen h-screen bg-black/60 top-0 left-0">
							<div ref={element}>
								<ProjectsModal />
							</div>
						</div>
					)}
					<div className="w-screen h-screen pl-12 lg:pl-[80px] pr-10 lg:pr-[72px] pb-8 pt-[130px] gap-8 flex relative">
						<MapBox />
						{(addingPlace && !selectedSpot) || editPlace ? (
							<AddPlace />
						) : creationStatus === "created" ? (
							<PlaceCreated />
						) : (
							<Section />
						)}
						{!hasUserJoinedProject && isParentRendered && (
							<div className="absolute left-0 bottom-0">
								<ParryDetailedView />
							</div>
						)}
						{clickOutsideArea && hasUserJoinedProject && <OutAreaParry />}
					</div>
					{currentSelectedProject && (
						<ClaimTokensWindow
							projectName={currentSelectedProject?.name}
							setIsClaimedTokens={setIsClaimedTokens}
							isOpen={isOpen}
							hasUserVerified={hasUserVerified}
							projectTokens={currentSelectedProject.tokens}
						/>
					)}
					{!hasUserClaimedVerification &&
						currentSelectedProject &&
						hasUserJoinedProject &&
						user &&
						hasUserVerified && (
							<ClaimVerificationTokensModal
								closeVerifyWindow={handleClaimVerifyTokensClose}
								isOpen={openClaimVerifyTokens}
								handleClose={handleClaimVerifyTokensClose}
								verificationTokens={currentSelectedProject.tokens.userVerified}
							/>
						)}
				</>
			)}
		</>
	);
};

export default MapPage;
