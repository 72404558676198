import { useAppSelector } from "../redux/hooks";

interface Props {
	name: "Ranking" | "Activity" | "MySpots" | "Mission";
}
const SpotsNavSvg = ({ name }: Props) => {
	const section = useAppSelector((state) => state.elementsView.section);
	const color = section === name ? "#3A85F4" : "#000000";
	return (
		<>
			{name === "Ranking" ? (
				<svg
					className="-translate-y-[1px]"
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M7.00391 14.899C8.27442 16.1955 10.0452 17 12.0039 17C13.9626 17 15.7334 16.1955 17.0039 14.899M7.00391 14.899C6.91827 14.8116 6.8349 14.722 6.75391 14.6302C5.66477 13.3962 5.00391 11.7753 5.00391 10C5.00391 6.13401 8.13791 3 12.0039 3C15.8699 3 19.0039 6.13401 19.0039 10C19.0039 11.7753 18.343 13.3962 17.2539 14.6302C17.1729 14.722 17.0895 14.8116 17.0039 14.899M7.00391 14.899V19C7.00391 20.3397 8.29538 21.3006 9.5786 20.9157L11.4292 20.3605C11.8041 20.248 12.2037 20.248 12.5786 20.3605L14.4292 20.9157C15.7124 21.3006 17.0039 20.3397 17.0039 19V14.899"
						stroke={color}
						strokeWidth="2"
					/>
					<path
						d="M11.6409 6.75655C11.7858 6.4529 12.2181 6.4529 12.363 6.75655L13.0605 8.21839C13.1188 8.3406 13.235 8.425 13.3692 8.4427L14.9751 8.65438C15.3087 8.69835 15.4422 9.10942 15.1982 9.34105L14.0235 10.4562C13.9253 10.5494 13.8809 10.686 13.9056 10.8191L14.2005 12.4118C14.2617 12.7426 13.912 12.9967 13.6163 12.8362L12.1928 12.0636C12.0738 11.999 11.9302 11.999 11.8111 12.0636L10.3876 12.8362C10.0919 12.9967 9.74218 12.7426 9.80344 12.4118L10.0984 10.8191C10.123 10.686 10.0786 10.5494 9.98043 10.4562L8.80569 9.34105C8.56168 9.10942 8.69524 8.69835 9.0288 8.65438L10.6347 8.4427C10.7689 8.425 10.8851 8.3406 10.9434 8.21839L11.6409 6.75655Z"
						fill={color}
					/>
				</svg>
			) : name === "MySpots" ? (
				<svg
					width="16"
					height="20"
					viewBox="0 0 16 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M12 8.03516C12 10.2443 10.2091 12.0352 8 12.0352C5.79086 12.0352 4 10.2443 4 8.03516C4 5.82602 5.79086 4.03516 8 4.03516C10.2091 4.03516 12 5.82602 12 8.03516ZM10 8.03516C10 9.13973 9.10457 10.0352 8 10.0352C6.89543 10.0352 6 9.13973 6 8.03516C6 6.93059 6.89543 6.03516 8 6.03516C9.10457 6.03516 10 6.93059 10 8.03516Z"
						fill={color}
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M16 8.03516C16 11.0134 13.1309 15.4454 10.8291 18.5219C9.3915 20.4433 6.6085 20.4433 5.17086 18.5219C2.86907 15.4454 0 11.0134 0 8.03516C0 3.06202 3.58172 0.0351562 8 0.0351562C12.4183 0.0351562 16 3.06202 16 8.03516ZM14 8.03516C14 8.99784 13.4955 10.4775 12.5171 12.2928C11.5778 14.0358 10.3494 15.8246 9.22775 17.3237C8.59003 18.1761 7.40996 18.1761 6.77225 17.3237C5.65064 15.8246 4.42216 14.0358 3.48287 12.2928C2.50454 10.4775 2 8.99784 2 8.03516C2 6.05371 2.70067 4.59135 3.72224 3.61872C4.75888 2.63173 6.24749 2.03516 8 2.03516C9.75251 2.03516 11.2411 2.63173 12.2778 3.61872C13.2993 4.59135 14 6.05371 14 8.03516Z"
						fill={color}
					/>
				</svg>
			) : name === "Activity" ? (
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M7 3C8.58271 3 9.95029 3.91886 10.5989 5.25253C11.0352 5.08934 11.5074 5 12 5C12.4926 5 12.9648 5.08934 13.4011 5.25253C14.0497 3.91886 15.4173 3 17 3C19.2091 3 21 4.79086 21 7C21 8.0648 20.5839 9.03243 19.9055 9.74918C21.7509 10.7725 23 12.7404 23 15V15.3333C23 17.3584 21.3584 19 19.3333 19H17.6002C16.9933 20.1872 15.7582 21 14.3333 21H9.66667C8.24177 21 7.00671 20.1872 6.39981 19H4.66667C2.64162 19 1 17.3584 1 15.3333V15C1 12.7404 2.2491 10.7725 4.09447 9.74918C3.41606 9.03243 3 8.0648 3 7C3 4.79086 4.79086 3 7 3ZM5 7C5 5.89543 5.89543 5 7 5C7.91191 5 8.68187 5.6108 8.9222 6.44512C8.40133 7.07219 8.06752 7.8623 8.00917 8.72733C7.71301 8.90092 7.36868 9 7 9C5.89543 9 5 8.10457 5 7ZM15.9908 8.72733C15.9325 7.8623 15.5987 7.07219 15.0778 6.44512C15.3181 5.6108 16.0881 5 17 5C18.1046 5 19 5.89543 19 7C19 8.10457 18.1046 9 17 9C16.6313 9 16.287 8.90092 15.9908 8.72733ZM12.7995 10.8341C12.5559 10.9405 12.2862 11 12 11C11.7138 11 11.4441 10.9405 11.2005 10.8341C10.851 10.6814 10.5521 10.4307 10.34 10.1161C10.1517 9.83687 10.0325 9.50851 10.0057 9.15334C10.0019 9.1029 10 9.05177 10 9C10 8.4105 10.2538 7.88163 10.6612 7.51417C11.0164 7.1938 11.4844 7 12 7C12.5156 7 12.9836 7.1938 13.3388 7.51417C13.7462 7.88163 14 8.4105 14 9C14 9.05177 13.9981 9.1029 13.9943 9.15334C13.9675 9.50851 13.8483 9.83687 13.66 10.1161C13.4479 10.4307 13.149 10.6814 12.7995 10.8341ZM15.1671 11.4435L15.139 11.4795C16.8303 12.2812 18 14.0042 18 16.0004V17H19.3333C20.2538 17 21 16.2538 21 15.3333V15C21 12.7909 19.2091 11 17 11C16.3377 11 15.7154 11.1602 15.1671 11.4435ZM8.86102 11.4795C7.16967 12.2812 6 14.0042 6 16.0004V17H4.66667C3.74619 17 3 16.2538 3 15.3333V15C3 12.7909 4.79086 11 7 11C7.66226 11 8.28463 11.1602 8.83289 11.4435L8.86102 11.4795ZM8 16.0004C8 14.3433 9.34331 13 11.0004 13H12.9996C14.6567 13 16 14.3433 16 16.0004V17.3333C16 18.2538 15.2538 19 14.3333 19H9.66667C8.74619 19 8 18.2538 8 17.3333V16.0004Z"
						fill={color}
					/>
				</svg>
			) : (
				<svg
					width="15"
					height="20"
					viewBox="0 0 15 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M6.54997 16.2033L11.725 10.0033H7.72497L8.44997 4.32826L3.82497 11.0033H7.29997L6.54997 16.2033ZM4.99997 13.0033H1.89997C1.49997 13.0033 1.20414 12.8241 1.01247 12.4658C0.820803 12.1074 0.841636 11.7616 1.07497 11.4283L8.54997 0.678261C8.71664 0.444928 8.9333 0.282428 9.19997 0.190761C9.46664 0.0990942 9.74164 0.103261 10.025 0.203261C10.3083 0.303261 10.5166 0.478261 10.65 0.728261C10.7833 0.978261 10.8333 1.24493 10.8 1.52826L9.99997 8.00326H13.875C14.3083 8.00326 14.6125 8.19493 14.7875 8.57826C14.9625 8.96159 14.9083 9.31993 14.625 9.65326L6.39997 19.5033C6.21664 19.7199 5.99164 19.8616 5.72497 19.9283C5.4583 19.9949 5.19997 19.9699 4.94997 19.8533C4.69997 19.7366 4.50414 19.5574 4.36247 19.3158C4.2208 19.0741 4.16664 18.8116 4.19997 18.5283L4.99997 13.0033Z"
						fill={color}
					/>
				</svg>
			)}
		</>
	);
};

export default SpotsNavSvg;
