import { useEffect, useState } from "react";
import i18next, { t } from "i18next";

export interface InterestDetails {
	id: string;
	icon: string;
	en: string;
	de: string;
}
interface ITopics {
	topics: InterestDetails[];
	handle: (interests: string[]) => void;
	saved: string[];
}

const Interests = ({ topics, handle, saved }: ITopics) => {
	const lang = i18next.language;
	const [selectedTopics, setSelectedTopics] = useState<string[]>([]);
	useEffect(() => {
		saved.length && setSelectedTopics([...saved]);
	}, [saved]);

	const handleSelect = (id: string) => {
		const arr = [...selectedTopics];
		arr.includes(id)
			? setSelectedTopics(arr.filter((item) => item !== id))
			: arr.push(id) && setSelectedTopics(arr);
	};

	useEffect(() => handle(selectedTopics), [selectedTopics]);

	return (
		<div className="flex-col flex w-custom">
			<div className="text-primaryBlack pt-3">{t("editProfile.interests")}</div>
			<div className="text-midGray pt-4">{t("editProfile.question")}</div>
			<div className="flex flex-wrap gap-2 w-[85%] text-primaryBlack pt-4">
				{topics.map(({ id, icon, en, de }: InterestDetails) => {
					const title = lang === "de" ? de : en;
					return (
						<div
							className={`flex cursor-pointer justify-center gap-1 rounded-full text-[12px] py-2 px-3 ${
								selectedTopics.includes(id)
									? "outline outline-2 outline-black"
									: "outline outline-1 outline-lightGray"
							}`}
							key={id}
							onClick={() => {
								handleSelect(id);
							}}
						>
							<div>{icon}</div>
							<div>{title}</div>
						</div>
					);
				})}
			</div>
			<div className="w-custom h-[1px] bg-underline mt-3"></div>
		</div>
	);
};

export default Interests;
