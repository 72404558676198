import arrowDown from "../../assets/shared/arrow-down.svg";

const Arrows = () => {
  return (
    <>
      <div className="-top-2 left-1/2 absolute flex gap-4">
        <img className="translate-y-[10px]" src={arrowDown} alt="" />
        <img src={arrowDown} alt="" />
      </div>
      <div className="top-1/2 -left-8 absolute flex flex-col gap-3 translate-y-5">
        <img src={arrowDown} alt="" />
        <img className="translate-x-2" src={arrowDown} alt="" />
      </div>
      <div
        className="top-1/2 right-2 absolute flex gap-5
      
       -translate-y-10"
      >
        <img src={arrowDown} alt="" />
        <img className="translate-y-1" src={arrowDown} alt="" />
      </div>
    </>
  );
};

export default Arrows;
