import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

const ChangeLanguage = () => {
	const [language, setLanguage] = useState("");
	const { i18n } = useTranslation();

	useEffect(() => {
		const lang = localStorage.getItem("lang");
		if (lang) {
			i18n.changeLanguage(lang);
			setLanguage(lang);
		} else {
			setLanguage(i18n.language);
		}
	}, [i18n.language]);

	const handleLanguageChange = (lang: string) => {
		i18n.changeLanguage(lang);
		localStorage.setItem("lang", i18n.language);
	};
	return (
		<div className="flex text-lg text-gray-200">
			<button
				className={language === "de" ? "text-black" : ""}
				onClick={() => handleLanguageChange("de")}
			>
				DE
			</button>
			<span>/</span>
			<button
				className={language === "en" ? "text-black" : ""}
				onClick={() => handleLanguageChange("en")}
			>
				EN
			</button>
		</div>
	);
};

export default ChangeLanguage;
