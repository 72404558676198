import { useEffect, useState } from "react";
import { Modal } from "../../shared/Modal";
import { User } from "firebase/auth";
import { AuthButton } from "./AuthButton";
import { welcomeBannerIcon, welcomeSmallIcon } from "../../assets/start-page";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../redux/hooks";
import { setOpenGuideWindow } from "../../redux/api/userSlice";
import useIsJoinedToProject from "../../hooks/useIsJoinedToProject";

import parries from "../../assets/detailedView/parries.svg";

interface IProps {
	handleClose: () => void;
	user: User;
	isOpenedFrom: string | null | "Project";
	isOpen: boolean;
	setIsOpen: (arg: boolean) => void;
}

export const WelcomeBanner = ({
	handleClose,
	user,
	isOpenedFrom,
	isOpen,
	setIsOpen,
}: IProps) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const { hasUserJoinedProject } = useIsJoinedToProject();
	const localStorageOpenedFrom = localStorage.getItem("isOpenedFrom");
	const [selectedProject, setSelectedProject] = useState<string | null>(
		localStorage.getItem("selectedProject")
	);
	const [isJoinedToProjects, setIsJoinedToProjects] = useState<string[] | null>(
		localStorage.getItem("userJoinedToProjects")?.split(",") || null
	);

	// Function to handle changes in localStorage
	const updateLocalStorageValues = () => {
		setSelectedProject(localStorage.getItem("selectedProject"));
		setIsJoinedToProjects(
			localStorage.getItem("userJoinedToProjects")?.split(",") || null
		);
	};

	useEffect(() => {
		// Update values when component mounts
		updateLocalStorageValues();

		// Listen for localStorage changes
		window.addEventListener("storage", updateLocalStorageValues);

		// Cleanup listener on component unmount
		return () => {
			window.removeEventListener("storage", updateLocalStorageValues);
		};
	}, []);

	// Effect to run when localStorage values change
	useEffect(() => {
		if (selectedProject && isJoinedToProjects?.includes(selectedProject)) {
			handleClose();
		}
	}, [selectedProject, isJoinedToProjects]);

	useEffect(() => {
		if (isOpenedFrom === "Project") {
			setIsOpen(true);
		}
	}, []);

	return (
		<Modal
			isOpen={isOpen}
			handleClose={handleClose}
			modalContainerClassName={
				"max-w-[1275px] w-[80vw] max-h-[622px] relative pb-20 overflow-y-auto scroll-transparent"
			}
		>
			<div className="max-w-[1080px] w-[90%] my-0 mx-auto pt-6">
				<img className="mx-auto my-10" src={welcomeBannerIcon} alt="main" />
				<h1 className="font-poppinsBold mt-6 w-fit mx-auto text-4xl max-[768px]:text-3xl text-center">{`${t(
					"auth.welcomeBanner.welcome"
				)}, ${user.displayName}!`}</h1>
				<p className="text-center mt-4 max-w-[60%] mx-auto text-lg">
					{t("auth.welcomeBanner.description")}
				</p>
				{(isOpenedFrom === "Project" || localStorageOpenedFrom === "Project") &&
				!hasUserJoinedProject ? (
					<button
						onClick={() => {
							dispatch(setOpenGuideWindow(true));
							handleClose();
						}}
						className="py-[12px] mt-6 mx-auto pl-[75px] pr-[75px] bg-black rounded-[38px] text-white text-base flex items-center gap-[6px] hover:bg-primaryBlack transition-all"
					>
						<img src={parries} alt="parries" />
						{t("ranking.detailedView.parry.buttonText")}
					</button>
				) : (
					<AuthButton
						text={t("auth.welcomeBanner.goToProject")}
						className="mt-6 !w-max mx-auto px-8"
						onClick={handleClose}
					/>
				)}

				<img
					src={welcomeSmallIcon}
					alt="bird"
					className="absolute right-3 bottom-3 max-[650px]:right-0 max-[650px]:bottom-0 max-[650px]:w-20 max-[650px]:h-20"
				/>
			</div>
		</Modal>
	);
};
