import { useTranslation } from "react-i18next";

interface IVotesPopUp {
	background: string;
	voteInfo: number | undefined;
	isVisible: boolean;
	userVotes: number | typeof NaN;
}

const VotesPopUp = ({
	background,
	voteInfo,
	isVisible,
	userVotes,
}: IVotesPopUp) => {
	const { t } = useTranslation();

	const currVotes = isNaN(userVotes) ? 1 : userVotes;

	const votesNum = currVotes && currVotes > 1 ? "mult" : "single";

	if (currVotes === 0) return null;

	return (
		<div
			style={{
				background: background,
				opacity: isVisible ? 1 : 0,
				transition: "opacity 1s ease-out",
				pointerEvents: isVisible ? "auto" : "none",
			}}
			className={`absolute top-[-80px] left-[50%] translate-x-[-50%] w-[130px] rounded-[6px]`}
		>
			<div className="w-full relative p-[8px] flex justify-center flex-col gap-[8px]">
				<div className="text-center text-[12px]">{voteInfo} Token</div>
				<div className="bg-white w-full text-center rounded-[6px] text-[16px]">{`${currVotes} ${t(
					`ranking.detailedView.districtInfo.votes-${votesNum}`
				)}`}</div>
				<div
					style={{
						width: 0,
						height: 0,
						borderLeft: "20px solid transparent",
						borderRight: "20px solid transparent",
						borderTop: `10px solid ${background}`,
						borderTopLeftRadius: "6px",
						borderTopRightRadius: "6px",
						borderBottomLeftRadius: "6px",
						borderBottomRightRadius: "6px",
					}}
					className="absolute bottom-[-9px] left-[50%] translate-x-[-50%]"
				></div>
			</div>
		</div>
	);
};

export default VotesPopUp;
