import {DocumentData} from "@firebase/firestore";
import {convertSpotDate} from "./convertSpotDate";

export const sortByVotes = (spots: DocumentData[]) => {
  const categoriesRank = {
    Dangerous: 3,
    Useful: 2,
    "Bike Friendly": 1,
  };
  return spots.slice().sort((a: DocumentData, b: DocumentData) => {
    if (a.total_votes !== b.total_votes) return b.total_votes - a.total_votes;
    return (
      categoriesRank[b.category as keyof typeof categoriesRank] -
      categoriesRank[a.category as keyof typeof categoriesRank]
    );
  });
};

export const sortByDate = (spots: DocumentData[], recent: boolean) => {
  return spots.slice().sort((a: DocumentData, b: DocumentData) => {
    const firstDate = convertSpotDate(a).getTime();
    const secondDate = convertSpotDate(b).getTime();
    return recent ? secondDate - firstDate : firstDate - secondDate;
  });
};
