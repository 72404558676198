interface IMissionInfo {
	title: string;
	text: string;
}

const MissionInfo = ({ title, text }: IMissionInfo) => {
	return (
		<div className="p-[16px] rounded-[10px] border-2 border-[#e8e8e8] text-[14px]">
			<h4 className="font-poppinsBold">{title}</h4>
			<p>{text}</p>
		</div>
	);
};

export default MissionInfo;
