import ContactSection from "./components/newProject/ContactSection";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./components/startPage/StartPage";
import Projects from "./components/projects/Projects";
import { paths } from "./constants";
import MapPage from "./components/mapbox/MapPage";
import Contact from "./components/contact/Contact";
import Policy from "./components/privacyAndTerms/policy/Policy";
import Terms from "./components/privacyAndTerms/terms/Terms";
import QvInfo from "./components/qv/QvInfo";
import About from "./components/about/About";
import AccountDeletion from "./components/accountDeletion/AccountDeletion";
import { useEffect } from "react";
import { AboutApp, AboutAppDe } from "./components/about/AboutApp";
import EditProfile from "./components/editProfile/EditProfile";

interface IRoute {
	path: string;
	element: JSX.Element;
}

function App() {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	const routes = [
		{ path: paths.startPage, element: <Home /> },
		{ path: paths.projects, element: <Projects /> },
		{ path: "/project/:projectName", element: <MapPage /> },
		{ path: paths.newProject, element: <ContactSection /> },
		{ path: paths.contact, element: <Contact /> },
		{ path: paths.imprint, element: <Contact /> },
		{ path: paths.policy, element: <Policy /> },
		{ path: paths.terms, element: <Terms /> },
		{ path: paths.qv, element: <QvInfo /> },
		{ path: paths.about, element: <About /> },
		{ path: paths.aboutForApp, element: <AboutApp /> },
		{ path: paths.aboutForAppDe, element: <AboutAppDe /> },
		{ path: paths.accountDeletion, element: <AccountDeletion /> },
		{ path: paths.editProfile, element: <EditProfile /> },
	];
	return (
		<div className="font-poppins ">
			<Routes>
				{routes.map((route: IRoute) => (
					<Route key={route.path} path={route.path} element={route.element} />
				))}
			</Routes>
		</div>
	);
}

export default App;
