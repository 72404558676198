import {useTranslation} from "react-i18next";
import Section from "../../Section";

const GDPR = () => {
  const {t} = useTranslation();
  return (
    <Section title={t("policy.gdpr.title")}>
      <p>{t("policy.gdpr.text_1")}</p>
      <p>{t("policy.gdpr.text_2")}</p>
      <ul className="list-disc pl-10">
        <li>{t("policy.gdpr.point_1")}</li>
        <li>{t("policy.gdpr.point_2")}</li>
        <li>{t("policy.gdpr.point_3")}</li>
        <li>{t("policy.gdpr.point_4")}</li>
        <li>{t("policy.gdpr.point_5")}</li>
        <li>{t("policy.gdpr.point_6")}</li>
      </ul>
      <p>{t("policy.gdpr.text_3")}</p>
    </Section>
  );
};

export default GDPR;
