import {useTranslation} from "react-i18next";

interface IModalAbout {
  name: string;
  about: string;
}

const ProfileModalAbout = ({name, about}: IModalAbout) => {
  const {t} = useTranslation();
  return (
    <div className="flex-col w-[18rem] pt-1">
      <p>
        {t("profileModal.about")} {name}
      </p>

      <div className="flex border-b-[1px] text-balance text-sm py-2">
        {about}
      </div>
    </div>
  );
};

export default ProfileModalAbout;
