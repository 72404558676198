import React, { ReactNode, useCallback, useEffect } from "react";
import { createPortal } from "react-dom";
import { closeIcon } from "../assets/shared";
import { twMerge } from "tailwind-merge";

export interface ModalProps {
	children?: ReactNode;
	wrapperId?: string;
	defaultLayout?: boolean;
	handleClose?: () => void;
	isOpen: boolean;
	contentContainerClassName?: string;
	modalContainerClassName?: string;
	modalOverlayClassName?: string;
	authStep?: string;
	closeButtonHidden?: boolean;
}

export const Modal: React.FC<ModalProps> = ({
	children,
	wrapperId = "root",
	defaultLayout = true,
	handleClose,
	isOpen,
	contentContainerClassName,
	modalContainerClassName,
	modalOverlayClassName,
	authStep,
	closeButtonHidden = false,
}) => {
	const handleCloseModal = useCallback(() => {
		if (
			authStep === "googleSignUp" ||
			authStep === "appleSignUp" ||
			authStep === "claimVerificationTokens" ||
			authStep === "tokenGuide"
		) {
			return;
		}
		handleClose?.();
	}, [authStep, handleClose]);

	useEffect(() => {
		const closeOnEscapeKey = (e: KeyboardEvent) =>
			e.key === "Escape" ? handleCloseModal?.() : null;
		document.body.addEventListener("keydown", closeOnEscapeKey);

		return () => {
			document.body.removeEventListener("keydown", closeOnEscapeKey);
		};
	}, [handleCloseModal, authStep]);

	useEffect(() => {
		if (isOpen) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "auto";
		}
	}, [isOpen]);

	const defaultModalLayout = (
		<div
			onClick={handleCloseModal}
			className={twMerge(
				`fixed inset-0 bg-modalOverlay bg-opacity-60 flex items-center justify-center overflow-hidden z-[99] p-6 md:p-10`,
				modalOverlayClassName
			)}
		>
			{defaultLayout ? (
				<div
					className={twMerge(
						`bg-white w-[60vw] min-w-[320px] max-[900px]:w-[75vw] max-w-[566px] rounded-xl p-4 pb-9	flex justify-end items-baseline relative max-h-screen overflow-scroll`,
						modalContainerClassName
					)}
					onClick={(e) => e.stopPropagation()}
				>
					<div className={twMerge(`grow`, contentContainerClassName)}>
						{children}
					</div>
					<button
						onClick={handleCloseModal}
						className={`${
							authStep === "googleSignUp" ||
							authStep === "appleSignUp" ||
							authStep === "claimVerificationTokens" ||
							authStep === "tokenGuide" ||
							closeButtonHidden
								? "hidden"
								: ""
						}  bg-[#f1f1f1] rounded-full h-12 w-12 flex justify-center items-center p-1 absolute top-3	right-3
              `}
					>
						<img src={closeIcon} alt="close" />
					</button>
				</div>
			) : (
				children
			)}
		</div>
	);

	if (!isOpen) {
		return null;
	}

	return createPortal(defaultModalLayout, document.getElementById(wrapperId)!);
};
