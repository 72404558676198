import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {paths} from "../../constants";

const BurgerFooter = () => {
  const {t} = useTranslation();
  return (
    <div className="text-sm border-t-2 border-[#DDDDDD] pt-4">
      <div className="flex justify-between mb-2">
        <Link to={paths.imprint}>{t("footer.links.imprint")}</Link>
        <Link to={paths.terms}>{t("footer.links.terms")}</Link>
      </div>
      <div className="flex justify-between">
        <Link to={paths.policy}>{t("footer.links.policy")}</Link>
        <div>2024 © CitizenTech</div>
      </div>
    </div>
  );
};

export default BurgerFooter;
