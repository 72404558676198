interface Props {
  paragraphs: string[];
}

const TextBlock = ({paragraphs}: Props) => {
  return (
    <div className="flex flex-col gap-5 leading-[22px]">
      {paragraphs.map((paragraph: string) => (
        <p key={paragraph}>{paragraph}</p>
      ))}
    </div>
  );
};

export default TextBlock;
