import { useTranslation } from "react-i18next";
import { AuthStep } from "../../types";

interface IProps {
  handleGoToStep: (newStep: AuthStep) => void;
}

export const SignUpConfirmEmail = ({ handleGoToStep }: IProps) => {
  const { t } = useTranslation();

  return (
    <>
      <h3 className="text-gray-800 text-sm font-medium w-fit uppercase">
        {t("auth.confirmEmail.createAccount")}
      </h3>
      <h2 className="font-semibold text-2xl text-center	my-4 w-fit">
        {t("auth.confirmEmail.completeEmailVerification")}
      </h2>
      <p className="mt-8">{t("auth.confirmEmail.sendDescription")}</p>
      <div className="mt-8">
        <p className="mr-1 whitespace-pre-line">
          {t("auth.confirmEmail.helpDescription")}{" "}
          <a
            className="text-outlineBlue underline"
            href="mailto:team@parry.info"
          >
            team@parry.info
          </a>
        </p>
      </div>
      <p className="text-center mt-8">
        <span className="mr-1">{t("auth.confirmEmail.isRegistered")}</span>
        <button
          className="underline"
          onClick={() => handleGoToStep("loginEmail")}
        >
          {t("header.login")}
        </button>
      </p>
    </>
  );
};
