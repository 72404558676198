import {useTranslation} from "react-i18next";
import PagesContainer from "../../startPage/PagesContainer";
import Section from "../Section";

const Terms = () => {
  const {t} = useTranslation();
  return (
    <PagesContainer title={"Terms & Conditions"}>
      <div className="text-center">
        <Section>
          <p>{t("terms.text_1")}</p>
        </Section>
        <Section title={t("terms.membership.title")}>
          <p>{t("terms.membership.text_1")}</p>
          <p>{t("terms.membership.text_2")}</p>
        </Section>
        <Section title={t("terms.user-content.title")}>
          <p>{t("terms.user-content.text_1")}</p>
        </Section>
        <Section title={t("terms.backups.title")}>
          <p>{t("terms.backups.text_1")}</p>
        </Section>
        <Section title={t("terms.resources.title")}>
          <p>{t("terms.resources.text_1")}</p>
        </Section>
        <Section title={t("terms.prohibited.title")}>
          <p>{t("terms.prohibited.text_1")}</p>
        </Section>
        <Section title={t("terms.intellectual.title")}>
          <p>{t("terms.intellectual.text_1")}</p>
        </Section>
        <Section title={t("terms.liability.title")}>
          <p>{t("terms.liability.text_1")}</p>
        </Section>
        <Section title={t("terms.indemnification.title")}>
          <p>{t("terms.indemnification.text_1")}</p>
        </Section>
        <Section title={t("terms.severability.title")}>
          <p>{t("terms.severability.text_1")}</p>
        </Section>
        <Section title={t("terms.amendments.title")}>
          <p>{t("terms.amendments.text_1")}</p>
          <p>{t("terms.amendments.text_2")}</p>
        </Section>
        <Section title={t("terms.acceptance.title")}>
          <p>{t("terms.acceptance.text_1")}</p>
        </Section>
        <Section title={t("terms.contact.title")}>
          <p>{t("terms.contact.text_1")}</p>
          <p>{t("terms.contact.text_2")}</p>
          <p>{t("terms.contact.text_3")}</p>
        </Section>
      </div>
    </PagesContainer>
  );
};

export default Terms;
