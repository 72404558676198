import { useTranslation } from "react-i18next";
import AppButton from "../../shared/AppButton";
import board from "../../assets/mobileNoAccess/board.svg";

const MobileNoAccess = () => {
	const { t } = useTranslation();
	return (
		<div className="w-screen h-screen mt-[175px] px-[34px]">
			<div className="mb-8">
				<p className="font-poppinsBold text-[38px] leading-[53px]">
					{t("mobileNoAccess.title")}
				</p>
			</div>
			<div className="mb-8">
				<p>
					{t("mobileNoAccess.text_1")}
					<span className="font-poppinsBold">{t("mobileNoAccess.span_1")}</span>
					{t("mobileNoAccess.text_2")}
					<span className="font-poppinsBold">{t("mobileNoAccess.span_2")}</span>
					{t("mobileNoAccess.text_3")} {t("mobileNoAccess.text_4")}
				</p>
			</div>
			<div className="flex justify-center mb-8">
				<img src={board} alt="board-icon" />
			</div>
			<div className="flex gap-4 justify-center">
				<AppButton app={"appstore"} />
				<AppButton app={"google"} />
			</div>
		</div>
	);
};

export default MobileNoAccess;
