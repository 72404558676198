import DetailedViewTitle from "../../../../../shared/DetailedViewTitle";
import ShareLink from "./ShareLink";
import { useTranslation } from "react-i18next";
import { copy } from "../../../../../assets/detailedView/index";
import { useState } from "react";
import CopyMessage from "../../../../../shared/CopyMessage";

interface Props {
	image: string;
	street: string;
}

const ShareSpotWindow = ({ image, street }: Props) => {
	const { t } = useTranslation();

	const [copyMessage, setCopyMessage] = useState(false);

	const copyLink = async () => {
		await navigator.clipboard.writeText(window.location.href);
		setCopyMessage(true);

		setTimeout(() => {
			setCopyMessage(false);
		}, 1500);
	};

	const trimUrl = (str: string) => {
		return str.split("/").slice(2).join("/");
	};

	return (
		<div className="w-[567px] h-[370px] rounded-[10px] bg-white flex justify-center items-center relative">
			<CopyMessage copyMessage={copyMessage} text={t("messages.copy")} />
			<div className=" w-full">
				<div className="mb-6 text-center">
					<DetailedViewTitle>
						{t("ranking.detailedView.shareSpot.title")}
					</DetailedViewTitle>
				</div>
				<div className="px-[86px]">
					<div className="flex gap-2 mb-6 items-center">
						<div className="w-[86px] h-[52px]">
							<img
								className="w-full h-full rounded-md object-cover"
								src={image}
								alt="card"
							/>
						</div>
						<div className="flex flex-col gap-y-1">
							<p className="font-poppinsBold text-[12px]">
								{t("ranking.detailedView.shareSpot.subtitle")}
							</p>
							<p className="text-[10px]">{street}</p>
							<p className="text-[10px]">{trimUrl(window.location.href)}</p>
						</div>
					</div>
					<div className="flex justify-center mt-12">
						<ShareLink onClickHandler={copyLink} image={copy}>
							{t("ranking.detailedView.shareSpot.copy")}
						</ShareLink>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ShareSpotWindow;
