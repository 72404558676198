import {Cookies} from "react-cookie";

type setCookie = (name: string, data: any) => void;
type getCookie = (name: string) => any;
type deleteCookie = (name: string) => void;

export const setCookie: setCookie = (name, data) => {
  const cookie = new Cookies();
  cookie.set(name, data);
};
export const deleteCookie: deleteCookie = (name) => {
  const cookie = new Cookies();
  cookie.remove(name);
};

export const getCookie: getCookie = (name: any) => {
  const cookie = new Cookies();
  return cookie.get(name);
};
