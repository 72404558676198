interface Props {
  imgSrc: string;
  onClickHandler: () => void;
}

const ZoomButon = ({ imgSrc, onClickHandler }: Props) => {
  return (
    <button
      onClick={onClickHandler}
      className="h-1/2 w-full flex justify-center items-center"
    >
      <img src={imgSrc} alt="zoom-in" />
    </button>
  );
};

export default ZoomButon;
