import { Modal } from "../../../shared/Modal";
import { useTranslation } from "react-i18next";

interface IDeletePlaceModal {
	isOpen: boolean;
	handleClose: () => void;
	handleDelete: () => void;
	deleteComments?: boolean;
}

const DeletePlaceModal = ({
	isOpen,
	handleClose,
	handleDelete,
	deleteComments,
}: IDeletePlaceModal) => {
	const { t } = useTranslation();

	return (
		<Modal
			isOpen={isOpen}
			handleClose={handleClose}
			modalContainerClassName={
				"max-w-[710px] relative p-[40px] rounded-xl overflow-visible"
			}
		>
			<div className="max-w-[710px] w-[100%] my-0 mx-auto overflow-auto">
				<div className="mb-[8px] flex items-center gap-[8px]">
					<svg
						width="21"
						height="22"
						viewBox="0 0 21 22"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M9.50224 9.87531C9.14853 9.44404 8.50337 9.37412 8.06124 9.71913C7.6191 10.0641 7.54742 10.6934 7.90113 11.1247L9.43919 13L7.90113 14.8753C7.54742 15.3066 7.6191 15.9359 8.06124 16.2809C8.50337 16.6259 9.14853 16.556 9.50224 16.1247L10.7521 14.6008L12.002 16.1247C12.3557 16.556 13.0008 16.6259 13.443 16.2809C13.8851 15.9359 13.9568 15.3066 13.6031 14.8753L12.065 13L13.6031 11.1247C13.9568 10.6934 13.8851 10.0641 13.443 9.71913C13.0008 9.37412 12.3557 9.44404 12.002 9.87531L10.7521 11.3992L9.50224 9.87531Z"
							fill="#16181C"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M11.7773 0.5C14.0421 0.5 15.8782 2.29086 15.8782 4.5H19.9789C20.5451 4.5 21.0041 4.94772 21.0041 5.5C21.0041 6.05228 20.5451 6.5 19.9789 6.5H18.9537V16.5C18.9537 19.2614 16.6587 21.5 13.8276 21.5H7.67639C4.84533 21.5 2.55032 19.2614 2.55034 16.5L2.55041 6.5H1.52521C0.959002 6.5 0.5 6.05228 0.5 5.5C0.5 4.94772 0.959002 4.5 1.52521 4.5H5.62605C5.62605 2.29086 7.46206 0.5 9.72689 0.5H11.7773ZM7.67647 4.5C7.67647 3.39543 8.59448 2.5 9.72689 2.5H11.7773C12.9097 2.5 13.8277 3.39543 13.8277 4.5H7.67647ZM16.9032 6.5V16.5C16.9032 18.1569 15.5262 19.5 13.8276 19.5H7.67639C5.97775 19.5 4.60074 18.1568 4.60076 16.5L4.60083 6.5H16.9032Z"
							fill="#16181C"
						/>
					</svg>
					<h4 className="font-poppins text-[22px] font-semibold">
						{deleteComments
							? t("addSpot.delete_comments")
							: t("addSpot.delete")}
					</h4>
				</div>
				<div className="mb-[8px]">
					<p className="font-poppins text-[16px] font-semibold">
						{deleteComments
							? t("addSpot.delete_comments_title")
							: t("addSpot.deleteTitle")}
					</p>
				</div>
				<div className="mb-[28px]">
					{!deleteComments && (
						<p className="font-poppins text-[14px] text-[#676A70]">
							{t("addSpot.deleteInfo")}
						</p>
					)}
				</div>
				<div>
					<button
						className="group flex w-full gap-[8px] items-center transition-all justify-center bg-[#D42124] rounded-[30px] py-[14px] text-center text-white font-poppins hover:bg-[#0A0B0E] transition-all"
						type="button"
						onClick={handleDelete}
					>
						<svg
							className="w-5 h-5 fill-current text-white"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 20 20"
						>
							<path d="M8.98149 9.06279C8.69398 8.7034 8.16956 8.64513 7.81018 8.93264C7.45079 9.22015 7.39252 9.74457 7.68003 10.104L8.93024 11.6667L7.68003 13.2295C7.39252 13.5889 7.45079 14.1133 7.81018 14.4008C8.16956 14.6883 8.69398 14.63 8.98149 14.2706L9.99743 13.0007L11.0134 14.2706C11.3009 14.63 11.8253 14.6883 12.1847 14.4008C12.5441 14.1133 12.6023 13.5889 12.3148 13.2295L11.0646 11.6667L12.3148 10.104C12.6023 9.74457 12.5441 9.22015 12.1847 8.93264C11.8253 8.64513 11.3009 8.7034 11.0134 9.06279L9.99743 10.3327L8.98149 9.06279Z" />
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M10.8308 1.25C12.6717 1.25 14.1641 2.74239 14.1641 4.58335H17.4974C17.9576 4.58335 18.3307 4.95645 18.3307 5.41669C18.3307 5.87692 17.9576 6.25002 17.4974 6.25002H16.664V14.5834C16.664 16.8846 14.7986 18.7501 12.4974 18.7501H7.49735C5.19615 18.7501 3.33065 16.8846 3.33067 14.5834L3.33073 6.25002H2.4974C2.03716 6.25002 1.66406 5.87692 1.66406 5.41669C1.66406 4.95645 2.03716 4.58335 2.4974 4.58335H5.83075C5.83075 2.74239 7.32314 1.25 9.1641 1.25H10.8308ZM7.49742 4.58335C7.49742 3.66287 8.24362 2.91667 9.1641 2.91667H10.8308C11.7512 2.91667 12.4974 3.66287 12.4974 4.58335H7.49742ZM14.9974 6.25002V14.5834C14.9974 15.9641 13.8781 17.0834 12.4974 17.0834H7.49735C6.11663 17.0834 4.99733 15.9641 4.99734 14.5834L4.9974 6.25002H14.9974Z"
							/>
						</svg>
						{deleteComments
							? t("addSpot.delete_comments_btn")
							: t("addSpot.delete")}
					</button>
					<button
						className="group flex w-full items-center border-2 border-[#F0F0F0] mt-[16px] transition-all justify-center bg-white rounded-[30px] py-[14px] text-center text-[#16181C] text-[16px] font-poppins hover:border-[#BFC2C7] hover:dg-[#FAFAFA] transition-all"
						type="button"
						onClick={handleClose}
					>
						{t("addSpot.cancel")}
					</button>
				</div>
			</div>
		</Modal>
	);
};

export default DeletePlaceModal;
