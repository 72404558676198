import { useTranslation } from "react-i18next";

import InfoIcon from "../../../assets/shared/IconInformation.svg";

const RestrictionBanner = () => {
	const { t } = useTranslation();

	return (
		<div className="fixed bottom-0 left-0 w-full bg-[#3A85F4] py-[6px] z-50 flex justify-center text-white items-center text-[14px] gap-[10px]">
			<img src={InfoIcon} alt="Info" />
			<p>{t("addSpot.bannerText")}</p>
		</div>
	);
};

export default RestrictionBanner;
