import avatar_1 from "./1.png";
import avatar_2 from "./2.png";
import avatar_3 from "./3.png";
import avatar_4 from "./4.png";
import avatar_5 from "./5.png";
import avatar_6 from "./6.svg";

export const avatars = {
  1: avatar_1,
  2: avatar_2,
  3: avatar_3,
  4: avatar_4,
  5: avatar_5,
  6: avatar_6
};
