import {addDoc, collection, getDocs} from "firebase/firestore";
import {db} from "./firebase";
interface IInitiative {
  initiative: string;
  userName: string;
  email: string;
}
export const addNewInitiative = async (newInitiative: IInitiative) => {
  try {
    const initiatives = collection(db, "initiativeLeads");

    const success = await addDoc(initiatives, newInitiative);

    return {success: success};
  } catch (err) {
    console.log(err);
    return {error: err};
  }
};
