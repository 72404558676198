import { useTranslation } from "react-i18next";

import VerifyIcon from "../../assets/shared/VerifyIcon.png";
import VerifyIconWhite from "../../assets/shared/VerifyIconWhite.png";
import ParryTransparent from "../../assets/shared/parryTransparent.png";
import ParryVisible from "../../assets/shared/parryVisible.png";
import RightArrow from "../../assets/shared/rightArrow.png";

import { VerificationStep } from "../../types";

interface IVerificationAnnounce {
	closeVerifyWindow: () => void;
	handleGoToStep: (arg: VerificationStep) => void;
	openedFrom: "profile" | "voting";
}

const VerificationAnnounce = ({
	closeVerifyWindow,
	handleGoToStep,
	openedFrom,
}: IVerificationAnnounce) => {
	const { t } = useTranslation();

	return (
		<div className=" w-[100%] my-0 mx-auto flex flex-col items-center justify-center gap-[50px] text-white">
			<div className="flex items-center flex-col text-center">
				<div className="flex items-center gap-[15px] mb-[40px]">
					<img src={ParryTransparent} alt="Transparent parry" />
					<img src={RightArrow} alt="arrow icon" />
					<img src={ParryVisible} alt="Visible Arrow" />
				</div>
				<div>
					<h4 className="text-[24px] font-poppins text-center text-[#f1f1f1] max-w-[342px]">
						{openedFrom === "voting"
							? t("verification.announceTitleVoting")
							: t("verification.announceTitle")}
					</h4>
				</div>
			</div>
			<div className="flex flex-col gap-[8px] font-poppinsBold max-w-[375px]">
				<div className="flex items-center gap-[8px] ">
					<img src={VerifyIcon} alt="Verify icon" />
					<span>{t("verification.benefits.first")}</span>
				</div>
				<div className="flex items-center gap-[8px]">
					<img src={VerifyIcon} alt="Verify icon" />
					<span>{t("verification.benefits.second")} - </span>
					<div className="relative">
						<span className="underline cursor-pointer group font-poppins">
							QV
							<span className="absolute font-poppins w-[324px] text-[12px] bg-[#242629] py-[8px] px-[12px] rounded-[5px] top-[-120px] right-[40px] transform scale-0 group-hover:scale-100 transition-transform duration-300 origin-bottom-right">
								{t("verification.announceExplanation")}
							</span>
						</span>
					</div>
				</div>
				<div className="flex items-center gap-[8px]">
					<img src={VerifyIcon} alt="Verify icon" />
					<span>{t("verification.benefits.third")}</span>
				</div>
			</div>
			<div className="flex flex-col gap-[16px] w-full max-w-[312px]">
				<button
					onClick={() => handleGoToStep("chooseMethod")}
					className="flex items-center gap-[8px] bg-black py-[16px] w-full justify-center px-[40px] rounded-[50px]"
				>
					<img src={VerifyIconWhite} alt="Verify icon" />
					<span>{t("verification.submit")}</span>
				</button>
				<button
					onClick={() => closeVerifyWindow()}
					className="text-[14px] underline"
				>
					{t("verification.skip")}
				</button>
			</div>
		</div>
	);
};

export default VerificationAnnounce;
