import { useTranslation } from "react-i18next";

import AreaOutParry from "../../../assets/shared/outAreaParry.png";

const OutAreaParry = () => {
	const { t } = useTranslation();

	return (
		<div className="absolute left-6 bottom-6">
			<div className="relative z-50">
				<div className="cursor-pointer">
					<div
						className={`bg-outAreaWindow p-4 w-[225px] pb-8 px-4 gap-[17px] bg-no-repeat ml-[-20px] text-center font-poppinsBold text-[14px]`}
					>
						<span className="relative w-[208px] text-center Poppins">
							{t("projects.restriction")}
						</span>
					</div>

					<div className="group relative">
						<img src={AreaOutParry} alt="parry-icon" />
					</div>
				</div>
			</div>
		</div>
	);
};

export default OutAreaParry;
