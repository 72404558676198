import {getAuth, onAuthStateChanged, User} from "@firebase/auth";
import {useEffect, useState} from "react";

export const useGetCurrentUser = () => {
  const [user, setUser] = useState<User | null>(null);
  const [initializing, setInitializing] = useState(true);

  useEffect(() => {
    const auth = getAuth();
  
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (initializing) {
        setInitializing(false)
      }

      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, []);

  return {user, initializing};
};
