interface Props {
  children: React.ReactNode;
  margin?: string;
}

const Title = ({ children, margin }: Props) => {
  return (
    <div className={`text-sm z-20 ${margin}`}>
      <p>{children}</p>
    </div>
  );
};

export default Title;
