import { Modal } from "../../shared/Modal";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { LoginForm } from "./LoginForm";
import { ConfirmLoginEmail } from "./ConfirmLoginEmail";
import { AuthOptions } from "./AuthOptions";
import { SignUpConfirmEmail } from "./SignUpConfirmEmail";
import { AuthStep } from "../../types";
import ProviderAuth from "./ProviderAuth";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setAuth } from "../../redux/api/userSlice";

interface IProps {
	openAuthModal: boolean;
	handleClose: () => void;
}

export const AuthModal = ({ openAuthModal, handleClose }: IProps) => {
	const dispatch = useAppDispatch();
	const { authStep = "basic" } = useAppSelector((state) => state.user.auth);

	// const [authStep, setAuthStep] = useState<AuthStep>("basic");
	const modalContentRef = useRef<HTMLDivElement>(null);
	const [modalContentHeight, setModalContentHeight] = useState(0);

	useEffect(() => {
		if (openAuthModal && authStep !== "createAccount") {
			dispatch(setAuth({ authStep: "basic" }));
		}
	}, [openAuthModal]);

	useLayoutEffect(() => {
		openAuthModal &&
			modalContentRef.current &&
			!modalContentHeight &&
			setModalContentHeight(modalContentRef.current?.offsetHeight);
	}, [modalContentHeight, openAuthModal]);

	const handleGoToStep = (newAuthStep: typeof authStep) =>
		dispatch(setAuth({ authStep: newAuthStep }));

	const sharedAuthOptions = (
		<AuthOptions
			authStep={authStep}
			handleGoToStep={handleGoToStep}
			handleClose={handleClose}
		/>
	);

	const variantsLayout: Record<typeof authStep, JSX.Element> = {
		basic: sharedAuthOptions,
		createAccount: sharedAuthOptions,
		signUpConfirm: <SignUpConfirmEmail handleGoToStep={handleGoToStep} />,
		loginEmail: <LoginForm handleGoToStep={handleGoToStep} />,
		loginConfirm: <ConfirmLoginEmail />,
		googleSignUp: <ProviderAuth handleClose={handleClose} provider="Google" />,
		appleSignUp: <ProviderAuth handleClose={handleClose} provider="Apple" />,
	};

	return (
		<Modal
			isOpen={openAuthModal}
			handleClose={handleClose}
			authStep={authStep}
			modalContainerClassName="overflow-y-auto scroll-transparent"
		>
			<div
				className={`max-w-[342px] w-[70%] my-0 mx-auto max-[560px]:max-w-[280px] pt-6 ${
					modalContentHeight && `!h-[${modalContentHeight}px]'`
				}`}
				style={modalContentHeight ? { height: modalContentHeight } : {}}
				ref={modalContentRef}
			>
				{variantsLayout[authStep]}
			</div>
		</Modal>
	);
};
