import { FC } from "react";
import { Modal } from "../../shared/Modal";

const withModal = <P extends object>(Component: FC<P>) => {
	return ({
		isOpen,
		handleClose,
		...props
	}: P & { isOpen: boolean; handleClose: () => void }) => {
		return (
			<Modal
				modalContainerClassName={`relative max-w-[567px] max-h-[622px] h-full overflow-y-auto overflow-x-hidden rounded-[10px] bg-white p-[40px]`}
				isOpen={isOpen}
				handleClose={handleClose}
				closeButtonHidden={true}
				contentContainerClassName={`flex self-center`}
			>
				<Component {...(props as P)} />
			</Modal>
		);
	};
};

export default withModal;
