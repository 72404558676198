import { useTranslation } from "react-i18next";

import phoneIcon from "../../assets/shared/phoneIcon.svg";
import qrIcon from "../../assets/shared/qr_code.svg";
import peopleIcon from "../../assets/shared/people.svg";
import personCheck from "../../assets/shared/person_check.svg";

import { VerificationStep } from "../../types";

interface IChooseMethodWindow {
	handleGoToStep: (arg: VerificationStep) => void;
}

const ChooseMethodWindow = ({ handleGoToStep }: IChooseMethodWindow) => {
	const { t } = useTranslation();

	return (
		<div className="max-w-[312px] w-[100%] my-0 mx-auto flex flex-col gap-[32px]">
			<div>
				<span className="uppercase text-base font-normal mb-[4px]">
					{t("profileModal.step", { step: "1" })}
				</span>
				<h3 className="font-poppinsBold text-[22px]">
					{t("verification.chooseOption.chooseTitle")}
				</h3>
			</div>
			<div className="flex flex-col gap-[16px] w-full">
				<span>{t("verification.chooseOption.chooseMethod")}</span>
				<button
					onClick={() => handleGoToStep("requestSMS")}
					className="flex gap-[12px] items-center border border-[#dedede] rounded-[15px] p-[16px] hover:bg-[#dedede] transition-all"
				>
					<div className="w-[50px] h-[50px] bg-black flex items-center justify-center rounded-full">
						<img src={phoneIcon} alt="phone icon" />
					</div>
					<div className="text-left">
						<h5 className="font-poppinsBold">SMS Code</h5>
						<p className="text-[14px]">
							{t("verification.chooseOption.smsDescription")}
						</p>
					</div>
				</button>
				<div className="w-full relative">
					<button
						disabled={true}
						className="flex gap-[12px] items-center border border-[#dedede] rounded-[15px] p-[16px] w-full"
					>
						<div className="w-[50px] h-[50px] bg-black flex items-center justify-center rounded-full">
							<img src={qrIcon} alt="phone icon" />
						</div>
						<div className="text-left">
							<h5 className="font-poppinsBold">QR Code</h5>
							<p className="text-[14px]">
								{t("verification.chooseOption.qrDescription")}
							</p>
						</div>
					</button>
					<div className="w-full h-full bg-[#FFFFFF] absolute left-0 top-0 opacity-50 rounded-[15px]"></div>
					<span className="text-[10px] absolute top-[8px] right-[8px] bg-[#f1f1f1] py-[4px] px-[8px] rounded-[20px]">
						{t("verification.chooseOption.notAvailable")}
					</span>
				</div>
				<div className="w-full relative">
					<button
						disabled={true}
						className="flex gap-[12px] items-center border border-[#dedede] rounded-[15px] p-[16px] w-full"
					>
						<div className="w-[50px] h-[50px] bg-black flex items-center justify-center rounded-full">
							<img src={peopleIcon} alt="phone icon" />
						</div>
						<div className="text-left">
							<h5 className="font-poppinsBold">Live Meetup</h5>
							<p className="text-[14px]">
								{t("verification.chooseOption.eventDescription")}
							</p>
						</div>
					</button>
					<div className="w-full h-full bg-[#FFFFFF] absolute left-0 top-0 opacity-50 rounded-[15px]"></div>
					<span className="text-[10px] absolute top-[8px] right-[8px] bg-[#f1f1f1] py-[4px] px-[8px] rounded-[20px]">
						{t("verification.chooseOption.notAvailable")}
					</span>
				</div>
				<div className="w-full relative">
					<button
						disabled={true}
						className="flex gap-[12px] items-center border border-[#dedede] rounded-[15px] p-[16px] w-full"
					>
						<div className="w-[50px] h-[50px] bg-black flex items-center justify-center rounded-full">
							<img src={personCheck} alt="phone icon" />
						</div>
						<div className="text-left">
							<h5 className="font-poppinsBold">Social Logins</h5>
							<p className="text-[14px]">
								{t("verification.chooseOption.socialDescription")}
							</p>
						</div>
					</button>
					<div className="w-full h-full bg-[#FFFFFF] absolute left-0 top-0 opacity-50 rounded-[15px]"></div>
					<span className="text-[10px] absolute top-[8px] right-[8px] bg-[#f1f1f1] py-[4px] px-[8px] rounded-[20px]">
						{t("verification.chooseOption.notAvailable")}
					</span>
				</div>
			</div>
		</div>
	);
};

export default ChooseMethodWindow;
