import { setTokens } from "../redux/api/userSlice";
import { useAppDispatch } from "../redux/hooks";
import { updateVoteInfo } from "../utils/createVoteInfo";
import { DocumentData } from "firebase/firestore";
import { updateTokensForSelectedProject } from "../redux/thunks";

interface IHandleAddSpotVote {
	direction: string;
	hasUserVerified: boolean;
	userID: string | undefined;
	tokens: number;
	userCurrTokens: number | null;
	placeData: DocumentData;
	setShowBanner: (arg: boolean) => void;
	setPlaceData: (arg: DocumentData) => void;
}

const useHandleAddSpotVote = () => {
	const dispatch = useAppDispatch();

	const handleVotesChange = ({
		direction,
		hasUserVerified,
		userID,
		tokens,
		userCurrTokens,
		placeData,
		setShowBanner,
		setPlaceData,
	}: IHandleAddSpotVote) => {
		switch (direction) {
			case "increase":
				if (!hasUserVerified) {
					setShowBanner(true);
					break;
				}

				const updateIncreaseVote = updateVoteInfo(
					placeData.votes,
					userID,
					tokens,
					true
				);

				if (
					updateIncreaseVote === null ||
					(updateIncreaseVote && updateIncreaseVote.tokens > tokens)
				) {
					break;
				}
				setPlaceData((prevData: DocumentData) => ({
					...prevData,
					total_votes: prevData.total_votes + 1,
					votes: { ...prevData.votes, [userID as string]: updateIncreaseVote },
				}));

				if (userID && userCurrTokens) {
					const currTokens = tokens
						? userCurrTokens -
						  (updateIncreaseVote?.tokens || 0) +
						  (placeData?.votes[userID]?.tokens || 0)
						: undefined;
					dispatch(updateTokensForSelectedProject(currTokens));
				}

				break;
			case "decrease":
				if (placeData.total_votes === 1) {
					break;
				}
				const updateDecreaseVote = updateVoteInfo(
					placeData.votes,
					userID,
					tokens,
					false
				);
				if (updateDecreaseVote === null) {
					break;
				}
				setPlaceData((prevData: DocumentData) => ({
					...prevData,
					total_votes: prevData.total_votes - 1,
					votes: { ...prevData.votes, [userID as string]: updateDecreaseVote },
				}));

				if (userID && userCurrTokens !== null && updateDecreaseVote) {
					if (userID && userCurrTokens !== null) {
						const currTokens =
							tokens !== null
								? userCurrTokens -
								  (updateDecreaseVote?.tokens || 0) +
								  (placeData?.votes[userID]?.tokens || 0)
								: undefined;
						dispatch(updateTokensForSelectedProject(currTokens));
					}
				}
				break;
			default:
				setPlaceData((prevData: DocumentData) => ({
					...prevData,
				}));
		}
	};

	return { handleVotesChange };
};

export default useHandleAddSpotVote;
