import { useLazyGetLocationQuery } from "../../../redux/api/apiSlice";
import { setAddPlace } from "../../../redux/api/locationSlice";
import { useAppDispatch } from "../../../redux/hooks";

interface IPlaceInput {
	value: string;
	onChange: (arg: string) => void;
	isFetching: boolean;
}

const ChangePlaceInput = ({ value, onChange, isFetching }: IPlaceInput) => {
	const dispatch = useAppDispatch();

	const [getLocations] = useLazyGetLocationQuery();

	const getQueryLocation = async (value: string) => {
		if (value?.length < 3) return;

		const { data } = await getLocations(value);

		if (data) {
			const { features } = data;
			dispatch(setAddPlace(features[0].center as [number, number]));
		}
	};

	return (
		<input
			className="p-[16px] font-poppins w-[100%] border-2 rounded-[8px] text-[#16181C] focus:outline-outlineBlue focus:bg-white focus:placeholder-transparent"
			type="text"
			value={isFetching ? "Fetching..." : value ?? ""}
			disabled={isFetching}
			onChange={(e) => {
				e.preventDefault();
				onChange(e.target.value);
			}}
			onBlur={() => getQueryLocation(value)}
		/>
	);
};

export default ChangePlaceInput;
