import { DocumentData } from "firebase/firestore";
import { getUserById } from "../getUser";
import { db } from "../firebase";
import {
	collection,
	doc,
	setDoc,
	updateDoc,
	serverTimestamp,
	runTransaction,
	arrayUnion,
	deleteDoc,
	deleteField,
} from "firebase/firestore";
import { Comment } from "../../interfaces";
import { getDayMonthYear, getHoursAndMinutes } from "../../utils/getTime";
import { formatCurrentTime } from "../../utils/getTime";
import {
	CommentArgs,
	UpdateCommentArgs,
	ReportCommentArgs,
	ReportComment,
} from "../../interfaces";

export const sortComments = (comments: DocumentData[]) => {
	const newComments = comments.map((comment) => {
		const [day, month, year] = comment.date.split(".");
		const [time, period] = comment.time.split(" ");
		const [hourString, minString] = time.split(":");
		const hour = parseInt(hourString) + (period === "pm" ? 12 : 0);
		comment.dateData = new Date(
			year,
			month - 1,
			day,
			hour,
			minString
		).getTime();
		return comment;
	});
	return newComments.sort((a, b) => a.dateData - b.dateData);
};

export const handleCommentsUser = async (comment: DocumentData) => {
	if (comment.deleted) {
		return {
			...comment,
			username: "deleted",
			name: null,
			comments: null,
		};
	} else {
		const userData = await getUserById(comment.submitted_by);

		return {
			...comment,
			username: userData?.username ?? "deleted",
			name: userData?.name ?? "",
			avatarIndex: (userData?.photoURL || userData?.defaultAvatarIndex) ?? "",
			deleted: !userData,
		};
	}
};

//ADD COMMENTS
export const getNewCommentId = (spotId: string) => {
	const commentDoc = doc(collection(db, "spots", spotId, "comments"));
	return commentDoc.id;
};

export const addComment = async (comment: Partial<Comment>) => {
	if (!comment.id || !comment.spot_id) {
		throw new Error("Comment ID and Spot ID are required");
	}

	const commentDocRef = doc(
		collection(db, "spots", comment.spot_id, "comments"),
		comment.id
	);

	await setDoc(commentDocRef, {
		...comment,
	});

	return comment.id;
};

export const addCommentThunk = async ({
	comment,
	newCommentId,
	pictures,
	parentCommentId,
	reply_Comment_SubmittedBy,
	spotSubmittedBy,
	selectedCard,
	userId,
	commentToReplyId,
}: CommentArgs & {
	spotSubmittedBy: string;
	reply_Comment_SubmittedBy: string;
}) => {
	try {
		await addComment({
			id: newCommentId,
			comments: comment,
			date: getDayMonthYear(),
			time: getHoursAndMinutes(),
			spot_id: selectedCard.id || "",
			submitted_by: userId,
			pictures: pictures,
			parent_comment_id: parentCommentId || "",
			reply_comment_id: parentCommentId ? commentToReplyId || "" : "",
		});
	} catch (err) {
		console.log("Error:", err);
	}
};

export const handleAddComment = async (
	selectedCard: DocumentData,
	allComments: DocumentData[],
	userCommentOnSpot: string,
	commentSubmittedBy: string,
	commentPictures: string[],
	commentToEditId?: string,
	commentToReplyId?: string,
	reply_Comment_SubmittedBy?: string | undefined
) => {
	//edit comment...
	if (commentToEditId) {
		await updateCommentThunk({
			comment: userCommentOnSpot || "",
			commentId: commentToEditId,
			pictures: commentPictures || [],
			selectedCard: selectedCard,
			userId: commentSubmittedBy,
		});

		return;
	}

	//  if the comment has a parent, use that as the parent for the new comment to prevent infinite nesting
	const commentToReply = allComments.find((m) =>
		m.replies.some((d: any) => d.id === commentToReplyId)
	);

	await addCommentThunk({
		newCommentId: getNewCommentId(selectedCard.id) || "",
		comment: userCommentOnSpot || "",
		pictures: commentPictures || [],
		parentCommentId: commentToReply?.id || commentToReplyId || "",
		reply_Comment_SubmittedBy: reply_Comment_SubmittedBy || "",
		spotSubmittedBy: selectedCard?.submitted_by || "",
		selectedCard: selectedCard,
		userId: commentSubmittedBy,
		commentToReplyId: commentToReplyId,
	});
};

// UPDATE COMMENTS
export const updateCommentThunk = async ({
	comment,
	pictures,
	commentId,
	selectedCard,
	userId,
}: UpdateCommentArgs) => {
	try {
		if (userId) {
			await updateComment({
				comments: comment,
				id: commentId,
				spot_id: selectedCard.id || "",
				pictures: pictures,
			});
		}
	} catch (err) {
		console.log("Error:", err);
	}
};

export const updateComment = async (comment: Partial<Comment>) => {
	if (!comment.spot_id || !comment.id) {
		throw new Error("Comment must have a spot_id and id");
	}

	const commentDocRef = doc(
		collection(db, "spots", comment.spot_id, "comments"),
		comment.id
	);

	await updateDoc(commentDocRef, {
		comments: comment.comments,
		pictures: comment.pictures || [],
		updatedOn: serverTimestamp(),
		date: getDayMonthYear(),
		time: getHoursAndMinutes(),
	});
};

// REPORT COMMENTS
export const reportCommentThunk = async ({
	reportType,
	comment,
	commentId,
	currentUser,
	spotId,
}: ReportCommentArgs) => {
	try {
		if (currentUser) {
			await reportComment({
				commentId: commentId,
				comment,
				reportTypes: reportType,
				spotId: spotId || "",
				userId: currentUser,
			});
		}
	} catch (err) {
		console.log("Error:", err);
	}
};

const reportComment = async ({
	spotId,
	commentId,
	comment,
	userId,
	reportTypes,
}: ReportComment) => {
	const spotsRef = collection(db, "spots");
	const commentRef = doc(spotsRef, spotId, "comments", commentId);
	const reportedCommentsRef = doc(db, "reportedComments", commentId);

	try {
		await runTransaction(db, async (transaction) => {
			reportTypes.forEach((reportType) => {
				transaction.update(commentRef, {
					[`reported.${reportType}`]: arrayUnion(userId),
				});

				transaction.set(
					reportedCommentsRef,
					{
						[reportType]: {
							[userId]: formatCurrentTime(),
						},
						status: "New",
						commentText: comment,
						spot: spotId,
					},
					{ merge: true }
				);
			});
		});
	} catch (error) {
		console.error("Error reporting comment:", error);
		throw error;
	}
};

//DELETE COMMENT
export const deleteComment = async (
	commentId: string,
	spotId: string,
	hasChildren = false
) => {
	// console.log(
	// 	"Deleting comment from Firestore",
	// 	commentId,
	// 	"Has replies",
	// 	hasChildren,
	// 	spotId
	// );

	const commentDocRef = doc(
		collection(db, "spots", spotId, "comments"),
		commentId
	);

	await updateDoc(commentDocRef, {
		deleted: true,
	});

	// const commentRef = doc(db, "spots", spotId, "comments", commentId);

	// // if (hasChildren) {
	// await updateDoc(commentRef, {
	// 	// comments: deleteField(),
	// 	// pictures: deleteField(),
	// 	// updatedOn: deleteField(),
	// 	// spot_id: deleteField(),
	// 	// submitted_by: deleteField(),
	// 	// parent_comment_id: deleteField(),
	// 	// reply_comment_id: deleteField(),
	// 	deleted: true,
	// });
	// return;
	// // }

	// await deleteDoc(commentRef);
};
