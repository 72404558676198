import { useTranslation } from "react-i18next";
import ChangePlaceInput from "./ChangePlaceInput";

interface IAddInput {
	strValue: string;
	handleChange: (arg: string) => void;
	isFetching: boolean;
}

const AddInput = ({ strValue, handleChange, isFetching }: IAddInput) => {
	const { t } = useTranslation();

	return (
		<div className="grid grid grid-cols-[140px,_auto] gap-[40px] bg-[#ffffff] p-[16px] rounded-[10px] mb-[16px]">
			<h4 className="text-xl text-[#1a1a1a]">{t("addSpot.location")}</h4>
			<div className="flex gap-4 w-[100%]">
				<ChangePlaceInput
					isFetching={isFetching}
					onChange={handleChange}
					value={strValue}
				/>
			</div>
		</div>
	);
};

export default AddInput;
