interface Props {
  children: React.ReactNode;
  image: string;
  onClickHandler: () => void;
}

const ShareLink = ({children, image, onClickHandler}: Props) => {
  return (
    <button
      onClick={onClickHandler}
      className="w-[186px] h-14 border-2 border-[#E8E8E8] hover:border-black flex items-center rounded-xl relative gap-4 pl-[26px] transition-all duration-300"
    >
      <img src={image} alt="share-link-icon" />
      <div className={`text-[12px]`}>
        <p>{children}</p>
      </div>
    </button>
  );
};

export default ShareLink;
