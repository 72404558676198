import { useState } from "react";

import { useAppDispatch } from "../redux/hooks";

import { setAuth } from "../redux/api/userSlice";

const useRestrictions = () => {
	const [isViewMod, setIsViewMod] = useState(false);
	const dispatch = useAppDispatch();

	const hangleSignIn = () => {
		dispatch(
			setAuth({
				openAuthModal: true,
				authStep: "createAccount",
				isOpenedFrom: null,
			})
		);

		setIsViewMod(false);
	};

	const hangleLogIn = () => {
		dispatch(
			setAuth({ openAuthModal: true, authStep: "basic", isOpenedFrom: null })
		);
		setIsViewMod(false);
	};

	const handleViewModClose = () => {
		setIsViewMod(false);
	};

	const handleViewModOpen = () => {
		setIsViewMod(true);
	};

	return {
		isViewMod,
		hangleSignIn,
		hangleLogIn,
		handleViewModClose,
		handleViewModOpen,
	};
};

export default useRestrictions;
