import {useTranslation} from "react-i18next";
import {high, low, mid} from "../../assets/qv";
import BlockContainer from "./BlockContainer";

const Levels = () => {
  const {t} = useTranslation();
  return (
    <BlockContainer styles="w-full xl:max-w-[630px]">
      <div className="p-7 md:p-[66px] flex md:flex-col gap-x-6">
        <div className="flex flex-col md:flex-row justify-between mb-9 md:w-full gap-y-8 md:gap-y-0">
          <div className="relative flex justify-center items-center w-[145px]">
            <div className="w-[115px] h-[115px] rounded-full bg-white absolute flex flex-col justify-center items-center">
              <p className="text-md font-bold">
                {t("ranking.detailedView.districtInfo.Low")}
              </p>
              <p>{t("qv.levels.low")} 50%</p>
            </div>
            <img src={low} alt="low-level" />
          </div>
          <div className="relative flex justify-center items-center">
            <div className="w-[115px] h-[115px] rounded-full bg-white absolute flex flex-col justify-center items-center">
              <p className="text-md font-bold">
                {t("ranking.detailedView.districtInfo.Middle")}
              </p>
              <p>50% - 80%</p>
            </div>
            <img src={mid} alt="mid-level" />
          </div>
          <div className="relative flex justify-center items-center">
            <div className="w-[115px] h-[115px] rounded-full bg-white absolute flex flex-col justify-center items-center">
              <p className="text-md font-bold">
                {t("ranking.detailedView.districtInfo.High")}
              </p>
              <p>Top 20%</p>
            </div>
            <img src={high} alt="high-level" />
          </div>
        </div>
        <div className="flex gap-y-9 justify-center md:justify-between md:flex-row flex-col">
          <p className="md:w-[230px]">{t("qv.levels.text_1")}</p>
          <p className="md:w-[230px]">
            {t("qv.levels.text_2")}{" "}
            <span className="font-bold">{t("qv.levels.span_1")} </span>{" "}
            <span>{t("qv.levels.text_3")}</span>{" "}
            <span className="font-bold">{t("qv.levels.span_2")}</span>
            <span>{t("qv.levels.span_3")}</span>
          </p>
        </div>
      </div>
    </BlockContainer>
  );
};

export default Levels;
