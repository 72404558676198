interface Props {
  text: string;
  disabled?: boolean;
}
const Button = ({text, disabled}: Props) => {
  const styles = "rounded-full w-full py-4   bg-black text-white border-2";
  const extraStyles = "text-white bg-lightGray border-lightGray text-[#858585]";
  const transition =
    "hover:bg-white hover:text-black border-black transition-all duration-400 text-white";

  return (
    <button
      type="submit"
      className={`${styles} ${disabled ? extraStyles : transition}
    `}
    >
      {text}
    </button>
  );
};

export default Button;
