import { useTranslation } from "react-i18next";

import MissionHeader from "./Mission/MissionHeader";
import MissionInfo from "./Mission/MissionInfo";

const Mission = () => {
	const { t } = useTranslation();

	return (
		<div className="h-full bg-white flex flex-col gap-y-4 relative overflow-y-auto flex-1 scroll-transparent scroll-thin pr-2 pb-8">
			<MissionHeader />
			<MissionInfo
				title={t("ranking.missionInfo.missionFirstTitle")}
				text={t("ranking.missionInfo.missionFirstText")}
			/>
			<MissionInfo
				title={t("ranking.missionInfo.missionSecondTitle")}
				text={t("ranking.missionInfo.missionSecondText")}
			/>
			<MissionInfo
				title={t("ranking.missionInfo.missionThirdTitle")}
				text={t("ranking.missionInfo.missionThirdText")}
			/>
		</div>
	);
};

export default Mission;
