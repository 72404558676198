import useClickOutside from "../hooks/useClickOutside";
import { dropdownIcon } from "../assets/shared/index";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { useEffect, useRef } from "react";
import { rankingStyles } from "../constants";
import { useTranslation } from "react-i18next";
import { setScrollLevel } from "../redux/api/elementsViewSlice";
interface Props {
	handleSelect: (item: any) => void;
	data: any[];
	selectedItem: any;
	containerClass: string;
	title?: string;
	alighWithMap?: boolean;
	paddingTop?: string;
	translate?: string;
	all?: boolean;
}

const Select = ({
	handleSelect,
	data,
	selectedItem,
	title,
	containerClass,
	alighWithMap,
	paddingTop,
	translate,
	all,
}: Props) => {
	const { element, isVisible, clickOutSide } =
		useClickOutside<HTMLDivElement>();
	const dropDownList = useRef<HTMLDivElement>(null);
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const handleItemSelect = (item: any) => {
		handleSelect(item);
		clickOutSide(false);
		dispatch(setScrollLevel(0));
	};
	const mapHeight = useAppSelector((state) => state.map.mapSize.height);

	useEffect(() => {
		if (dropDownList.current && mapHeight && alighWithMap) {
			const difference =
				rankingStyles.listTopPosition.value +
				rankingStyles.dropTitleHeight.value +
				rankingStyles.navbarHeight.value +
				rankingStyles.borderFix;
			dropDownList.current.style.height = mapHeight - difference + "px";
		}
	}, [mapHeight]);

	return (
		<div ref={element} className={`relative ${containerClass}`}>
			<div
				onClick={() => clickOutSide(!isVisible)}
				className={`flex py-[10.5px] px-4 rounded-full h-10 border-2 ${
					isVisible ? "border-outlineBlue" : "border-lightGray"
				}  cursor-pointer justify-between items-center`}
			>
				<div className="text-sm">
					<span>
						{translate
							? t(`${translate}.${selectedItem.sortType}`)
							: selectedItem.name === "All"
							? t("ranking.all")
							: selectedItem.name}
					</span>
				</div>
				<div>
					<img
						className={`transition-all duration-600 ${
							isVisible && "-rotate-180"
						}`}
						src={dropdownIcon}
						alt="dropdown-icon"
					/>
				</div>
			</div>
			<div
				className={`${isVisible ? "absolute" : "hidden"} ${
					rankingStyles.listTopPosition.style
				} left-0 w-full border-outlineBlue border-2 rounded-lg bg-white z-[100] overflow-hidden pr-1`}
			>
				{title && (
					<div
						className={`w-full bg-white ${rankingStyles.dropTitleHeight.style} flex items-center justify-center`}
					>
						{title}
					</div>
				)}
				<div
					ref={dropDownList}
					className={`overflow-y-auto scroll-transparent scroll-thin scroll-thumb-lightgray px-4 pb-4 ${paddingTop}`}
				>
					{data?.map((item: any) => (
						<span
							key={item.name}
							className={`text-sm cursor-pointer flex last:mb-0 mb-4 hover:text-black ${
								selectedItem.name === item.name
									? "text-black font-poppinsBold"
									: "text-midGray"
							}`}
							onClick={() => handleItemSelect(item)}
						>
							{translate
								? t(`${translate}.${item.sortType}`)
								: item.name === "All"
								? t("ranking.all")
								: item.name}
						</span>
					))}
				</div>
			</div>
		</div>
	);
};

export default Select;
