import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { parryDetailed } from "../../../assets/detailedView";
import { useGetCurrentUser } from "../../../hooks/useGetCurrentUser";
import {
	setAuth,
	setOpenJoinProjectWindow,
	setOpenGuideWindow,
} from "../../../redux/api/userSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import ParryDetailedViewModal from "./ParryDetailedViewModal";

import parries from "../../../assets/detailedView/parries.svg";

const ParryDetailedView = () => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const isOpen = useAppSelector((state) => state.user.openJoinProjectWindow);
	const { user } = useGetCurrentUser();

	const [background, setBackground] = useState("");
	const [currLang, setCurrLang] = useState<"de" | "en">("de");

	const handleClose = () => {
		dispatch(setOpenJoinProjectWindow(false));
	};

	const lang = localStorage.getItem("lang");
	useEffect(() => {
		if (lang) {
			setCurrLang(lang as "de" | "en");
		} else {
			setCurrLang("de");
		}
	}, [lang]);

	return (
		<>
			<div className="absolute left-6 bottom-6">
				<div className="relative z-50">
					<div className="cursor-pointer">
						<div
							className={`${
								currLang === "de"
									? "bg-detailedWindowLarge pt-3 w-[246px] pb-5"
									: "bg-detailedWindowBig p-4 w-[236px] pb-6"
							} px-4 gap-[17px] bg-no-repeat ml-5 flex flex-col items-center justify-between text-xs`}
						>
							<span className="relative w-[208px] text-center Poppins">
								{t("ranking.detailedView.parry.message")}
							</span>
							<div className="mb-[25px]">
								<button
									onClick={() => {
										if (!user) {
											dispatch(
												setAuth({
													openAuthModal: true,
													authStep: "basic",
													isOpenedFrom: "Project",
												})
											);
											localStorage.setItem("isOpenedFrom", "Project");
											return;
										}
										dispatch(setOpenGuideWindow(true));
									}}
									className="py-[12px] pl-[15px] pr-[24px] bg-black rounded-[38px] text-white text-base flex items-center gap-[6px] hover:bg-primaryBlack transition-all"
								>
									<img src={parries} alt="parries" />
									{t("ranking.detailedView.parry.buttonText")}
								</button>
							</div>
						</div>

						<div className="group relative">
							<img src={parryDetailed} alt="parry-icon" />
						</div>
					</div>
				</div>
			</div>
			<ParryDetailedViewModal isOpen={isOpen} handleClose={handleClose} />
		</>
	);
};

export default ParryDetailedView;
