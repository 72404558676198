import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "../../shared";
import { AuthButton } from "./AuthButton";
import {
	query,
	collection,
	where,
	getDocs,
	doc,
	updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { useForm } from "react-hook-form";
import { ISignUp } from "../../interfaces";
import { useDebounce } from "../../hooks/useDebounce";
import { useDebounceCallback } from "../../hooks/useDebounceCallback";
import { signUpSchema } from "../../utils/validation";
import { useGetCurrentUser } from "../../hooks/useGetCurrentUser";
import { useNavigate } from "react-router-dom";
import { paths } from "../../constants";
import { getAuth, updateProfile } from "firebase/auth";

interface IProps {
	provider?: "Google" | "Apple";
	handleClose: () => void;
}

const ProviderAuth = ({ provider, handleClose }: IProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [availableUserName, setAvailableUserName] = useState(false);
	const [userNameFocusStatus, setUserNameFocusStatus] = useState(false);
	const [loading, setLoading] = useState(false);
	const { user } = useGetCurrentUser();
	const auth = getAuth();
	const userID = user?.uid;

	const {
		register,
		formState: { errors },
		watch,
		setError,
	} = useForm<ISignUp>({
		mode: "all",
		values: { userName: "", email: "" },
		shouldUnregister: false,
	});

	// const { displayName } = watch();
	const { userName } = watch();
	// localStorage.setItem("userNameValue", userName);
	// localStorage.setItem("isUserNameConfirmed", "false");
	const debouncedValidateUserName = useDebounceCallback(
		async (value: string) => {
			try {
				await signUpSchema.validateAt("userName", { userName: value });
				setError("userName", {});
				return true;
			} catch (error) {
				setError("userName", { message: (error as Error)?.message });
			}
		}
	);

	const checkUsernameAvailability = useCallback(async () => {
		try {
			const q = query(
				collection(db, "users"),
				where("username", "==", userName.toLowerCase())
			);
			const querySnapshot = await getDocs(q);
			return querySnapshot.empty;
		} catch (error) {
			console.error("Error checking username availability:", error);
			return false;
		}
	}, [userName]);

	const validateUsername = useCallback(async () => {
		const isAvailable = await checkUsernameAvailability();
		if (!userName) {
			return;
		}
		if (isAvailable) {
			setAvailableUserName(true);
			return;
		}

		setAvailableUserName(false);
		setError("userName", {
			type: "manual",
			message: t("auth.signUp.userRegistered"),
		});
	}, [checkUsernameAvailability, setError, t, userName]);

	const debouncedUserName = useDebounce(userName);

	useEffect(() => {
		setAvailableUserName(false);
	}, [userName]);

	useEffect(() => {
		const validateUserNameAndCheckAvailability = async () => {
			const isValid = await debouncedValidateUserName(debouncedUserName);

			if (isValid) {
				validateUsername();
			}
		};

		validateUserNameAndCheckAvailability();
	}, [debouncedUserName]);

	const generateFieldCondition = <T, K, D>({
		errorPresent,
		basic,
		defaultRes,
	}: {
		errorPresent: T;
		basic: K;
		defaultRes?: D;
	}) => {
		const error = errors.userName?.message;
		if (debouncedUserName && error) return errorPresent;
		if (!userNameFocusStatus)
			return defaultRes !== undefined ? defaultRes : basic;
		return basic;
	};

	const handleAddDisplayName = async () => {
		if (provider === "Apple") {
			localStorage.setItem("isUserNameConfirmed", "true");
			const auth = getAuth();
			const name = auth.currentUser?.displayName;
			user && !name && (await updateProfile(user, { displayName: userName }));
		}
	};

	const handleAddUserName = async () => {
		if (!userID) return;
		localStorage.setItem("isUserNameConfirmed", "true");
		const userRef = doc(db, "users", userID);
		await updateDoc(userRef, { username: userName });
		// navigate(paths.projects);
	};

	return (
		<form
			onSubmit={(e) => {
				e.preventDefault();
				provider === "Apple" && handleAddDisplayName();
				handleAddUserName();
				handleClose();
			}}
		>
			<div>
				<h3 className="font-medium text-sm ">{t("auth.signUp.oneMore")}</h3>
				<h2 className="font-semibold text-2xl	mb-6">
					{t("auth.signUp.create")}
				</h2>
				<label>
					{/* {provider === "Apple" && (
            <div className="mt-10">
              <p className="">{t("auth.signUp.enterName")}</p>
              <Input
                placeholder=""
                className="!pl-2 border border-solid border-gray-300 rounded-md py-1 mt-2 mb-0 h-10"
                error=""
                register={register}
                name="displayName"
                type="text"
              />
            </div>
          )} */}
					<div className="mt-10">
						<p className="">{t("auth.signUp.enterUserName")}</p>
						<Input
							placeholder=""
							className={`!pl-2 border border-solid border-gray-300 rounded-md py-1 mt-2 mb-0 h-10 ${generateFieldCondition(
								{
									errorPresent: "border-2 !border-error",
									basic: "!border-gray-300",
								}
							)}`}
							error={generateFieldCondition({
								errorPresent: errors.userName?.message,
								basic: availableUserName ? t("auth.signUp.avalaible") : " ",
								defaultRes: " ",
							})}
							setFocusStatus={setUserNameFocusStatus}
							register={register}
							name="userName"
							type="text"
							alarmClassName={`justify-end !bg-white text-error h-6 pt-0 ${generateFieldCondition(
								{
									errorPresent: "text-error",
									basic: "text-midGreen",
								}
							)}`}
						/>
					</div>
				</label>
				<AuthButton
					className="mt-28"
					isLoading={loading}
					disabled={!!errors.userName?.message || !availableUserName}
					text={t("auth.signUp.join")}
				/>
			</div>
		</form>
	);
};

export default ProviderAuth;
