import { useTranslation } from "react-i18next";
import { useDebounce } from "../../../hooks/useDebounce";
import { useState, useEffect } from "react";

interface IAddDescription {
	handleChange: (arg: string) => void;
	value: string;
}

const AddDescription = ({ handleChange, value }: IAddDescription) => {
	const { t } = useTranslation();
	const [inputValue, setInputValue] = useState(value);
	const debouncedInputValue = useDebounce(inputValue, 600);

	useEffect(() => {
		handleChange(debouncedInputValue);
	}, [debouncedInputValue]);

	const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		setInputValue(event.target.value);
	};

	return (
		<div className="grid grid-cols-[140px,_auto] gap-[40px] bg-[#ffffff] p-[16px] rounded-[10px] mb-[16px]">
			<div>
				<h4 className="text-xl text-[#1a1a1a]">{t("addSpot.description")}</h4>
				<span className="text-[#676A70] poppins text-[12px] mb-[16px] block">
					{t("addSpot.description_optional")}
				</span>
				<p className="text-midGray poppins text-sm max-w-[100px] block">
					{t("addSpot.description_text")}
				</p>
			</div>
			<div className="relative">
				<textarea
					id="name"
					className="h-[120px] outline outline-2 outline-lightGray w-full rounded-[8px] bg-white p-3 pb-5 placeholder-midGray focus:outline-outlineBlue focus:bg-white focus:placeholder-transparent resize-none"
					maxLength={560}
					value={inputValue}
					onChange={handleInputChange}
				/>
				<span className="absolute bottom-[8px] right-[16px] text-[12px] text-[#676A70] bg-white">
					{inputValue.length}/560
				</span>
			</div>
		</div>
	);
};

export default AddDescription;
