import {
  phoneScreen1,
  phoneScreen2,
  phoneScreen3,
} from "../../assets/start-page";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/swiper.min.css";
import {useRef, useState} from "react";
import SwiperCore from "swiper";

const PhoneSlider = () => {
  const screens = [phoneScreen1, phoneScreen2, phoneScreen3];
  const [activeScreen, setActiveScreen] = useState(0);
  const swiperRef = useRef<SwiperCore | null>(null);

  const handleScreens = (index: number) => {
    swiperRef.current?.slideTo(index);
  };

  return (
    <Swiper
      onSlideChangeTransitionStart={(swiper) =>
        setActiveScreen(swiper.activeIndex)
      }
      onInit={(swiper) => {
        swiperRef.current = swiper;
      }}
      style={{margin: 0}}
      className="lg:w-[515px] w-[calc(100vw-60px)] flex order-2"
      slidesPerView={1}
    >
      {screens.map((screen: string) => (
        <SwiperSlide key={screen}>
          <div className="w-full flex justify-center">
            <img
              className="w-[248px] h-[500px] lg:order-1"
              width="515px"
              height="711"
              src={screen}
              alt="phone-screen"
            />
          </div>
        </SwiperSlide>
      ))}
      <div className="flex gap-2 w-full justify-center pt-8">
        {screens.map((_screen: string, index: number) => (
          <button
            key={index}
            onClick={() => handleScreens(index)}
            className={`w-4 h-4 rounded-full cursor-pointer ${
              index === activeScreen ? "bg-[#ADE190]" : "bg-[#D9D9D9]"
            }`}
          ></button>
        ))}
      </div>
    </Swiper>
  );
};

export default PhoneSlider;
