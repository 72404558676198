import {useTranslation} from "react-i18next";
import PagesContainer from "../../startPage/PagesContainer";
import Section from "../Section";
import {
  Collection,
  Automatic,
  ChildrenPol,
  Processing,
  Managing,
  Disclosure,
  Retention,
  Analytics,
  Firebase,
  Push,
  Resources,
  GDPR,
  GDPRRights,
  Exercising,
  Security,
  DataBreach,
  Amendments,
  Acceptance,
  Contacting,
} from "./paragraphs/index";

const Policy = () => {
  const {t} = useTranslation();
  return (
    <PagesContainer title={"Privacy Policy"}>
      <div className="text-center">
        <Section>
          <p>{t("policy.text_1")}</p>
          <p>{t("policy.text_2")}</p>
        </Section>
        <Automatic />
        <Collection />
        <ChildrenPol />
        <Processing />
        <Managing />
        <Disclosure />
        <Retention />
        <Analytics />
        <Firebase />
        <Push />
        <Resources />
        <GDPR />
        <GDPRRights />
        <Exercising />
        <Security />
        <DataBreach />
        <Amendments />
        <Acceptance />
        <Contacting />
      </div>
    </PagesContainer>
  );
};

export default Policy;
