import { useTranslation } from "react-i18next";
import Footer from "../Footer";
import Header from "../header/Header";

import {
  arrowPointer,
  stepFour,
  stepOne,
  stepThree,
  stepTwo,
  stepOneDe,
  stepTwoDe,
  stepThreeDe,
  stepFourDe,
} from "../../assets/account-deletion";
import React from "react";

const AccountDeletion = () => {
  const { t, i18n } = useTranslation();

  const currentLang = i18n.language;

  const steps = [
    {
      header: t("account-deletion.steps.one"),
      image: currentLang === "de" ? stepOneDe : stepOne,
    },
    {
      header: t("account-deletion.steps.two"),
      image: currentLang === "de" ? stepTwoDe : stepTwo,
    },
    {
      header: t("account-deletion.steps.three"),
      image: currentLang === "de" ? stepThreeDe : stepThree,
    },
    {
      header: t("account-deletion.steps.four"),
      image: currentLang === "de" ? stepFourDe : stepFour,
    },
  ];

  return (
    <>
      <Header />

      <div className="flex px-20 mt-48 mb-20">
        <div className="max-w-[1280px]">
          <h2 className="text-2xl font-medium text-black">{t("account-deletion.header")}</h2>

          <div className="flex flex-col mt-16 lg:flex-row gap-x-5 gap-y-5 lg:gap-y-0">
            {steps.map((step, index) => {
              return (
                <React.Fragment key={index}>
                  <div className="flex flex-col">
                    <h2 className="mb-5 text-lg font-light text-gray-700">{step.header}</h2>
                    <img
                      className="w-[248px] h-[500px]"
                      width="515px"
                      height="711"
                      src={step.image}
                      alt="phone-screen"
                    />
                  </div>
                  {index !== steps.length - 1 && <img src={arrowPointer} className="w-8 rotate-90 lg:rotate-0 lg:w-auto" alt="pointer" />}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AccountDeletion;
